import service from '@/store/services/limittaxs-service';

const state = {};

const mutations = {};

const actions = {
  async listYear({ commit, dispatch }, params) {
    return await service.listYear(params);
  },
  async listMonth({ commit, dispatch }, params) {
    return await service.listMonth(params);
  },
  async listEmp({ commit, dispatch }, params) {
    return await service.listEmp(params);
  },
  async listLimitTaxEmp({ commit, dispatch }, params) {
    return await service.listLimitTaxEmp(params);
  },
  async add({ commit, dispatch }, params) {
    return await service.add(params);
  },
};

const getters = {};

const limittaxs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default limittaxs;
