<template>
  <md-dialog
    :md-active.sync="updatedShow"
    :md-fullscreen="false"
    style="max-height: 300px; max-width: 500px; min-height: 25%; min-width: 30%;"
  >
    <md-dialog-title align="center" style="font-size:1.8em">
      Private Code ?
    </md-dialog-title>
    <md-content align="center">
      <input-private
        @resultPrivateCode="resultPrivateCode($event)"
        ref="inputprivate"
      ></input-private>
    </md-content>
    <md-dialog-actions class="center">
      <md-button class="md-button md-success" @click="securePrivatecode()">
        Yes, Approve it!
      </md-button>
      <md-button class="md-button md-danger" @click="closeModal()">
        Close
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>
<script>
import Swal from 'sweetalert2';
import InputPrivateCode from '@/pages/Dashboard/Components/InputPrivateCode';
// import SignaturePad from 'signature_pad';
export default {
  name: 'BoxAppved',
  components: {
    'input-private': InputPrivateCode,
  },
  data() {
    return {
      updatedShow: false,
    };
  },
  created() {},
  updated: () => {},
  mounted: () => {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  watch: {
    show(v) {
      this.updatedShow = v;
    },
    updatedShow(v) {
      this.$emit('update-show', v);
    },
  },
  methods: {
    async resultPrivateCode(res) {
      if (res != 'empty') {
        if (res == true) {
          this.callback();
        } else {
          Swal.fire('กรุณากรอก Private Code ให้ถูกต้อง!', '', 'warning');
        }
        this.updatedShow = false;
      }
    },
    async callback() {
      this.$emit('callback');
    },
    async securePrivatecode() {
      await this.$refs.inputprivate.accept();
    },
    closeModal() {
      this.updatedShow = false;
    },
  },
};
</script>
<style lang="scss"></style>
