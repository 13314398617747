<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" v-show="!showdefaultavatar" />
      <img :src="defaultavatar" v-show="showdefaultavatar" />
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span>
          {{ title }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <a @click="goToProfile">
                  <span class="sidebar-mini"></span>
                  <span class="sidebar-normal">
                    <md-icon>
                      person
                    </md-icon>
                    My Profile
                  </span>
                </a>
              </li>
              <li>
                <a @click="resetPassword">
                  <span class="sidebar-mini"></span>
                  <span class="sidebar-normal">
                    <md-icon>
                      lock_reset
                    </md-icon>
                    Reset password
                  </span>
                </a>
              </li>
              <li>
                <a @click="logout" icon="account_box">
                  <span class="sidebar-mini"></span>
                  <span class="sidebar-normal">
                    <md-icon>
                      logout
                    </md-icon>
                    Logout
                  </span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import { createElement } from '@fullcalendar/core';

export default {
  data() {
    return {
      isClosed: true,
      title: 'Profile',
      rtlTitle: '',
      avatar: '',
      defaultavatar:
        process.env.VUE_APP_APP_BASE_URL + '/img/default-avatar.png',
      showdefaultavatar: 0,
    };
  },

  async created() {
    this.$store.watch(
      () => this.$store.getters['profile/me'],
      async (me) => {
        this.title = me.employees.first_nameen + ' ' + me.employees.last_nameen;
        this.avatar = process.env.VUE_APP_IMAGE_BASE_URL + me.profile_image;
        var img = createElement('img');
        img.src = process.env.VUE_APP_IMAGE_BASE_URL + me.profile_image;
        global = this;
        img.onerror = function() {
          global.showdefaultavatar = 1;
        };
        localStorage.setItem('roles', JSON.stringify(me.roles));
        localStorage.setItem('employees', JSON.stringify(me.employees));
        localStorage.setItem('branchs', JSON.stringify(me.employees.branchs));
        // localStorage.setItem('permissions', JSON.stringify(permissions));
        //localStorage.setItem("privatecode", "");
      },
    );
    // await this.$store.dispatch('profile/me');
  },

  methods: {
    clicked: function(e) {
      e.preventDefault();
    },
    toggleMenu: function() {
      this.isClosed = !this.isClosed;
    },
    goToProfile() {
      this.$router.push({ name: 'User Profile' });
    },
    async logout() {
      const confirmation = await Swal.fire({
        title: 'Logout ?',
        showCancelButton: true,
        confirmButtonText: 'Yes, Logout!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'md-button md-success',
          cancelButton: 'md-button md-danger',
        },
      });

      if (confirmation.value === true) {
        localStorage.setItem('privatecode', '');
        this.$store.dispatch('logout');
      }
    },
    resetPassword() {
      this.$router.push({ name: 'Reset Password' });
    },
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
