<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <h5><label>Username : </label>{{ user.emp_id }}</h5>
        </div>
      </div>
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="user.password"
            rules="required|confirmed:user.password_confirmation"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>Password</label>
              <md-input
                v-model="user.password"
                type="password"
                :disabled="isupdate"
              />
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            vid="user.password_confirmation"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>Confirm Password</label>
              <md-input
                v-model="user.password_confirmation"
                type="password"
                :disabled="isupdate"
              />
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';

extend('required', required);
extend('confirmed', confirmed);
export default {
  data() {
    return {
      //user: Object,
      available_branches: [],
      username: null,
      isupdate: true,
    };
  },
  props: {
    user: Object,
  },
  mounted() {
    this.isupdate = false;
  },
  // watch: {
  //   user: function() {
  //     this.isupdate = false;
  //   },
  // },
  beforeDestroy() {
    this.available_branches.destroy;
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        if (!res) {
          return;
        }
        this.$emit('on-validated', res);
        return res;
      });
    },
  },
};
</script>
<style lang="scss">
.spacing {
  padding-bottom: 1rem;
}
</style>
