<template>
  <div>
    <!--ไดอะลอก add-->
    <md-dialog
      style="min-height: 40%; min-width: 50%;"
      :md-active.sync="add_setting"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.6rem" align="center">
        Add
      </md-dialog-title>
      <md-content align="left">
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label style="margin-top:1.3rem; text-align: right;">เลือก: </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
            align="center"
          >
            <md-field class="searchbranchs">
              <label>สถานที่</label>
              <md-select v-model="otTypeflat_rate" name="otTypeflat_rate">
                <md-option
                  v-for="item in flat_rate_list"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.otType_name }}
                </md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label style="margin-top:1.3rem; text-align: right;"
              >ชื่อทรีทเม้นท์:
            </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
            align="center"
          >
            <md-field>
              <label>กรอกข้อมูล </label>
              <md-input
                class="md-size-50 searchID"
                v-model="treatmentName"
              ></md-input>
            </md-field>
          </div>
        </div>
      </md-content>
      <md-dialog-actions>
        <div class="md-layout spacing md-alignment-center">
          <md-button class="md-success md-wd md-sm" @click="insert_data()"
            >บันทึก</md-button
          >
        </div>
      </md-dialog-actions>
    </md-dialog>
    <!--ปิดไดอะลอก add-->
    <!--ไดอะลอก edit-->
    <md-dialog
      style="min-height: 40%; min-width: 50%;"
      :md-active.sync="edit_setting"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.6rem" align="center">
        Add
      </md-dialog-title>
      <md-content align="left">
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label style="margin-top:1.3rem; text-align: right;">เลือก: </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
            align="center"
          >
            <md-field class="searchbranchs">
              <label>สถานที่</label>
              <md-select v-model="edit_ot_flat_rate_id" name="otTypeflat_rate">
                <md-option
                  v-for="item in flat_rate_list"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.otType_name }}
                </md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label style="margin-top:1.3rem; text-align: right;"
              >ชื่อทรีทเม้นท์:
            </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
            align="center"
          >
            <md-field>
              <label>กรอกข้อมูล </label>
              <md-input
                class="md-size-50 searchID"
                v-model="edit_treatmentName"
              ></md-input>
            </md-field>
          </div>
        </div>
      </md-content>
      <md-dialog-actions>
        <div class="md-layout spacing md-alignment-center">
          <md-button class="md-success md-wd md-sm" @click="edit_data()"
            >บันทึก</md-button
          >
        </div>
      </md-dialog-actions>
    </md-dialog>
    <!--ปิดไดอะลอก edit-->
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card-content>
          <div class="add-memo" style="text-align: right;">
            <md-button class="md-primary md-dense" @click="add_setting = true">
              เพิ่ม TREATMENT
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-30 md-medium-size-35 md-small-size-35 md-xsmall-size-100"
            style="text-align: right;"
            :description="searchTreatment"
          >
            <md-field>
              <label>ค้นหา ทรีทเม้นท์</label>
              <md-input
                v-model="search_treatment"
                class="md-size-50 searchID"
              ></md-input>
            </md-field>
          </div>
          <br />
          <md-table
            :value="showSetTreatment"
            class="paginated-table table-striped table-hover"
            table-header-color="green"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="สถานที่">
                <span class="upfont">
                  {{ item.otType_name }}
                </span>
              </md-table-cell>
              <md-table-cell class="backwhite" md-label="ชื่อทรีทเม้นท์">
                <span class="upfont">
                  {{ item.treatment_name }}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Action">
                <md-button
                  class="md-warning md-just-icon md-round"
                  style="margin: 0.2rem"
                  @click="goto_edit_data(item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-rose md-just-icon md-round"
                  style="margin: 0.2rem"
                  @click="goto_delete(item)"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import $ from 'jquery';
import Vuex from 'vuex';
import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {},
  data() {
    return {
      add_setting: false,
      edit_setting: false,
      otTypeflat_rate: '',
      treatmentName: '',
      edit_otTypeflat_rate: '',
      edit_treatmentName: '',
      edit_treatment_id: '',
      edit_ot_flat_rate_id: '',
      search_treatment: '',
    };
  },
  async created() {},
  props: {
    flat_rate_list: {
      type: Array,
      default: () => {},
    },
    available_Treatment: {
      type: Array,
      default: () => {},
    },
  },
  computed: {
    key_search_treatment() {
      return this.search_treatment.trim().toLowerCase();
    },
    showSetTreatment() {
      const search_treatment = this.key_search_treatment;
      if (search_treatment) {
        return this.available_Treatment.filter(
          (ssd) =>
            ssd.treatment_name.toLowerCase().indexOf(search_treatment) > -1,
        );
      }
      return this.available_Treatment;
    },
    searchTreatment() {
      if (this.search_treatment && this.showSetTreatment.length === 0) {
        return 'There are no tags matching your search criteria';
      }
      return '';
    },
  },
  methods: {
    async insert_data() {
      let data_add = {};
      if (!this.otTypeflat_rate) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสถานที่');
        return false;
      }
      if (!this.treatmentName) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกข้อมูล');
        return false;
      }
      data_add['ot_flat_rate_id'] = this.otTypeflat_rate;
      data_add['treatment_name'] = this.treatmentName;
      let resp = await this.$store.dispatch(
        'otsflatrate/addTreatment',
        data_add,
      );
      if (resp == 0) {
        await this.$store.dispatch('alerts/error', 'มีรายการนี้ในระบบแล้ว!');
        this.$emit('getTreatment');
        return false;
      } else if (resp == 1) {
        await this.$store.dispatch('alerts/success', 'บันทึกสำเร็จ');
        this.add_setting = false;
        this.$emit('getTreatment');
        this.otTypeflat_rate = '';
        this.treatmentName = '';
      }
    },
    async goto_edit_data(data) {
      this.edit_treatment_id = data.treatment_id;
      this.edit_otTypeflat_rate = data.otType_name;
      this.edit_treatmentName = data.treatment_name;
      this.edit_ot_flat_rate_id = data.ot_flat_rate_id;
      this.edit_setting = true;
    },
    async edit_data() {
      let data_edit = {};
      if (!this.edit_ot_flat_rate_id) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสถานที่');
        return false;
      }
      if (!this.edit_treatmentName) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกข้อมูล');
        return false;
      }
      data_edit['ot_flat_rate_id'] = this.edit_ot_flat_rate_id;
      data_edit['treatment_name'] = this.edit_treatmentName;
      data_edit['id'] = this.edit_treatment_id;
      const confirmation = await Swal.fire({
        title: 'ยืนยัน ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Accept!',
        cancelButtonText: 'No, Cancel',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        await this.$store
          .dispatch('otsflatrate/update_treatment', data_edit)
          .then((response) => {
            this.$store.dispatch('alerts/success', 'ตั้งค่าเรียบร้อยแล้ว.');
            this.edit_setting = false;
            this.$emit('getTreatment');
          });
      }
    },
    async goto_delete(data) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            let params = {
              ...{ id: data.treatment_id },
            };
            let resp = await this.$store.dispatch(
              'otsflatrate/deleteTreatment',
              params,
            );
            if ((resp = 1)) {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'fingerscan has been deleted.',
                'success',
              );
              this.$emit('getTreatment');
            } else if ((resp = 0)) {
              swalWithBootstrapButtons.fire(
                'Error!',
                'Oops, something went wrong!',
                'error',
              );
            }
          } else if (result.dismiss) {
            swalWithBootstrapButtons.fire(
              'Cancelled',
              'Your imaginary SanthiyaSOS is safe :)',
              'error',
            );
          }
        });
    },
    async searchtreatment() {},
  },
};
</script>
<style>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
.backwhite {
  background-color: white;
}
/* .searchstaffs {
  display: none;
} */
</style>
