<template>
  <div>
    <div class="md-layout spacing">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>
          <md-card-content>
            <md-button class="md-raised md-success" @click="getOT">
              <md-icon>refresh</md-icon>
              refresh
            </md-button>
            <md-table
              v-model="otstafflist"
              table-header-color="green"
              :value="otstafflist"
              class="paginated-table table-striped table-hover"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="รหัสพนักงาน">
                  {{ item.emp_id }}
                </md-table-cell>
                <md-table-cell md-label="สาขา/ตำแหน่ง">
                  {{ item.br_nameth }}
                  {{ item.pos_nameth }}
                </md-table-cell>
                <md-table-cell md-label="ชื่อพนักงาน">
                  {{ item.first_nameen }} {{ item.last_nameen }}
                </md-table-cell>
                <md-table-cell md-label="วันที่">
                  {{ item.from_date_format }}
                </md-table-cell>
                <md-table-cell md-label="ระยะเวลา">
                  {{ item.from_date_time }} - {{ item.to_date_time }}
                </md-table-cell>
                <md-table-cell md-label="รวมเวลา">
                  {{ item.hours_time }} ชั่วโมง {{ item.minutes_time }} นาที
                </md-table-cell>
                <md-table-cell md-label="วันที่ขออนุมัติ">
                  {{ item.request_date_format }}
                </md-table-cell>
                <md-table-cell md-label="หมายเหตุ" v-if="item.modifications">
                  <div v-if="item.modifications.notes">
                    {{ item.modifications.notes.modified }}
                  </div>
                  <div v-else>ไม่ได้ระบุหมายเหตุ</div>
                </md-table-cell>
                <md-table-cell md-label="หมายเหตุ" v-else>
                  ไม่ได้ระบุหมายเหตุ
                </md-table-cell>
                <md-table-cell md-label="Actions">
                  <div>
                    <md-button
                      class="md-icon-button md-raised md-round md-success"
                      @click="onApprove(item.id)"
                      style="margin: 0.2rem"
                    >
                      <md-icon>check</md-icon>
                    </md-button>
                    <md-tooltip md-direction="top">Approve</md-tooltip>
                  </div>

                  <div>
                    <md-button
                      class="md-icon-button md-raised md-round md-danger"
                      @click="onDisapprove(item.id)"
                      style="margin: 0.2rem"
                    >
                      <md-icon>close</md-icon>
                    </md-button>
                    <md-tooltip md-direction="top">Disapprove</md-tooltip>
                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import moment from 'moment';
import Vuex from 'vuex';
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';

export default {
  mixins: [employeeMixin],
  data() {
    return {
      available_ots: [],
      profileMe: null,
      otstafflist: [],
      moment: moment,
      approval: {
        type: 'approvals',
        id: null,
        status: null,
      },
    };
  },
  async created() {
    await this.getOT();
    this.profileMe = await this.getProfileData();
    await this.getOTType(this.profileMe[0].com_id);
  },
  methods: {
    async getOT() {
      let params = {};
      await this.$store.dispatch('ots/otstafflist', params);
      const otstafflist = this.$store.getters['ots/otstafflist'];
      if (otstafflist) {
        this.otstafflist = await Promise.all(
          otstafflist.map((val) => {
            let fromdate = moment(val.modifications.from_date.modified).format(
              'DD/MM/YYYY',
            );
            let requestdate = moment(
              val.modifications.requestdate.modified,
            ).format('DD/MM/YYYY');

            let fromdate_time = moment(val.modifications.from_date.modified);
            let todate_time = moment(val.modifications.to_date.modified);
            var datediff_hours = todate_time.diff(fromdate_time, 'hours');
            var datediff_minutes = todate_time.diff(fromdate_time, 'minutes');

            val.from_date_time = moment(
              val.modifications.from_date.modified,
            ).format('HH:mm');
            val.to_date_time = moment(
              val.modifications.to_date.modified,
            ).format('HH:mm');
            val.from_date_format = fromdate;
            val.request_date_format = requestdate;
            val.hours_time = datediff_hours;
            val.minutes_time = datediff_minutes % 60;

            return val;
          }),
        );
      } else {
        this.otstafflist = [];
      }
    },
    async getOTType(id) {
      let params = {
        filter: {
          ...{ company_id: id },
        },
      };
      await this.$store.dispatch('ots/list', params);
      const ottypelist = this.$store.getters['ots/list'];
      if (ottypelist) {
        ottypelist.forEach((el) => {
          this.available_ots[el['id']] = el['name'];
        });
      }
    },
    async onApprove(id) {
      const confirmation = await Swal.fire({
        title: 'อนุมัติ OT ?',
        type: 'warning',
        input: 'select',
        inputLabel: 'OT',
        inputOptions: this.available_ots,
        inputPlaceholder: 'เลือกประเภท OT',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              resolve();
            } else {
              resolve('กรุณาเลือกประเภท OT');
            }
          });
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, approve!',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });
      if (confirmation.dismiss) {
        return false;
      } else {
        if (confirmation.value) {
          this.approval.id = id;
          this.approval.ot_id = confirmation.value;
          this.approval.status = 'approve';
          await this.$store.dispatch('ots/otapprove', this.approval);
          await this.getOT();
          this.$store.dispatch('alerts/success', 'Successfully.');
        }
      }
    },

    async onDisapprove(id) {
      const confirmation = await Swal.fire({
        title: 'ไม่อนุมัติ OT ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, disapprove!',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        this.approval.id = id;
        this.approval.status = 'disapprove';
        await this.$store.dispatch('ots/otapprove', this.approval);
        await this.getOT();
        this.$store.dispatch('alerts/success', 'Successfully.');
      }
    },
    // async onReject(id) {
    //   const confirmation = await Swal.fire({
    //     title: 'ไม่อนุมัติ OT ?',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, reject!',
    //     cancelButtonText: 'No,',
    //     confirmButtonClass: 'md-button md-success',
    //     cancelButtonClass: 'md-button md-danger',
    //   });

    //   if (confirmation.value === true) {
    //     this.approval.id = id;
    //     this.approval.status = 'reject';
    //     await this.$store.dispatch('staffs/otapprove', this.approval);
    //     await this.$store.dispatch('alerts/success', 'Successfully.');

    //     //set amout of notify
    //     let params = {};
    //     await this.$store.dispatch('staffs/staffotlist', params).then(() => {
    //       if (this.$store.getters['staffs/ottotal']) {
    //         this.$store.commit(
    //           'staffs/SET_OTAMOUT',
    //           this.$store.getters['staffs/ottotal'],
    //         );
    //       } else {
    //         this.$store.commit('staffs/SET_OTAMOUT', 0);
    //       }
    //     });

    //     this.getOT();
    //   }
    // },

    ...Vuex.mapActions({
      setOTAmout: 'ots/setOTAmout',
    }),
  },
};
</script>

<style></style>
