<template>
  <div>
    <md-dialog :md-active.sync="showEditDialog" md-fullscreen>
      <md-dialog-title>เพิ่ม กะการทำงาน</md-dialog-title>
      <ValidationObserver v-slot="{ handleSubmit }">
        <md-dialog-content>
          <form @submit.prevent="handleSubmit(submit)">
            <div class="md-layout spacing">
              <div
                class="md-layout-item md-size-100 mt-2 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
              >
                <h5>
                  <label>
                    {{ staffs.firstname_en + ' ' + staffs.lastname_en }}
                  </label>
                </h5>
              </div>
              <div
                class="md-layout-item md-size-50 mt-2 md-small-size-50 md-small-size-100 md-xsmall-size-100"
              >
                <ValidationProvider
                  name="employee_working_edit.working_id"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <md-autocomplete
                    v-model="workSearchTerm"
                    :md-options="filters_workingtime"
                    @md-opened="searchWorking"
                    @md-changed="searchWorking"
                    @md-selected="selectWorktime"
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>เลือก กะทำงาน</label>
                    <template slot="md-autocomplete-item" slot-scope="{ item }">
                      {{ item.name }}
                    </template>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">
                        done
                      </md-icon>
                    </slide-y-down-transition>
                  </md-autocomplete>
                </ValidationProvider>
              </div>
              <div
                class="md-layout-item md-size-50 mt-2 md-small-size-100 md-xsmall-size-100"
              >
                <ValidationProvider
                  name="employee_working_edit.work_type"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label for="work_type">ประเภท</label>
                    <md-select v-model="employee_working_edit.work_type">
                      <!-- <md-option value="major">ประจำ</md-option> -->
                      <md-option value="minor">เปลี่ยนกะทำงาน</md-option>
                    </md-select>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">
                        done
                      </md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="md-layout spacing md-alignment-top-center">
              <div
                class="md-layout-item mt-2 md-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
              >
                <ValidationProvider
                  name="employee_working_edit.start_date"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <md-datepicker
                    v-model="employee_working_edit.start_date"
                    md-immediately
                    :md-disabled-dates="disabledStartDates"
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>วันที่เริ่ม</label>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">
                        done
                      </md-icon>
                    </slide-y-down-transition>
                  </md-datepicker>
                </ValidationProvider>
              </div>
              <div
                class="md-layout-item mt-2 md-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
              >
                <ValidationProvider
                  name="employee_working_edit.finish_date"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <md-datepicker
                    v-model="employee_working_edit.finish_date"
                    md-immediately
                    :md-disabled-dates="disabledFinishDates"
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>วันที่สิ้นสุด</label>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">
                        done
                      </md-icon>
                    </slide-y-down-transition>
                  </md-datepicker>
                </ValidationProvider>
              </div>
              <div
                class="md-layout-item mt-2 md-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
              >
                <ValidationProvider
                  name="employee_working_edit.notes"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>หมายเหตุ</label>
                    <md-input v-model="employee_working_edit.notes" />
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">
                        done
                      </md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>
          </form>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button
            class="md-success"
            @click="staffWorkingtimeCreateApprove(employee_working_edit)"
          >
            Save
          </md-button>
          <md-button class="md-danger" @click="showEditDialog = false">
            Close
          </md-button>
        </md-dialog-actions>
      </ValidationObserver>
    </md-dialog>

    <div class="md-layout spacing md-alignment-center">
      <div
        class="md-layout-item md-size-30 md-medium-size-35 md-small-size-50 md-xsmall-size-100"
      >
        <md-datepicker v-model="fromdate" md-immediately>
          <label>วันที่เริ่ม</label>
        </md-datepicker>
      </div>
      <div
        class="md-layout-item md-size-30 md-medium-size-35 md-small-size-50 md-xsmall-size-100"
      >
        <md-datepicker
          v-model="todate"
          :md-disabled-dates="disabledToDates"
          md-immediately
        >
          <label>วันที่สุดท้าย</label>
        </md-datepicker>
      </div>
      <div class="md-layout-item md-size-10">
        <md-progress-spinner
          :md-diameter="50"
          :md-stroke="3"
          md-mode="indeterminate"
          :md-value="100"
          v-show="showProgressBar"
        >
        </md-progress-spinner>
      </div>
    </div>

    <div class="md-layout spacing md-alignment-center">
      <div class="md-layout-item md-size-20 md-alignment-center">
        <b-button
          @click="getStaffWorkingTime(fromdate, todate)"
          variant="info"
          size="lg"
        >
          Search
        </b-button>
      </div>
    </div>
    <div class="md-layout spacing">
      <div class="md-layout-item">
        <b-button @click="goToAdd()" :disabled="disabled_add" variant="primary">
          เพิ่มกะทำงาน
        </b-button>
      </div>
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-content>
            <div class="md-layout-item md-size-100 mt-4 md-small-size-100">
              <md-table
                v-if="tableData"
                v-model="tableData"
                table-header-color="green"
                class="paginated-table table-striped table-hover"
              >
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="รหัสกะ">
                    {{ item.working_id }}
                  </md-table-cell>
                  <md-table-cell md-label="กะการทำงาน">
                    {{ item.code }}
                  </md-table-cell>
                  <md-table-cell md-label="ช่วงเวลาการทำงาน">
                    {{ item.working_name }}
                  </md-table-cell>
                  <md-table-cell md-label="วันที่เริ่ม">
                    {{ item.start_date }}
                  </md-table-cell>
                  <md-table-cell
                    v-if="
                      item.finish_date == 'null' || item.finish_date == null
                    "
                    md-label="วันที่สิ้นสุด"
                  >
                    ยังไม่ได้ระบุ
                  </md-table-cell>
                  <md-table-cell v-else md-label="วันที่สิ้นสุด">
                    {{ item.finish_date }}
                  </md-table-cell>
                  <md-table-cell md-label="ประเภท">
                    <div v-if="item.work_type === 'major'">
                      ประจำ
                    </div>
                    <div v-else-if="item.work_type === 'minor'">
                      เปลี่ยนกะทำงาน
                    </div>
                  </md-table-cell>
                  <md-table-cell md-label="หมายเหตุ">
                    <div :style="item.type == 'manager' ? 'color:coral;' : ''">
                      {{ item.notes }}
                    </div>
                  </md-table-cell>
                  <md-table-cell md-label="สถานะการอนุมัติ">
                    <div
                      v-if="
                        item.approve_status_working == 'pending' ||
                          item.approve_status_working == 'request'
                      "
                    >
                      <span style="color: blue">รอการอนุมัติ</span>
                    </div>
                    <div v-else-if="item.approve_status_working == 'approve'">
                      <span style="color: green">อนุมัติแล้ว</span>
                    </div>
                    <div
                      v-else-if="item.approve_status_working == 'disapprove'"
                    >
                      <span style="color: red">ไม่อนุมัติ</span>
                    </div>
                  </md-table-cell>
                  <md-table-cell md-label="วันที่ขอ">
                    {{ item.requestdate }}
                  </md-table-cell>
                  <md-table-cell md-label="วันที่ประกาศกะ">
                    {{ item.created_at }}
                  </md-table-cell>
                  <md-table-cell
                    md-label="ผู้อนุมัติ"
                    v-if="
                      item.approve_status_working === 'approve' ||
                        item.approve_status_working === 'disapprove'
                    "
                  >
                    {{ item.approver_firstname }}
                    {{ item.approver_lastname }}
                  </md-table-cell>

                  <md-table-cell md-label="ผู้อนุมัติ" v-else> </md-table-cell>
                  <md-table-cell md-label="Actions">
                    <md-button
                      class="md-icon-button md-raised md-round md-danger"
                      style="margin: 0.2rem"
                      v-if="
                        (item.approve_status_working == 'pending' ||
                          item.approve_status_working == 'request') &&
                          item.work_type != 'major'
                      "
                      @click="showDialogDelete(item)"
                    >
                      <md-icon>delete</md-icon>
                    </md-button>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationError from '@/components';
import Pagination from '@/components/Pagination';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import moment from 'moment';
import Vuex from 'vuex';
import Swal from 'sweetalert2';
import employeeMixin from '@/mixins/employee-mixin';
// import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';
extend('required', required);

export default {
  components: {
    // 'filter-employee': FilterEmployee,
  },
  mixins: [employeeMixin],
  data() {
    return {
      headLabel: 'กะเวลาทำงาน',
      tableData: null,
      total: 1,
      fromdate: null,
      todate: null,
      workSearchTerm: null,
      available_workingtimes: [],
      filters_workingtime: [],
      showProgressBar: false,
      disabled_submit: false,
      available_branches: [],
      branches: [
        {
          id: null,
          type: 'branches',
        },
      ],

      disabledStartDates: (date) => {
        let fdate = moment(date);
        let tdate = moment([
          moment()
            .subtract(1, 'months')
            .year(),
          moment()
            .subtract(1, 'months')
            .month(),
          11,
        ]);
        let datediff = fdate.diff(tdate, 'days');
        return datediff < 0;
      },

      disabledFinishDates: (date) => {
        let fdate = moment(date);
        let tdate = moment(this.employee_working_edit.start_date);
        let datediff = fdate.diff(tdate, 'days');
        return datediff < 0 && fdate != tdate;
      },

      disabledToDates: (date) => {
        let fdate = moment(date);
        let tdate = moment(this.fromdate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        tdate = moment(tdate);
        let datediff = fdate.diff(tdate, 'days');
        return datediff < 0 && fdate != tdate;
      },

      employee_working_edit: {
        work_type: 'minor',
        type: 'manager',
      },
      branch_id: null,
      staffs: [],
      employee_id: null,
      moment: moment,
      disabled_employee: true,
      disabled_add: false,
      showEditDialog: false,
      showEditWorkingDialog: false,
      showdeleteWorkingDialog: false,
      editWorking: {},
      employee_working: {
        type: 'employeehasworkingtimes',
        id: null,
        status: 'inactive',
      },
    };
  },

  props: {
    employeeID: null,
  },

  created() {
    this.employee_id = this.employeeID;
    this.getStaffs();
    // if (this.$route.params.tabactive) {
    //   this.tabactive = this.$route.params.tabactive;
    // }
  },

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },

  watch: {
    employee_working_edit: {
      handler: function() {
        if (this.employee_working_edit.finish_date) {
          var finish_date = moment(
            this.employee_working_edit.finish_date,
            'DD/MM/YYYY',
          );
          if (
            moment(finish_date).diff(
              moment(this.employee_working_edit.start_date),
              'days',
            ) < 0
          ) {
            this.employee_working_edit.finish_date = moment(
              this.employee_working_edit.start_date,
            ).format('DD/MM/YYYY');
          }
        }
      },
      deep: true,
    },
    fromdate() {
      if (this.fromdate == 'Invalid date') {
        this.fromdate = null;
      }
      setTimeout(() => {
        if (this.todate) {
          let todate = moment(this.todate, 'DD/MM/YYYY');
          if (
            moment(todate).diff(moment(this.fromdate, 'DD/MM/YYYY'), 'days') < 0
          ) {
            this.todate = moment(this.fromdate, 'DD/MM/YYYY').format(
              'DD/MM/YYYY',
            );
          }
        }
      }, 100);
    },
    todate() {
      if (this.todate == 'Invalid date') {
        this.todate = null;
      }
      setTimeout(() => {
        if (this.fromdate && this.todate) {
          let todate = moment(this.todate, 'DD/MM/YYYY');
          if (
            moment(todate).diff(moment(this.fromdate, 'DD/MM/YYYY'), 'days') < 0
          ) {
            this.todate = moment(this.fromdate, 'DD/MM/YYYY').format(
              'DD/MM/YYYY',
            );
          }
        } else {
          this.fromdate = moment(this.todate, 'DD/MM/YYYY').format(
            'DD/MM/YYYY',
          );
        }
      }, 100);
    },
  },

  methods: {
    ...Vuex.mapActions({
      setTabActive: 'stafftransaction/setTabactive',
      setTransbranches: 'stafftransaction/setTransbranches',
      setEmployee: 'stafftransaction/setEmployee',
    }),

    async getStaffs() {
      loading_start();
      let params = {
        emp_id: this.employee_id,
      };
      let staffs = await this.$store.dispatch('staffs/searchStaff', params);
      this.staffs = staffs[0];
      loading_close();
    },

    async getWorkingtime(company_id) {
      let params = {
        filter: {
          ...{ status: 'active' },
          ...{ company_id: company_id },
        },
      };
      await this.$store.dispatch('workingtimes/list', params).then(() => {
        this.available_workingtimes = this.$store.getters[
          'workingtimes/dropdown'
        ];
      });
    },

    async getStaffWorkingTime(fromdate, todate) {
      if (!this.branches) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก สำนักงาน');
        return false;
      } else if (!this.employee_id) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
        return false;
      } else if (!fromdate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!todate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด');
        return false;
      }

      let params = {
        emp_id: this.employee_id,
        filter: {
          fromdate: moment(fromdate, 'DD/MM/YYYY').format('YYYY-MM-DD') ?? {},
          todate:
            todate !== null
              ? moment(todate, 'DD/MM/YYYY').format('YYYY-MM-DD') !==
                'Invalid date'
                ? moment(todate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : {}
              : {},
        },
      };

      loading_start();

      let data = await this.$store.dispatch(
        'staffhasworks/emphasworkinglist',
        params,
      );

      if (typeof data != 'undefined' && Object.keys(data).length > 0) {
        await data.forEach((d) => {
          let start_date = moment(d.start_date).format('DD/MM/YYYY');
          let finish_date = moment(d.finish_date).format('DD/MM/YYYY');
          let requestdate = moment(d.requestdate).format('DD/MM/YYYY');
          let created_at = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss');

          d.start_date = start_date !== 'Invalid date' ? start_date : '';
          d.finish_date = finish_date !== 'Invalid date' ? finish_date : '';
          d.requestdate = requestdate !== 'Invalid date' ? requestdate : '';
          d.created_at = created_at !== 'Invalid date' ? created_at : '';
        });
        this.tableData = data;
      } else {
        data = [];
        this.tableData = [];
      }
      this.disabled_add = false;

      loading_close();
      return data;
    },

    async staffWorkingtimeCreateApprove(data) {
      let employee_working_submit = { ...data };
      // console.log(employee_working_submit);

      if (
        !employee_working_submit.start_date ||
        employee_working_submit.start_date == null
      ) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกวันที่เริ่ม');
        return;
      }

      if (
        !employee_working_submit.finish_date ||
        employee_working_submit.finish_date == null
      ) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกวันที่สิ้นสุด');
        return;
      }

      if (
        !employee_working_submit.notes ||
        employee_working_submit.notes == ''
      ) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกหมายเหตุ');
        return;
      }

      employee_working_submit.start_date = moment(data.start_date, [
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      ]).format('YYYY-MM-DD');

      employee_working_submit.finish_date =
        data.finish_date !== null
          ? moment(data.finish_date, ['DD/MM/YYYY', 'YYYY-MM-DD']).format(
              'YYYY-MM-DD',
            )
          : null;
      employee_working_submit.status = 'active';
      employee_working_submit.approve_status = 'request';
      employee_working_submit.requestdate = moment().format('YYYY-MM-DD');
      employee_working_submit.notes = employee_working_submit.notes.trim();
      employee_working_submit.type = 'manager';

      await this.$store.dispatch(
        'staffhasworks/emphasworkingcreateapprove',
        employee_working_submit,
      );

      let params = {
        filter: {
          ...(employee_working_submit.employee_id
            ? { emp_id: employee_working_submit.employee_id }
            : {}),
          ...(employee_working_submit.start_date
            ? {
                fromdate: moment(employee_working_submit.start_date).format(
                  'YYYY-MM-DD',
                ),
              }
            : {}),
          ...(employee_working_submit.finish_date
            ? {
                todate: moment(employee_working_submit.finish_date).format(
                  'YYYY-MM-DD',
                ),
              }
            : {}),
        },
      };

      await this.getStaffWorkingTime(this.fromdate, this.todate);
      await this.$store.dispatch('alerts/success', 'บันทึกเรียบร้อยแล้ว');
      this.showEditDialog = false;
    },

    async goToAdd() {
      this.employee_working_edit = { work_type: 'minor', type: 'manager' };
      this.workSearchTerm = null;
      this.employee_working_edit.employee_id = this.staffs.emp_id;

      loading_start();
      await this.getWorkingtime(this.staffs.com_id);
      loading_close();

      this.showEditDialog = true;
    },

    async showDialogDelete(v) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });

      let result = await swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });

      if (result.value == true) {
        let params = {
          ...{ modification_id: v.modification_id },
          ...{ approve_status: v.approve_status },
          ...{ id: v.id },
          ...{ id_delete: v.id },
        };

        let resp = await this.$store.dispatch(
          'staffhasworks/deleteWorking',
          params,
        );

        await this.getStaffWorkingTime(this.fromdate, this.todate);

        if (resp == 1) {
          await this.$store.dispatch(
            'alerts/success',
            'ลบรายการเรียบร้อยแล้ว!',
          );
          this.showEditWorkingDialog = false;
        } else if (resp == 0) {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },

    async searchWorking(searchTerm) {
      if (!searchTerm) {
        searchTerm = this.workSearchTerm;
      }

      this.filters_workingtime = await new Promise((resolve) => {
        window.setTimeout(() => {
          if (!searchTerm) {
            resolve(this.available_workingtimes);
          } else {
            const term = searchTerm.toLowerCase();
            resolve(
              this.available_workingtimes.filter(
                ({ id, name }) =>
                  id.toLowerCase().includes(term) ||
                  name.toLowerCase().includes(term),
              ),
            );
          }
        }, 450);
      });
    },

    selectWorktime(worktime) {
      if (worktime != null) {
        this.employee_working_edit.working_id = worktime.id;
        this.workSearchTerm = worktime.name;
      } else {
        this.employee_working_edit.working_id = null;
        this.workSearchTerm = null;
      }
    },
  },
};
</script>

<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.stop-work {
  color: red;
}
.weekend-work {
  color: #edd1a8;
}
</style>
