<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(changePassword)">
      <md-card>
        <md-card-header class="md-card-header-icon">
          <div class="card-icon">
            <md-icon>
              lock_reset
            </md-icon>
          </div>
          <h4 class="title">Change Password</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <ValidationProvider
                name="password"
                rules="required|confirmed:password_confirmation|min:8"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Password (8 character)</label>
                  <md-input v-model="password" type="password" />
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                vid="password_confirmation"
                rules="required|confirmed:password|min:8"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Confirm Password</label>
                  <md-input v-model="password_confirmation" type="password" />
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
              <!-- <md-field class="md-invalid">
              <label>Current Password</label>
              <md-input v-model="password" type="password"/>
              <validation-error :errors="apiValidationErrors.password"/>
            </md-field> -->
              <!-- <md-field class="md-invalid">
              <label>New Password</label>
              <md-input v-model="new_password" type="password"/>
              <validation-error :errors="apiValidationErrors.password_confirmation"/>
            </md-field>
            <md-field class="md-invalid">
              <label>Confirm New Password</label>
              <md-input v-model="confirm_password" type="password"/>
              <validation-error :errors="apiValidationErrors.password_confirmation"/>
            </md-field> -->
            </div>
          </div>
        </md-card-content>

        <md-card-actions>
          <md-button class="md-info" type="submit">
            Change Password
          </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>

<script>
import { extend } from 'vee-validate';
import { ValidationError } from '@/components';
import formMixin from '@/mixins/form-mixin';
export default {
  name: 'edit-password-card',
  props: {
    user: Object,
  },

  components: {},

  mixins: [formMixin],

  data() {
    return {
      password: null,
      password_confirmation: null,
      passwordReset: {
        type: 'password-reset',
        password: null,
        password_confirmation: null,
        //email: null,
        //token: "",
      },
    };
  },

  methods: {
    async changePassword() {
      this.passwordReset.password = this.password;
      this.passwordReset.password_confirmation = this.password_confirmation;
      this.passwordReset.changetype = 'self';
      //this.passwordReset.email = this.user.email;

      try {
        await this.$store.dispatch('users/passwordReset', this.passwordReset);
        await this.$store.dispatch(
          'alerts/success',
          'Password changed successfully.',
        );
        this.user = await this.$store.getters['profile/me'];
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
      }
    },

    //   validate() {
    //   return this.$refs.form.validate().then(res => {
    //     if (!res) {
    //       return;
    //     }
    //     this.$emit("on-validated", res);
    //     return res;
    //   });
    // },
  },
};
</script>
