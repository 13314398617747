import service from '@/store/services/salaryperiod-service';

const state = {};

const mutations = {};

const actions = {
  async listYear({ commit, dispatch }, params) {
    return await service.listYear(params);
  },
  async listMonth({ commit, dispatch }, params) {
    return await service.listMonth(params);
  },
  async listMonthly({ commit, dispatch }, params) {
    return await service.listMonthly(params);
  },
  async listPeriod({ commit, dispatch }, params) {
    return await service.listPeriod(params);
  },
  async approverReport({ commit, dispatch }, params) {
    return await service.approverReport(params);
  },
};

const getters = {
  meta: (state) => state.meta,
};

const salaryperiod = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default salaryperiod;
