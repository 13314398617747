<template>
  <div>
    <div class="md-layout spacing">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>
          <md-card-content>
            <md-button class="md-raised md-success" @click="getWorkingPending">
              <md-icon>refresh</md-icon> refresh
            </md-button>
            <md-table
              v-model="workinglistpending"
              table-header-color="green"
              :value="workinglistpending"
              class="paginated-table table-striped table-hover"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="รหัสพนักงาน">
                  {{ item.emp_id }}
                </md-table-cell>
                <md-table-cell md-label="สาขา/ตำแหน่ง">
                  {{ item.br_nameth }}
                  {{ item.pos_nameth }}
                </md-table-cell>
                <md-table-cell md-label="ชื่อพนักงาน">
                  {{ item.first_nameen }}
                  {{ item.last_nameen }}
                </md-table-cell>
                <md-table-cell md-label="กะการทำงาน">
                  {{ item.code }}
                </md-table-cell>
                <md-table-cell md-label="ช่วงเวลาการทำงาน">
                  {{ item.working_name }}
                </md-table-cell>
                <md-table-cell md-label="Start Date">
                  {{ item.start_date_format }}
                </md-table-cell>
                <md-table-cell
                  v-if="
                    item.finish_date_format == 'null' ||
                      item.finish_date_format == null
                  "
                  md-label="Finish Date"
                >
                  ยังไม่ได้ระบุ
                </md-table-cell>
                <md-table-cell v-else md-label="Finish Date">
                  {{ item.finish_date_format }}
                </md-table-cell>
                <md-table-cell md-label="ประเภท">
                  <div v-if="item.work_type == 'major'">
                    ประจำ
                  </div>
                  <div v-else-if="item.work_type == 'minor'">
                    เปลี่ยนกะทำงาน
                  </div>
                </md-table-cell>
                <md-table-cell md-label="Actions">
                  <div>
                    <md-button
                      class="md-icon-button md-raised md-round md-success"
                      @click="onApprove(item.id)"
                      style="margin: 0.2rem"
                    >
                      <md-icon>check</md-icon>
                    </md-button>
                    <md-tooltip md-direction="top">Approve</md-tooltip>
                  </div>

                  <div>
                    <md-button
                      class="md-icon-button md-raised md-round md-danger"
                      @click="onDisapprove(item.id)"
                      style="margin: 0.2rem"
                    >
                      <md-icon>close</md-icon>
                    </md-button>
                    <md-tooltip md-direction="top">Disapprove</md-tooltip>
                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import moment from 'moment';
import Vuex from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      workinglistpending: [],
      moment: moment,
      approval: {
        type: 'approvals',
        id: null,
        status: null,
      },
      // query: null,
    };
  },
  async created() {
    await this.getWorkingPending();
  },

  methods: {
    ...Vuex.mapActions({
      setWorkingAmount: 'staffhasworks/setWorkingAmount',
    }),

    async getWorkingPending() {
      let params = {};
      await this.$store.dispatch(
        'staffhasworks/emphasworkinglistapprove',
        params,
      );
      const workinglistpending = this.$store.getters[
        'staffhasworks/workinglistapprove'
      ];
      if (workinglistpending) {
        this.workinglistpending = await Promise.all(
          workinglistpending.map((val) => {
            let start_date = moment(
              val.modifications.start_date.modified,
            ).format('DD/MM/YYYY');
            let finish_date = moment(
              val.modifications.finish_date.modified,
            ).format('DD/MM/YYYY');
            let requestdate = moment(
              val.modifications.requestdate.modified,
            ).format('DD/MM/YYYY');

            val.start_date_format = start_date;
            val.finish_date_format = finish_date;
            val.request_date_format = requestdate;
            val.work_type = val.modifications.work_type.modified;

            return val;
          }),
        );
      } else {
        this.workinglistpending = [];
      }
    },

    async onApprove(id) {
      const confirmation = await Swal.fire({
        title: 'อนุมัติกะการทำงาน ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve!',
        cancelButtonText: 'No,',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        this.approval.id = id;
        this.approval.status = 'approve';
        await this.$store.dispatch(
          'staffhasworks/emphasworkingapprove',
          this.approval,
        );
        this.getWorkingPending();
        this.$store.dispatch('alerts/success', 'Successfully.');
      }
    },

    async onDisapprove(id) {
      const confirmation = await Swal.fire({
        title: 'ไม่อนุมัติกะการทำงาน ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, disapprove!',
        cancelButtonText: 'No,',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        this.approval.id = id;
        this.approval.status = 'disapprove';
        await this.$store.dispatch(
          'staffhasworks/emphasworkingapprove',
          this.approval,
        );
        this.getWorkingPending();
        this.$store.dispatch('alerts/success', 'Successfully.');
        return true;
      }
    },
    // async onReject(id) {
    //   const confirmation = await Swal.fire({
    //     title: 'ไม่อนุมัติ OT ?',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, reject!',
    //     cancelButtonText: 'No,',
    //     confirmButtonClass: 'md-button md-success',
    //     cancelButtonClass: 'md-button md-danger',
    //   });

    //   if (confirmation.value === true) {
    //     this.approval.id = id;
    //     this.approval.status = 'reject';
    //     await this.$store.dispatch('staffs/otapprove', this.approval);
    //     await this.$store.dispatch('alerts/success', 'Successfully.');

    //     //set amout of notify
    //     let params = {};
    //     await this.$store.dispatch('staffs/staffotlist', params).then(() => {
    //       if (this.$store.getters['staffs/ottotal']) {
    //         this.$store.commit(
    //           'staffs/SET_OTAMOUT',
    //           this.$store.getters['staffs/ottotal'],
    //         );
    //       } else {
    //         this.$store.commit('staffs/SET_OTAMOUT', 0);
    //       }
    //     });

    //     this.getWorkingPending();
    //   }
    // },
  },
};
</script>

<style></style>
