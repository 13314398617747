<template>
  <div>
    <md-dialog class="edit-data" :md-active.sync="showSetting" md-fullscreen>
      <md-dialog-title style="font-size: 1.5rem">
        กรอก - แก้ไขข้อมูล
      </md-dialog-title>
      <md-dialog-content v-if="emp_setting" style="font-size: 1.1rem">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-small-size-100">
            รหัสพนักงาน : {{ emp_setting.emp_code }} <br />
            ชื่อพนักงาน :
            {{ emp_setting.firstname_th + ' ' + emp_setting.lastname_th }}
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <working-time-edit :employeeID="emp_setting.emp_id" />
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showSetting = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon style="transform: scaleX(-1);">
                manage_accounts
              </md-icon>
            </div>
            <!-- <h5 class="title">เปลี่ยนกะพนักงาน (ของหัวหน้างาน)</h5> -->
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
              >
                <div class="md-layout">
                  <div
                    v-if="staffList.length > 0"
                    class="md-layout-item md-size-70 mt-2 md-small-size-100 md-xsmall-size-100 md-alignment-left"
                  >
                    <md-autocomplete
                      v-model="staffSearchTerm"
                      :md-options="staffs"
                      @md-opened="searchStaffs"
                      @md-changed="searchStaffs"
                      @md-selected="selectStaff"
                      class="searchstaffs"
                    >
                      <label>ค้นหาพนักงาน</label>
                      <template
                        slot="md-autocomplete-item"
                        slot-scope="{ item }"
                      >
                        {{
                          item.empreq_data.firstname_en +
                            ' ' +
                            item.empreq_data.lastname_en
                        }}
                      </template>
                    </md-autocomplete>
                  </div>
                  <div
                    v-else
                    class="md-layout-item md-size-70 mt-2 md-small-size-100 md-xsmall-size-100 md-alignment-left"
                  ></div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                </div>
              </div>
            </div>
            <md-table
              v-if="staffs.length > 0"
              :value="staffs"
              class="paginated-table table-striped table-hover"
              table-header-color="green"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="รหัสพนักงาน">
                  <span class="upfont">
                    {{ item.empreq_data.emp_code }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="ชื่อพนักงาน">
                  <span class="upfont">
                    {{ item.empreq_data.firstname_en }}
                    {{ item.empreq_data.lastname_en }}
                    ({{ item.empreq_data.firstname_th }}
                    {{ item.empreq_data.lastname_th }})
                  </span>
                </md-table-cell>
                <md-table-cell md-label="Action">
                  <b-button
                    variant="success"
                    @click="getdata(item.empreq_data)"
                  >
                    จัดการ
                  </b-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import _ from 'lodash';
import Vuex from 'vuex';
import WorkingTimeEditForManager from '@/pages/Dashboard/Pages/Staff/Transactions/Working/WorkingTimeEditForManager.vue';
import { loading_start, loading_close } from '@/utils/loading.js';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';

export default {
  mixins: [employeeMixin],
  components: {
    'working-time-edit': WorkingTimeEditForManager,
  },
  data() {
    return {
      profile: null,
      editData: {},
      tableData: [],
      sortation: {
        field: 'created_at',
        order: 'asc',
      },
      boolean: true,
      status: 'active',
      // permissions: [],
      staffs: [],
      showSetting: false,
      emp_setting: null,
      staffList: [],
      staffSearchTerm: '',
    };
  },
  async created() {
    if (this.me?.employee_id) {
      this.profile = await this.getProfileData(this.me.employee_id);
    } else {
      this.profile = await this.getProfileData();
    }
    this.staffList = await this.getListEmp();
    if (this.staffList.length > 0) {
      this.searchStaffs();
    }
  },
  watch: {},
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var per = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          per.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          per.push(permission.name);
        });
      }
      return per;
    },
  },
  methods: {
    headdropdown(event) {
      var con = $(event.target).closest('.md-layout-item');
      var content = $('.contentleave', con);
      content.slideToggle();
      content.css('display', 'grid');
    },
    async getListEmp() {
      let params = {
        ...{ com_id: this.profile[0].com_id ? this.profile[0].com_id : {} },
        ...{ approver_id: this.profile[0].id ? this.profile[0].id : {} },
      };

      loading_start();
      let staffList = await this.$store.dispatch('uniqueapprover/list', params);
      loading_close();

      return staffList;
    },

    async getdata(emp) {
      if (emp == null) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return false;
      }
      this.emp_setting = emp;
      this.showSetting = true;
    },

    async searchStaffs(searchTerm = '') {
      if (searchTerm == '') {
        searchTerm = this.staffSearchTerm;
      }
      this.staffs = await new Promise((resolve) => {
        window.setTimeout(() => {
          if (searchTerm == '') {
            resolve(this.staffList);
          } else {
            const term = searchTerm.toLowerCase();
            resolve(
              this.staffList.filter(
                ({ empreq_data }) =>
                  empreq_data.emp_code.toLowerCase().includes(term) ||
                  empreq_data.firstname_th.toLowerCase().includes(term) ||
                  empreq_data.lastname_th.toLowerCase().includes(term) ||
                  empreq_data.firstname_en.toLowerCase().includes(term) ||
                  empreq_data.lastname_en.toLowerCase().includes(term),
              ),
            );
          }
        }, 300);
      });
    },

    selectStaff(c) {
      if (this.staffSearchTerm != '') {
        this.staffSearchTerm = c.empreq_data.emp_code;
      } else {
        this.staffSearchTerm = '';
      }
    },

    // setPermission(val) {
    //   val.permissions.map((permis) => {
    //     this.permissions.push(permis.name);
    //   });
    //   val.roles[0].permissions.map((permission) => {
    //     this.permissions.push(permission.name);
    //   });
    // },
  },
};
</script>
<style>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
</style>
