<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-100 mt2 md-small-size-100">
          <md-field>
            <label>ที่อยู่</label>
            <md-input v-model="user.address" />
          </md-field>
        </div>
      </div>
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="user.provinces"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>จังหวัด</label>
              <md-select
                v-model="user.provinces.id"
                @md-selected="provinceSelect"
              >
                <md-option
                  v-for="item in available_provinces"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>

        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="user.districts"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>อำเภอ</label>
              <md-select
                v-model="user.districts.id"
                :disabled="disabled_district"
                @md-selected="districSelect"
              >
                <md-option
                  v-for="item in district"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
      </div>
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="user.subdistricts"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>ตำบล</label>
              <md-select
                v-model="user.subdistricts.id"
                :disabled="disabled_subdistrict"
                @md-selected="subdistricSelect"
              >
                <md-option
                  v-for="item in subdistrict"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
        <div class="md-layout-item md-size-40 mt2 md-small-size-100">
          <ValidationProvider
            name="user.zipcode"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>รหัสไปรษณีย์</label>
              <md-input v-model="user.zipcode" />

              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import _ from 'lodash';
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import employeeMixin from '@/mixins/employee-mixin';

extend('required', required);

export default {
  mixins: [employeeMixin],
  data() {
    return {
      available_provinces: [],
      available_districts: [],
      available_subdist: [],
      district: [],
      subdistrict: [],
      disabled_district: true,
      disabled_subdistrict: true,
      old_province: null,
      old_distric: null,
      old_subdistric: null,
    };
  },
  props: {
    user: Object,
  },
  created() {
    this.getList();
  },
  watch: {
    user(v) {
      this.old_province = v.provinces.id;
      this.old_distric = v.districts.id;
      this.old_subdistric = v.subdistricts.id;
    },
  },
  beforeDestroy() {
    // this.available_provinces.destroy;
    // this.available_districts.destroy;
    // this.available_subdist.destroy;
    // this.district.destroy;
    // this.subdistrict.destroy;
  },
  methods: {
    async getList() {
      await this.getProvinceData().then(() => {
        this.available_provinces = this.$store.getters[
          'provinces/dropdownProvince'
        ];
      });
      await this.getDistrictData().then(() => {
        this.available_districts = this.$store.getters[
          'provinces/dropdownDistrict'
        ];
        // this.district = this.available_districts;
      });
      await this.getSubDistrictData().then(() => {
        this.available_subdist = this.$store.getters[
          'provinces/dropdownSubDistrict'
        ];
        // this.subdistrict = this.available_subdist;
      });
      await this.provinceSelect(this.user.provinces.id);
      await this.districSelect(this.user.districts.id);
      await this.subdistricSelect(this.user.subdistricts.id);
    },

    validate() {
      return this.$refs.form.validate().then((res) => {
        if (!res) {
          return;
        }
        this.$emit('on-validated', res);
        return res;
      });
    },

    // async getProvinces() {
    //   await this.$store.dispatch('provinces/listProvince').then(() => {
    //     this.available_provinces = this.$store.getters[
    //       'provinces/dropdownProvince'
    //     ];
    //   });
    // },

    // async getDistricts(v) {
    //   let province_id = v;

    //   let params = {
    //     filter: {
    //       ...{ province_id: province_id },
    //     },
    //   };
    //   await this.$store.dispatch('provinces/listDistrict', params).then(() => {
    //     this.available_districts = this.$store.getters[
    //       'provinces/dropdownDistrict'
    //     ];
    //     this.district = this.available_districts;
    //   });
    // },

    // async getSubDistricts(v) {
    //   let district_id = v;

    //   let params = {
    //     filter: {
    //       ...{ district_id: district_id },
    //     },
    //   };
    //   await this.$store
    //     .dispatch('provinces/listSubDistrict', params)
    //     .then(() => {
    //       this.available_subdist = this.$store.getters[
    //         'provinces/dropdownSubDistrict'
    //       ];
    //       this.subdistrict = this.available_subdist;
    //     });
    // },

    async provinceSelect(v) {
      if (v) {
        if (this.district.length && this.old_province !== v) {
          this.user.districts.id = '';
          this.user.subdistricts.id = '';
          this.user.zipcode = '';
        }
        let dist_filter = this.available_districts;
        this.district = [];
        this.subdistrict = [];
        // this.getDistricts(v);

        this.district = dist_filter.filter((dist) => {
          return dist.province == v;
        });
        this.disabled_district = false;
        this.disabled_subdistrict = true;
      }
    },

    async districSelect(v) {
      if (v) {
        if (this.subdistrict.length && this.old_distric !== v) {
          this.user.subdistricts.id = '';
          this.user.zipcode = '';
        }
        let subdist_filter = this.available_subdist;
        this.subdistrict = [];
        // this.getSubDistricts(v);
        this.subdistrict = subdist_filter.filter((subdist) => {
          return subdist.district == v;
        });
        this.disabled_subdistrict = false;
      }
    },

    async subdistricSelect(v) {
      if (v) {
        if (this.subdistrict.length && this.old_subdistric !== v) {
          this.user.zipcode = '';
        }
        let subdistric = this.subdistrict.find((subd) => {
          return subd.id == v;
        });
        if (subdistric) this.user.zipcode = subdistric.zip_code;
      }
    },
  },
};
</script>
<style lang="scss">
.spacing {
  padding-bottom: 1rem;
}
</style>
