import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/getunique-approver`, options);
  return response.data;
}

async function listflatreat(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(
    `${url}/v1/getuniqueflatreat-approver`,
    options,
  );
  return response.data;
}

async function listreportflatreat(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(
    `${url}/v1/getuniqueflatreat-approverreport`,
    options,
  );
  return response.data;
}

function add(params) {
  const payload = jsona.serialize({
    stuff: params,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.post(`${url}/v1/createunique-approver`, payload, options).then(
    (response) => {
      return response.data;
      // return jsona.deserialize(response.data);
    },
    (error) => {
      return error.response.status;
    },
  );
}

function addflatreat(params) {
  const payload = jsona.serialize({
    stuff: params,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios
    .post(`${url}/v1/createuniqueflatreat-approver`, payload, options)
    .then(
      (response) => {
        return response.data;
        // return jsona.deserialize(response.data);
      },
      (error) => {
        return error.response.status;
      },
    );
}

async function addReportflatreat(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/createuniqueflatreat-approverreport`,
    params,
    options,
  );
  return response.data;
}

async function edit(params) {
  const payload = jsona.serialize({
    stuff: params,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/updateunique-approver`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function editflatreat(params) {
  const payload = jsona.serialize({
    stuff: params,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/updateuniqueflatreat-approver`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function editreport(params) {
  const payload = jsona.serialize({
    stuff: params,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/updateunique-approverreport`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function editreportflatrate(params) {
  const payload = jsona.serialize({
    stuff: params,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/updateunique-approverreportflatrate`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

export default {
  list,
  add,
  edit,
  listflatreat,
  addflatreat,
  listreportflatreat,
  addReportflatreat,
  editreport,
  editreportflatrate,
  editflatreat,
};
