<template>
  <div class="md-layout">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>lock</md-icon>
        </div>
        <h4 class="title"></h4>
      </md-card-header>
      <md-card-content>
        <div class="text-right">
          <md-button @click="goBack" class="md-primary md-dense">
            Back to List
          </md-button>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-20">
            <md-field>
              <label>เลือก สิทธิ์การใช้งาน</label>
              <md-select v-model="permission">
                <md-option
                  v-for="item in available_permission"
                  :key="item.name"
                  :value="item.name"
                >
                  {{ item.name }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-20">
            <md-button
              class="md-icon-button md-raised md-round md-info"
              @click="addPermission(permission)"
            >
              <md-icon>add</md-icon>
            </md-button>
          </div>
        </div>
        <md-table
          class="paginated-table table-striped table-hover"
          table-header-color="green"
        >
          <md-table-row>
            <md-table-head>สิทธิ์การใช้งาน</md-table-head>
            <md-table-head>Actions</md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, index) in permissionData" :key="index">
            <md-table-cell md-label="สิทธิ์การใช้งาน">{{ item }}</md-table-cell>
            <md-table-cell md-label="Actions">
              <md-button
                class="md-icon-button md-raised md-round md-danger"
                @click="removePermission(index)"
                style="margin: 0.2rem"
              >
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
      <md-card-actions md-alignment="right">
        <md-button
          type="submit"
          class="md-success"
          @click="savePermission"
          :disabled="disable_button"
        >
          Save Permission
        </md-button>
      </md-card-actions>
    </md-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    available_permission: [],
    permission: null,
    permissionData: [],
    direct_permission: {
      type: 'assignpermission-direct',
      to_target: null,
      status: 'assign',
      permissions: null,
    },
    disable_button: true,
  }),

  created() {
    this.getPermissionList();
  },

  watch: {
    permissionData() {
      this.disable_button = true;
      if (this.permissionData.length) {
        this.disable_button = false;
      }
    },
  },

  methods: {
    /**
     * get permission list
     */
    async getPermissionList() {
      await this.$store.dispatch('permissions/list').then(() => {
        this.available_permission = this.$store.getters['permissions/dropdown'];
      });
    },

    /**
     * add permission to list data
     */
    addPermission(v) {
      this.permissionData.push(v);
      const dataArrWithSet = new Set(this.permissionData);
      this.permissionData = [...dataArrWithSet];
    },

    /**
     * remove permission from list data
     */
    removePermission(v) {
      this.permissionData.splice(v, 1);
    },

    /**
     * save permission to api
     */
    async savePermission() {
      this.direct_permission.to_target = this.$route.params.id;
      this.direct_permission.permissions = this.permissionData;

      try {
        if (this.$route.params.type_name == 'byrole') {
          await this.$store.dispatch(
            'permissions/assignRolePermission',
            this.direct_permission,
          );
        } else {
          await this.$store.dispatch(
            'permissions/assignDirectPermission',
            this.direct_permission,
          );
        }
        await this.$store.dispatch(
          'alerts/success',
          'Permission added successfully.',
        );
        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            'alerts/error',
            e.response.data.errors[0].title,
          );
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },

    /**
     * Go back to permission list page
     */
    goBack() {
      this.$router.push({
        name: 'List Permissions Access',
        params: {
          id: this.$route.params.id,
          type_name: this.$route.params.type_name,
        },
      });
    },
  },
};
</script>
