<template>
  <div v-if="user" class="md-layout">
    <div class="md-layout-item md-small-size-100 mx-auto">
      <div class="md-layout-item md-size-100">
        <private-code-card :user="user" :available_branches="branches" />
      </div>
    </div>
  </div>
</template>

<script>
import PrivatecodeCard from '@/pages/Dashboard/Pages/Admin/PrivatecodeManagement/PrivatecodeCard.vue';
import Swal from 'sweetalert2';

export default {
  name: 'private-code',
  components: {
    'private-code-card': PrivatecodeCard,
  },

  data: () => ({
    user: null,
    branches: null,
  }),

  created() {
    this.getProfile();
    this.getBranches();
  },

  methods: {
    async getProfile() {
      await this.$store.dispatch('profile/me');
      this.user = await this.$store.getters['profile/me'];
    },

    async getBranches() {
      await this.$store.dispatch('branches/list').then(() => {
        this.branches = this.$store.getters['branches/dropdown'];
      });
    },
  },
};
</script>
<style scoped></style>
