import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function getListyearReport(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/getlistyearreport`, options);
  return response.data;
}
async function slipSalary(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/slipsalary`, options);
  return response.data;
}

async function getListSalary(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/getlistsalary`, options);
  return response.data;
}

async function salaryDecrypt(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/staffdetail`, options);
  return {
    list: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

function salaryCalculate(params) {
  const payload = jsona.serialize({
    stuff: params,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.post(`${url}/v1/salary-process`, payload, options).then(
    (response) => {
      if (response.data.comment) {
        return response.data;
      }
      return jsona.deserialize(response.data);
    },
    (error) => {
      return error.response.status;
    },
  );
}

async function salaryPeriod(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };
  const response = await axios.get(`${url}/v1/salary-period`, options);
  return {
    list: response.data,
    meta: response.data.meta,
  };
}

async function getReport(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  const response = await axios.get(`${url}/v1/getreport`, options);
  return response.data;
}

async function listReport(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  const response = await axios.get(`${url}/v1/listreport`, options);
  return response.data;
}

async function approverReport(params) {
  const payload = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };
  // const payload = jsona.serialize({
  //   stuff: params,
  //   includeNames: null,
  // });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  const response = await axios.post(
    `${url}/v1/approverreportsalary`,
    payload,
    options,
  );
  return response.data;
}

async function approveReport(params) {
  // const options = {
  //   params: params,
  //   paramsSerializer: function(params) {
  //     return qs.stringify(params, { encode: true });
  //   },
  // };
  const response = await axios.post(`${url}/v1/approvereport`, params);
  return response.data;
}

async function addIncomeCost(incomecost) {
  const payload = jsona.serialize({
    stuff: incomecost,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  const response = await axios.post(
    `${url}/v1/addincomecost`,
    payload,
    options,
  );
  return {
    list: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

export default {
  getListyearReport,
  slipSalary,
  getListSalary,
  salaryDecrypt,
  salaryCalculate,
  salaryPeriod,
  addIncomeCost,
  listReport,
  getReport,
  approverReport,
  approveReport,
};
