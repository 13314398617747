import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/getemployee-sso`, options).then((response) => {
    return response.data;
  });
}

function add(params) {
  const payload = jsona.serialize({
    stuff: params,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.post(`${url}/v1/createemployee-sso`, payload, options).then(
    (response) => {
      return jsona.deserialize(response.data);
    },
    (error) => {
      return error.response.status;
    },
  );
}

function edit(params) {
  const payload = jsona.serialize({
    stuff: params,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/updateemployee-sso`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  list,
  add,
  edit,
};
