var render = function render(){var _vm=this,_c=_vm._self._c;return _c('sidebar-item',{attrs:{"opened":"","link":{ name: 'Notification', icon: 'notifications' }}},[(
      _vm.permissions.includes('view report') ||
        (_vm.emp_id != 0 && _vm.approverOTOverLimit.includes(_vm.emp_id))
    )?_c('sidebar-item',{attrs:{"link":{
      name: 'อนุมัติ OT เกิน 3 ชั่วโมง',
      path: '/staff/appvotover',
    }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
      name: 'การอนุมัติรายการทั้งหมด' + _vm.showtotal,
      path: '/staff/approveall',
    }}}),_c('sidebar-item',{attrs:{"link":{
      name: 'ประวัติการอนุมัติ',
      path: '/staff/historyapprove',
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }