import service from '@/store/services/staffleaves-service';

const state = {
  list: {},
  staffleave: {},
  meta: {},
  leaveamout: 0,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, staffleave) => {
    state.staffleave = staffleave;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_LEAVEAMOUT: (state, leaveamout) => {
    state.leaveamout = leaveamout;
  },
};

const actions = {
  leavecreate({ commit, dispatch }, params) {
    return service.leavecreate(params).then((leave) => {
      commit('SET_RESOURCE', leave);
    });
  },

  leaveCreateApprove({ commit, dispatch }, params) {
    return service.leaveCreateApprove(params).then((leave) => {
      commit('SET_RESOURCE', leave);
    });
  },

  deleteLeave({ commit, dispatch }, params) {
    return service.deleteLeave(params).then((list) => {
      return list;
    });
  },
  updateLeave({ commit, dispatch }, params) {
    return service.updateLeave(params).then((list) => {
      return list;
    });
  },
  leavepending({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      commit('SET_META', meta);
    });
  },

  leaveapprove({ commit, dispatch }, params) {
    return service.approve(params).then((approval) => {
      commit('SET_RESOURCE', approval);
    });
  },

  setLeaveAmout({ commit, dispatch }, value) {
    commit('SET_LEAVEAMOUT', value);
  },

  staffleavelist({ commit, dispatch }, params) {
    return service.staffleavelist(params).then((staffleave) => {
      // commit('SET_RESOURCE', staffleave);
      return staffleave;
    });
  },

  staffleaveremain({ commit, dispatch }, params) {
    return service.staffleaveremain(params).then((leaveremain) => {
      return leaveremain;
    });
  },
};

const getters = {
  list: (state) => state.list,
  leavetotal: (state) => state.meta.page.total,
  staffleave: (state) => state.staffleave,
  // dropdown: (state) => {
  // 	return state.list.map((leave) => ({
  // 		id: leave.id,
  // 		name: leave.leave_nameth,
  // 	}));
  // },
};

const staffleaves = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default staffleaves;
