import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function addStaffOTflatReat(ot) {
  const payload = jsona.serialize({
    stuff: ot,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios
    .post(`${url}/v1/otflatreatcreate`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function addStaffOTflatReatApprove(ot) {
  const payload = jsona.serialize({
    stuff: ot,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios
    .post(`${url}/v1/otflatreatcreateapprove`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function staffotlist(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios
    .get(`${url}/v1/staffotlist_flatrate`, options)
    .then((response) => {
      return {
        staffot: jsona.deserialize(response.data),
        meta: response.data.meta,
      };
    });
}

function updateOT(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/updateOT_flatrate`, params, options)
    .then((response) => {
      return response.data;
    });
}

function deleteOT(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/deleteOT_flatrate`, params, options)
    .then((response) => {
      return response.data;
    });
}

function getPriceOT(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/get_PriceOT`, options).then((response) => {
    return response.data;
  });
}

function addPriceOT(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/add_PriceOT`, params, options)
    .then((response) => {
      return response.data;
    });
}

function addTreatment(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/add_Treatment`, params, options)
    .then((response) => {
      return response.data;
    });
}

function updatePriceOT(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/update_PriceOT`, params, options)
    .then((response) => {
      return response.data;
    });
}

function deletePriceOT(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/delete_PriceOT`, params, options)
    .then((response) => {
      return response.data;
    });
}

function getTypeOT(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/get_TypeOT`, options).then((response) => {
    return response.data;
  });
}

function listOT_flat_rat(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/listOT_flatRat`, options).then((response) => {
    return response.data;
  });
}

function get_treatment(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/get_treatment`, options).then((response) => {
    return response.data;
  });
}

function update_treatment(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/update_treatment`, params, options)
    .then((response) => {
      return response.data;
    });
}

function deleteTreatment(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/delete_Treatment`, params, options)
    .then((response) => {
      return response.data;
    });
}

function listProcess(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/list_process`, options).then((response) => {
    return response.data;
  });
}

function listflatreatProcess(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/v1/list_flatreatProcess`, options)
    .then((response) => {
      return response.data;
    });
}

function createReportSpa(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/create_reportSpa`, params, options)
    .then((response) => {
      return response.data;
    });
}

function createReportOTFlatrate(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/create_reportOTFlatrate`, params, options)
    .then((response) => {
      return response.data;
    });
}

function getListyearReport(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/v1/getlistyearreportflatreat`, options)
    .then((response) => {
      return response.data;
    });
}

function listReportFlatreat(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  return axios.get(`${url}/v1/listReportFlatreat`, options).then((response) => {
    return response.data;
  });
}

function getReport(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  return axios.get(`${url}/v1/getreportFlatreat`, options).then((response) => {
    return response.data;
  });
}

function approverReport(params) {
  const payload = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios
    .post(`${url}/v1/approverreportspa`, payload, options)
    .then((response) => {
      return response.data;
    });
}

function approveReport(params) {
  return axios.post(`${url}/v1/approvereportspa`, params).then((response) => {
    return response.data;
  });
}

function get_sendrp_flat_rate(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };
  return axios
    .get(`${url}/v1/get_sendrp_flat_rate`, options)
    .then((response) => {
      return response.data;
    });
}

function deleteReport(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/delete_ReportFlatrateSpa`, params, options)
    .then((response) => {
      return response.data;
    });
}

function checkApproverReport(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };
  return axios
    .get(`${url}/v1/check_ApproverReport`, options)
    .then((response) => {
      return response.data;
    });
}

export default {
  staffotlist,
  updateOT,
  deleteOT,
  getPriceOT,
  addPriceOT,
  updatePriceOT,
  deletePriceOT,
  getTypeOT,
  listOT_flat_rat,
  addTreatment,
  get_treatment,
  update_treatment,
  deleteTreatment,
  addStaffOTflatReat,
  addStaffOTflatReatApprove,
  listProcess,
  createReportSpa,
  createReportOTFlatrate,
  getListyearReport,
  listReportFlatreat,
  getReport,
  approverReport,
  approveReport,
  listflatreatProcess,
  get_sendrp_flat_rate,
  deleteReport,
  checkApproverReport,
};
