import service from '@/store/services/users-service';

const state = {
  list: {},
  user: {},
  meta: {},
  url: null,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, user) => {
    state.user = user;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      commit('SET_META', meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((user) => {
      commit('SET_RESOURCE', user);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((user) => {
      commit('SET_RESOURCE', user);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((user) => {
      commit('SET_RESOURCE', user);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },

  passwordReset({ commit, dispatch }, params) {
    return service.passwordReset(params).then((user) => {
      commit('SET_RESOURCE', user);
    });
  },

  privateSetup({ commit, dispatch }, params) {
    return service.privateSetup(params).then((user) => {
      commit('SET_RESOURCE', user);
    });
  },

  upload({ commit, dispatch }, { user, image }) {
    return service.upload(user, image).then((url) => {
      commit('SET_URL', url);
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  user: (state) => state.user,
  userWorkingTime: (state) =>
    state.user.employeehasworkingtimes.reduce((filtered, option) => {
      if (option.status == 'active') {
        var v = {
          id: option.id,
          start_date: option.start_date,
          finish_date: option.finish_date,
          work_type: option.work_type,
          working_id: option.working_id,
          working_name: option.workingtimes.working_name,
        };
        filtered.push(v);
      }
      return filtered;
    }, []),
  url: (state) => state.url,
  // userget: (state) => {
  // 	return state.user.assign({ departments: 1 });
  // },
};

const users = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default users;
