<template>
  <div v-if="permissions.includes('view reportflatrate')">
    <b-container fluid>
      <b-row>
        <b-col>
          <!-- <b-button class="" variant="success" @click="exportExcel()">
            EXPORT
          </b-button> -->
          <b-button class="" variant="success" @click="exportPDF()">
            PDF
          </b-button>
          <b-button
            class=""
            variant="success"
            v-if="permissions.includes('debug')"
            @click="getReport()"
          >
            getreport
          </b-button>
        </b-col>
        <b-col v-if="ots == 4"><h3>รายงานค่าเที่ยวเรือ</h3></b-col>
        <b-col v-else-if="ots == 5"><h3>รายงานค่านวดสปา</h3></b-col>
        <b-col v-else-if="ots == 6"><h3>รายงานค่าการแสดง</h3></b-col>
        <b-col v-else><h3>รายงาน</h3></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="3" lg="3">
          <b-form-select v-model="ots">
            <b-form-select-option :value="null" disabled>
              เลือกประเภทรายงาน
            </b-form-select-option>
            <b-form-select-option
              v-for="item in available_ots"
              :key="item.id + 'id'"
              :value="item.id"
            >
              {{ item.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col sm="12" md="3" lg="3">
          <b-form-select v-model="branchs">
            <b-form-select-option :value="null" disabled>
              เลือก สำนักงาน
            </b-form-select-option>
            <b-form-select-option
              v-for="item in available_branchs"
              :key="item.bch_id + 'key'"
              :value="item.bch_id"
            >
              {{ item.brname_th }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col sm="6" md="3" lg="3">
          <b-form-select v-model="years" :disabled="disabled_rp">
            <b-form-select-option :value="null">
              เลือก ปี
            </b-form-select-option>
            <b-form-select-option
              v-for="item in listyear"
              :key="item + 'key'"
              :value="item"
            >
              {{ item }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col sm="12" md="6" lg="6">
          <b-form-select v-model="report_select" :disabled="disabled_rp">
            <b-form-select-option :value="null" disabled>
              เลือก รายงาน
            </b-form-select-option>
            <b-form-select-option
              v-for="item in available_report"
              :key="item.report_id + 'key'"
              :value="item"
            >
              งวดจ่าย {{ item.name_pay_date_th }} (
              {{ item.name_fromdate_th }} - {{ item.name_todate_th }})
              <!-- <span v-if="item.stafftype == 'day'">(รายวัน)</span>
              <span v-else-if="item.stafftype == 'month'">(รายเดือน)</span>
              <span v-else-if="item.stafftype == 'all'">(ทั้งหมด)</span> -->
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col sm="6" md="3" lg="3" v-if="report">
          <md-button
            v-if="showbutton == true"
            class="md-primary md-sm"
            @click="showDetail()"
            >ดูรายละเอียด</md-button
          >&nbsp;
          <md-button
            class="md-rose md-sm"
            v-if="checkAppReport == true && candelete == true"
            @click="deleteReport()"
            ><md-icon class="font-imp">delete</md-icon>ลบรายงาน</md-button
          >
        </b-col>
      </b-row>
    </b-container>
    <br />
    <b-container
      fluid
      v-for="(items_report, index_report) in report"
      :key="index_report + 'rp'"
      :value="items_report"
    >
      <div class="box-header-salary" v-if="report_select">
        <div>{{ items_report.bch_nameth }}</div>
        <div v-if="ots == 5">
          ค่ามือพนักงานสปาประจำรอบเดือน วันที่
          {{ items_report.name_pay_date_full_th }}
        </div>
        <div v-if="ots != 5">
          ค่าล่วงเวลาเหมาจ่าย ประจำรอบเดือน วันที่
          {{ items_report.name_pay_date_full_th }}
        </div>
      </div>
      <div v-if="report_select && ots == 5">
        <b-table-simple>
          <b-thead head-variant="dark" class="header_table">
            <b-tr>
              <b-th rowspan="3" class="text-center">วันที่</b-th>
              <b-th v-bind:colspan="num_col" class="text-center">
                SPA THERAPIST INCENTIVE
              </b-th>
              <b-th rowspan="3" class="text-center">รวมค่ามือที่ต้องจ่าย</b-th>
            </b-tr>
            <b-tr>
              <b-th
                v-for="(col, index_col) in columns"
                :key="col.employee_id + index_col"
              >
                {{ col.title_nameen }} {{ col.first_nameen }}
              </b-th>
            </b-tr>
            <b-tr>
              <b-th
                class="text-center"
                v-for="(col, index_col) in columns"
                :key="col.emp_id + 'k' + index_col"
              >
                ID: {{ col.emp_id }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody class="background-white">
            <b-tr
              v-for="(row, i) in data_rows"
              :key="row + 'data' + i"
              :value="row"
            >
              <b-td
                class="text_r"
                v-for="(item, index) in row"
                :key="index + 'k'"
              >
                {{ item }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot foot-variant="dark">
            <b-tr>
              <b-th
                class="text_r"
                v-for="(items, index_footer) in data_tfoot"
                :key="items + 'key' + index_footer"
              >
                {{ items }}
              </b-th>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
      <div v-if="report_select && ots != 5">
        <b-table-simple>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="text-center">รหัสพนักงาน</b-th>
              <b-th class="text-center">ชื่อ - นามสกุล</b-th>
              <b-th class="text-center" v-if="ots == 6">แผนก</b-th>
              <b-th class="text-center" v-if="ots == 6">ตำแหน่ง</b-th>
              <b-th class="text-center">วันที่ทำโอที</b-th>
              <b-th class="text-center" v-if="ots == 4">ตำแหน่งงาน</b-th>
              <b-th class="text-center" v-if="ots == 6">ชื่อรายการ</b-th>
              <b-th class="text-center">ตั้งแต่เวลา</b-th>
              <b-th class="text-center">ถึงเวลา</b-th>
              <b-th class="text-center" v-if="ots == 6">อัตรา/รอบ</b-th>
              <b-th class="text-center" v-if="ots == 4">
                รวมโอทีคิดเป็นเที่ยว
              </b-th>
              <b-th class="text-center" v-if="ots == 6">รายละเอียด</b-th>
            </b-tr>
          </b-thead>
          <b-tbody
            class="background-white"
            v-for="(rows, i) in data_rows[index_report]"
            :key="rows + 'data' + i"
            :value="rows"
          >
            <!-- <b-tbody class="background-white"> -->
            <b-tr
              v-for="(item, i) in rows"
              :key="item + 'data' + i"
              :value="item"
            >
              <b-td>
                <span v-if="item.checkrowc == 0">
                  {{ item.emp_id }}
                </span>
              </b-td>
              <b-td>
                <span v-if="item.checkrowc == 0">
                  {{ item.title_nameen }} {{ item.first_nameen }}
                  {{ item.last_nameen }}
                </span>
              </b-td>
              <b-td>
                <span v-if="item.checkrowc == 0 && ots == 6">
                  {{ item.pos_nameen }}
                </span>
              </b-td>
              <b-td>
                <span v-if="item.checkrowc == 0 && ots == 6">
                  {{ item.dep_nameen }}
                </span>
              </b-td>
              <b-td>
                <span v-if="item.checkrowdate == 0">
                  {{ item.fromdate }}
                </span>
              </b-td>
              <b-td>
                {{ item.otType_name }}
              </b-td>
              <b-td>
                {{ item.time_from_date }}
              </b-td>
              <b-td>
                {{ item.time_to_date }}
              </b-td>
              <b-td v-if="ots == 6 || ots == 4">
                {{ item.sum_ot }}
              </b-td>
              <b-td v-if="ots == 6">
                {{ item.notes }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-container>
    <BoxSpaAppved
      v-if="data_rows"
      :persons="persons"
      :branchs="branchs"
      :ot_id="ots"
      :report_select="report_select"
      :profile="profile"
      @getreport="getReport"
    />
  </div>
</template>
<script>
import $ from 'jquery';
import BoxSpaAppved from '@/pages/Dashboard/Components/BoxSpaAppved.vue';
import moment from 'moment';
import Vuex from 'vuex';
import md5 from 'js-md5';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { loading_start, loading_close } from '@/utils/loading.js';
import Swal from 'sweetalert2';
export default {
  name: 'ApprovedSalary',
  components: { BoxSpaAppved },
  mixins: [],
  data() {
    return {
      listyear: [],
      years: null,
      profile: null,
      branchs: null,
      available_branchs: null,
      ots: null,
      available_ots: null,
      disabled_rp: true,
      disabled_privatecode: false,
      report: null,
      report_select: {
        report_id: null,
        from_date: null,
        to_date: null,
        // stafftype: null,
      },
      available_report: null,
      available_datasreport: null,
      sum_all: [],
      sum_all_property: [],
      host: process.env.VUE_APP_API_BASE_URL,
      accesstoken: localStorage.getItem(
        'vue-authenticate.vueauth_access_token',
      ),
      comIds: [],
      columns: null,
      num_col: 0,
      data_rows: null,
      data_tfoot: null,
      showbutton: false,
      detail: null,
      checkAppReport: true,
      candelete: false,
    };
  },
  async created() {
    moment.locale('th');
    loading_start();
    let params = { profile: true };
    let profile = await this.$store.dispatch('staffs/searchStaff', params);
    this.profile = { ...profile[0] };
    await this.getOTType();
    // this.permissions.push('reportnotprivatecode');
    if (
      localStorage.getItem('privatecode') ||
      this.permissions.includes('reportnotprivatecode')
    ) {
      this.disabled_privatecode = true;
    }
    loading_close();
  },
  mounted() {},
  props: {},
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var pms = [];
      if (this.me) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }
      return pms;
    },
  },
  watch: {
    ots: function() {
      this.getBranchs();
      this.years = null;
      this.report_select = null;
      this.data_rows = null;
      this.showbutton = false;
    },
    branchs: function() {
      this.getListyearReport();
      this.listReport();
      this.years = null;
      this.report_select = null;
      this.data_rows = null;
      this.showbutton = false;
    },
    years: function(years) {
      if (years != null) {
        this.listReport();
        this.report_select = null;
        this.data_rows = null;
        this.showbutton = false;
      }
    },
    departments: function() {
      if (this.report_select) {
        this.getReport();
      }
    },
    report_select: function() {
      if (this.report_select != null) {
        this.getReport();
      }
    },
  },
  methods: {
    async getListyearReport() {
      let params = {
        bch_id: this.branchs,
        ots_id: this.ots,
      };
      let list = await this.$store.dispatch(
        'otsflatrate/getListyearReport',
        params,
      );
      let arr = [];
      for (let value of list) {
        arr.push(value.years);
      }
      this.listyear = arr;
      if (arr.length > 0) {
        this.years = arr[arr.length - 1];
      } else {
        this.listyear = ['ไม่มีรายงาน'];
        this.years = 'ไม่มีรายงาน';
        this.disabled_rp = true;
      }
    },

    async getBranchs() {
      let params = {
        com_id: this.profile.com_id,
        bch_only: '1',
      };
      await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
        this.available_branchs = data;
      });
      if (!this.permissions.includes('process salary')) {
        var arr = [];
        this.available_branchs.map((bch) => {
          if (this.permissions.includes(bch.brname_en)) {
            arr.push(bch);
          }
        });
        this.available_branchs = arr;
      }
    },

    async getOTType() {
      let params = {
        filter: {
          ...{ company_id: 1 },
        },
      };
      await this.$store.dispatch('ots/list', params);
      const ottypelist = this.$store.getters['ots/list'];
      var arr = [];
      var array = [];
      if (ottypelist) {
        $.each(ottypelist, function(i, v) {
          let item = { id: v['id'], name: v['name'] };
          if ([4, 5, 6].includes(item.id)) {
            arr.push(item);
          } else if ([1, 2, 3].includes(item.id)) {
            array.push(item);
          }
        });
        this.available_ots = arr;
      }
    },

    async listReport() {
      this.available_report = null;
      loading_start();
      let params = {
        ...{ years: this.years ? this.years : {} },
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{ ot_id: this.ots ? this.ots : {} },
      };

      let res = await this.$store.dispatch(
        'otsflatrate/listReportFlatreat',
        params,
      );

      res.map((data) => {
        let from_date_years = moment(data.from_date).year();
        let to_date_years = moment(data.to_date).year();
        let pay_date_years = moment(data.pay_date).year();
        let from_date_years_fmt = from_date_years + 543;
        let to_date_years_fmt = to_date_years + 543;
        let pay_date_years_fmt = pay_date_years + 543;

        data.name_fromdate_th = moment(data.from_date)
          .format('ll')
          .replace(from_date_years, from_date_years_fmt);
        data.name_fromdate_full_th = moment(data.from_date)
          .format('LL')
          .replace(from_date_years, from_date_years_fmt);
        data.name_todate_th = moment(data.to_date)
          .format('ll')
          .replace(to_date_years, to_date_years_fmt);
        data.name_todate_full_th = moment(data.to_date)
          .format('LL')
          .replace(to_date_years, to_date_years_fmt);
        data.name_pay_date_th = moment(data.pay_date)
          .format('ll')
          .replace(pay_date_years, pay_date_years_fmt);
        data.name_pay_date_full_th = moment(data.pay_date)
          .format('LL')
          .replace(pay_date_years, pay_date_years_fmt);
      });

      if (res.length > 0) {
        this.available_report = res;
        this.disabled_rp = false;
      }

      loading_close();
    },

    async getReport() {
      if (this.ots == 5) {
        this.getReportSpa();
      } else {
        this.getReportFlatRate();
      }
    },

    async getReportSpa() {
      loading_start();
      let params = {
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{
          from_date: this.report_select.from_date
            ? this.report_select.from_date
            : {},
        },
        ...{
          to_date: this.report_select.to_date ? this.report_select.to_date : {},
        },
        ...{ ot_id: this.ots ? this.ots : {} },
      };
      await this.approverReport();
      await this.checkApprover();
      this.report = null;
      await this.$store
        .dispatch('otsflatrate/getReport', params)
        .then(async (data) => {
          if (data.length) {
            var temp_approver = [];
            var col = {};
            var list = {};
            var dateall = {};
            var total = {};
            var detl = {};
            $.each(data, function(i, data) {
              data.name_pay_date_th = moment(data.pay_date)
                .add(543, 'year')
                .format('ll');
              data.name_pay_date_full_th = moment(data.pay_date)
                .add(543, 'year')
                .format('LL');
              col = data.data_detail.data;
              detl = data.data_detail.detail;
              $.each(col, function(key, val) {
                dateall[key] = {};
                $.each(val, function(k, v) {
                  list[k] = v;
                  dateall[key][k] = v.t_incentive;
                  dateall[key]['total'] = 0;
                  total[k] = parseFloat(v.total_incentive);
                  total['total'] = 0;
                });
              });
              $.each(list, function(key, val) {
                $.each(dateall, function(k, v) {
                  dateall[k]['0'] = k;
                  if (typeof v[key] == 'undefined') {
                    dateall[k][key] = '-';
                  } else {
                    dateall[k]['total'] += parseFloat(v[key]);
                  }
                });
              });
              temp_approver = Object.values(JSON.parse(data.approver));
            });
            var sum = 0;
            $.each(total, function(key, val) {
              sum += parseFloat(val);
            });
            total['total'] = sum;
            total['0'] = 'รวม';
          }
          await this.setPriority(temp_approver);
          if (data.length > 0) {
            this.available_datasreport = data;
            this.report = data;
            this.data_tfoot = total;
            this.columns = list;
            this.num_col = Object.keys(list).length;
            this.data_rows = dateall;
            this.showbutton = true;
          }
          this.detail = detl;
        });
      loading_close();
    },

    async getReportFlatRate() {
      loading_start();
      let params = {
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{
          from_date: this.report_select.from_date
            ? this.report_select.from_date
            : {},
        },
        ...{
          to_date: this.report_select.to_date ? this.report_select.to_date : {},
        },
        ...{ ot_id: this.ots ? this.ots : {} },
      };
      await this.approverReport();
      await this.checkApprover();
      this.report = null;
      await this.$store
        .dispatch('otsflatrate/getReport', params)
        .then(async (res) => {
          if (res.length) {
            var temp_approver = [];
            var rows = {};
            var list = [];
            $.each(res, function(i, data) {
              list[i] = [];
              let pay_date_year = moment(data.pay_date).year();
              let bud_year = pay_date_year + 543;
              data.name_pay_date_th = moment(data.pay_date)
                .format('ll')
                .replace(pay_date_year, bud_year);
              data.name_pay_date_full_th = moment(data.pay_date)
                .format('LL')
                .replace(pay_date_year, bud_year);
              rows = data.data_detail;
              $.each(rows, function(key_r, row) {
                var d = {};
                var items = []; //person
                $.each(row, function(key, val) {
                  // date
                  $.each(val, function(k, v) {
                    // in date
                    items.push(v);
                    if (v.checkrowc == 0) {
                      d = {
                        checkrowc: 1,
                        checkrowdate: 1,
                        time_to_date: 'รวม',
                        sum_ot: v.total_ot,
                      };
                    }
                  });
                });
                items.push(d);
                list[i].push(items);
              });
              temp_approver = Object.values(JSON.parse(data.approver));
            });
          }
          await this.setPriority(temp_approver);
          if (res.length > 0) {
            this.data_rows = list;
            this.report = res;
          }
        });
      loading_close();
    },

    async showDetail() {
      var headername = {};
      headername.name = 'ประจำงวดจ่าย';
      var pdfname = 'Spa Therapist Insentive Report';
      var font = await this.$store.dispatch('exports/fontthsarabunnew');
      headername.head = 'SPA THERAPIST INCENTIVE';
      headername.name_fromdate_th = moment(this.fromdate).format('LL');
      headername.name_todate_th = moment(this.todate).format('LL');
      var doc = new jsPDF('landscape');
      doc.addFileToVFS('THSarabunNew.ttf', font);
      doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
      doc.setFont('THSarabunNew');
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.text(
        headername.head +
          '\n' +
          headername.name_fromdate_th +
          ' - ' +
          headername.name_todate_th +
          ' ',
        150,
        10,
        'center',
      );
      var notformat = [
        'bill_no',
        'checkrow',
        'date',
        'emp_id',
        'employee_id',
        'first_nameen',
        'from_date',
        'from_time',
        'incentive',
        'last_nameen',
        'incentive',
        'nick_name',
        'notes',
        'ot_id',
        'ot_treatment_id',
        'otname',
        'report_id',
        'room_no',
        'rp_detail_id',
        'rp_sum_id',
        'title_nameen',
        'to_date',
        'to_time',
        'treatment_name',
        'type_ot',
        'type_ot_name',
      ];
      var arr = [];
      var nf = new Intl.NumberFormat('en-US');
      var m = Math;
      $.each(this.detail, function(i, v) {
        var rows = [];
        $.each(v.items, function(key, val) {
          $.each(val, function(k1, v1) {
            if (v1.checkrow == 0) {
              v1.date = v1.date;
            } else {
              v1.date = '';
            }
            Object.keys(v1).forEach((key) => {
              if (!notformat.includes(key)) {
                v1[key] = nf.format(m.round(v1[key]).toFixed(2));
              }
            });
            rows.push(v1);
          });
        });
        var footer = {
          date: '',
          duration: v.total_duration,
          incentive: 'รวมทั้งหมด',
          t_incentive: v.total,
        };
        rows.push(footer);
        var header_emp = {};
        header_emp.title = v.title_nameen;
        header_emp.first_nameen = v.first_nameen;
        header_emp.last_nameen = v.last_nameen;
        header_emp.emp = 'Employee No.';
        header_emp.emp_id = v.emp_id;
        var hname =
          header_emp.title +
          ' ' +
          header_emp.first_nameen +
          ' ' +
          header_emp.last_nameen;
        var emp = header_emp.emp + ' ' + header_emp.emp_id;
        let total = {
          time_to_date: 'รวม',
          duration: v.total_duration,
          total_price: v.total,
        };
        var d = {
          hname: hname,
          emp: emp,
          rows: rows,
          footer: total,
        };
        arr.push(d);
      });
      var first = true;
      arr.forEach((val, key) => {
        var head = [
          [
            {
              content: val.hname,
              colSpan: 11,
              styles: {
                halign: 'center',
                fillColor: [19, 120, 100],
                fontStyle: 'bold',
              },
            },
          ],
          [
            {
              content: val.emp,
              colSpan: 11,
              styles: { halign: 'center', fillColor: [22, 160, 133] },
              fontStyle: 'bold',
            },
          ],
          [
            'DATE',
            'TYPE',
            'BILL NO.',
            'ROOM NO.',
            'TREATMENT BOOKED',
            'FROM TIME',
            'TO TIME',
            'INCENTIVE',
            'DURATION',
            'TOTAL INCENTIVE',
            'REMARK',
          ],
        ];
        var column = [
          { header: 'DATE', dataKey: 'date' },
          { header: 'TYPE', dataKey: 'type_ot_name' },
          { header: 'BILL NO.', dataKey: 'bill_no' },
          { header: 'ROOM NO.', dataKey: 'room_no' },
          { header: 'TREATMENT BOOKED', dataKey: 'treatment_name' },
          { header: 'FROM TIME', dataKey: 'from_time' },
          { header: 'TO TIME', dataKey: 'to_time' },
          { header: 'INCENTIVE', dataKey: 'incentive' },
          { header: 'DURATION', dataKey: 'duration' },
          { header: 'TOTAL INCENTIVE', dataKey: 't_incentive' },
          { header: 'REMARK', dataKey: 'notes' },
        ];
        var config = {
          head: head,
          columns: column,
          theme: 'grid',
          body: val.rows,
          styles: {
            fontSize: 12,
            font: 'THSarabunNew',
          },
          columnStyles: {
            date: {
              halign: 'center',
            },
            bill_no: {
              halign: 'center',
            },
            room_no: {
              halign: 'center',
            },
            from_time: {
              halign: 'center',
            },
            to_time: {
              halign: 'center',
            },
            incentive: {
              halign: 'right',
            },
            duration: {
              halign: 'right',
            },
            t_incentive: {
              halign: 'right',
            },
          },
        };
        if (first == true) {
          config.startY = 22;
          config.startX = 2;
        }
        doc.autoTable(config);
        first = false;
      });
      doc.setProperties({
        title: pdfname,
      });
      window.open(doc.output('bloburl'), '_blank');
      // doc.save(pdfname + '.pdf'); // download
    },

    async deleteReport() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            let params = {
              ...{ report_id: this.report[0].report_id },
              ...{ ots_id: this.ots },
            };
            let resp = await this.$store.dispatch(
              'otsflatrate/deleteReport',
              params,
            );
            if ((resp = 1)) {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'Report has been deleted.',
                'success',
              );
              this.checkAppReport = false;
              this.data_rows = null;
              this.ots = null;
            } else if ((resp = 0)) {
              swalWithBootstrapButtons.fire(
                'Error!',
                'Oops, something went wrong!',
                'error',
              );
            }
          } else if (result.dismiss) {
            swalWithBootstrapButtons.fire('Cancelled', 'Report', 'error');
          }
        });
    },

    async approverReport() {
      let params = {
        bch_id: this.branchs,
        ot_id: this.ots,
      };
      this.persons = await this.$store.dispatch(
        'otsflatrate/approverReport',
        params,
      );
      var emp_id = this.me.employee_id;
      var check = false;
      $.each(this.persons, function(i, o) {
        if (o.priority == 1) {
          if (emp_id == o.emp_id) {
            check = true;
          }
        }
      });
      this.candelete = check;
    },

    async checkApprover() {
      let params = {
        report_id: this.report_select.arr_report_id,
      };
      let resp = await this.$store.dispatch(
        'otsflatrate/checkApproverReport',
        params,
      );
      if (resp.length > 0) {
        this.checkAppReport = false;
      }
    },

    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },

    async setPriority(temp_approver) {
      var persons = [...this.persons];
      let array_approver = [];
      var priority = [];
      var priority_count = [];
      var approve_count = 0;
      temp_approver.forEach((approver) => {
        array_approver.push(approver);
        approve_count++;
      });
      let i = 0;
      persons.forEach((p) => {
        priority.push(p.priority);
        i++;
        priority_count[p.priority] = { pri: p.priority, c: i };
      });
      let now_pri = priority_count.filter((pc) => {
        return pc.c <= approve_count;
      });

      let max_pri = priority_count[1];
      if (now_pri.length > 0) {
        max_pri = now_pri.reduce((max, el) => (max.c > el.c ? max : el));
      }
      persons.forEach(async (p) => {
        p.status = 'disabled';
        let have = await array_approver.find((arr_ap) => {
          return arr_ap == p.approver_id;
        });
        if (have) {
          p.status = 'approved';
        } else if (approve_count < 1) {
          if (p.priority == 1) {
            p.status = 'pending';
          }
        } else {
          if (p.priority == max_pri.pri) {
            p.status = 'pending';
          } else if (
            approve_count >= max_pri.c &&
            p.priority == max_pri.pri + 1
          ) {
            p.status = 'pending';
          }
        }
      });
      persons.sort((a, b) => {
        if (a.number < b.number) return -1;
        if (a.number > b.number) return 1;
        return 0;
      });
      this.persons = await Promise.all(persons);
    },

    clearDepartment() {
      if (this.departments == null) {
        return false;
      }
      this.departments = null;
      this.report = null;
    },

    decodeBase64(data) {
      if (data.length <= 0) {
        return false;
      }
      let decode = this.Base64_decode(data);
      return decode;
    },

    Base64_encode(input) {
      // private property
      var _keyStr =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

      // public method for encoding
      var output = '';
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;

      input = this.Base64_utf8_encode(input);

      while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
          enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }

        output =
          output +
          _keyStr.charAt(enc1) +
          _keyStr.charAt(enc2) +
          _keyStr.charAt(enc3) +
          _keyStr.charAt(enc4);
      }

      return output;
    },

    Base64_decode(input) {
      var _keyStr =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
      var output = '';
      var chr1, chr2, chr3;
      var enc1, enc2, enc3, enc4;
      var i = 0;

      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

      while (i < input.length) {
        enc1 = _keyStr.indexOf(input.charAt(i++));
        enc2 = _keyStr.indexOf(input.charAt(i++));
        enc3 = _keyStr.indexOf(input.charAt(i++));
        enc4 = _keyStr.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        output = output + String.fromCharCode(chr1);

        if (enc3 != 64) {
          output = output + String.fromCharCode(chr2);
        }
        if (enc4 != 64) {
          output = output + String.fromCharCode(chr3);
        }
      }

      output = this.Base64_utf8_decode(output);

      return output;
    },

    Base64_utf8_encode(string) {
      string = string.replace(/\r\n/g, '\n');
      var utftext = '';

      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }

      return utftext;
    },

    Base64_utf8_decode(utftext) {
      var string = '';
      var i = 0;
      var c2 = 0;
      var c1 = c2;
      var c = c1;

      while (i < utftext.length) {
        c = utftext.charCodeAt(i);

        if (c < 128) {
          string += String.fromCharCode(c);
          i++;
        } else if (c > 191 && c < 224) {
          c2 = utftext.charCodeAt(i + 1);
          string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
          i += 2;
        } else {
          c2 = utftext.charCodeAt(i + 1);
          c3 = utftext.charCodeAt(i + 2);
          string += String.fromCharCode(
            ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63),
          );
          i += 3;
        }
      }

      return string;
    },

    securePrivatecode(private_code) {
      let employee = JSON.parse(localStorage.getItem('employees'));
      if (private_code && employee.privatecode) {
        if (employee.privatecode === md5(private_code)) {
          localStorage.setItem('privatecode', private_code);
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    async exportPDF() {
      if (typeof this.report_select['years'] == 'undefined') {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกรายงาน');
        return false;
      }
      var headername = {};
      headername.name = 'ประจำงวดจ่าย';

      var font = await this.$store.dispatch('exports/fontthsarabunnew');
      headername.bch_nameth = this.report[0].bch_nameth;
      headername.pay_date = this.report[0].name_pay_date_full_th;
      if (this.ots != 5) {
        var pdfname = 'รายงาน OT เหมาจ่าย';
        // headername.bch_nameth = this.available_report[0].bch_nameth;
        // headername.pay_date = this.available_report[0].name_pay_date_full_th;
      } else {
        var pdfname = 'รายงานค่านวดสปา';
        // headername.bch_nameth = this.available_datasreport[0].bch_nameth;
        // headername.pay_date = this.available_datasreport[0].name_pay_date_full_th;
      }
      let doc;
      if (this.ots == 4) {
        doc = new jsPDF();
      } else if (this.ots == 6 || this.ots == 5) {
        doc = new jsPDF('landscape');
      }
      doc.addFileToVFS('THSarabunNew.ttf', font);
      doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
      doc.setFont('THSarabunNew');
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      if (this.ots == 4) {
        doc.text(
          headername.bch_nameth +
            '\n' +
            'ค่าล่วงเวลาเหมาจ่าย ประจำรอบเดือน วันที่' +
            ' ' +
            headername.pay_date,
          105,
          10,
          'center',
        );
      } else if (this.ots == 6) {
        doc.text(
          headername.bch_nameth +
            '\n' +
            'ค่าล่วงเวลาเหมาจ่าย ประจำรอบเดือน วันที่' +
            ' ' +
            headername.pay_date,
          148,
          10,
          'center',
        );
      } else if (this.ots == 5) {
        doc.text(
          headername.bch_nameth +
            '\n' +
            'ค่ามือพนักงานสปาประจำรอบเดือน วันที่' +
            ' ' +
            headername.pay_date,
          148,
          10,
          'center',
        );
      }

      var body = [];

      this.report.forEach(async (rp, i) => {
        await Promise.all(
          this.data_rows[i].map(async (rows) => {
            body = body.concat(rows);
          }),
        );
      });

      var rows = [];
      let temp = [];
      if (this.ots != 5) {
        for (const [k_bd, bd] of Object.entries(body)) {
          let b = { ...bd };
          if (b.checkrowc == 0) {
            b.fullname_en = Object.assign(
              b.title_nameen + b.first_nameen + ' ' + b.last_nameen,
            );
            b.emp_id = b.emp_id;

            b.dep_nameen = b.dep_nameen;
            b.pos_nameen = b.pos_nameen;
          } else {
            b.emp_id = '';
            b.fullname_en = '';
            b.dep_nameen = '';
            b.pos_nameen = '';
          }
          if (b.checkrowdate == 0) {
            b.fromdate = b.fromdate;
          } else {
            b.fromdate = '';
          }
          temp.push(b);
        }
        rows = temp;
      } else {
        rows = body;
      }

      var list_name = [];
      var list_emp_id = [];
      $.each(this.columns, function(key, val) {
        var name = val.title_nameen + val.first_nameen + ' ' + val.last_nameen;
        var emp_id = val.emp_id;
        list_name.push(name);
        list_emp_id.push(emp_id);
      });
      var head = [];
      head[0] = [
        {
          content: 'วันที่',
          rowSpan: 3,
          styles: {
            halign: 'center',
            fillColor: [22, 160, 133],
          },
        },
        {
          content: 'SPA THERAPIST INCENTIVE',
          colSpan: this.num_col,
          styles: {
            halign: 'center',
            fillColor: [22, 160, 133],
          },
        },
        {
          content: 'รวมค่ามือที่ต้องจ่าย',
          rowSpan: 3,
          styles: {
            halign: 'center',
            fillColor: [22, 160, 133],
          },
        },
      ];
      var col2 = [];
      var col3 = [];
      $.each(list_name, function(key, val) {
        var items = {
          content: val,
          styles: {
            halign: 'center',
            fillColor: [22, 160, 133],
          },
        };
        col2.push(items);
      });
      $.each(list_emp_id, function(key, val) {
        var items = {
          content: val,
          styles: {
            halign: 'center',
            fillColor: [22, 160, 133],
          },
        };
        col3.push(items);
      });
      head[1] = col2;
      head[2] = col3;
      if (this.ots == 4) {
        var columns = [
          { header: 'รหัสพนักงาน', dataKey: 'emp_id' },
          { header: 'ชื่อพนักงาน', dataKey: 'fullname_en' },
          { header: 'วันที่ทำโอที', dataKey: 'fromdate' },
          { header: 'ตำแหน่งงาน', dataKey: 'otType_name' },
          { header: 'ตั้งแต่เวลา', dataKey: 'time_from_date' },
          { header: 'ถึงเวลา', dataKey: 'time_to_date' },
          { header: 'รวมโอทีคิดเป็นเที่ยว', dataKey: 'sum_ot' },
        ];
      } else if (this.ots == 6) {
        var columns = [
          { header: 'รหัสพนักงาน', dataKey: 'emp_id' },
          { header: 'ชื่อพนักงาน', dataKey: 'fullname_en' },
          { header: 'แผนก', dataKey: 'dep_nameen' },
          { header: 'ตำแหน่ง', dataKey: 'pos_nameen' },
          { header: 'วันที่ทำโอที', dataKey: 'fromdate' },
          { header: 'ชื่อรายการ', dataKey: 'otType_name' },
          { header: 'ตั้งแต่เวลา', dataKey: 'time_from_date' },
          { header: 'ถึงเวลา', dataKey: 'time_to_date' },
          { header: 'อัตรา/รอบ', dataKey: 'sum_ot' },
          { header: 'รายละเอียด', dataKey: 'notes' },
        ];
      }
      var list_body = [];
      if (this.ots == 5) {
        $.each(rows, function(key, val) {
          var contentlist = [];
          var b = [];
          $.each(val, function(k, v) {
            b = {
              content: v,
              styles: {
                halign: 'right',
              },
            };
            contentlist.push(b);
          });
          list_body.push(contentlist);
        });
        var t = [];
        var tfoot = [];
        $.each(this.data_tfoot, function(key, val) {
          t = {
            content: val,
            styles: {
              halign: 'right',
            },
          };
          tfoot.push(t);
        });
        list_body.push(tfoot);
        rows = list_body;
      }

      var styles = {
        fontSize: 12,
        font: 'THSarabunNew',
        cellPadding: 0.8,
        lineColor: [19, 120, 100],
        lineWidth: 0.001,
      };
      if (this.ots != 5) {
        var config = {
          startY: 22, // ตำแหน่งที่เริ่ม
          startX: 2, // ตำแหน่งที่เริ่ม
          theme: 'grid',
          styles: styles,
          margin: { left: 10, right: 10 },
          showHead: 'everyPage',
          columns: columns,
          body: rows,
          columnStyles: {
            emp_id: {
              //cellWidth: 13,
            },
            fullname_en: {
              //cellWidth: 16,
            },
            fromdate: {
              halign: 'center',
              //cellWidth: 16,
            },
            otType_name: {
              halign: 'center',
              //cellWidth: 16,
            },
            time_from_date: {
              halign: 'center',
              //cellWidth: 13,
            },
            time_to_date: {
              halign: 'center',
              //cellWidth: 6,
            },
            sum_ot: {
              halign: 'right',
              //cellWidth: 13,
            },
          },
        };
      } else {
        var config = {
          head: head,
          startY: 22, // ตำแหน่งที่เริ่ม
          startX: 2, // ตำแหน่งที่เริ่ม
          theme: 'grid',
          styles: styles,
          margin: { left: 10, right: 10 },
          showHead: 'everyPage',
          body: list_body,
        };
      }
      doc.autoTable(config);
      doc.setProperties({
        title: pdfname,
      });
      window.open(doc.output('bloburl'), '_blank');
    },
    to_Base64(img) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(img);
      });
    },
    number_format(num = 0) {
      let new_num = parseFloat(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return new_num;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons');
.box-header-salary {
  text-align: center;
  font-weight: bold;
  font-family: 'Kanit';
  padding: 1em 0;
}
.tablebordercolor .theadbordercolor > tr > th {
  background-color: #343a40;
  border-color: #ddd;
}
.background-white {
  background-color: white;
}
.font-imp {
  font-size: 18px !important;
  color: rgb(255, 255, 255) !important;
}
.font-size {
  font-size: 12px !important;
}
.text_r {
  text-align: right;
}
.header_table {
  position: sticky;
  top: 0;
}
</style>
