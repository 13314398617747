import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/weekends`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.get(`${url}/v1/weekends/${id}`, options).then((response) => {
    let weekend = jsona.deserialize(response.data);
    delete weekend.links;
    return weekend;
  });
}

function add(weekend) {
  const payload = jsona.serialize({
    stuff: weekend,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.post(`${url}/v1/weekends`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(weekend) {
  const payload = jsona.serialize({
    stuff: weekend,
    includeNames: [],
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .patch(`${url}/v1/weekends/${weekend.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

// function destroy(id) {
// 	const options = {
// 		headers: {
// 			Accept: 'application/vnd.api+json',
// 			'Content-Type': 'application/vnd.api+json',
// 		},
// 	};

// 	return axios.delete(`${url}/users/${id}`, options);
// }

export default {
  list,
  get,
  add,
  update,
  // destroy,
};
