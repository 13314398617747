<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>lock</md-icon>
          </div>
          <h4 class="title"></h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout spacing">
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <md-radio v-model="radio_select" value="byrole">Role</md-radio>
            </div>
            <div class="md-layout-item md-size-20 mt-2 md-small-size-40">
              <md-field>
                <label for="role">Role</label>
                <md-select v-model="roles">
                  <md-option
                    v-for="item in available_roles"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout spacing">
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <md-radio v-model="radio_select" value="byuser">User</md-radio>
            </div>
            <filter-employee
              :headLabel="headLabel"
              :permissionsList="permissions"
              @childBranches="setBranches"
              @childStaffs="setStaffs"
            >
            </filter-employee>
          </div>
          <div class="md-layout spacing">
            <div
              class="md-layout-item md-size-100 mt-2 md-small-size-100 text-center"
            >
              <md-button @click="searchPermissionAccess" class="md-info"
                >Search</md-button
              >
            </div>
          </div>

          <div class="md-layout">
            <div
              class="md-layout-item md-size-100 mt-4 md-small-size-100 text-right"
            >
              <md-button
                @click="goToAdd()"
                class="md-primary md-dense"
                :disabled="disable_add_permission"
              >
                เพิ่ม สิทธิ์การใช้งาน
              </md-button>
            </div>
          </div>

          <md-table
            :value="tableData"
            class="paginated-table table-striped table-hover"
            table-header-color="green"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="สิทธิ์การใช้งาน">{{
                item.name
              }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button
                  class="md-icon-button md-raised md-round md-danger"
                  @click="revokeDirectPermission(item.name)"
                  style="margin: .2rem;"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import Vuex from 'vuex';
import employeeMixin from '@/mixins/employee-mixin';
import FilterEmployee from '@/components/FilterEmployee';
import Swal from 'sweetalert2';

export default {
  components: {
    'filter-employee': FilterEmployee,
  },
  mixins: [employeeMixin],

  data: () => ({
    headLabel: '',
    branches: [
      {
        id: null,
        type: 'branches',
      },
    ],
    tableData: [],
    roles: null,
    available_roles: [],
    radio_select: 'byrole',
    employee: {
      id: null,
      first_name: null,
      last_name: null,
      full_name: null,
    },
    staffList: [],
    //permissions: [],
    employee_id: null,
    direct_permission: {
      type: 'assignpermission-direct',
      to_target: null,
      status: 'assign',
      permissions: null,
    },
    disable_add_permission: true,
    functype_type: null,
  }),

  created() {
    this.getRole();
    if (this.$route.params.type_name) {
      this.radio_select = this.$route.params.type_name;
    }
    if (this.$route.params.id && !isNaN(this.$route.params.id)) {
      if (this.$route.params.type_name == 'byrole') {
        this.roles = this.$route.params.id;
      } else {
        this.employee_id = this.$route.params.id;
      }

      this.searchPermissionAccess();
    }
  },

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      let pms = [];
      if (this.me) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }

      return pms;
    },
  },
  watch: {
    employee_id() {
      this.disable_add_permission = true;
      if (this.employee_id) {
        this.disable_add_permission = false;
      }
    },
    roles() {
      this.disable_add_permission = true;
      if (this.roles) {
        this.disable_add_permission = false;
      }
    },
  },

  methods: {
    async searchPermissionAccess() {
      let params = {};
      this.functype_type = this.radio_select;
      if (this.radio_select == 'byrole') {
        if (!this.roles) {
          await this.$store.dispatch('alerts/error', 'กรุณาเลือก Role');
          return false;
        }
        params = {
          ...{ id: this.roles },
          ...{ type: 'role' },
        };
      } else {
        if (!this.employee_id) {
          await this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
          return false;
        }

        params = {
          ...{ id: this.employee_id },
          ...{ type: 'user' },
        };
      }

      await this.$store
        .dispatch('permissions/getUserPermission', params)
        .then(() => {
          this.tableData = [];
          if (this.$store.getters['permissions/userpermission']) {
            this.tableData = this.$store.getters['permissions/userpermission'];
          } else {
            this.$store.dispatch('alerts/error', 'No data!!!');
          }
        });
    },

    async getRole() {
      await this.$store.dispatch('roles/list').then(() => {
        this.available_roles = this.$store.getters['roles/dropdown'];
      });
    },

    /**
     * set branch value from child component
     */
    setBranches(v) {
      this.branches = v;
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employee_id = v.id;
      }
    },

    /**
     * revoke direct permission to user
     */
    async revokeDirectPermission(name) {
      this.direct_permission.status = 'revoke';
      this.direct_permission.permissions = name;

      const confirmation = await Swal.fire({
        title: 'Remove this permission?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        try {
          if (this.functype_type == 'byrole') {
            this.direct_permission.to_target = this.roles;
            await this.$store.dispatch(
              'permissions/assignRolePermission',
              this.direct_permission,
            );
          } else {
            this.direct_permission.to_target = this.employee_id;
            await this.$store.dispatch(
              'permissions/assignDirectPermission',
              this.direct_permission,
            );
          }
          await this.$store.dispatch(
            'alerts/success',
            'Permission added successfully.',
          );
          this.searchPermissionAccess();
        } catch (e) {
          if (e.response.data.errors[0]) {
            await this.$store.dispatch(
              'alerts/error',
              e.response.data.errors[0].title,
            );
          } else {
            await this.$store.dispatch(
              'alerts/error',
              'Oops, something went wrong!',
            );
          }
        }
      }
    },

    /**
     * add direct permission to user
     */
    goToAdd() {
      let id = null;
      if (this.functype_type == 'byrole') {
        id = this.roles;
      } else {
        id = this.employee_id;
      }

      this.$router.push({
        name: 'add Permissions Access',
        params: { id: id, type_name: this.radio_select },
      });
    },
  },
};
</script>
