import qs from 'qs';
import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

async function getFont(params) {
  const response = await axios.get(`${url}/v1/getfont`, { params });
  return response.data;
}
async function getBranchLogo(params) {
  const response = await axios.get(`${url}/v1/getbranchlogo`, { params });
  return response.data;
}

export default {
  getFont,
  getBranchLogo,
};
