<template>
  <div>
    <md-dialog
      :md-active.sync="showSetting"
      style="min-width: 60%;"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.5rem">
        เพิ่มการตั้งค่า
      </md-dialog-title>
      <md-dialog-content style="font-size: 1.2rem">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <employee-weekend
              :employeeID="emp_id_setting"
              :permissions="permissions"
            ></employee-weekend>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showSetting = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon style="transform: scaleX(-1);">
                manage_accounts
              </md-icon>
            </div>
            <h5 class="title">ตั้งค่าวันหยุดประจำสัปดาห์</h5>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
              >
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-70 mt-2 md-small-size-100 md-xsmall-size-100 md-alignment-left"
                  >
                    <filter-employee
                      :permissionsList="permissions"
                      @childBranches="setBranchs"
                      @childDepartment="setDepartments"
                      @childStaffs="setStaffs"
                    >
                    </filter-employee>
                  </div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                </div>
              </div>
            </div>
            <md-table
              :value="tableData"
              class="paginated-table table-striped table-hover"
              table-header-color="green"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="รหัสพนักงาน">
                  <span class="upfont">
                    {{ item.emp_code }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="ชื่อพนักงาน">
                  <span class="upfont">
                    {{ item.first_nameth }}
                    {{ item.last_nameth }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="Action">
                  <b-button
                    variant="success"
                    @click="get_setting_weekend(item.emp_id)"
                  >
                    ตั้งค่า
                  </b-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import _ from 'lodash';
import Vuex from 'vuex';
import FilterEmployee from '@/components/FilterEmployee';
import EmployeeWeekend from '@/components/EmployeeWeekend';
import { loading_start, loading_close } from '@/utils/loading.js';
import moment from 'moment';

export default {
  components: {
    'filter-employee': FilterEmployee,
    'employee-weekend': EmployeeWeekend,
  },
  data() {
    return {
      editData: {},
      tableData: [],
      sortation: {
        field: 'created_at',
        order: 'asc',
      },
      boolean: true,
      status: 'active',
      permissions: [],
      staffs: null,
      employee_id: null,
      departments: null,
      branchs: null,
      employee: null,
      showSetting: false,
      emp_id_setting: null,
    };
  },
  async created() {
    await this.setPermission(this.me);
  },
  watch: {
    departments: function(val) {
      if (val != null) {
        this.getListEmp();
      }
    },
    employee_id: function(val) {
      if (val != null) {
        this.getListEmp();
      }
    },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  methods: {
    async getListEmp() {
      loading_start();
      let params = {
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{ dep_id: this.departments ? this.departments : {} },
        ...{ emp_id: this.employee_id ? this.employee_id : {} },
      };
      this.tableData = await this.$store.dispatch('staffs/searchStaff', params);
      loading_close();
    },

    async get_setting_weekend(emp_id) {
      if (emp_id == null) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return false;
      }
      this.emp_id_setting = emp_id;
      this.showSetting = true;
    },

    // set branch value from child component
    async setBranchs(v) {
      if (v && v != null) {
        this.branchs = v;
      }
    },
    // set departments value from child component
    async setDepartments(v) {
      if (v && v != []) {
        this.departments = v;
      }
    },
    // set staffs value from child component
    async setStaffs(v) {
      if (v && v != []) {
        this.employee_id = v;
      } else {
        this.employee_id = null;
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },
  },
};
</script>
<style>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
</style>
