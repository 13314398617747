<template>
  <div class="BoxSpaAppved box-appv">
    <b-container>
      {{ persons.lenght }}
      <b-row v-if="persons.length > 1">
        <b-col
          md="6"
          sm="12"
          v-for="item_persons in persons"
          :key="item_persons.id"
          :value="item_persons.id"
        >
          <div class="box-appv-control">
            <div class="box-appv-person">
              <strong>
                {{ item_persons.firstname_th }} {{ item_persons.lastname_th }}
                {{ item_persons.position_detail }}
              </strong>
              <div>
                <a v-if="item_persons.status == 'approved'">
                  <md-icon class="approve">
                    check_circle
                  </md-icon>
                </a>
                <div v-if="item_persons.status == 'pending'">
                  <a
                    class="pointer"
                    @click="salary_accept(item_persons.approver_id)"
                    v-if="item_persons.emp_id == profile.emp_id"
                  >
                    <md-icon class="pending">
                      pending
                    </md-icon>
                  </a>
                  <a v-else>
                    <md-icon class="pending">
                      pending
                    </md-icon>
                  </a>
                </div>
                <a v-else-if="item_persons.status == 'disabled'"></a>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col
          md="12"
          sm="12"
          v-for="item_persons in persons"
          :key="item_persons.id"
          :value="item_persons.id"
        >
          <div class="box-appv-control">
            <div class="box-appv-person">
              <strong>
                {{ item_persons.firstname_th }} {{ item_persons.lastname_th }}
                {{ item_persons.position_detail }}
              </strong>
              <div>
                <a v-if="item_persons.status == 'approved'">
                  <md-icon class="approve">
                    check_circle
                  </md-icon>
                </a>
                <div v-if="item_persons.status == 'pending'">
                  <a
                    class="pointer"
                    @click="salary_accept(item_persons.approver_id)"
                    v-if="item_persons.emp_id == profile.emp_id"
                  >
                    <md-icon class="pending">
                      pending
                    </md-icon>
                  </a>
                  <a v-else>
                    <md-icon class="pending">
                      pending
                    </md-icon>
                  </a>
                </div>
                <a v-else-if="item_persons.status == 'disabled'"></a>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import md5 from 'js-md5';
// import SignaturePad from 'signature_pad';

export default {
  name: 'BoxAppved',
  data() {
    return {
      // sign_pad: {},
    };
  },
  created() {},
  updated: function() {},
  mounted: function() {},
  props: {
    profile: {
      type: Object,
    },
    report_select: {
      type: Object,
    },
    persons: {
      type: Array,
    },
    branchs: {
      type: Number,
      default: null,
    },
    ot_id: {
      type: Number,
      default: null,
    },
  },
  computed: {},
  watch: {},
  methods: {
    async salary_accept(approver_id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Approve it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            let params = {
              ...{ approver_id: approver_id ? approver_id : {} },
              ...{ bch_id: this.branchs ? this.branchs : {} },
              ...{ ot_id: this.ot_id ? this.ot_id : {} },
              ...{ from_date: this.report_select.from_date },
              ...{ to_date: this.report_select.to_date },
              // ...{ stafftype: this.report_select.stafftype },
            };
            this.$store.dispatch('otsflatrate/approveReport', params).then(
              async (data) => {
                Swal.fire('Approve!', '', 'success');
                this.$emit('getreport');
              },
              (err) => {
                Swal.fire('Error!', '', 'error');
              },
            );
          } else if (result.dismiss) {
            swalWithBootstrapButtons.fire('Cancelled', 'Approve', 'error');
          }
        });
    },

    // async securePrivatecode(private_code) {
    //   let employee = JSON.parse(localStorage.getItem('employees'));
    //   if (private_code && employee.privatecode) {
    //     if (employee.privatecode === md5(private_code)) {
    //       localStorage.setItem('privatecode', private_code);
    //       return true;
    //     } else {
    //       // await this.$store.dispatch(
    //       //   'alerts/error',
    //       //   'Invalid private code !!!',
    //       // );
    //       return false;
    //     }
    //   }
    //   return false;
    // },
  },
};
</script>
<style lang="scss">
.BoxSpaAppved {
  .md-icon.md-icon-font.approve {
    font-size: 35px !important;
    color: rgb(0, 160, 0);
  }
  .md-icon.md-icon-font.pending {
    font-size: 35px !important;
    color: #feba12;
  }
  .box-appv-person {
    font-weight: bold;
    font-size: 16.5px;
    font-family: 'Kanit';
    text-align: center;
    padding: 0 1.5em;
    display: inline-block;
    width: 100%;
    margin-top: 0.5em;
  }

  .box-appv-control {
    margin-top: 2em;
  }

  .pointer {
    cursor: pointer;
  }
}
</style>
