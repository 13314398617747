<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(savePrivatecode)">
      <md-card>
        <md-card-header class="md-card-header-icon">
          <div class="card-icon">
            <md-icon>lock</md-icon>
          </div>
          <h4 class="title">Private code setup</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout spacing">
            <div class="md-layout-item md-size-40 mt-2 md-small-size-40">
              <md-button
                class="md-primary md-round md-theme-default"
                @click="active = true"
                :disabled="disabled_pvcode"
                ><md-icon>lock</md-icon>Private code</md-button
              >
            </div>
            <div>
              <md-dialog-prompt
                :md-active.sync="active"
                v-model="privatecodeIn"
                md-title="Admin Private code"
                md-input-maxlength="30"
                md-input-placeholder="Type your private code..."
                md-confirm-text="Send"
              />
            </div>
            <div class="md-layout-item md-size-10">
              <md-progress-spinner
                :md-diameter="50"
                :md-stroke="3"
                md-mode="indeterminate"
                :md-value="100"
                v-show="showProgressBar"
              >
              </md-progress-spinner>
            </div>
          </div>

          <div class="md-layout spacing">
            <div class="md-layout-item md-size-20">
              <md-field>
                <label>เลือก สำนักงาน</label>
                <md-select v-model="branches" :disabled="invalid_privatecode">
                  <md-option
                    v-for="item in available_branches"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-autocomplete
                v-model="staffSearchTerm"
                :md-options="staffs"
                @md-changed="searchStaffs"
                @md-opened="searchStaffs"
                @md-selected="selectStaff"
                :disabled="disabled_employee"
              >
                <label>เลือก พนักงาน</label>
                <template slot="md-autocomplete-item" slot-scope="{ item }">
                  {{ item.first_nameen + ' ' + item.last_nameen }}
                </template>
              </md-autocomplete>
            </div>
          </div>

          <!-- <div class="md-layout spacing">
          <div class="md-layout-item md-size-30">
            <ValidationProvider
              name="password"
              rules="required|min:6"
              v-slot="{ passed, failed }"
            >
            <md-field :class="[
                { 'md-error': failed },
                { 'md-valid': passed },
              ]">
              <label>Staff Private code 6 character</label>
              <md-input v-model="private_code" type="password" :disabled="invalid_privatecode" />
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
            </ValidationProvider>
          </div>
        </div> -->
        </md-card-content>

        <md-card-actions>
          <md-button type="submit" :disabled="disable_button">
            Setup Privatecode
          </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>

<script>
import { extend } from 'vee-validate';
import formMixin from '@/mixins/form-mixin';
import $ from 'jquery';
import Swal from 'sweetalert2';
import md5 from 'js-md5';
export default {
  name: 'privatecode-card',
  props: {
    user: Object,
    available_branches: Array,
  },
  components: {},
  mixins: [formMixin],
  data() {
    return {
      privatecode_data: {
        type: 'privatecode-setup',
        adminprivatecode: null,
        privatecode: null,
        to_employee: null,
        status: null,
      },
      private_code: null,
      disabled_pvcode: false,
      disabled_employee: true,
      active: false,
      privatecodeIn: null,
      branches: null,
      staffSearchTerm: this.value,
      showProgressBar: false,
      staffs: [],
      employee_id: null,
      invalid_privatecode: true,
      disable_button: true,
    };
  },
  watch: {
    privatecodeIn: function() {
      this.securePrivatecode(this.privatecodeIn);
    },
    user: function() {
      this.securePrivatecode(localStorage.getItem('privatecode'));
    },
    branches: function() {
      this.disabled_employee = true;
      if (this.branches.length > 0) {
        this.getEmployee();
      }
    },
    value(val) {
      this.staffSearchTerm = val;
    },
    employee_id(v) {
      this.disable_button = true;
      if (v) {
        this.disable_button = false;
      }
    },
  },
  async mounted() {
    this.staffSearchTerm = this.staffs.first_nameen; // !!!here's the thing!!!
    this.employee_id = this.staffs.emp_id;
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        if (!res) {
          return;
        }
        this.$emit('on-validated', res);
        return res;
      });
    },

    async savePrivatecode() {
      const confirmation = await Swal.fire({
        title: 'New Private code',
        type: 'warning',
        input: 'text',
        // input: 'password',
        inputLabel: 'New Private code',
        inputPlaceholder: 'Enter Private Code',
        inputAttributes: {
          max: 20,
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Gen new Private code',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
        preConfirm: () => {
          if (!$('.swal2-input').val()) {
            Swal.showValidationMessage('กรุณากรอก New Private Code!');
          }
          return [true, $('.swal2-input').val()];
        },
      });
      if (confirmation.dismiss) {
      } else {
        if (confirmation.value[0] === true) {
          if (confirmation.value[1].length < 8) {
            await this.$store.dispatch(
              'alerts/error',
              'กรุณากรอกรหัสให้มากกว่า 7 ตัว',
            );
            return false;
          }
          // this.privatecode_data.privatecode = this.genPrivateCode(); old
          this.privatecode_data.privatecode = confirmation.value[1];
          this.privatecode_data.status = 'new';
          this.privatecode_data.to_employee = this.employee_id;
          try {
            await this.$store.dispatch(
              'users/privateSetup',
              this.privatecode_data,
            );
            await this.$store.dispatch(
              'alerts/success',
              'Setup private code successfully.',
            );
            this.employee_id = null;
            this.staffSearchTerm = '';
          } catch (e) {
            if (e.response.data.errors[0]) {
              await this.$store.dispatch(
                'alerts/error',
                e.response.data.errors[0],
              );
            } else {
              await this.$store.dispatch(
                'alerts/error',
                'Oops, something went wrong!',
              );
            }
          }
        }
      }
    },

    /**
     * function for check private code
     * for display salary
     */
    async securePrivatecode(private_code) {
      let employee = JSON.parse(localStorage.getItem('employees'));
      if (private_code && employee.privatecode) {
        if (employee.privatecode === md5(private_code)) {
          localStorage.setItem('privatecode', private_code);
          this.privatecode_data.adminprivatecode = private_code;
          this.disabled_salary = false;
          this.disabled_pvcode = true;
          this.user.privatecode = private_code;
          this.invalid_privatecode = false;
          return true;
        }
        {
          await this.$store.dispatch(
            'alerts/error',
            'Invalid private code !!!',
          );
        }
      }
      return false;
    },

    /**
     * get staff with branch
     */
    async getEmployee() {
      // const branch_id = this.branches;
      let params = {
        filter: {
          ...{ igr_emp: '99999999' },
          ...{ status: 'active' },
          ...(this.branches ? { branch: this.branches } : {}),
        },
      };
      this.showProgressBar = true;

      await this.$store.dispatch('staffs/list', params);
      this.staffList = this.$store.getters['staffs/list'];
      if (this.staffList.length > 0) {
        this.disabled_employee = false;
      }
      this.showProgressBar = false;
    },
    /**
     * find staff
     */
    searchStaffs(searchTerm) {
      this.staffs = new Promise((resolve) => {
        window.setTimeout(() => {
          if (!searchTerm) {
            resolve(this.staffList);
          } else {
            const term = searchTerm.toLowerCase();
            resolve(
              this.staffList.filter(
                ({
                  emp_id,
                  first_nameth,
                  last_nameth,
                  first_nameen,
                  last_nameen,
                }) =>
                  first_nameth.toLowerCase().includes(term) ||
                  last_nameth.toLowerCase().includes(term) ||
                  first_nameen.toLowerCase().includes(term) ||
                  last_nameen.toLowerCase().includes(term) ||
                  emp_id.toLowerCase().includes(term),
              ),
            );
          }
        }, 500);
      });
    },

    /**
     * staff select
     */
    selectStaff(c) {
      this.staffSearchTerm = c.first_nameen + ' ' + c.last_nameen; // !!!here's the thing!!!
      this.employee_id = c.id;
    },

    // random special char
    randomSpecialChar() {
      // example set of special chars as a string in no particular order
      var s = '_+*#!$%&/()=?';

      // generating a random index into the string and extracting the character at that position
      return s.substr(Math.floor(s.length * Math.random()), 1);
    },

    genPrivateCode() {
      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
      // eslint-disable-next-line prettier/prettier
      return (
        Math.random()
          .toString(36)
          .substr(2, 7) + this.randomSpecialChar()
      );
    },
  },
};
</script>

<style scoped></style>
