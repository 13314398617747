<template>
  <md-card>
    <md-card-header>
      <div class="card-icon md-card-header-green">
        <md-icon>update</md-icon>
      </div>
      <h4 class="title"></h4>
    </md-card-header>
    <md-card-content>
      <div class="md-layout">
        <div
          class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
        >
          <md-field class="searchbranchs">
            <label for="branch_id">เลือก สำนักงาน</label>
            <md-select v-model="branch_id" name="branch_id">
              <md-option
                v-for="item in available_branchs"
                :key="item.bch_id"
                :value="item.bch_id"
              >
                {{ item.brname_th }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-20 md-medium-size-20 md-small-size-50 md-xsmall-size-100"
        >
          <md-checkbox v-model="branch_all">
            <label for="branchall">ตั้งค่าทุกสาขา</label>
          </md-checkbox>
        </div>
        <div
          class="md-layout-item md-large-size-100 md-size-0 md-medium-size-0 md-small-size-0 md-xsmall-size-0"
        ></div>
        <div
          class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <md-switch v-model="limit_status">
            <label for="limitot">จำกัดชั่วโมง OT (Limit OT)</label>
          </md-switch>
        </div>
        <div
          class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <md-field>
            <label for="limithour">ชั่วโมงที่จำกัด OT</label>
            <md-input type="number" min="1" v-model="limit_hour" />
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <md-switch v-model="default_status">
            <label for="limitot">ตั้งค่า OT (Fixed OT)</label>
          </md-switch>
        </div>
        <div
          class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <md-field>
            <label for="ot">เลือก OT</label>
            <md-select v-model="default_ot">
              <md-option v-for="item in ot" :key="item.id" :value="item.id">
                {{ item.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout md-alignment-center md-layout-item md-size-100 spacing"
        >
          <md-button class="md-info" @click="submitSetOT">ยืนยัน</md-button>
        </div>
        <div class="md-layout-item md-size-100">
          <b-table
            id="table-otlistsetting"
            :fields="fields"
            :items="otlistsetting"
            :current-page="currentPage"
            per-page="10"
            head-variant="light"
            responsive
            hover
            show-empty
          >
            <template #cell(limit_ot_status)="data">
              <md-switch :class="data.value == 1 ? 'md-checked' : ''">
                <label for="limit_ot_status">
                  {{ data.value == 1 ? 'เปิด' : 'ปิด' }}
                </label>
              </md-switch>
            </template>
            <template #cell(default_ot_status)="data">
              <md-switch :class="data.value == 1 ? 'md-checked' : ''">
                <label for="default_ot_status">
                  {{ data.value == 1 ? 'เปิด' : 'ปิด' }}
                </label>
              </md-switch>
            </template>
            <template #empty>
              <center><h4>ไม่มีการตั้งค่า</h4></center>
            </template>
          </b-table>
          <div class="overflow-auto">
            <b-pagination
              v-if="otlistsetting.length > 0"
              v-model="currentPage"
              align="right"
              aria-controls="table-otlistsetting"
              :total-rows="otlistsetting.length"
              per-page="10"
              size="lg"
              limit="7"
            ></b-pagination>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import FilterEmployee from '@/components/FilterEmployee.vue';
import employeeMixin from '@/mixins/employee-mixin';
export default {
  components: {
    // 'employee-filter': FilterEmployee,
  },
  mixins: [employeeMixin],
  data() {
    return {
      profile: null,
      permissions: [],
      available_branchs: [],
      default_ot: null,
      limit_status: false,
      limit_hour: 0,
      default_status: false,
      branch_all: false,
      branch_id: null,
      ot: [],
      fields: [
        { key: 'br_nameth', label: 'สำนักงาน' },
        { key: 'limit_ot_status', label: 'จำกัดชั่วโมง (เปิด/ปิด)' },
        { key: 'limit_ot_hour', label: 'ชั่วโมงที่จำกัด OT' },
        { key: 'default_ot_status', label: 'ตั้งค่า OT (เปิด/ปิด)' },
        { key: 'ot_name', label: 'จำนวนเท่า OT ที่อนุมัติ' },
      ],
      otlistsetting: [],
      currentPage: 1,
    };
  },
  async created() {
    if (this.$store.getters['profile/me']) {
      this.profile = await this.$store.getters['profile/me'];
    }
    this.setPermission(this.profile).then(async () => {
      await this.getBranch();
      await this.getOTlist();
      await this.getOTlistSetting();
    });
  },
  methods: {
    async getBranch() {
      let params = {
        com_id: this.profile.employees.branchs.company_id,
        bch_only: '1',
      };
      await this.$store
        .dispatch('staffs/searchStaff', params)
        .then(async (data) => {
          this.available_branchs = await data.filter((branch) => {
            return this.permissions.includes(branch.brname_en);
          });
        });
    },

    async setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      return await Promise.all(this.permissions);
    },

    async getOTlist() {
      let params = {
        filter: { company_id: this.profile.employees.branchs.company_id },
      };
      await this.$store.dispatch('ots/list', params);
      this.ot = await this.$store.getters['ots/list'];
    },

    async getOTlistSetting() {
      let params = {
        com_id: this.profile.employees.branchs.company_id,
      };
      this.otlistsetting = await this.$store.dispatch(
        'ots/otlistsetting',
        params,
      );
    },

    async submitSetOT() {
      var branch_id = null;

      if (this.branch_all) {
        branch_id = [];
        this.available_branchs.map((data) => {
          branch_id.push(data.bch_id);
        });
      } else {
        if (!this.branch_id) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกสำนักงาน!');
          return false;
        }
        branch_id = this.branch_id;
      }

      try {
        let params = {
          ...{ bch_id: branch_id },
          ...{ com_id: this.profile.employees.branchs.company_id },
          ...{ limit_ot_hour: this.limit_hour },
          ...{ limit_ot_status: this.limit_status },
          ...{ default_ot_status: this.default_status },
          ...{ default_ot: this.default_ot },
        };
        await this.$store.dispatch('ots/createotsetting', params);
        this.$store.dispatch('alerts/success', 'ตั้งค่าเรียบร้อยแล้ว');
        this.getOTlistSetting();
      } catch (error) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
      }
    },
  },
};
</script>

<style></style>
