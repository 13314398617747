<template>
  <div class="md-layout">
    <!-- Progress bar -->
    <vue-progress-bar></vue-progress-bar>
    <div class="md-layout-item md-small-size-100 mx-auto">
      <simple-wizard>
        <template slot="header">
          <h3 class="title">ข้อมูลพนักงาน</h3>
          <div class="text-right">
            <md-button @click="goBack()" class="md-primary md-dense">
              Back to List
            </md-button>
          </div>
        </template>
        <wizard-tab :before-change="() => validateStep('step1')">
          <template slot="label">ข้อมูลส่วนตัว</template>
          <personal-form
            ref="step1"
            @on-validated="onStepValidated"
            :user="user"
          >
          </personal-form>
        </wizard-tab>
        <!--  -->
        <wizard-tab :before-change="() => validateStep('step2')">
          <template slot="label">ข้อมูลพนักงาน</template>
          <staff-form
            ref="step2"
            @on-validated="onStepValidated"
            v-if="is_getData"
            :user="user"
            :branchs="branchs"
          >
          </staff-form>
        </wizard-tab>
        <!--   -->
        <wizard-tab :before-change="() => validateStep('step3')">
          <template slot="label">ข้อมูลที่อยู่</template>
          <address-form
            ref="step3"
            @on-validated="onStepValidated"
            v-if="is_getData"
            :user="user"
          ></address-form>
        </wizard-tab>
        <!--  -->
        <wizard-tab :before-change="() => validateStep('step4')">
          <template slot="label">ข้อมูล login</template>
          <password-form
            ref="step4"
            @on-validated="wizardComplete"
            :user="user"
          ></password-form>
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import PersonalForm from './Forms/PersonalForm.vue';
import StaffForm from './Forms/StaffForm.vue';
import PasswordForm from './Forms/PasswordForm.vue';
import AddressForm from './Forms/AddressForm.vue';
// import Swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from '@/components';
import formMixin from '@/mixins/form-mixin';
import employeeMixin from '@/mixins/employee-mixin';
import Vuex from 'vuex';
export default {
  mixins: [formMixin, employeeMixin],

  data() {
    return {
      wizardModel: {},
      user: {
        type: 'employees',
        emp_id: null,
        password: null,
        password_confirmation: null,
        title_nameth: null,
        title_nameen: null,
        first_nameth: null,
        first_nameen: null,
        last_nameth: null,
        last_nameen: null,
        nick_name: null,
        phone: null,
        birth_date: null,
        sex: null,
        idcard: null,
        bank_number: null,
        bank_name: null,
        marital_st: null,
        religion: null,
        race: null,
        nationality: null,
        status: true,
        canleave: true,
        start_date: null,
        finish_date: null,
        staff_st_msg: 'ทำงาน',
        canleave_msg: 'เปิดสิทธิ์',
        address: null,
        zipcode: null,
        stafftype: null,
        private_code: '',
        email: '',
        branchs: {
          id: null,
          type: 'branchs',
        },
        departments: {
          id: null,
          type: 'departments',
        },
        positions: {
          id: null,
          type: 'positions',
        },
        weekends: {
          id: null,
          type: 'weekends',
        },
        workingtimes: {
          type: 'employee_has_working',
          id: null,
          working_id: null,
          start_date: null,
          status: 'active',
          work_type: 'major',
        },
        salarys: {
          type: 'salarys',
          salary_amt: 0,
          status: 'active',
        },
        provinces: {
          id: null,
          type: 'provinces',
        },
        districts: {
          id: null,
          type: 'districts',
        },
        subdistricts: {
          id: null,
          type: 'subdistricts',
        },
      },
      branchs: [],
      //is_getData: false,
    };
  },
  components: {
    PersonalForm,
    StaffForm,
    PasswordForm,
    AddressForm,
    SimpleWizard,
    WizardTab,
  },
  created() {
    // this.start();
    //this.get();
  },

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
      branchData: (state) => state.branches.list,
      departData: (state) => state.departments.list,
      workingData: (state) => state.workingtimes.list,
    }),
    is_getData() {
      if (this.branchData && this.departData && this.workingData) {
        this.finish();
        return true;
      } else {
        return false;
      }
    },
  },
  beforeDestroy() {
    // this.user.destroy;
    // this.wizardModel.destroy;
    // this.branchData.destroy;
    // this.departData.destroy;
    // this.workingData.destroy;
    // this.branchs.destroy;
  },
  methods: {
    // async get() {
    //   await this.getProfileData().then(() => {
    //     this.getDepartmentData(this.me.employees.branchs.company_id);
    //     this.getWorkingData(this.me.employees.branchs.company_id);
    //   });
    //   await this.getBranchData().then(() => {
    //     this.setBranchesDropdown();
    //   });
    // },
    async validateStep(ref) {
      // return true; //debug
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    async wizardComplete() {
      try {
        this.start();
        var user_insert = { ...this.user };
        user_insert.start_date = moment(
          user_insert.start_date,
          'DD/MM/YYYY',
        ).format('YYYY-MM-DD');
        user_insert.birth_date = moment(
          user_insert.birth_date,
          'DD/MM/YYYY',
        ).format('YYYY-MM-DD');
        if (user_insert.finish_date != null) {
          user_insert.finish_date = moment(
            user_insert.finish_date,
            'DD/MM/YYYY',
          ).format('YYYY-MM-DD');
        }
        user_insert.status = 'active';

        user_insert.privatecode = localStorage.getItem('privatecode');
        await this.$store.dispatch('users/add', user_insert);

        // this.user = await this.$store.getters["users/user"]

        // if(!!this.file) {
        //   // upload image and get image url
        //   await this.$store.dispatch("users/upload", {user: this.user, image: this.file})
        //   this.user.profile_image = await this.$store.getters["users/url"]
        //   // update user with image url
        //   await this.$store.dispatch("users/update", this.user)
        //   this.user = await this.$store.getters["users/user"]
        // }

        this.finish();
        await this.$store.dispatch(
          'alerts/success',
          'User added successfully.',
        );

        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch('alerts/error', e.response.data.errors[0]);
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }

        if (this.user.id) {
          await this.$store.dispatch('users/destroy', this.user.id);
        }
      }
    },
    goBack() {
      this.$router.push({ path: 'list-users' });
    },

    async setBranchesDropdown() {
      this.branchs = this.$store.getters['branches/dropdown'];
      //this.is_getData = true;
      //this.finish();
    },

    start() {
      this.$Progress.start();
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
  },
};
</script>
<style lang="scss">
.spacing {
  padding-bottom: 1rem;
}
.tab-content {
  width: 100%;
}
.text-right {
  display: flex;
}
</style>
