import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function getsetting(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/get_sc`, options).then((response) => {
    return response.data;
  });
}
function getData(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/get_data`, options).then((response) => {
    return response.data;
  });
}
function searchContract(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/search_contract`, options).then((response) => {
    return response.data;
  });
}
function getEmpContract(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/get_empcontract`, options).then((response) => {
    return response.data;
  });
}
function saveContract(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/save_contract`, params, options)
    .then((response) => {
      return response.data;
    });
}
function updateContract(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/update_contract`, params, options)
    .then((response) => {
      return response.data;
    });
}
function getbranch(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/get_branch`, options).then((response) => {
    return response.data;
  });
}
function updateSettingWit(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/update_settingWit`, params, options)
    .then((response) => {
      return response.data;
    });
}

export default {
  getsetting,
  saveContract,
  getData,
  searchContract,
  getEmpContract,
  updateContract,
  getbranch,
  updateSettingWit,
};
