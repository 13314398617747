<template>
  <div class="md-layout">
    <!-- Progress bar -->
    <vue-progress-bar></vue-progress-bar>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>people</md-icon>
          </div>
          <h4 class="title"></h4>
        </md-card-header>
        <md-card-content>
          <!-- Filter employee -->
          <filter-employee
            :headLabel="headLabel"
            :branchId="branches"
            :inactive="1"
            :permissionsList="permissions"
            v-on:childBranches="setBranches"
            v-on:childDepartments="setDepartments"
            v-on:childStaffs="setStaffs"
          >
          </filter-employee>

          <!-- Table data -->
          <md-table
            :value="tableData"
            :md-sort.sync="sortation.field"
            :md-sort-order.sync="sortation.order"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
            table-header-color="green"
          >
            <md-table-toolbar>
              <!-- Filter per page-->
              <md-field>
                <label>Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>

            <!-- Display table data -->
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                md-label="รหัสพนักงาน (Staff ID)"
                md-sort-by="emp_id"
              >
                {{ item.emp_id }}
              </md-table-cell>
              <md-table-cell md-label="ชื่อ-นามสกุล" md-sort-by="first_nameth">
                {{
                  item.title_nameth +
                    ' ' +
                    item.first_nameth +
                    ' ' +
                    item.last_nameth
                }}
              </md-table-cell>
              <md-table-cell md-label="Fullname" md-sort-by="first_nameen">
                {{
                  item.title_nameen +
                    ' ' +
                    item.first_nameen +
                    ' ' +
                    item.last_nameen
                }}
              </md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button
                  class="md-icon-button md-raised md-round md-info"
                  @click="goToEdit(item)"
                  style="margin: .2rem;"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <!-- <md-button class="md-icon-button md-raised md-round md-danger" @click="destroy(item.id)" style="margin: .2rem;">
                <md-icon>delete</md-icon>
              </md-button> -->
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </md-card-actions>
      </md-card>
      <modal-resetpwd
        :show="showModal"
        @close-modal="showModal = false"
        :modal_item="modal_item"
      ></modal-resetpwd>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import Swal from 'sweetalert2';
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import FilterEmployee from '@/components/FilterEmployee';
import Modal_resetpwd from '@/components/Modal_resetpwd.vue';
import Vuex from 'vuex';

export default {
  components: {
    'modal-resetpwd': Modal_resetpwd,
    'pagination': Pagination,
    'filter-employee': FilterEmployee,
  },
  mixins: [employeeMixin],

  data() {
    return {
      headLabel: 'เลือกพนักงาน',
      tableData: [],
      query: null,
      employee_id: null,
      // branch: null,
      sortation: {
        field: 'emp_id',
        order: 'asc',
      },

      pagination: {
        currentPage: 1,
        perPage: 5,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      branches: [],
      branchid: null,
      departments: null,
      //permissions: [],

      showModal: false,
      modal_item: null,
    };
  },

  props: {
    employeeID: null,
    branchID: Object,
  },

  computed: {
    sort() {
      if (this.sortation.order === 'desc') {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      let pms = [];
      if (this.me) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }

      return pms;
    },
  },
  created() {
    this.branches = this.$route.params.branchid;
    if (this.$route.params.page) {
      this.pagination.currentPage = this.$route.params.page.currentPage;
      this.pagination.perPage = this.$route.params.page.size;
    }
  },

  watch: {
    query: {
      handler: 'getListDebounced',
      immediate: true,
    },
    sortation: {
      handler: 'getListDebounced',
      immediate: false,
      deep: true,
    },
    pagination: {
      handler: 'getListDebounced',
      immediate: false,
      deep: true,
    },
    branches: {
      handler: 'getListDebounced',
      immediate: false,
      deep: true,
    },
    departments: {
      handler: 'getListDebounced',
      immediate: false,
      deep: true,
    },
    employee_id: {
      handler: 'getListDebounced',
      immediate: false,
      deep: true,
    },
    // me: {
    //   handler: function(val) {
    //     val.permissions.map((permis) => {
    //       this.permissions.push(permis.name);
    //     });
    //     val.roles[0].permissions.map((permission) => {
    //       this.permissions.push(permission.name);
    //     });
    //   },
    //   deep: true,
    // },
  },

  methods: {
    getListDebounced: _.debounce(function() {
      this.getList();
    }, 100),
    async getList() {
      this.start();
      if (this.branches) {
        let params = {
          //include: "roles",
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.employee_id ? { id: this.employee_id } : {}),
            ...(this.branches ? { branch: this.branches } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };
        await this.$store.dispatch('users/list', params).then(
          (response) => {
            this.finish();
          },
          (response) => {
            this.$Progress.fail();
          },
        );

        this.tableData = this.$store.getters['users/list'];
        this.total = this.$store.getters['users/listTotal'];
      } else {
        this.finish();
      }
    },
    goToEdit(item) {
      this.showModal = true;
      this.modal_item = item;
    },

    customSort() {
      return false;
    },

    /**
     * set branch value from child component
     */
    setBranches(v) {
      if (v) {
        this.employee_id = null;
        this.branches = v;
      }
    },

    /**
     * set branch value from child component
     */
    setDepartments(v) {
      if (v) {
        this.employee_id = null;
        this.departments = v;
      }
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employee_id = v.id;
      }
    },

    start() {
      this.$Progress.start();
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
  },
};
</script>
