<template>
  <div>
    <!--ไดอะลอก-->
    <md-dialog style="min-height: 30%;" :md-active.sync="first" md-fullscreen>
      <md-dialog-title style="font-size: 1.6rem" align="center">
        QR Code
      </md-dialog-title>
      <md-content align="center">
        <img v-bind:src="qrcode" alt="" />
      </md-content>
      <md-dialog-actions> </md-dialog-actions>
    </md-dialog>
    <!--ปิดไดอะลอก-->
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <filter-contractemp
          @childBranches="setBranches"
          @childDepartment="setDepartment"
          @childStaffs="setStaffs"
          :permissionsList="permissions"
          v-if="permissions.includes('view select staff')"
        />
      </div>
    </div>
    <div class="md-layout-item md-size-100 md-alignment-top-center">
      <div class="row">
        <div class="col-md-3" align="right">
          <b-button id="add_contract" variant="primary" @click="goToAdd">
            เพิ่มสัญญาจ้าง
          </b-button>
        </div>
        <div class="col-md-6" align="left">
          <md-field>
            <label>ค้นหา เลขบัตรประชาชน </label>
            <md-input
              v-model="search"
              class="md-size-50 searchID"
              @keyup="showListContract()"
            ></md-input>
          </md-field>
        </div>
      </div>
    </div>
    <div><p></p></div>
    <md-table
      class="paginated-table table-striped table-hover"
      :value="tableDataCon"
      table-header-color="green"
    >
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="ID Card">{{ item.emp_idcard }}</md-table-cell>
        <md-table-cell md-label="Name">{{ item.emp_name }}</md-table-cell>
        <md-table-cell md-label="Description">{{
          item.department_name
        }}</md-table-cell>
        <md-table-cell md-label="Actions">
          <md-button
            class="md-icon-button md-raised md-round md-info"
            @click="goToEdit(item.con_id)"
            style="margin: 0.2rem"
          >
            <md-icon>edit</md-icon>
          </md-button>
          <md-button
            class="md-icon-button md-raised md-round md-warning"
            @click="goToSignCon(item.con_id)"
            style="margin: 0.2rem"
          >
            <md-icon>extension</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import $ from 'jquery';
import moment from 'moment';
import employeeMixin from '@/mixins/employee-mixin';
import FilterContactEmp from '@/pages/Dashboard/Pages/Admin/Contract/FilterContactEmp';
export default {
  components: {
    'filter-contractemp': FilterContactEmp,
  },
  mixins: [employeeMixin],

  data() {
    return {
      search: '',
      showdialog: false,
      showcon: false,
      showcon_add: false,
      contract_id: null,
      departments_id: null,
      branch_id: null,
      employee_id: null,
      staffs: [],
      formdata: [],
      tableDataCon: [],
      data: {},
      qrcode: null,
      first: false,
    };
  },

  props: {
    permissions: { type: Array, default: null },
  },

  created() {},
  watch: {},
  methods: {
    receivedata(v) {
      let data = v;
    },
    showForm() {
      this.showcon = !this.showcon;
    },
    goToAdd() {
      this.$router.push({
        path: 'add-contract',
        name: 'เพิ่ม สัญญาจ้าง',
      });
    },
    setBranches(v) {
      // this.branches = v;
      this.branch_id = v;
    },
    setDepartment(v) {
      if (v) {
        this.departments_id = v;
      }
    },
    setStaffs(v) {
      if (v) {
        this.contract_id = v.id;
        this.showListContract();
      } else {
        this.contract_id = null;
      }
    },
    async showListContract(emp_idcard) {
      $('.head-non').css('display', '');
      if (emp_idcard != null) {
        this.search = emp_idcard;
      }
      let obj = {
        ...{ con_id: this.contract_id },
        emp_idcard: this.search,
      };
      let resp = await this.$store.dispatch('contract/searchContract', obj);
      this.tableDataCon = resp;
    },
    goToEdit(id) {
      let getdata = this.tableDataCon;
      var data = [];
      $(getdata).each(async function(i, o) {
        if (id == o.con_id) {
          data = [o];
        }
      });
      this.$router.push({
        path: 'edit-contract',
        name: 'แก้ไข สัญญาจ้าง',
        params: {
          id,
          data,
        },
      });
    },
    async goToSignCon(id) {
      let getdata = this.tableDataCon;
      var data = [];
      $(getdata).each(async function(i, o) {
        if (id == o.con_id) {
          data = [o];
        }
      });
      let params = {
        link: process.env.VUE_APP_APP_BASE_URL + '/sign-contract/' + id,
        size: '180x180',
      };
      let link = await this.$store.dispatch('alerts/genQRCode', params);
      this.qrcode = link;
      this.first = true;
    },
  },
};
</script>
<style scoped>
md-table-head-container {
  text-align: center !important;
}
</style>
