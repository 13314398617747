import service from '@/store/services/uniqueapprover-service';

const state = {
  list: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },

  listflatreat({ commit, dispatch }, params) {
    return service.listflatreat(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },

  listreportflatreat({ commit, dispatch }, params) {
    return service.listreportflatreat(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },

  addflatreat({ commit, dispatch }, params) {
    return service.addflatreat(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },

  addReportflatreat({ commit, dispatch }, params) {
    return service.addReportflatreat(params).then((data) => {
      return data;
    });
  },

  edit({ commit, dispatch }, params) {
    return service.edit(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },

  editflatreat({ commit, dispatch }, params) {
    return service.editflatreat(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },

  editreport({ commit, dispatch }, params) {
    return service.editreport(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },
  editreportflatrate({ commit, dispatch }, params) {
    return service.editreportflatrate(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },
};

const getters = {
  list: (state) => state.list,
};

const uniqueapprover = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default uniqueapprover;
