<template>
  <div style="min-height: 580px;" v-if="file_pdf.length > 0">
    <div v-if="file_pdf[1] == 'pdf'">
      <iframe
        :src="file_pdf[0]"
        style="width: 100%; height:100%; min-height: 580px;"
        allowfullscreen
      ></iframe>
    </div>
    <div v-else-if="file_pdf[1] == 'pic'">
      <img
        class="canzoom"
        @click="zoomedImg($event.currentTarget)"
        :src="file_pdf[0]"
      />
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import { loading_start, loading_close } from '@/utils/loading.js';
import Swal from 'sweetalert2';

export default {
  components: {},
  async created() {
    await this.get_file(this.file_id);
  },
  computed: {},
  data() {
    return {
      file_pdf: '',
    };
  },
  props: {
    file_id: {
      type: String,
      default: null,
    },
  },
  watch: {
    async file_id() {
      await this.get_file(this.file_id);
    },
  },
  methods: {
    goBack() {
      let keySearch = this.$route.params.keySearch;
      let currentPage = this.$route.params.currentPage;
      let backPage = this.$route.params.backPage;

      if (backPage == 'list') {
        this.$router.push({
          path: 'listAppeal',
          name: 'รายงานข้อเสนอแนะ / ข้อร้องเรียน',
          params: {
            keySearch,
            currentPage,
          },
        });
      } else {
        this.$router.push({
          path: 'listAppeal',
          name: 'รายงานข้อเสนอแนะ / ข้อร้องเรียน',
          params: {
            keySearch,
            currentPage,
          },
        });
      }
    },

    async get_file(id) {
      try {
        let file_pdf = '';

        loading_start();
        let files = await this.getfile(id);
        loading_close();

        files.forEach((file) => {
          file_pdf = file.file;
        });
        let split = file_pdf.split(';', 1);

        if (split == 'data:application/pdf') {
          this.file_pdf = [file_pdf, 'pdf'];
        } else {
          this.file_pdf = [file_pdf, 'pic'];
        }
      } catch (error) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        loading_close();
      }
    },

    async getfile(id) {
      if (id) {
        let params = {
          type: 'file',
          id: id,
        };

        let resp = await this.$store.dispatch('info/getFileInfo', params);

        return resp;
      } else {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return [];
      }
    },

    async show_img_full(file) {
      if (file == '') {
        return;
      }
      var win = window.open();
      if (file.toLowerCase().indexOf('data:application/pdf;') >= 0) {
        win.document.write(
          '<iframe src="' +
            file +
            '" frameborder="0" style="width: 100%; height:100%;" allowfullscreen></iframe>',
        );
      } else {
        win.document.write(
          '<img style="width: 100%; height: auto;" src="' + file + '"></img>',
        );
      }
    },
    zoomedImg(element) {
      element.classList.toggle('zoomed');
    },
  },
};
</script>
<style>
img.canzoom {
  width: 100%;
  height: auto;
  transition: transform 0.25s ease;
  cursor: zoom-in;
  transform-origin: top left;
}

img.canzoom.zoomed {
  transform: scale(2);
  cursor: zoom-out;
}
</style>
