<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100" v-if="isParams">
      <tabs
        :tab-name="tabList.name"
        :tab-icon="tabList.icon"
        :tab-active="tabactive"
        nav-pills-icons
        plain
        color-button="warning"
      >
        <!-- here you can add your content for tab-content -->
        <template slot="tab-pane-1">
          <md-card>
            <md-card-header>
              <h4 class="title"></h4>
              <p class="category"></p>
            </md-card-header>
            <md-card-content> </md-card-content>
          </md-card>
        </template>

        <template slot="tab-pane-2">
          <md-card>
            <md-card-header>
              <h4 class="title"></h4>
              <p class="category"></p>
            </md-card-header>
            <md-card-content> </md-card-content>
          </md-card>
        </template>

        <template slot="tab-pane-3">
          <md-card>
            <md-card-header>
              <h4 class="title"></h4>
              <p class="category"></p>
            </md-card-header>
            <md-card-content> </md-card-content>
          </md-card>
        </template>

        <template slot="tab-pane-4">
          <md-card>
            <md-card-header>
              <h4 class="title"></h4>
              <p class="category"></p>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div
                  class="md-layout-item md-large-size-25 md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                >
                  <md-field>
                    <label for="years">ปี</label>
                    <md-select v-model="years">
                      <md-option
                        v-for="mo in available_years"
                        :key="mo"
                        :value="mo"
                      >
                        {{ mo }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-large-size-25 md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                >
                  <md-field>
                    <label for="months">เดือน</label>
                    <md-select v-model="months">
                      <md-option
                        v-for="mo in available_months[years]"
                        :key="mo"
                        :value="mo"
                      >
                        {{ mo }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <b-button variant="info" @click="print_html()">
                  PRINT
                </b-button>
              </div>

              <slip-salary
                :emp_id="employee_id"
                :month="months"
                :year="years"
              />
            </md-card-content>
          </md-card>
        </template>

        <!-- <template
          slot="tab-pane-5"
          v-if="!this.permissionsList.includes('process salary')"
        >
          <md-card>
            <md-card-header>
              <h4 class="title"></h4>
              <p class="category"></p>
            </md-card-header>

            <md-card-content>
              <ot-list
                :permissions="permissionsList"
                :employeeID="employee_id"
              />
            </md-card-content>
          </md-card>
        </template> -->

        <!-- <template slot="tab-pane-6" v-if="this.permissionsList.includes('process salary')">
          <md-card>
            <md-card-header>
              <h4 class="title"></h4>
              <p class="category"></p>
            </md-card-header>

            <md-card-content>
              <salary-calculate 
                :permissions="permissionsList"
                :employeeID="employee_id"
                />
            </md-card-content>
          </md-card>
        </template> -->
      </tabs>
    </div>
  </div>
</template>
<script>
import { Tabs } from '@/components';
import moment from 'moment';
import TimeInOut from '@/pages/Dashboard/Pages/Staff/Transactions/TimeInOut/TimeInOut.vue';
import RawTime from '@/pages/Dashboard/Pages/Staff/Transactions/TimeInOut/RawTime.vue';
import WorkingTime from '@/pages/Dashboard/Pages/Staff/Transactions/Working/WorkingTime.vue';
import SlipSalary from '@/components/SlipSalary.vue';
import jsPDF from 'jspdf';
// import SalaryCalculate from '@/pages/Dashboard/Pages/Staff/Transactions/SalaryCalculate/SalaryCalculate.vue';
// import SalaryInfo from '@/pages/Dashboard/Pages/Staff/Transactions/SalaryInfo/SalaryInfo.vue';
// import LeaveList from '@/pages/Dashboard/Pages/Staff/Transactions/LeaveSystem/ListLeavePage.vue';
// import OTList from '@/pages/Dashboard/Pages/Staff/Transactions/OT/OTListPage.vue';
import Vuex from 'vuex';
import _ from 'lodash';

export default {
  components: {
    Tabs,
    // 'time-inout': TimeInOut,
    // 'raw-time': RawTime,
    // 'working-time': WorkingTime,
    'slip-salary': SlipSalary,
  },

  data() {
    return {
      tabactive: 0,
      tabList: {
        name: [],
        icon: [],
      },
      permissionsList: [],
      employee_id: null,
      months: moment()
        // .subtract(1, 'month')
        .month(),
      years: moment().year(),
      available_years: [],
      available_months: [],
      branchs: {},
      isParams: false,
      me: null,
      query: null,
    };
  },

  created() {
    this.getProfile().then(async () => {
      this.setPermission(this.me);
      let params = { emp_id: this.me.employee_id };
      let list = await this.$store.dispatch('salarys/getListSalary', params);
      list.forEach((l) => {
        this.available_years.push(l.years);
        this.available_months[l.years] = l.listmonths.split(',').map(Number);
      });
      this.setEmployeeID(this.me).then(() => {
        this.isParams = true;

        if (this.permissionsList.includes('process salary')) {
          //   //this.tabList.name = ['เวลาเข้าออกพนักงาน','กะเวลาทำงาน','ระบบลา','OT','เงินเดือน'];
          //   //this.tabList.icon = ['transfer_within_a_station','schedule','indeterminate_check_box','av_timer','monetization_on'];
          this.tabList.name = [
            'เวลาเข้าออกพนักงาน',
            'เวลาก่อนประมวลผล',
            'กะเวลาทำงาน',
            'เงินเดือน',
          ];
          this.tabList.icon = [
            'work_history',
            'transfer_within_a_station',
            'schedule',
            'local_atm',
          ];
        } else {
          this.setstaffTab();
        }
      });
    });

    if (this.$route.params.tabactive) {
      this.tabactive = this.$route.params.tabactive;
    }
  },

  watch: {
    years: function(y) {
      this.months = Math.max(...this.available_months[y]);
    },
  },

  computed: {
    // ...Vuex.mapState({
    //   me: (state) => state.profile.me,
    // }),
  },

  methods: {
    setTabActive() {
      if (this.$route.params.tabactive) {
        this.tabactive = this.$route.params.tabactive;
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissionsList.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissionsList.push(permission.name);
      });
    },

    async setEmployeeID(me) {
      // if (me.roles[0].name === 'Super admin') {
      //   this.employee_id = null;
      //   this.branchs = {};
      // } else {
      this.branchs = me.employees.branchs;
      this.employee_id = me.employee_id;
      // }
    },

    setstaffTab() {
      this.tabList.name = [
        'เวลาเข้าออกพนักงาน',
        'เวลาก่อนประมวลผล',
        'กะเวลาทำงาน',
        'เงินเดือน',
      ];
      this.tabList.icon = [
        'work_history',
        'transfer_within_a_station',
        'schedule',
        'local_atm',
      ];
    },
    async print_html() {
      const gethtml = this.$el.querySelector('.slipdata');
      if (gethtml != null) {
        const m = new moment();
        let datetext = m.format('DD/MM/YYYY HH:mm');
        let url = process.env.VUE_APP_APP_BASE_URL + this.$route.fullPath;
        let print = gethtml.innerHTML;
        /* eslint-disable */
        print +=
          `<div class="footer left">
              <p>
                หมายเหตุ: บริษัทถือว่าขัอความในเอกสารฉบับนี้เป็นความลับ ห้ามเปิดเผยให้ผู้อื่นทราบโดยเด็ดขาด <br/>
                Please Use this e-letter as the payroll or other income payment slip. The infomation is confidential. please do not reveal it to any one.<br/>
                เรียน ท่านผู้เกี่ยวข้อง <br/>
                เอกสารฉบับนี้เป็นเอกสารสำคัญออกจากระบบอิเลคทรอนิคส์ ออกโดย บริษัท ซึ่งสามารถใช้แทนหนังสือรับรองการทำงานและหนังสือรับรองเงินเดือนได้
              </p>
            </div>
            <div class="footer right">
              <a  href="` +
          url +
          `">` +
          url +
          `</a>
              <span>` +
          datetext +
          `</span>
            </div>`;
        /* eslint-enable */
        print += `
          <style scoped>
            @media screen {
              div.footer {
                display: none;
              }
            }
            @media print {
              div.footer.right {
                display: fixed;
                position: fixed;
                right: 0;
                bottom: 0;
              }
              div.footer.left {
                display: fixed;
                position: fixed;
                left: 0;
                bottom: 0;
              }
              div.footer.left p {
                color: #b50000;
              }
            }
            table {
              border-collapse: collapse;
            }

            th,
            td {
              text-align: left;
              border: 1px solid #ddd;
            }

            table.slip-head {
              border: 0px solid;
              font-weight: 600;
            }

            table.slip-head th,
            table.slip-head td {
              border: 0px solid;
            }

            table.slip-head .txt-center {
              text-align: center;
            }

            table.slip-head .txt-left {
              text-align: left;
            }

            table.slip-head .txt-right {
              text-align: right;
            }

            table.slip-data {
              font-weight: 600;
            }

            table.slip-data th,
            table.slip-data td {
              border: 1px solid;
              padding: 1px 5px;
            }

            table.slip-data.incost th {
              border: 1px solid;
            }

            table.slip-data.incost td {
              border: 1px solid;
              border-bottom: 0px solid;
              border-top: 0px solid;
            }

            table.slip-data.incost td font.income {
              color: #006700;
            }

            table.slip-data.incost td font.cost {
              color: #b50000;
            }

            table.slip-data .noborder {
              border: 0px solid;
            }

            table.slip-data .txt-center {
              text-align: center;
            }

            table.slip-data .txt-left {
              text-align: left;
            }

            table.slip-data .txt-right {
              text-align: right;
            }

            table.slip-noborder th,
            table.slip-noborder td {
              border: 1px solid rgba(255, 255, 255, 0);
            }
            .backgrourdslip {
              /* background-image: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 0.85),
                  rgba(255, 255, 255, 0.85)
                ),
                url('http://livepocket.local:8081/img/logo-santhiya.png'); */
              background-image: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 0.85),
                  rgba(255, 255, 255, 0.85)
                ),
                url('https://livepocket.com/img/logo-santhiya.png');
              /*filter: grayscale(100%);*/
              background-position: left;
              background-repeat: no-repeat;
              background-size: 790px auto;
            }
          </style>`;
        let WindowObject = window.open(
          '',
          'PrintWindow',
          // 'width=1024,height=500,top=0,left=0,toolbars=no,scrollbars=no,status=no,resizable=no',
        );
        WindowObject.document.writeln(print);
        WindowObject.document.close();
        WindowObject.focus();
        setTimeout(function() {
          WindowObject.print();
          WindowObject.close();
        }, 200);

        // const jspdf = new jsPDF('landscape');
        // var font = await this.$store.dispatch('exports/fontthsarabunnew');

        // jspdf.html(printhtml, {
        //   callback: function() {
        //     jspdf.output('dataurlnewwindow', 'testtest.pdf');
        //   },
        //   x: 15,
        //   y: 10,
        // });
        // jspdf.text(15, 15, 'สวัสดี ยินดีที่ได้รู้จักคุณ');
        // jspdf.save('test.pdf');
      }
      return false;
    },
    async getProfile() {
      await this.$store.dispatch('profile/me');
      this.me = await this.$store.getters['profile/me'];
    },
  },
};
</script>
<style lang="scss">
.tab-pane-1,
.tab-pane-2,
.tab-pane-3,
.tab-pane-4,
.tab-pane-5,
.tab-pane-6 {
  width: 100%;
}
</style>
