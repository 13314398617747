<template>
  <div>
    <div class="md-layout spacing md-alignment-center">
      <div
        class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
        align="center"
      >
        <md-field class="searchbranchs">
          <label>เลือก สำนักงาน</label>
          <md-select v-model="branches" name="branch_id">
            <md-option
              v-for="item in available_branches"
              :key="item.branch_id"
              :value="item.branch_id"
            >
              {{ item.br_nameth }}
            </md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>พยานคนที่ 1 </label>
          <md-input class="wit1"></md-input>
        </md-field>
        <md-field>
          <label>พยานคนที่ 2 </label>
          <md-input class="wit2"></md-input>
        </md-field>
        <div>
          <label style="font-size: 12px; color: orangered;">
            **กรุณาระบุ คำนำหน้า, ชื่อ-นามสกุล ของพยาน
            เพื่อนำไปใช้ในเอกสารสัญญาจ้าง
          </label>
        </div>
        <div>
          <span class="btn-pd">
            <b-button
              class="btn btn-success"
              id="btn_save"
              @click="saveSetWit()"
            >
              บันทึก
            </b-button>
          </span>
        </div>
      </div>
    </div>
    <div
      class="table_wit"
      style="margin-left: 4rem; margin-right: 4rem; margin-bottom: 4rem;"
    >
      <md-table
        class="paginated-table table-striped table-hover"
        :value="available_branches"
        table-header-color="green"
      >
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Branchs">{{ item.br_nameth }}</md-table-cell>
          <md-table-cell md-label="Branchs TH">
            {{ item.bchname_th }}
          </md-table-cell>
          <md-table-cell md-label="witness No.1">
            {{ item.witness_name }}
          </md-table-cell>
          <md-table-cell md-label="witness No.2">
            {{ item.witness_name2 }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
export default {
  data() {
    return {
      available_branches: null,
      branches: '',
    };
  },
  created() {
    this.getBranches();
  },
  methods: {
    async getBranches() {
      let resp = await this.$store.dispatch('contract/getbranch');
      this.available_branches = resp;
    },
    async saveSetWit() {
      let data = {};
      let b_id = this.branches;
      let name_wit1 = $('.wit1').val();
      let name_wit2 = $('.wit2').val();
      if (name_wit1 == '') {
        data['name_wit1'] = this.available_branches.witness_name;
      } else {
        data['name_wit1'] = name_wit1;
      }
      if (name_wit2 == '') {
        data['name_wit2'] = this.available_branches.witness_name2;
      } else {
        data['name_wit2'] = name_wit2;
      }
      data['b_id'] = b_id;
      let resp = await this.$store.dispatch('contract/updateSettingWit', data);
      if (resp.length <= 0) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        return false;
      } else {
        await this.$store.dispatch('alerts/success', 'Update สัญญาจ้างสำเร็จ.');
      }
    },
  },
};
</script>
<style>
md-table-head-container {
  text-align: center !important;
}
@media screen and (min-width: 300px) and (max-width: 800px) {
  .table_wit {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
}
</style>
