<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(update)">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>add_circle_outline</md-icon>
          </div>
          <h4 class="title"></h4>
          <div class="text-right">
            <md-button @click="goBack" class="md-primary md-dense">
              Back to List
            </md-button>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout spacing">
            <div class="md-layout-item md-size-20 mt-2 md-small-size-40">
              <h5>
                <label>{{
                  employee.firstname_en + ' ' + employee.lastname_en
                }}</label>
              </h5>
            </div>
            <div class="md-layout-item md-size-30 mt-2 md-small-size-30">
              <ValidationProvider
                name="employee_working.working_id"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>เลือก กะทำงาน</label>
                  <md-select v-model="employee_working.working_id">
                    <md-option
                      v-for="item in available_workingtimes"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-20 mt-2 md-small-size-20">
              <ValidationProvider
                name="employee_working.work_type"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="work_type">ประเภท</label>
                  <md-select v-model="employee_working.work_type">
                    <md-option value="major">ประจำ</md-option>
                    <md-option value="minor">รายวัน</md-option>
                  </md-select>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>

          <div class="md-layout spacing">
            <div class="md-layout-item md-size-20 mt2 md-small-size-40">
              <ValidationProvider
                name="employee_working.start_date"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-datepicker
                  v-model="employee_working.start_date"
                  md-immediately
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>วันที่เริ่ม</label>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-datepicker>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-20 mt2 md-small-size-40">
              <md-datepicker
                v-model="employee_working.finish_date"
                md-immediately
              >
                <label>วันที่สิ้นสุด</label>
              </md-datepicker>
            </div>
          </div>
        </md-card-content>
        <md-card-actions>
          <md-button type="submit" class="md-success">save </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
// import { ValidationError } from '@/components';
import formMixin from '@/mixins/form-mixin';
import Vuex from 'vuex';
import moment from 'moment';

extend('required', required);

export default {
  //components: { ValidationError },
  mixins: [formMixin],
  data() {
    return {
      employee_working: {
        type: 'employeehasworkingtimes',
        employee_id: [{ first_nameen: null, last_nameen: null }],
        working_id: null,
        start_date: null,
        finish_date: null,
        status: 'active',
        work_type: null,
      },

      staffList: null,
      employee: [],
      available_workingtimes: [],
      workingtimes: [],
      profile: [],
      moment: moment,
    };
  },

  created() {
    this.get();
    this.getUserProfile();
  },

  computed: {
    ...Vuex.mapState({
      tabactive: (state) => state.stafftransaction.tabactive,
      branch_staff: (state) => state.stafftransaction.transbranches,
      employee_staff: (state) => state.stafftransaction.employee_id,
    }),
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch('staffworks/get', id);
        let staffwork = this.$store.getters['staffworks/staffwork'];
        if (staffwork) {
          staffwork.start_date = moment(staffwork.start_date).format(
            'DD/MM/YYYY',
          );
          staffwork.finish_date =
            moment(staffwork.finish_date).format('DD/MM/YYYY') == 'Invalid date'
              ? moment(staffwork.finish_date).format('DD/MM/YYYY')
              : null;
        }
        this.employee_working = staffwork;
        this.getEmployee();
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
      }
    },

    async getEmployee() {
      let params = {
        emp_id: this.employee_working.employee_id,
      };
      let staff = await this.$store.dispatch('staffs/searchStaff', params);

      if (staff) {
        this.employee = staff[0];
      }
    },

    async getWorkingtime(company_id) {
      let params = {
        filter: {
          ...{ status: 'active' },
          ...{ company_id: company_id },
        },
      };
      await this.$store.dispatch('workingtimes/list', params).then(() => {
        this.available_workingtimes = this.$store.getters[
          'workingtimes/dropdown'
        ];
      });
    },

    async getUserProfile() {
      this.profile = this.$store.getters['profile/me'];
      this.getWorkingtime(this.profile.employees.branchs.company_id);
    },

    async update() {
      this.employee_working.start_date = moment(
        this.employee_working.start_date,
      ).format('YYYY-MM-DD');
      this.employee_working.finish_date =
        this.employee_working.finish_date !== null
          ? moment(this.employee_working.finish_date).format('YYYY-MM-DD')
          : null;

      if (
        this.employee_working.work_type === 'minor' &&
        this.employee_working.finish_date == null
      ) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด ');
        return false;
      }

      try {
        await this.$store.dispatch('staffworks/update', this.employee_working);
        await this.$store.dispatch('alerts/success', 'updated successfully.');
        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            'alerts/error',
            e.response.data.errors[0].title,
          );
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },

    goBack() {
      this.$router.push({
        path: 'transaction',
        name: 'Transaction',
        params: { tabactive: 1 },
      });
    },
  },
};
</script>
<style scoped></style>
