<template>
  <div
    class="sidebar"
    :data-color="activeColor"
    :data-image="backgroundImage"
    :data-background-color="backgroundColor"
    :style="sidebarStyle"
  >
    <div class="logo">
      <a href="#" class="simple-text logo-mini fix">
        <template>LP</template>
      </a>
      <a href="#" class="simple-text logo-normal fix">
        <template>{{ title }}</template>
        <md-button
          class=".md-just-icon md-simple md-toolbar-toggle fontsize"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <md-icon class="fontsize">close</md-icon>
        </md-button>
      </a>
      <!-- <a href="#" class="simple-text logo-mini fix">
        <md-button
          class="md-just-icon md-simple md-round md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <md-icon class="fontsize">close</md-icon>
        </md-button>
      </a> -->

      <div class="navbar-minimize">
        <md-button
          id="minimizeSidebar"
          class="md-round md-just-icon md-transparent"
          @click="minimizeSidebar"
        >
          <i
            class="material-icons text_align-center visible-on-sidebar-regular"
          >
            more_vert
          </i>
          <i
            class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
          >
            view_list
          </i>
        </md-button>
      </div>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <md-list class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Livepocket',
    },
    activeColor: {
      type: String,
      default: 'green',
      validator: (value) => {
        let acceptedValues = [
          '',
          'purple',
          'azure',
          'green',
          'orange',
          'danger',
          'rose',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundImage: {
      type: String,
      default: `${process.env.VUE_APP_APP_BASE_URL}/img/sidebar-3.jpg`,
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        let acceptedValues = ['', 'black', 'white', 'red'];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    logo: {
      type: String,
      default: `${process.env.VUE_APP_APP_BASE_URL}/img/vue-logo.png`,
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
      //default: false
    },
  },
  created() {
    this.$sidebar.toggleMinimize();
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
