<template>
  <md-card>
    <md-card-header>
      <h4>นำเข้าข้อมูลการลา</h4>
    </md-card-header>
    <md-card-content>
      <div class="md-layout md-alignment-center-left">
        <div
          class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-65 md-xsmall-size-100 spacing"
        >
          <b-input-group>
            <b-form-file
              accept=".csv, .xlsx"
              ref="file"
              v-model="file_import"
              :state="Boolean(file_import)"
              placeholder="Choose a file or drop file here..."
              drop-placeholder="Drop file here..."
              @change="change_check()"
            />
            <b-input-group-append>
              <b-button variant="info" @click="check_fileType()">
                Read File
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div
          v-if="check_file_exl == true"
          class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <b-form-select v-model="selectedSheet" :options="list_worksheet" />
        </div>
        <div
          class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <b-form-checkbox
            v-if="check_file_exl == true"
            v-model="removeHeader"
            switch
          >
            ลบแถวหัวข้อในไฟล์ Excel
          </b-form-checkbox>
          <b-form-checkbox
            v-if="check_file_csv == true"
            v-model="removeHeaderCsv"
            switch
          >
            ลบแถวหัวข้อในไฟล์ csv
          </b-form-checkbox>
        </div>
      </div>
      <div class="md-layout md-alignment-top-left">
        <div
          class="md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-33 md-small-size-50 md-xsmall-size-100 spacing md-alignment"
        >
          <b-table-simple small hover responsive v-if="leaves.length > 0">
            <b-thead>
              <b-tr>
                <b-th>
                  รหัสการลา
                </b-th>
                <b-th>
                  ประเภทการลา
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(data, index) in leaves" :key="'data-leave' + index">
                <b-td>
                  {{ data.id }}
                </b-td>
                <b-td>
                  {{ data.leave_nameth }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div
          class="md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-33 md-small-size-50 md-xsmall-size-100 spacing md-alignment"
        >
          <b-table-simple small hover responsive v-if="leaves_type.length > 0">
            <b-thead>
              <b-tr>
                <b-th>
                  รหัสช่วงการลา
                </b-th>
                <b-th>
                  ช่วงการลา
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(data, index) in leaves_type"
                :key="'data-leave-type' + index"
              >
                <b-td>
                  {{ data.id }}
                </b-td>
                <b-td>
                  {{ data.type_name }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item spacing md-alignment">
          <b-button
            v-if="check_file_exl == true"
            variant="success"
            @click="importFileLeaves()"
          >
            Import File
          </b-button>
          <b-button
            v-if="check_file_csv == true"
            variant="success"
            @click="importFileLeavesCsv()"
          >
            Import File
          </b-button>
          <b-button variant="primary" @click="setdefaultExcel()">
            Export หัวข้อ
          </b-button>
        </div>
      </div>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item spacing md-alignment">
          <b-table-simple responsive v-if="dataExcel.length > 0">
            <b-thead>
              <b-tr>
                <b-th v-for="(col, index) in headeritems" :key="'col-' + index">
                  {{ col.col_name_th }}
                </b-th>
              </b-tr>
              <b-tr>
                <b-th v-for="(col, index) in columnList" :key="'col-' + index">
                  {{ col }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(data, index) in dataExcel.slice(0, limit)"
                :key="'data-' + index"
              >
                <b-td v-for="(d, i) in data" :key="'d-' + i">
                  {{ d }}
                </b-td>
              </b-tr>
              <b-tr v-if="limit < dataExcel.length">
                <b-td :colspan="dataExcel[0].length">
                  <b-button
                    variant="info"
                    style="width:60%"
                    @click="limit += 10"
                  >
                    Load more
                  </b-button>
                  <b-button
                    variant="primary"
                    style="width:40%"
                    @click="limit = dataExcel.length"
                  >
                    Load All
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';
import { loading_start, loading_close } from '@/utils/loading.js';
import Excel from 'exceljs';
import draggable from 'vuedraggable';
import Swal from 'sweetalert2';

export default {
  mixins: [employeeMixin],
  components: {
    // draggable,
  },
  data() {
    return {
      file_import: null,
      selectedSheet: null,
      removeHeader: false,
      removeHeaderCsv: false,
      check_file_exl: false,
      check_file_csv: false,
      list_worksheet: [{ value: null, text: 'Please select an Sheet' }],
      limit: 15,
      leaves: [],
      leaves_type: [
        {
          id: 'f',
          type_name: 'ลาเต็มวัน',
        },
        {
          id: 'hm',
          type_name: 'ลาครึ่งเช้า',
        },
        {
          id: 'hn',
          type_name: 'ลาครึ่งบ่าย',
        },
      ],
      workbook: {},
      columnList: [],
      dataExcel: [],
    };
  },

  async created() {
    loading_start();
    let type = 3;
    this.headeritems = await this.getImportColumnList(type);
    this.profile = await this.getProfileData();
    this.leaves = await this.getLeaves();
    loading_close();
  },
  destroyed() {
    this.file_import = null;
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },

  props: {
    permissions: {
      type: Array,
      default: () => {},
    },
    available_branches: {
      type: Array,
      default: () => {},
    },
  },

  watch: {
    branches: async function(bch_id) {
      if (bch_id) {
        this.genListYear(bch_id);
      }
    },
    years: async function(years) {
      if (years) {
        this.genListMonth(this.branches, years);
      } else {
        this.available_months = [];
        this.months = '';
      }
    },
    selectedSheet: function() {
      this.getSheet();
    },
    removeHeader: function() {
      this.getSheet();
    },
    // removeHeaderCsv: function() {
    //   this.getSheetCsv();
    // },
    // from_date: function() {
    //   if (this.check_file_exl) {
    //     this.getSheet();
    //   }
    // },
    // to_date: function() {
    //   if (this.check_file_exl) {
    //     this.getSheet();
    //   }
    // },
  },

  methods: {
    async getLeaves() {
      const res = await this.$store.dispatch('adjunct/getLeaves');
      return res;
    },
    async getImportColumnList(type) {
      const res = await this.$store.dispatch('adjunct/importColumnlist', {
        type: type,
      });
      return res;
    },
    change_check() {
      this.check_file_exl = false;
      this.check_file_csv = false;
    },
    async check_fileType() {
      this.check_file_exl = false;
      this.check_file_csv = false;
      if (this.file_import == null) {
        Swal.fire({
          icon: 'warning',
          title: 'โปรดเลือกไฟล์ / \n Please Choose a file',
        });
        return;
      }
      if (
        this.file_import.type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.check_file_exl = true;
        this.readFile();
      } else if (this.file_import.type == 'text/csv') {
        this.check_file_csv = true;
        this.readfile_csv();
      }
    },
    async readFile() {
      if (
        this.file_import.type !=
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'ประเภทไฟล์อาจไม่ถูกต้อง',
        });
        return;
      }
      loading_start();

      try {
        this.limit = 10;
        var workbook = new Excel.Workbook();

        this.workbook = null;

        let options = {
          ignoreNodes: [
            'dataValidations', // ignores the workbook's Data Validations
            'autoFilter',
            'picture',
          ],
        };

        this.workbook = await workbook.xlsx
          .load(this.file_import, options)
          .catch((err) => {
            Swal.fire({
              icon: 'warning',
              title: 'ไม่สามารถอ่านไฟล์นี้ได้',
            });
            throw err;
          });
      } catch (error) {
        if (
          error instanceof RangeError &&
          error.message.includes('out of memory')
        ) {
          // Handle out-of-memory error
          Swal.fire({
            icon: 'warning',
            title: 'Out of Memory: Please reload the page or try again later',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถอ่านไฟล์นี้ได้',
          });
        }
        loading_close();
      }
      let list_worksheet = [];

      workbook.worksheets.forEach((el, index) => {
        list_worksheet.push({
          value: index,
          text: workbook.getWorksheet(el.id).name,
        });
      });
      this.list_worksheet = [
        { value: null, text: 'Please select an Sheet' },
        ...list_worksheet,
      ];
      loading_close();
    },
    getSheet() {
      let temp_row = [];
      this.columnList = [];
      this.dataExcel = [];
      const pattern_date = /^\d{4}-\d{2}-\d{2}/;
      if (this.selectedSheet == null) {
        return;
      }
      let select = this.selectedSheet;
      this.workbook.worksheets[select].eachRow((row, rownum) => {
        if (rownum > 8000) {
          return;
        }
        if (
          (this.removeHeader == true && rownum > 1) ||
          this.removeHeader == false
        ) {
          let temp_col = [];
          row.eachCell({ includeEmpty: true }, (cell, colnum) => {
            if (colnum > 51) {
              return;
            }
            if (cell.value != '') {
              if (cell.value instanceof Date) {
                const formattedDate = moment(cell.value).format('YYYY-MM-DD');
                cell.value = formattedDate;
              }
              temp_col.push(cell.value);
            }
          });
          temp_row.push(temp_col);
        }
      });

      let temp_col_list = [];
      let count_col = this.workbook.worksheets[select].columnCount;
      if (count_col > 51) {
        count_col = 51;
      }
      for (let i = 0; i < count_col; i++) {
        let pre = '';
        let str = 65 + i;
        if (i > 25) {
          pre = 'A';
          str = str - 26;
        }
        temp_col_list.push(pre + String.fromCharCode(str));
      }
      this.columnList = temp_col_list;
      this.dataExcel = temp_row;
    },
    async setdefaultExcel() {
      let columns = [];
      columns = [
        ...this.headeritems.map((v) => {
          return { header: v.col_name_th, key: v.id, width: 20 };
        }),
      ];
      const workbook = new Excel.Workbook();
      const sheet = workbook.addWorksheet('Sheet');
      sheet.columns = columns;
      const buffer = await workbook.xlsx.writeBuffer();
      this.downloadBlob(
        buffer,
        'file.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      );
    },
    downloadBlob(data, fileName, mimeType) {
      var blob, url;

      blob = new Blob([data], {
        type: mimeType,
      });

      url = window.URL.createObjectURL(blob);
      let downloadURL = (data, fileName) => {
        var a = document.createElement('a');
        a.href = data;
        a.download = fileName;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        a.remove();
      };
      downloadURL(url, fileName);

      setTimeout(function() {
        return window.URL.revokeObjectURL(url);
      }, 1500);
    },
    checkFormat(regex, bb) {
      return regex.test(bb);
    },
    async importFileLeaves() {
      let chk = true;
      const pattern_date = /^\d{4}-\d{2}-\d{2}/;
      if (this.file_import == null) {
        await Swal.fire({
          icon: 'warning',
          title: 'Please Choose a file',
        });
        setTimeout(() => {
          let el = this.$refs.file.$el;
          el.scrollIntoView(false);
        }, 500);
        return;
      }
      if (this.selectedSheet == null) {
        await Swal.fire({
          icon: 'warning',
          title: 'Please select an Sheet',
        });
        setTimeout(() => {
          let el = this.$refs.file.$el;
          el.scrollIntoView(false);
        }, 500);
        return;
      }
      // if (this.columnList.length != this.dataExcel.length) {
      //   await Swal.fire({
      //     icon: 'warning',
      //     title: 'ช่วงวันที่ที่เลือก กับข้อมูลไม่เท่ากัน',
      //   });
      //   return;
      // }

      const checkFormats = (header, value) => {
        if (header === 2 || header === 3)
          return this.checkFormat(pattern_date, value);
        return true;
      };

      let format_false = [];
      this.dataExcel.forEach((array) => {
        array.forEach((arr, i) => {
          if (!checkFormats(i, arr)) {
            chk = false;
            format_false.push(arr);
            return;
          }
        });
      });

      let result = format_false.join(',');
      if (!chk) {
        Swal.fire({
          icon: 'warning',
          title: 'ข้อมูลไม่ตรงกับ format ที่เลือก กรุณาตรวจสอบ',
          text: result,
        });
        return;
      }

      let params = {
        // col: this.columnList,
        col: this.headeritems,
        rows: this.dataExcel,
      };

      try {
        let result = await this.$store.dispatch('adjunct/importLeave', params);
        if (result['nonemp'] != '') {
          await Swal.fire({
            icon: 'success',
            title: 'Import File Complete',
            text: 'ไม่มีรหัสพนักงานนี้ในระบบ ' + result['nonemp'],
          });
        } else {
          await Swal.fire({
            icon: 'success',
            title: 'Import File Complete',
            text: '',
          });
        }
      } catch (error) {
        await Swal.fire({
          icon: 'error',
          title: 'Import File Fail',
          text: '',
        });
      }
    },
  },
};
</script>
<style lang="scss">
.tab-pane {
  width: 100%;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
</style>
