<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="card-icon">
        <md-icon>add_circle_outline</md-icon>
      </div>
      <h4 class="title"></h4>
      <div class="text-right">
        <md-button @click="goBack" class="md-primary md-dense">
          Back to List
        </md-button>
      </div>
    </md-card-header>
    <md-card-content>
      <div
        class="md-layout-item md-size-100 md-alignment-top-center add-contract"
      >
        <form-contract v-if="showcon_add == true" />
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import Form_Contract from '@/pages/Dashboard/Pages/Admin/Contract/Form_Contract.vue';
export default {
  components: {
    'form-contract': Form_Contract,
  },
  created() {
    this.showfrom_add();
  },
  data() {
    return {
      showcon_add: false,
    };
  },
  methods: {
    showfrom_add() {
      this.showcon_add = !this.showcon_add;
    },
    goBack() {
      this.$router.push({
        path: 'form-contract',
        name: 'ฟอร์มสัญญาจ้าง',
      });
    },
  },
};
</script>
