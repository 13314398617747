<template>
  <md-card class="md-card-profile">
    <div class="md-card-avatar">
      <img
        class="img pointer"
        :src="cardUserImage"
        @click="openEdit()"
        v-show="showDefaultavatar"
      />
      <img
        class="img pointer"
        :src="avatar"
        @click="openEdit()"
        v-show="!showDefaultavatar"
      />
      <md-button
        class="md-icon-button md-round md-warning editprofileimg"
        @click="openEdit()"
      >
        <md-icon>edit</md-icon>
      </md-button>
    </div>
    <md-card-content>
      <h5 class="category">{{ employee_position }}</h5>
      <h4 class="card-title">{{ employee_name }}</h4>
      <div class="md-layout">
        <div class="md-layout-item md-size-40 md-small-size-50 text-align-left">
          <span>Employee ID:</span>
        </div>
        <div class="md-layout-item md-size-60 md-small-size-50 text-align-left">
          <span>{{ employee_id }}</span>
        </div>
        <div class="md-layout-item md-size-40 md-small-size-50 text-align-left">
          <span>Start date:</span>
        </div>
        <div class="md-layout-item md-size-60 md-small-size-50 text-align-left">
          <span>{{ employee_startdate }}</span>
        </div>
        <div class="md-layout-item md-size-40 md-small-size-50 text-align-left">
          <span>Birth date:</span>
        </div>
        <div class="md-layout-item md-size-60 md-small-size-50 text-align-left">
          <span>{{ employee_birthdate }}</span>
        </div>
        <div class="md-layout-item md-size-40 md-small-size-50 text-align-left">
          <span>Department:</span>
        </div>
        <div class="md-layout-item md-size-60 md-small-size-50 text-align-left">
          <span>{{ employee_department }}</span>
        </div>
      </div>
      <!-- <p class="card-description">
        Don't be scared of the truth because we need to restart the human
        foundation in truth And I love you like Kanye loves Kanye I love Rick
        Owens’ bed design but the back is...
      </p> -->
    </md-card-content>
  </md-card>
</template>

<script>
import { createElement } from '@fullcalendar/core';
import moment from 'moment';

export default {
  name: 'user-profile-card',
  props: {
    cardUserImage: {
      type: String,
      default: '',
    },
    buttonColor: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDefaultavatar: 1,
      avatar: process.env.VUE_APP_APP_BASE_URL + '/img/default-avatar.png',
    };
  },
  created() {
    this.checkImg();
    if (this.cardUserImage != '') {
      this.intervalImg();
    } else {
      this.showDefaultavatar = 0;
    }
  },
  mounted() {
    this.$emit('init', true);
  },
  destroyed() {
    this.clearintervalImg();
  },
  computed: {
    employee_name: function() {
      return (
        this.user.employees.first_nameen + ' ' + this.user.employees.last_nameen
      );
    },
    employee_position: function() {
      return this.user.employees.salarys[0].positions.pos_nameen;
    },
    employee_id: function() {
      return this.user.employees.emp_id;
    },
    employee_department: function() {
      return this.user.employees.salarys[0].positions.departments.dep_nameen;
    },
    employee_startdate: function() {
      let startdate = moment(this.user.employees.start_date).format(
        'DD/MM/YYYY',
      );
      return startdate != 'Invalid date'
        ? startdate
        : this.user.employees.start_date;
    },
    employee_birthdate: function() {
      let birthdate = moment(this.user.employees.birth_date).format(
        'DD/MM/YYYY',
      );
      return birthdate != 'Invalid date'
        ? birthdate
        : this.user.employees.birth_date;
    },
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = this.avatar;
    },
    intervalImg() {
      this.globalInterval = setInterval(this.checkImg, 30000);
    },
    clearintervalImg() {
      clearInterval(this.globalInterval);
    },
    checkImg() {
      let img = createElement('img');
      img.src = this.cardUserImage;
      let global = this;
      img.onerror = function() {
        global.showDefaultavatar = 0;
      };
      if (this.showDefaultavatar == 1) {
        this.clearintervalImg();
      }
    },
    openEdit() {
      this.$emit('openEdit', true);
    },
    getColorButton: (buttonColor) => 'md-' + buttonColor + '',
  },
};
</script>
<style scope>
.text-align-left {
  text-align: left;
}
</style>
