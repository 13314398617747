<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100" v-if="isParams">
      <tabs
        :tab-name="tabList.name"
        :tab-icon="tabList.icon"
        :tab-active="tabactive"
        nav-pills-icons
        plain
        color-button="warning"
      >
        <!-- here you can add your content for tab-content -->
        <template slot="tab-pane-1">
          <md-card>
            <md-card-header>
              <h4 class="title"></h4>
              <p class="category"></p>
            </md-card-header>
            <md-card-content>
              <F_Contract :permissions="permissionsList" />
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-2">
          <md-card>
            <md-card-header>
              <h4 class="title"></h4>
              <p class="category"></p>
            </md-card-header>
            <md-card-content>
              <setting_witness :permissions="permissionsList" />
            </md-card-content>
          </md-card>
        </template>
      </tabs>
    </div>
  </div>
</template>
<script>
import { Tabs } from '@/components';
import moment from 'moment';
import F_Contract from '@/pages/Dashboard/Pages/Admin/Contract/F_Contract.vue';
import setting_witness from '@/pages/Dashboard/Pages/Admin/Contract/setting_witness.vue';
// import Vuex from 'vuex';
import _ from 'lodash';

export default {
  components: {
    Tabs,
    F_Contract: F_Contract,
    setting_witness: setting_witness,
    // 'raw-time': RawTime,
    // 'working-time': WorkingTime,
    // 'slip-salary': SlipSalary,
  },

  data() {
    return {
      tabactive: 0,
      tabList: {
        name: [],
        icon: [],
      },
      permissionsList: [],
      employee_id: null,
      months: moment()
        // .subtract(1, 'month')
        .month(),
      years: moment().year(),
      branchs: {},
      isParams: false,
      me: null,
      query: null,
    };
  },

  async created() {
    this.getProfile().then(() => {
      this.setPermission(this.me);
      this.setEmployeeID(this.me).then(() => {
        this.isParams = true;
        if (this.permissionsList.includes('process salary')) {
          //   //this.tabList.name = ['เวลาเข้าออกพนักงาน','กะเวลาทำงาน','ระบบลา','OT','เงินเดือน'];
          //   //this.tabList.icon = ['transfer_within_a_station','schedule','indeterminate_check_box','av_timer','monetization_on'];
          this.tabList.name = [
            'ฟอร์มสัญญาจ้าง',
            'setting witness',
            // 'กะเวลาทำงาน',
            // 'เงินเดือน',
          ];
          this.tabList.icon = [
            'library_books',
            'settings_applications',
            // 'schedule',
            // 'local_atm',
          ];
        } else {
          this.setstaffTab();
        }
      });
    });

    if (this.$route.params.tabactive) {
      this.tabactive = this.$route.params.tabactive;
    }
  },

  computed: {
    // ...Vuex.mapState({
    //   me: (state) => state.profile.me,
    // }),
  },

  methods: {
    setTabActive() {
      if (this.$route.params.tabactive) {
        this.tabactive = this.$route.params.tabactive;
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissionsList.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissionsList.push(permission.name);
      });
    },

    async setEmployeeID(me) {
      // if (me.roles[0].name === 'Super admin') {
      //   this.employee_id = null;
      //   this.branchs = {};
      // } else {
      this.branchs = me.employees.branchs;
      this.employee_id = me.employee_id;
      // }
    },

    setstaffTab() {
      this.tabList.name = ['ฟอร์มสัญญาจ้าง', 'setting witness'];
      this.tabList.icon = ['library_books', 'settings_applications'];
    },

    async getProfile() {
      await this.$store.dispatch('profile/me');
      this.me = await this.$store.getters['profile/me'];
    },
  },
};
</script>
<style lang="scss">
.tab-pane-1,
.tab-pane-2,
.tab-pane-3,
.tab-pane-4,
.tab-pane-5,
.tab-pane-6 {
  width: 100%;
}
</style>
