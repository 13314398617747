<template>
  <div v-if="user" class="md-layout md-gutter">
    <div class="md-layout-item md-size-66 md-small-size-100">
      <div class="md-layout-item md-size-100">
        <user-password-card :user="user" />
      </div>
    </div>
  </div>
</template>

<script>
import UserPasswordCard from '@/pages/Dashboard/Pages/Profile/UserProfile/EditPasswordCard.vue';

export default {
  name: 'reset-password',

  components: {
    'user-password-card': UserPasswordCard,
  },

  data: () => ({
    user: null,
  }),

  created() {
    this.getProfile();
  },

  methods: {
    async getProfile() {
      await this.$store.dispatch('profile/me');
      this.user = await this.$store.getters['profile/me'];
    },
  },
};
</script>
