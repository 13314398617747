<template>
  <div style="min-height: 600px;">
    <md-card v-if="Object.keys(files).length > 0">
      <md-card-content>
        <b-tabs pills>
          <b-tab
            v-for="(file, index) in files"
            :key="index + file.filename + file.info_id"
            :title="file.filename"
            @click="set_file_id(file.info_id)"
          >
          </b-tab>
        </b-tabs>
      </md-card-content>
    </md-card>
    <informationrules v-if="file_id != null" :file_id="file_id" />
  </div>
</template>
<script>
import $ from 'jquery';
import { loading_start, loading_close } from '@/utils/loading.js';
import Swal from 'sweetalert2';

import InformationRules from '@/pages/Dashboard/Pages/Staff/Transactions/Information/InformationRules.vue';

export default {
  components: {
    informationrules: InformationRules,
  },
  async created() {
    this.files = await this.getfile();
  },
  computed: {},
  data() {
    return {
      files: null,
      file_id: null,
    };
  },
  watch: {},
  methods: {
    async getfile() {
      let params = {
        bch_id: '',
        dep_id: '',
        emp_id: '',
      };
      let resp = await this.$store.dispatch('info/getFileInfo', params);
      return resp;
    },
    set_file_id(id) {
      this.file_id = null;
      if (id != null) {
        this.file_id = id;
      }
    },
  },
};
</script>
<style></style>
