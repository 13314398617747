import service from '@/store/services/holidays-service';

const state = {
  list: {},
  holiday: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, holiday) => {
    state.holiday = holiday;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      return list;
    });
  },
  // add({ commit, dispatch }, params) {
  // 	return service.add(params).then((leave) => {
  // 		commit('SET_RESOURCE', leave);
  // 	});
  // },

  // approve({ commit, dispatch }, params) {
  // 	return service.approve(params).then((leave) => {
  // 		commit('SET_RESOURCE', leave);
  // 	});
  // },
};

const getters = {
  list: (state) => state.list,
  holiday: (state) => state.holiday,
  dropdown: (state) => {
    return state.list.map((holiday) => ({
      id: holidays.id,
      name_th: holidays.name_th,
      name_en: holidays.name_en,
      holiday_date: holidays.holiday_date,
      year: holidays.year,
      company_id: company_id,
    }));
  },
};

const holidays = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default holidays;
