import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function leavecreate(leave) {
  const payload = jsona.serialize({
    stuff: leave,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(`${url}/v1/leavecreate`, payload, options);
  return jsona.deserialize(response.data);
}

async function leaveCreateApprove(leave) {
  const payload = jsona.serialize({
    stuff: leave,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/leavecreateapprove`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function deleteLeave(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  const response = await axios.post(`${url}/v1/deleteleave`, params, options);
  return response;
}

async function updateLeave(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  const response = await axios.post(`${url}/v1/updateleave`, params, options);
  return response.data;
}

async function list(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/leavelist`, options);
  return {
    list: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

async function approve(approval) {
  const payload = jsona.serialize({
    stuff: approval,
    includeNames: [],
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.patch(
    `${url}/v1/leaveapprove/${approval.id}`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function staffleavelist(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/staffleavelist`, options);
  if (response.data.length <= 0) {
    return [];
  }
  return jsona.deserialize(response.data);
}

async function staffleaveremain(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/leaveremain`, options);
  return response.data;
}

export default {
  leavecreate,
  leaveCreateApprove,
  deleteLeave,
  updateLeave,
  list,
  approve,
  staffleavelist,
  staffleaveremain,
};
