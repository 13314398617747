import service from '@/store/services/weekends-service';

const state = {
  list: {},
  weekend: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, weekend) => {
    state.weekend = weekend;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      commit('SET_META', meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((weekend) => {
      commit('SET_RESOURCE', weekend);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((weekend) => {
      commit('SET_RESOURCE', weekend);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((weekend) => {
      commit('SET_RESOURCE', weekend);
    });
  },

  // destroy({ commit, dispatch }, params) {
  // 	return service.destroy(params);
  // },
};

const getters = {
  list: (state) => state.list,
  //listTotal: (state) => state.meta.page.total,
  weekend: (state) => state.weekend,
  dropdown: (state) => {
    return state.list.map((weekend) => ({
      id: weekend.id,
      name: weekend.name,
    }));
  },
};

const weekends = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default weekends;
