import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function get() {
  // return axios.get(`${url}/v1/me`)
  //   .then(response => {
  //     return {
  //       list: jsona.deserialize(response.data),
  //       meta: response.data.meta
  //     };
  //   });
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .get(
      `${url}/v1/me?include=roles,employees,employees.branchs,roles.permissions,permissions,employees.salarys,employees.salarys.positions,employees.salarys.positions.departments`,
      options,
    )
    .then((response) => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta,
      };
    });
}

function update(profile) {
  const payload = jsona.serialize({
    stuff: profile,
    includeNames: [],
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .patch(
      `${url}/v1/me?include=roles,employees,employees.branchs,roles.permissions,permissions`,
      payload,
      options,
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  get,
  update,
};
