<template>
  <md-card>
    <md-card-header>
      <h4>นำเข้าเวลาเข้า - ออก งาน</h4>
    </md-card-header>
    <md-card-content>
      <div class="md-layout md-alignment-center-left">
        <div
          class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <b-input-group>
            <b-form-file
              accept=".csv, .xlsx"
              ref="file"
              v-model="file_import"
              :state="Boolean(file_import)"
              placeholder="Choose a file or drop file here..."
              drop-placeholder="Drop file here..."
              @change="change_check()"
            />
            <b-input-group-append>
              <!-- <b-button variant="info" @click="readFile()">Read File</b-button> -->
              <b-button variant="info" @click="check_fileType()"
                >Read File</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>
        <div
          v-if="check_file_exl == true"
          class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <b-form-select v-model="selectedSheet" :options="list_worksheet" />
        </div>
        <div
          class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <b-form-checkbox
            v-if="check_file_exl == true"
            v-model="removeHeader"
            switch
          >
            ลบแถวหัวข้อในไฟล์ Excel
          </b-form-checkbox>
          <b-form-checkbox
            v-if="check_file_csv == true"
            v-model="removeHeaderCsv"
            switch
          >
            ลบแถวหัวข้อในไฟล์ csv
          </b-form-checkbox>
        </div>
      </div>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item">
          <label>
            หัวข้อ Time:
          </label>
          <div class="md-layout-item spacing">
            <b-input-group>
              <b-button variant="warning" @click="resetHeader()">
                Reset หัวข้อ
              </b-button>
              <b-input-group-append>
                <b-button variant="primary" @click="setdefaultExcel()">
                  Export หัวข้อ
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="md-layout-item spacing">
            <draggable
              class="list-group"
              tag="ul"
              v-model="headerList"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
            >
              <transition-group
                type="transition"
                :name="!drag ? 'flip-list' : null"
              >
                <li
                  class="list-group-item"
                  v-for="(element, index) in headerList"
                  :key="element.col_name_th"
                >
                  <span
                    v-if="element.col_name_th != 'รหัสพนักงาน'"
                    @click="removeAt(element, index)"
                  >
                    <md-icon class="pointer" style="color:rgb(255, 0, 0)">
                      close
                    </md-icon>
                  </span>
                  <span v-if="index <= 25">
                    {{ String.fromCharCode(index + 65) }} :
                    {{ element.col_name_th }}
                  </span>
                  <span v-else>
                    A{{ String.fromCharCode(index - 26 + 65) }} :
                    {{ element.col_name_th }}
                  </span>
                </li>
              </transition-group>
              <!-- <button slot="header">Add</button> -->
            </draggable>
          </div>
        </div>
      </div>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item spacing md-alignment">
          <b-button
            v-if="check_file_exl == true"
            variant="success"
            @click="importFileTime()"
          >
            Import File
          </b-button>
          <b-button
            v-if="check_file_csv == true"
            variant="success"
            @click="importFileTimeCsv()"
          >
            Import File
          </b-button>
        </div>
      </div>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item spacing md-alignment">
          <b-table-simple responsive v-if="dataExcel.length > 0">
            <b-thead>
              <b-tr>
                <b-th v-for="(col, index) in columnList" :key="'col-' + index">
                  {{ col }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(data, index) in dataExcel.slice(0, limit)"
                :key="'data-' + index"
              >
                <b-td v-for="(d, i) in data" :key="'d-' + i">
                  {{ d.val }}
                </b-td>
              </b-tr>
              <b-tr v-if="limit < dataExcel.length">
                <b-td :colspan="dataExcel[0].length">
                  <b-button
                    variant="info"
                    style="width:60%"
                    @click="limit += 10"
                  >
                    Load more
                  </b-button>
                  <b-button
                    variant="primary"
                    style="width:40%"
                    @click="limit = dataExcel.length"
                  >
                    Load All
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';
import { loading_start, loading_close } from '@/utils/loading.js';
import Excel from 'exceljs';
import draggable from 'vuedraggable';
import Swal from 'sweetalert2';

export default {
  mixins: [employeeMixin],
  components: {
    draggable,
  },
  data() {
    return {
      permissionsList: [],
      headeritems: [],
      headerList: [],
      check_file_exl: false,
      check_file_csv: false,
      file_import: null,
      list_worksheet: [{ value: null, text: 'Please select an Sheet' }],
      selectedSheet: null,
      dataArray: [],
      dataArray_ins: [],
      removeHeader: false,
      removeHeaderCsv: false,
      columnList: [],
      dataExcel: [],
      workbook: {},
      drag: false,
      limit: 15,
    };
  },

  async created() {
    loading_start();
    let type = 2;
    this.headeritems = await this.getImportColumnList(type);
    this.headerList = this.headeritems.map((val, index) => {
      return val;
    });
    this.profile = await this.getProfileData();
    loading_close();
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },

  props: {
    permissions: {
      type: Array,
      default: () => {},
    },
    available_branches: {
      type: Array,
      default: () => {},
    },
  },

  watch: {
    branches: async function(bch_id) {
      if (bch_id) {
        this.genListYear(bch_id);
      }
    },
    years: async function(years) {
      if (years) {
        this.genListMonth(this.branches, years);
      } else {
        this.available_months = [];
        this.months = '';
      }
    },
    selectedSheet: function() {
      this.getSheet();
    },
    removeHeader: function() {
      this.getSheet();
    },
    removeHeaderCsv: function() {
      this.getSheetCsv();
    },
  },

  methods: {
    async getImportColumnList(type) {
      const res = await this.$store.dispatch('adjunct/importColumnlist', {
        type: type,
      });
      return res;
    },

    change_check() {
      this.check_file_exl = false;
      this.check_file_csv = false;
    },

    async check_fileType() {
      this.check_file_exl = false;
      this.check_file_csv = false;
      if (this.file_import == null) {
        Swal.fire({
          icon: 'warning',
          title: 'โปรดเลือกไฟล์ / \n Please Choose a file',
        });
        return;
      }
      if (
        this.file_import.type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.check_file_exl = true;
        this.readFile();
      } else if (this.file_import.type == 'text/csv') {
        this.check_file_csv = true;
        this.readfile_csv();
      }
    },

    async readFile() {
      if (
        this.file_import.type !=
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'ประเภทไฟล์อาจไม่ถูกต้อง',
        });
        return;
      }
      loading_start();

      try {
        this.limit = 10;
        var workbook = new Excel.Workbook();

        this.workbook = null;

        let options = {
          ignoreNodes: [
            'dataValidations', // ignores the workbook's Data Validations
            'autoFilter',
            'picture',
          ],
        };

        this.workbook = await workbook.xlsx
          .load(this.file_import, options)
          .catch((err) => {
            Swal.fire({
              icon: 'warning',
              title: 'ไม่สามารถอ่านไฟล์นี้ได้',
            });
            throw err;
          });
      } catch (error) {
        if (
          error instanceof RangeError &&
          error.message.includes('out of memory')
        ) {
          // Handle out-of-memory error
          Swal.fire({
            icon: 'warning',
            title: 'Out of Memory: Please reload the page or try again later',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถอ่านไฟล์นี้ได้',
          });
        }
        loading_close();
      }
      let list_worksheet = [];

      workbook.worksheets.forEach((el, index) => {
        list_worksheet.push({
          value: index,
          text: workbook.getWorksheet(el.id).name,
        });
      });
      this.list_worksheet = [
        { value: null, text: 'Please select an Sheet' },
        ...list_worksheet,
      ];
      loading_close();
    },

    async readfile_csv() {
      if (this.file_import.type != 'text/csv') {
        Swal.fire({
          icon: 'warning',
          title: 'ประเภทไฟล์อาจไม่ถูกต้อง',
        });
        return;
      }

      loading_start();

      let promise = new Promise((resolve, reject) => {
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e) => {
          resolve((vm.fileinput = reader.result));
        };
        reader.readAsText(this.file_import);
      });

      const pattern_datetime = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
      const pattern_date = /^\d{4}-\d{2}-\d{2}/;
      const pattern_time = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

      promise.then(
        (result) => {
          let rows = result.split('\n');
          let temp_row = [];
          let temp_row_ins = [];
          let chk = true;

          let headerRow = rows[0]
            .split(',')
            .map((value) => value.replace(/"|(\r|\\$)/g, ''));
          this.columnList = headerRow;

          if (this.headerList.length !== this.columnList.length) {
            Swal.fire({
              icon: 'warning',
              title: 'กรุณาเลือกให้หัวข้อเท่ากันกับหัวข้อไฟล์',
            });
            return;
          }

          const checkFormats = (header, value) => {
            if (header === 'Date Time')
              return this.checkFormat(pattern_datetime, value);
            if (header === 'Date') return this.checkFormat(pattern_date, value);
            if (header === 'Time') return this.checkFormat(pattern_time, value);
            return true;
          };

          rows.forEach((row, i) => {
            let values = row.split(',');
            let formattedValues = values.map((value) =>
              value.replace(/"|(\r|\\$)/g, ''),
            );
            let empty = false;
            let arr_new = [];
            formattedValues.forEach((value, idx) => {
              if (value !== '') {
                if (!checkFormats(this.headerList[idx]['col_name_en'], value)) {
                  chk = false;
                  return;
                }
                arr_new.push({ col: idx + 1, val: value });
              } else {
                empty = true;
              }
            });
            if (!chk) return;
            if (!empty) {
              temp_row_ins.push(formattedValues);
              temp_row.push(arr_new);
            }
          });

          if (!chk) {
            Swal.fire({
              icon: 'warning',
              title: 'ข้อมูลไม่ตรงกับ format ที่เลือก กรุณาตรวจสอบ',
            });
            return;
          }

          this.dataArray = temp_row;
          this.dataArray_ins = temp_row_ins;
          this.getSheetCsv();
        },
        (error) => {
          // console.log(error);
        },
      );

      loading_close();
    },

    checkFormat(regex, bb) {
      return regex.test(bb);
    },

    excelTimeToJSTime(excelTime) {
      const totalSeconds = Math.round(excelTime * 24 * 60 * 60);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      return { hours, minutes };
    },

    getSheet() {
      let chk = true;
      const pattern_datetime = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
      const pattern_date = /^\d{4}-\d{2}-\d{2}/;
      const pattern_time = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
      let temp_row = [];
      this.columnList = [];
      this.dataExcel = [];
      if (this.selectedSheet == null) {
        return;
      }
      let select = this.selectedSheet;
      let count_col = this.workbook.worksheets[select].columnCount;
      if (this.headerList.length != count_col) {
        Swal.fire({
          icon: 'warning',
          title: 'กรุณาเลือกให้หัวข้อเท่ากันกับหัวข้อไฟล์',
        });
        return;
      }
      const checkFormats = (header, value) => {
        if (header === 'Date Time')
          return this.checkFormat(pattern_datetime, value);
        if (header === 'Date') return this.checkFormat(pattern_date, value);
        if (header === 'Time') return this.checkFormat(pattern_time, value);
        return true;
      };
      this.workbook.worksheets[select].eachRow((row, rownum) => {
        if (rownum > 8000) {
          return;
        }
        if (
          (this.removeHeader == true && rownum > 1) ||
          this.removeHeader == false
        ) {
          let temp_col = [];
          row.eachCell({ includeEmpty: true }, (cell, colnum) => {
            if (colnum > 51) {
              return;
            }
            if (
              !checkFormats(
                this.headerList[colnum - 1]['col_name_en'],
                cell.value,
              ) &&
              typeof cell.value === 'string'
            ) {
              chk = false;
              return;
            }

            if (cell.value != '') {
              if (cell.value instanceof Date) {
                const formattedDate = moment(cell.value).format('YYYY-MM-DD');
                cell.value = formattedDate;
              }
              if (typeof cell.value === 'number' && cell.value < 1) {
                const jsTime = this.excelTimeToJSTime(cell.value);
                const formattedTime = moment({
                  hours: jsTime.hours,
                  minutes: jsTime.minutes,
                }).format('HH:mm:ss');
                cell.value = formattedTime;
              }
              temp_col.push({ col: colnum, val: cell.value });
            }
          });
          if (!chk) {
            return;
          }
          temp_row.push(temp_col);
        }
      });
      let temp_col_list = [];
      count_col = this.workbook.worksheets[select].columnCount;
      if (count_col > 51) {
        count_col = 51;
      }
      for (let i = 0; i < count_col; i++) {
        let pre = '';
        let str = 65 + i;
        if (i > 25) {
          pre = 'A';
          str = str - 26;
        }
        temp_col_list.push(pre + String.fromCharCode(str));
      }
      if (!chk) {
        Swal.fire({
          icon: 'warning',
          title: 'ข้อมูลไม่ตรงกับ format ที่เลือก กรุณาตรวจสอบ',
        });
        return;
      }
      this.columnList = temp_col_list;
      this.dataExcel = temp_row;
    },

    getSheetCsv() {
      let temp_row = [];
      let temp_col_list = [];
      this.dataArray.forEach((row, i) => {
        if (
          (this.removeHeaderCsv == true && i > 0) ||
          this.removeHeaderCsv == false
        ) {
          temp_row.push(row);
        }
      });
      let count_col = this.columnList.length;
      if (count_col > 51) {
        count_col = 51;
      }
      for (let i = 0; i < count_col; i++) {
        let pre = '';
        let str = 65 + i;
        if (i > 25) {
          pre = 'A';
          str = str - 26;
        }
        temp_col_list.push(pre + String.fromCharCode(str));
      }
      this.columnList = temp_col_list;
      this.dataExcel = temp_row;
    },

    resetHeader() {
      this.headerList = [...this.headeritems];
    },

    async setdefaultExcel() {
      let columns = [];
      columns = [
        ...this.headerList.map((v) => {
          return { header: v.col_name_th, key: v.id, width: 20 };
        }),
      ];
      const workbook = new Excel.Workbook();
      const sheet = workbook.addWorksheet('Sheet');
      sheet.columns = columns;
      const buffer = await workbook.xlsx.writeBuffer();
      this.downloadBlob(
        buffer,
        'file.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      );
    },

    removeAt(element, index) {
      if (this.headerList[index].col_name_th == 'รหัสพนักงาน') {
        return;
      }
      if (element['col_name_en'] == 'Time') {
        let i = this.headerList.findIndex(
          (item) => item['col_name_en'] === 'Date',
        );
        this.headerList.splice(index, 1);
        this.headerList.splice(i, 1);
      } else if (element['col_name_en'] == 'Date') {
        let i = this.headerList.findIndex(
          (item) => item['col_name_en'] === 'Time',
        );
        this.headerList.splice(i, 1);
        this.headerList.splice(index, 1);
      } else {
        this.headerList.splice(index, 1);
      }
    },

    async importFileTime() {
      if (this.file_import == null) {
        await Swal.fire({
          icon: 'warning',
          title: 'Please Choose a file',
        });
        setTimeout(() => {
          let el = this.$refs.file.$el;
          el.scrollIntoView(false);
        }, 500);
        return;
      }
      if (this.selectedSheet == null) {
        await Swal.fire({
          icon: 'warning',
          title: 'Please select an Sheet',
        });
        setTimeout(() => {
          let el = this.$refs.file.$el;
          el.scrollIntoView(false);
        }, 500);
        return;
      }

      let select = this.selectedSheet;
      let count_col = this.workbook.worksheets[select].columnCount;
      if (this.headerList.length != count_col) {
        await Swal.fire({
          icon: 'warning',
          title: 'กรุณาเลือกให้หัวข้อเท่ากันกับหัวข้อไฟล์',
        });
        return;
      }

      let temp = this.workbook.worksheets[this.selectedSheet];
      let temp_row = [];
      temp.eachRow((row, rownum) => {
        if (
          (this.removeHeader == true && rownum > 1) ||
          this.removeHeader == false
        ) {
          let temp_col = [];
          row.eachCell({ includeEmpty: true }, (cell, colnum) => {
            temp_col.push(cell.value);
          });
          temp_row.push(temp_col);
        }
      });

      let params = {
        col: this.headerList,
        rows: temp_row,
        emp_id: this.profile[0]['emp_id'],
      };

      try {
        let result = await this.$store.dispatch('adjunct/importTime', params);
        await Swal.fire({
          icon: 'success',
          title: 'Import File Complete',
        });
      } catch (error) {
        await Swal.fire({
          icon: 'error',
          title: 'Import File Fail',
        });
      }
    },

    async importFileTimeCsv() {
      let temp_row = [];
      if (this.file_import == null) {
        await Swal.fire({
          icon: 'warning',
          title: 'Please Choose a file',
        });
        setTimeout(() => {
          let el = this.$refs.file.$el;
          el.scrollIntoView(false);
        }, 500);
        return;
      }

      let count_col = this.columnList.length;
      if (this.headerList.length != count_col) {
        await Swal.fire({
          icon: 'warning',
          title: 'กรุณาเลือกให้หัวข้อเท่ากันกับหัวข้อไฟล์',
        });
        return;
      }

      this.dataArray_ins.forEach((row, i) => {
        if (
          (this.removeHeaderCsv == true && i > 0) ||
          this.removeHeaderCsv == false
        ) {
          temp_row.push(row);
        }
      });

      let params = {
        col: this.headerList,
        rows: temp_row,
        emp_id: this.profile[0]['emp_id'],
      };

      try {
        let result = await this.$store.dispatch('adjunct/importTime', params);
        await Swal.fire({
          icon: 'success',
          title: 'Import File Complete',
        });
      } catch (error) {
        await Swal.fire({
          icon: 'error',
          title: 'Import File Fail',
        });
      }
    },
  },
};
</script>
<style lang="scss">
.tab-pane {
  width: 100%;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
</style>
