<template>
  <div>
    <!--ไดอะลอก ADD-->
    <md-dialog
      style="min-height: 30%; min-width: 30%;"
      :md-active.sync="showADD"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.6rem" align="center">
        กรอกรหัสสแกนนิ้ว
      </md-dialog-title>
      <md-content align="left">
        <div class="md-layout spacing md-alignment-center">
          <div class="md-layout-item md-size-90">
            <md-field>
              <md-icon>person_add</md-icon>
              <label>กรอกรหัสสแกนนิ้ว</label>
              <md-input v-model="fixed_fingerscan_id"></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
          >
            <md-button class="md-primary md-sm" @click="add_fingerscan()"
              >เพิ่ม</md-button
            >
          </div>
        </div>
      </md-content>
    </md-dialog>
    <!--ปิดไดอะลอก ADD-->
    <!--ไดอะลอก UPDATE-->
    <md-dialog
      style="min-height: 30%; min-width: 30%;"
      :md-active.sync="showUPDATE"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.6rem" align="center">
        แก้ไข รหัสสแกนนิ้ว
      </md-dialog-title>
      <md-content align="left">
        <div class="md-layout spacing md-alignment-center">
          <div class="md-layout-item md-size-90">
            <md-field>
              <md-icon>edit</md-icon>
              <label>กรอกรหัสสแกนนิ้ว</label>
              <md-input v-model="edit_fingerscan_id"></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
          >
            <md-button class="md-primary md-sm" @click="update_fingerscan()"
              >อัพเดท</md-button
            >
          </div>
        </div>
      </md-content>
    </md-dialog>
    <!--ปิดไดอะลอก UPDATE-->
    <div class="md-layout">
      <div class="md-layout md-gutter md-layout-item md-size-100">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <md-card>
            <div class="header" @click="headdropdown($event)">
              <md-card-header class="md-card-header-icon md-card-header-rose">
                <div class="card-icon">
                  <md-icon>
                    playlist_add_check
                  </md-icon>
                </div>
                <h5 class="title">ตรวจสอบลายนิ้วมือในระบบ</h5>
              </md-card-header>
            </div>
            <md-card-content class="contentleave">
              <!-- Date -->
              <div class="md-layout spacing md-alignment-center">
                <div
                  class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
                >
                  <md-field>
                    <md-icon>search</md-icon>
                    <label>ค้นหา</label>
                    <md-input v-model="search_emp"></md-input>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
                >
                  <md-datepicker v-model="fromdate" md-immediately>
                    <label>วันที่เริ่ม</label>
                  </md-datepicker>
                </div>
                <div
                  class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
                >
                  <md-datepicker
                    v-model="todate"
                    md-immediately
                    :md-disabled-dates="disabledToDates"
                  >
                    <label>วันที่สิ้นสุด</label>
                  </md-datepicker>
                </div>
                <div class="md-layout-item md-size-10">
                  <md-progress-spinner
                    :md-diameter="50"
                    :md-stroke="3"
                    md-mode="indeterminate"
                    :md-value="100"
                    v-show="showProgressBar"
                  >
                  </md-progress-spinner>
                </div>
              </div>

              <!-- Button -->
              <div class="md-layout spacing md-alignment-center">
                <div
                  class="md-layout-item spacing md-large-size-15 md-size-15 md-medium-size-20 md-small-size-20 md-xsmall-size-50"
                >
                  <b-button size="lg" variant="info" @click="searchTimeInout">
                    Search
                  </b-button>
                </div>
              </div>

              <!-- Display table -->
              <div
                class="md-layout spacing"
                v-if="Object.keys(tableData).length > 0"
              >
                <div class="md-layout-item md-size-100">
                  <md-table
                    table-header-color="green"
                    class="table-striped table-hover"
                  >
                    <md-table-cell>วันที่</md-table-cell>
                    <md-table-cell>เวลาสแกน</md-table-cell>
                    <md-table-row
                      v-for="(items, name, index) in tableData"
                      :key="{ index } + name"
                    >
                      <md-table-cell>
                        {{ name }}
                      </md-table-cell>
                      <md-table-cell>
                        <md-table-cell
                          v-for="(time, index) in items"
                          :key="{ index } + time.id_staffinouts"
                        >
                          {{ time.time_record }}
                        </md-table-cell>
                      </md-table-cell>
                    </md-table-row>
                  </md-table>
                  <!-- </md-card-content>
                </md-card> -->
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout md-gutter md-layout-item md-size-100">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <md-card>
            <div class="header" @click="headdropdown($event)">
              <md-card-header
                class="md-card-header-icon md-card-header-warning"
              >
                <div class="card-icon">
                  <md-icon>
                    edit
                  </md-icon>
                </div>
                <h5 class="title">เพิ่ม/แก้ไข รหัสสแกนลายนิ้วมือ</h5>
              </md-card-header>
            </div>
            <md-card-content class="contentleave">
              <filter-employee
                @childBranches="setBranches"
                @childDepartment="setDepartment"
                @childStaffs="setStaffs"
                :permissionsList="permissions"
              >
              </filter-employee>
              <div class="md-layout spacing md-alignment-center">
                <div
                  class="md-layout-item spacing md-large-size-10 md-size-10 md-medium-size-15 md-small-size-20 md-xsmall-size-35"
                >
                  <md-button
                    class="md-success md-sm"
                    @click="search_fingerscan()"
                    >Search</md-button
                  >
                </div>
                <div
                  class="md-layout-item spacing md-large-size-10 md-size-10 md-medium-size-15 md-small-size-20 md-xsmall-size-35"
                >
                  <md-button class="md-primary md-sm" @click="check_showADD()"
                    >เพิ่ม</md-button
                  >
                </div>
              </div>
              <md-table
                class="paginated-table table-striped table-hover"
                table-header-color="green"
              >
                <md-table-row v-if="tableDataFingerscan != ''">
                  <md-table-head>รหัสสแกนลายนิ้วมือ</md-table-head>
                  <md-table-head>รหัสพนักงาน</md-table-head>
                  <md-table-head>ชื่อพนักงาน</md-table-head>
                  <md-table-head>Action</md-table-head>
                </md-table-row>
                <md-table-row
                  slot="md-table-row"
                  v-for="data in tableDataFingerscan"
                  :key="data.emp_id + data.tag_id"
                  :value="data"
                >
                  <md-table-cell>
                    <span class="upfont">
                      {{ data.tag_id }}
                    </span>
                  </md-table-cell>
                  <md-table-cell>
                    <span class="upfont">
                      {{ data.emp_id }}
                    </span>
                  </md-table-cell>
                  <md-table-cell>
                    <span class="upfont">
                      {{ data.first_nameth }}
                      {{ data.last_nameth }}
                    </span>
                  </md-table-cell>
                  <md-table-cell>
                    <md-button
                      class="md-warning md-just-icon md-round"
                      style="margin: 0.2rem"
                      @click="goto_edit(data)"
                    >
                      <md-icon>edit</md-icon>
                    </md-button>
                    <md-button
                      class="md-rose md-just-icon md-round"
                      style="margin: 0.2rem"
                      @click="showDialogDelete(data.tag_id)"
                    >
                      <md-icon>delete</md-icon>
                    </md-button>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import $ from 'jquery';
import Vuex from 'vuex';
import Swal from 'sweetalert2';
import employeeMixin from '@/mixins/employee-mixin';
import FilterEmployee from '@/components/FilterEmployee';
export default {
  components: {
    'filter-employee': FilterEmployee,
  },
  mixins: [employeeMixin],
  data() {
    return {
      fromdate: null,
      todate: null,
      disabledToDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      permissions: [],
      showProgressBar: false,
      showADD: false,
      showUPDATE: false,
      tableData: [],
      emp_id: null,
      branch_id: null,
      search_emp: '',
      fixed_emp_id: '',
      fixed_fingerscan_id: '',
      tableDataFingerscan: [],
      edit_fingerscan_id: '',
      key_update: '',
      tag_id: '',
      key_edit: '',
    };
  },
  watch: {
    employee_id: function() {
      if (
        (this.employeeID > 0 || this.departments_id > 0) &&
        this.fromdate &&
        this.todate
      ) {
        this.disabled_process = false;
      } else {
        this.disabled_process = true;
      }
    },
    departments_id: function() {
      if (
        (this.employeeID > 0 || this.departments_id > 0) &&
        this.fromdate &&
        this.todate
      ) {
        this.disabled_process = false;
      } else {
        this.disabled_process = true;
      }
    },
    fromdate: function() {
      if (this.todate) {
        var todate = moment(this.todate, 'DD/MM/YYYY');
        if (moment(todate).diff(moment(this.fromdate), 'days') < 0) {
          this.todate = this.fromdate;
        }
      }
      if (
        (this.employeeID > 0 || this.departments_id > 0) &&
        this.fromdate &&
        this.todate
      ) {
        this.disabled_process = false;
      } else {
        this.disabled_process = true;
      }
    },
    todate: function() {
      if (
        (this.employeeID > 0 || this.departments_id > 0) &&
        this.fromdate &&
        this.todate
      ) {
        this.disabled_process = false;
      } else {
        this.disabled_process = true;
      }
    },
  },
  async created() {
    this.setPermission(this.me);
  },
  methods: {
    headdropdown(event) {
      var con = $(event.target).closest('.md-layout-item');
      var content = $('.contentleave', con);
      content.slideToggle();
      content.css('display', 'grid');
    },
    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },
    /**
     * set branch value from child component
     */
    setBranches(v) {
      // this.branches = v;
      this.branch_id = v;
    },

    /**
     * set employee id value from child component
     */
    setDepartment(v) {
      if (v) {
        this.departments_id = v;
      }
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.emp_id = v.id;
      } else {
        this.staffs = [];
        this.emp_id = null;
      }
    },
    async searchTimeInout() {
      if (!this.search_emp) {
        await this.$store.dispatch('alerts/error', 'กรุณาระบุ รหัสสแกนนิ้ว');
        return false;
      } else if (!this.fromdate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!this.todate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด');
        return false;
      }
      let params = {
        filter: {
          ...(this.search_emp ? { search_emp: this.search_emp } : {}),
          ...(this.fromdate
            ? { fromdate: moment(this.fromdate).format('YYYY-MM-DD') }
            : {}),
          ...(this.todate
            ? { todate: moment(this.todate).format('YYYY-MM-DD') }
            : {}),
        },
      };
      this.showProgressBar = true;

      try {
        await this.$store
          .dispatch('stafftimeinout/getcheck_scan', params)
          .then((data) => {
            var nObj = {};
            $.each(data, (i, obj) => {
              nObj[moment(i).format('DD/MM/YYYY')] = obj;
            });
            this.tableData = [];
            if (nObj) {
              this.tableData = nObj;
            } else {
              this.$store.dispatch('alerts/error', 'No data!!!');
            }
            this.showProgressBar = false;
          });
      } catch (e) {
        this.showProgressBar = false;
        this.disabled_export = true;
        await this.$store.dispatch('alerts/error', 'No data!!!');
      }
    },
    async search_fingerscan() {
      if (!this.branch_id) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกสาขา');
        return false;
      }
      let params = {
        ...{ bch_id: this.branch_id ? this.branch_id : {} },
        ...{ dep_id: this.departments_id ? this.departments_id : {} },
        ...{ emp_id: this.emp_id ? this.emp_id : {} },
      };
      let resp = await this.$store.dispatch(
        'stafftimeinout/get_search_fingerscan',
        params,
      );
      this.tableDataFingerscan = [];
      if (resp.length == 0) {
        await this.$store.dispatch('alerts/error', 'ไม่มีข้อมูลในระบบ');
      } else {
        this.tableDataFingerscan = resp;
      }
    },
    async add_fingerscan() {
      if (!this.emp_id) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกพนักงาน');
        return false;
      }
      if (!this.fixed_fingerscan_id) {
        await this.$store.dispatch('alerts/error', 'กรุณากรอกรหัสสแกนนิ้วมือ');
        return false;
      }
      let data = {};
      data['fixed_emp_id'] = this.emp_id;
      data['fixed_fingerscan_id'] = this.fixed_fingerscan_id;
      let resp = await this.$store.dispatch(
        'stafftimeinout/addfingerscan',
        data,
      );
      if (resp == 0) {
        await this.$store.dispatch(
          'alerts/error',
          'มีข้อมูลรหัสสแกนนิ้วนี้ในระบบแล้ว',
        );
        this.showADD = false;
        this.search_fingerscan();
        return false;
      } else if (resp == 1) {
        await this.$store.dispatch('alerts/success', 'บันทึกสำเร็จ.');
        this.fixed_fingerscan_id = '';
        this.showADD = false;
        this.search_fingerscan();
      }
    },
    async check_showADD() {
      if (!this.emp_id) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกพนักงาน');
        return false;
      }
      this.showADD = true;
    },
    async goto_edit(data) {
      this.edit_fingerscan_id = data.tag_id;
      this.key_edit = data.tag_id;
      this.key_update = data.id;
      this.showUPDATE = true;
    },
    async update_fingerscan() {
      if (!this.edit_fingerscan_id) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกรหัสสแกนนิ้ว');
        return false;
      }
      let data_edit = {};
      data_edit['emp_id'] = this.key_update;
      data_edit['key_edit'] = this.key_edit;
      data_edit['tag_id'] = this.edit_fingerscan_id;
      const confirmation = await Swal.fire({
        title: 'ยืนยัน ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Accept!',
        cancelButtonText: 'No, Cancel',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        await this.$store
          .dispatch('stafftimeinout/updatefingerscan', data_edit)
          .then((response) => {
            this.$store.dispatch('alerts/success', 'ตั้งค่าเรียบร้อยแล้ว.');
            this.showUPDATE = false;
            this.search_fingerscan();
          });
      }
    },
    async showDialogDelete(tag_id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            let params = {
              ...{ tag_id: tag_id },
            };
            let resp = await this.$store.dispatch(
              'stafftimeinout/deletefingerscan',
              params,
            );
            if ((resp = 1)) {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'fingerscan has been deleted.',
                'success',
              );
              this.search_fingerscan();
            } else if ((resp = 0)) {
              swalWithBootstrapButtons.fire(
                'Error!',
                'Oops, something went wrong!',
                'error',
              );
            }
          } else if (result.dismiss) {
            swalWithBootstrapButtons.fire(
              'Cancelled',
              'Your imaginary SanthiyaSOS is safe :)',
              'error',
            );
          }
        });
    },
  },
};
</script>
<style></style>
