import service from '@/store/services/staffsso-service';

const state = {
  list: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },

  edit({ commit, dispatch }, params) {
    return service.edit(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },
};

const getters = {
  list: (state) => state.list,
};

const staffsso = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default staffsso;
