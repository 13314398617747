<template>
  <div>
    <!--ไดอะลอก Property-->
    <md-dialog
      style="min-height: 40%; min-width: 40%"
      :md-active.sync="showProperty"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.6rem" align="center">
        Set Property
      </md-dialog-title>
      <md-content
        align="left"
        class="scrol md-scrollbar md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
      >
        <div v-for="(prop, index) in showSetProperty" :key="index">
          <md-checkbox
            class="md-primary"
            v-model="setNewProp"
            :value="prop.value"
          >
            <!-- {{ prop.text.br_nameen }} -->
            {{ prop.text.br_nameth }}
          </md-checkbox>
        </div>
      </md-content>
      <md-dialog-actions>
        <md-button class="md-success md-sm" @click="setProp()">
          OK
        </md-button>
        <md-button class="md-rose md-sm" @click="showProperty = false">
          Close
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <!--ปิดไดอะลอก Property-->

    <!--ไดอะลอก Department-->
    <md-dialog
      class="md-layout-item md-size-40 md-medium-size-60 md-small-size-80 md-xsmall-size-80"
      style="height: 70%; width: 40%"
      :md-active.sync="showDepartment"
    >
      <md-dialog-title style="font-size: 1.6rem" align="center">
        Set Department
      </md-dialog-title>
      <md-content
        align="left"
        class="scrol md-scrollbar md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
      >
        <div
          class="md-layout-item md-size-80 md-medium-size-95 md-small-size-90 md-xsmall-size-90"
          style="text-align: left"
        >
          <md-field>
            <label>Search Department</label>
            <md-input v-model="search_dep" class="md-size-100 searchDep">
            </md-input>
            <md-icon>search</md-icon>
          </md-field>
        </div>
        <div v-for="(department, index) in showSetDepartment" :key="index">
          <md-checkbox
            class="md-primary"
            v-model="setNewDep"
            :value="department.value"
          >
            {{ department.text.dep_nameen }}
            {{ department.text.dep_nameth }}
          </md-checkbox>
        </div>
      </md-content>
      <md-dialog-actions>
        <md-button class="md-success md-sm" @click="setDep()">
          OK
        </md-button>
        <md-button class="md-rose md-sm" @click="showDepartment = false">
          Close
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <!--ปิดไดอะลอก Department-->

    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <md-button @click="goBack" class="md-primary md-dense">
            Back to List
          </md-button>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout spacing md-alignment-left memoName">
          <div
            class="md-layout-item md-size-10 md-medium-size-20 md-small-size-25 md-xsmall-size-50"
            style="text-align: left"
          >
            <label style="margin-top: 1.3rem; text-align: left">
              Memo Name:
            </label>
          </div>
          <div
            class="md-layout-item md-size-90 md-medium-size-80 md-small-size-75 md-xsmall-size-100"
            style="text-align: left"
          >
            <md-field>
              <md-input class="md-size-50" v-model="memo_name"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout spacing md-alignment-left detail">
          <div
            class="md-layout-item md-size-10 md-medium-size-20 md-small-size-25 md-xsmall-size-50"
            style="text-align: left"
          >
            <label style="margin-top: 1.3rem; text-align: left">
              Detail:
            </label>
          </div>
          <div
            class="md-layout-item md-size-90 md-medium-size-80 md-small-size-75 md-xsmall-size-100"
          >
            <md-field>
              <md-textarea v-model="memo_detail"></md-textarea>
            </md-field>
          </div>
        </div>

        <div class="md-layout spacing md-alignment-left prop">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <md-button
              class="md-rose md-round md-sm bt-size"
              @click="setProperty()"
            >
              <template v-if="sos_data.property.length > 0">
                Change Property
              </template>
              <template v-else>Set Property</template>
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
            v-if="showSetProp"
          >
            <div v-for="prop in showSetProp" :key="prop.id + prop.br_nameth">
              {{ prop.br_nameth }}
            </div>
          </div>
        </div>

        <div class="md-layout spacing md-alignment-left dep">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <md-button
              class="md-warning md-round md-sm bt-size"
              @click="setDepartment()"
            >
              <template v-if="sos_data.department.length > 0">
                Change Department
              </template>
              <template v-else>Set Department</template>
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
          >
            <div v-for="dep in showSetDep" :key="dep.id + dep.dep_nameth">
              {{ dep.dep_nameth }}
            </div>
          </div>
        </div>

        <div class="md-layout spacing md-alignment-left images">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <md-button class="md-info md-round md-fileinput md-sm bt-size">
              <md-icon>add_photo_alternate</md-icon>
              <template v-if="!image">Set Image</template>
              <template v-else>Change Image</template>
              <input type="file" accept="image/*" @change="onFileChange" />
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
          >
            <div v-if="image">
              <div class="image-container">
                <img :src="image" />
              </div>
              <md-button
                class="md-danger md-sm md-round"
                @click="removeImage(image)"
                v-if="image"
              >
                <i class="fa fa-times" />
                Remove Image
              </md-button>
            </div>
          </div>
        </div>

        <div class="md-layout spacing md-alignment-left PDF">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
          >
            <md-button
              class="md-primary md-round md-fileinput md-sm bt-size Add_PDF"
            >
              <template v-if="pdf == null">ADD PDF</template>
              <template v-else>Change PDF</template>
              <input type="file" accept=".pdf" @change="onFilePDFChange" />
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-25 md-medium-size-50 md-small-size-50 md-xsmall-size-50"
          >
            <md-button
              class="md-info md-round md-sm show_PDF"
              v-if="pdf != null"
              v-model="PDFname"
              @click="show_img_full(pdf)"
            >
              <i class="fa fa-file-pdf"></i>
              View PDF
            </md-button>
            &nbsp;
            <md-button
              class="md-danger md-round md-sm"
              @click="removePDF()"
              v-if="pdf != null"
            >
              <i class="fa fa-times" />
              Remove PDF
            </md-button>
          </div>
        </div>

        <div class="md-layout spacing md-alignment-left Other_file">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <md-button
              class="md-dark md-round md-fileinput bt-size Add_file"
              style="display: none"
            >
              <template v-if="!files">ADD File</template>
              <template v-else>Change File</template>
              <input
                type="file"
                accept=".xlsx,.csv,.docx,.pptx"
                @change="onFilefileChange"
              />
            </md-button>
          </div>

          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
          >
            <!-- <md-input class="md-size-50 setProperty" v-model="filename">
              {{ filename }}
            </md-input> -->
            <b-button
              class="show_file"
              v-if="files"
              variant="outline-warning"
              :download="files[1]"
              target="_blank"
              :href="files[0]"
              @click="file_download_newAdd(files, filename)"
            >
              <md-icon>attach_file</md-icon>
              Attachment: {{ filename }}
            </b-button>
            <md-button
              class="md-just-icon md-round md-sm"
              @click="removefile()"
              v-if="files"
            >
              <i class="fa fa-times" />
            </md-button>
          </div>
        </div>

        <div class="md-layout spacing md-alignment-left addtag">
          <b-form-tags
            v-model="add_tag"
            no-outer-focus
            class="mb-2 md-layout-item md-size-60 md-medium-size-70 md-small-size-90 md-xsmall-size-100"
          >
            <template v-slot="{ tags, disabled, removeTag, inputAttrs }">
              <ul
                v-if="tags.length > 0"
                class="list-inline d-inline-block mb-2"
              >
                <li
                  v-for="(tag, index) in add_tag"
                  :key="index + ' addtag'"
                  class="scrol md-scrollbar list-inline-item"
                >
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag.tag_sos_name"
                    :disabled="disabled"
                    variant="info"
                    v-if="typeof tag == 'object'"
                  >
                    {{ tag.tag_sos_name }}
                  </b-form-tag>
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag.tag_sos_name"
                    :disabled="disabled"
                    variant="info"
                    v-else-if="typeof tag == 'string'"
                  >
                    {{ JSON.parse(tag).tag_sos_name }}
                  </b-form-tag>
                </li>
              </ul>
              <b-input-group class="mb-2">
                <b-form-input
                  v-model="search"
                  v-bind="inputAttrs"
                  @keyup.enter="onOptionClick({ id: 0, tag_sos_name: search })"
                  id="tag-search-input"
                  type="search"
                  autocomplete="off"
                  placeholder="New tag - Press enter to add"
                  class="form-control"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    @click="onOptionClick({ id: 0, tag_sos_name: search })"
                    variant="primary"
                  >
                    Add
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-dropdown
                size="sm"
                variant="outline-secondary"
                block
                menu-class="w-100"
                id="dropdown_tag"
              >
                <template #button-content>
                  <b-icon icon="tag-fill"></b-icon> Choose tags
                </template>
                <b-dropdown-form @submit.stop.prevent="() => {}">
                  <b-form-group
                    label="Search tags"
                    label-for="tag-search-input"
                    label-cols-md="auto"
                    class="mb-0"
                    label-size="sm"
                    :description="searchDesc"
                    :disabled="disabled"
                  >
                    <b-form-input
                      v-model="search_tag"
                      id="tag-search-input"
                      type="search"
                      size="sm"
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                </b-dropdown-form>
                <b-dropdown-divider></b-dropdown-divider>
                <div
                  style="min-height: 300px; max-height: 500px; overflow-x: auto"
                >
                  <b-dropdown-item-button
                    v-for="option in availableOptions"
                    :key="option.id + ' option'"
                    :value="option"
                    @click="onOptionClick(option)"
                  >
                    {{ option.tag_sos_name }}
                  </b-dropdown-item-button>
                </div>
                <b-dropdown-text v-if="availableOptions.length === 0">
                  There are no tags available to select
                </b-dropdown-text>
              </b-dropdown>
            </template>
          </b-form-tags>
        </div>
        <hr />
        <md-button class="md-success md-dense" @click="update_sos()">
          Save
        </md-button>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import $ from 'jquery';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  components: {},
  data() {
    return {
      sos_id: '',
      image: '',
      tranfer_Attachment64: '',
      tranfer_Attachment_add64: '',
      memo_name: '',
      memo_detail: '',
      sos_data: this.$route.params.data,

      setNewProp: [],
      showSetProp: [],
      showProperty: false,
      showSetProperty: [],

      showDepartment: false,
      setNewDep: [],
      showSetDep: [],
      search_dep: '',
      Department: [],
      data_dep: [],

      arr: [],
      porp: '',
      pdf: null,
      PDFname: null,
      file: null,
      files: '',
      filename: '',
      add_tag: [],
      search: '',
      search_tag: '',
      options: [],
    };
  },
  watch: {},
  computed: {
    criteria() {
      return this.search_tag.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.options.filter(
        (opt) => this.add_tag.indexOf(opt) === -1,
      );
      if (criteria) {
        // Show only options that match criteria
        return options.filter((opt) => {
          if (opt.tag_sos_name.toLowerCase().indexOf(criteria) > -1) {
            return opt;
          }
        });
      }
      // Show all options available

      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria';
      }
      return '';
    },
    key_search_tag() {
      // Compute the search criteria
      return this.search_dep.trim().toLowerCase();
    },
    showSetDepartment() {
      const search_dep = this.key_search_tag;
      let data_dep = [];
      if (search_dep) {
        $(this.Department).each((i, o) => {
          data_dep[i] = o.value;
        });
        return this.Department.filter(
          (ssd) =>
            ssd.text.dep_nameth.toLowerCase().indexOf(search_dep) > -1 ||
            ssd.text.dep_nameen.toLowerCase().indexOf(search_dep) > -1,
        );
      }
      return this.Department;
    },
    searchDepart() {
      if (this.search_dep && this.showSetDepartment.length === 0) {
        return 'There are no tags matching your search criteria';
      }
      return '';
    },
  },

  async created() {
    this.getlistbranch();
    this.getlistdep();
    this.data_set();
    await this.get_tag();
  },
  methods: {
    goBack() {
      let keySearch = this.$route.params.keySearch;
      let currentPage = this.$route.params.currentPage;
      this.$router.push({
        path: 'santhiayaSOS-management',
        name: 'จัดการสัญธิญา SOS',
        params: {
          keySearch,
          currentPage,
        },
      });
    },
    async data_set() {
      let sos_data = { ...this.sos_data };

      this.sos_id = sos_data.sos_id;
      this.memo_name = sos_data.sos_name;
      this.memo_detail = sos_data.sos_detail;
      this.add_tag = sos_data.tag_sos;
      this.showSetProp = sos_data.property;

      this.showSetProp = sos_data.property.map((prop) => {
        return {
          id: prop.property_id.toString(),
          br_nameth: prop.br_nameth,
          br_nameen: prop.br_nameen,
        };
      });
      this.showSetDep = sos_data.department.map((dep) => {
        return {
          id: dep.department_id.toString(),
          dep_nameth: dep.dep_nameth,
          dep_nameen: dep.dep_nameen,
        };
      });
      loading_start();
      let files = await this.getfile(sos_data.sos_id);
      loading_close();

      files.forEach((file) => {
        this.image = file.image_sos || null;
        this.pdf = file.pdf_file_sos || null;
        this.files = file.file_other_sos || null;
      });
    },
    async getfile(sos_id) {
      let params = {
        sos_id: sos_id,
      };
      let resp = await this.$store.dispatch('santhiyaSOS/getFileSOS', params);
      return resp;
    },

    async getlistbranch() {
      let resp = await this.$store.dispatch('branches/list');
      let branchs = [];
      $(resp).each((i, o) => {
        branchs.push({
          text: {
            id: o.id.toString(),
            br_nameth: o.br_nameth,
            br_nameen: o.br_nameen,
          },
          value: JSON.stringify({
            id: o.id.toString(),
            br_nameth: o.br_nameth,
            br_nameen: o.br_nameen,
          }),
        });
      });
      this.showSetProperty = branchs;
    },

    async setProperty() {
      this.setNewProp = [
        ...this.showSetProp.map((prop) => {
          return JSON.stringify(prop);
        }),
      ];
      this.showProperty = true;
    },

    async setProp() {
      this.showSetProp = [
        ...this.setNewProp.map((dep) => {
          return JSON.parse(dep);
        }),
      ];
      this.showProperty = false;
      this.disabled_prop = false;
    },

    async getlistdep() {
      let resp = await this.$store.dispatch('staffs/getdepartment');
      let dep = [];
      $(resp).each((i, o) => {
        dep.push({
          text: {
            id: o.id.toString(),
            dep_nameth: o.dep_nameth,
            dep_nameen: o.dep_nameen,
          },
          value: JSON.stringify({
            id: o.id.toString(),
            dep_nameth: o.dep_nameth,
            dep_nameen: o.dep_nameen,
          }),
        });
      });
      this.Department = dep;
    },
    async setDepartment() {
      this.showDepartment = true;
      this.setNewDep = [
        ...this.showSetDep.map((dep) => {
          return JSON.stringify(dep);
        }),
      ];
    },
    async setDep() {
      this.showDepartment = false;
      this.disabled_dep = false;
      this.showSetDep = [
        ...this.setNewDep.map((dep) => {
          return JSON.parse(dep);
        }),
      ];
    },

    async onFileChange(e) {
      let files = '';
      $(e.target.files).each(async function(i, o) {
        if (
          o.type == 'image/jpeg' ||
          o.type == 'image/png' ||
          o.type == 'image/gif'
        ) {
          files = e.target.files || e.dataTransfer.files;
        }
      });
      if (files.length == 1) {
        if (!files.length) return;
        this.createImage(files[0]);
      } else {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกไฟล์รูปภาพ');
        return false;
      }
    },
    async onFilePDFChange(e) {
      let files = '';
      let filePDFname = '';
      $(e.target.files).each(async function(i, o) {
        if (o.type == 'application/pdf') {
          files = e.target.files || e.dataTransfer.files;
          filePDFname = o.name;
        }
      });
      if (files.length == 1) {
        this.createPDF(files[0]);
        this.PDFname = filePDFname;
        this.data_filePDF = files;
      } else {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกไฟล์ PDF');
        return false;
      }
      $('.show_PDF').css('display', '');
    },
    async onFilefileChange(e) {
      let files = '';
      let filefilename = '';
      $(e.target.files).each(async function(i, o) {
        if (o.type != 'application/pdf') {
          if (o.type != 'image/jpeg') {
            if (o.type != 'image/png') {
              if (o.type != 'image/gif') {
                files = e.target.files || e.dataTransfer.files;
                filefilename = o.name;
              }
            }
          }
        }
      });
      if (files.length == 1) {
        this.createfile(files[0]);
        this.filename = filefilename;
      } else {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกไฟล์');
        return false;
      }
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },
    createPDF(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.pdf = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },
    createfile(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.files = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },

    removeImage() {
      this.image = null;
    },
    removePDF() {
      $('.show_PDF').css('display', 'none');
      $('.Add_PDF').css('display', '');
      this.PDFname = null;
      this.pdf = null;
    },
    removefile() {
      $('.show_file').css('display', 'none');
      $('.Add_file').css('display', '');
      this.filename = null;
      this.files = null;
    },

    async file_download_newAdd(files, filename) {
      this.tranfer_Attachment64 = [files, filename, ''];
    },
    async show_img_full(file) {
      if (file == '') {
        return;
      }
      var win = window.open();
      if (file.toLowerCase().indexOf('data:application/pdf;') >= 0) {
        win.document.write(
          '<iframe src="' +
            file +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width: 100%; height:100%;" allowfullscreen></iframe>',
        );
      } else {
        win.document.write(
          '<img style="width: 100%; height: auto;" src="' + file + '"></img>',
        );
      }
    },
    onOptionClick(option) {
      this.add_tag.push({
        tag_id: option.id,
        tag_sos_name: option.tag_sos_name,
      });
      this.search_tag = '';
      this.search = '';
    },
    async get_tag() {
      let obj = '1';
      let resp = await this.$store.dispatch('santhiyaSOS/gettagsos', obj);
      this.options = resp;
    },
    async update_sos() {
      let data = {};
      let id_prop = [];
      let id_dep = [];
      var tags = [];

      $(this.showSetProp).each(function(i, o) {
        id_prop.push(o.property_id || o.id);
      });

      $(this.showSetDep).each(function(i, o) {
        id_dep.push(o.department_id || o.id);
      });

      $(this.add_tag).each(function(index, v) {
        let tag = JSON.parse(v);
        tag.tag_id = tag.tag_id || tag.id;
        delete tag.id;
        tags.push(tag);
      });

      if (this.memo_name == '') {
        await this.$store.dispatch('alerts/error', 'กรุณาระบุชื่อ Memo');
        return false;
      }

      if (this.memo_detail == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุรายละเอียดของ Memo',
        );
        return false;
      }

      if (id_prop.length <= 0) {
        await this.$store.dispatch('alerts/error', 'กรุณา Set Property');
        return false;
      }
      if (id_dep.length <= 0) {
        await this.$store.dispatch('alerts/error', 'กรุณา Set Department');
        return false;
      }

      if (tags.length <= 0) {
        await this.$store.dispatch('alerts/error', 'กรุณาระบุ Tag search');
        return false;
      }

      data['sos_id'] = this.sos_id;
      data['memo_name'] = this.memo_name;
      data['memo_detail'] = this.memo_detail;
      data['memo_property'] = id_prop;
      data['memo_dep'] = id_dep;
      data['memo_image'] = this.image;
      data['memo_PDF'] = this.pdf;
      data['memo_file_other'] = this.files;
      data['memo_tag_data'] = tags;
      // console.log(data);
      let resp = await this.$store.dispatch('santhiyaSOS/updateSos', data);
      if (resp.status == 0) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        return;
      } else if (resp.status == 1) {
        await this.$store.dispatch('alerts/success', 'แก้ไข Memmo สำเร็จ.');
        this.goBack();
      }
    },
  },
};
</script>
<style scoped>
p#memo_detail\ hard_break {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.show_porp {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.show_PDF {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}
.show_file {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}
.md-checkbox {
  margin: 5px 5px 5px 5px;
}
</style>
