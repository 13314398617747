import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  let options = {
    params: params,
    paramsSerializer: function() {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios.get(`${url}/v1/taxincomes`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

export default {
  list,
};
