<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title"></h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div
              class="md-layout-item md-size-40 mt-2 md-small-size-100 md-alignment-left"
            >
              <!-- <md-switch v-model="boolean">{{status}}</md-switch> -->
            </div>
            <div
              class="md-layout-item md-size-60 mt-4 md-small-size-100 text-right"
            >
              <md-button @click="goToAdd" class="md-primary md-dense">
                เพิ่ม ตำแหน่ง
              </md-button>
            </div>
          </div>
          <div class="md-layout spacing">
            <div class="md-layout-item md-size-40 mt-2 md-small-size-40">
              <md-field>
                <label for="department">แผนก</label>
                <md-select
                  v-model="departments.id"
                  name="department"
                  @md-selected="departmentSelect"
                >
                  <md-option
                    v-for="item in available_departments"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <md-table
            :value="tableData"
            class="paginated-table table-striped table-hover"
            table-header-color="green"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name TH">{{
                item.pos_nameth
              }}</md-table-cell>
              <md-table-cell md-label="Name EN">{{
                item.pos_nameen
              }}</md-table-cell>
              <md-table-cell md-label="Description">{{
                item.pos_desc
              }}</md-table-cell>
              <md-table-cell md-label="Approver" v-if="item.is_approve == 0"
                >No</md-table-cell
              >
              <md-table-cell md-label="Approver" v-if="item.is_approve == 1"
                >Yes</md-table-cell
              >
              <md-table-cell md-label="Actions">
                <md-button
                  class="md-icon-button md-raised md-round md-info"
                  @click="goToEdit(item.id)"
                  style="margin: 0.2rem"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  v-if="item.status == 'active'"
                  class="md-icon-button md-raised md-round md-danger"
                  @click="destroy(item.id)"
                  style="margin: 0.2rem"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import _ from 'lodash';

export default {
  data() {
    return {
      available_departments: [],
      tableData: [],
      departments: {
        id: 0,
        data: [],
      },
      query: null,
      position: {
        type: 'positions',
        id: null,
        pos_code: null,
        pos_nameth: null,
        pos_nameen: null,
        pos_desc: null,
        dep_id: null,
        is_approve: null,
      },
      sortation: {
        field: 'created_at',
        order: 'asc',
      },
    };
  },

  created() {
    let branchs = JSON.parse(localStorage.getItem('branchs'));
    this.getDepartments(branchs.company_id);
  },

  watch: {
    query: {
      handler: 'getListDebounced',
      immediate: true,
    },
    departments: {
      handler: 'getList',
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function() {
      this.getList();
    }, 300),

    async getList() {
      let branchs = JSON.parse(localStorage.getItem('branchs'));
      let params = {
        filter: {
          ...(this.departments.id ? { dep_id: this.departments.id } : {}),
        },
      };
      this.$store.watch(
        () => this.$store.getters['positions/list'],
        (position) => {
          this.tableData = position;
        },
      );

      await this.$store.dispatch('positions/list', params);
    },

    async getDepartments(company_id) {
      let params = {
        filter: {
          ...{ company_id: company_id },
        },
      };
      await this.$store.dispatch('departments/list', params).then(() => {
        this.available_departments = this.$store.getters[
          'departments/dropdown'
        ];
      });
    },

    departmentSelect() {
      this.getList();
    },

    goToAdd() {
      this.$router.push({
        path: 'add-position',
        name: 'เพิ่ม ตำแหน่ง',
      });
    },

    goToEdit(id) {
      this.$router.push({
        path: 'edit-position',
        name: 'แก้ไข ตำแหน่ง',
        params: {
          id,
        },
      });
    },
    customSort() {
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.md-switch {
  display: flex;
}
</style>
