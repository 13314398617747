<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="user.branch_id"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>เลือก สำนักงาน</label>
              <md-select
                v-model="user.branchs.id"
                name="branch_id"
                @md-selected="branchSelect"
              >
                <md-option
                  v-for="item in available_branches"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>

        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="user.emp_id"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>รหัสพนักงาน</label>
              <md-input v-model="user.emp_id" />
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
      </div>
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="department"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label for="department">แผนก</label>
              <md-select
                v-model="user.departments.id"
                name="department"
                :disabled="disabled_department"
                @md-selected="departmentSelect"
              >
                <md-option
                  v-for="item in available_departments"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="position"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label for="position">ตำแหน่ง</label>
              <md-select
                v-model="user.positions.id"
                name="position"
                :disabled="disabled_position"
              >
                <md-option
                  v-for="item in available_positions"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
      </div>
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt2 md-small-size-100">
          <ValidationProvider
            name="start_date"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-datepicker
              v-model="user.start_date"
              md-immediately
              :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
            >
              <label>วันที่เริ่มงาน</label>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-datepicker>
          </ValidationProvider>
        </div>
        <div class="md-layout-item md-size-40 mt2 md-small-size-100">
          <ValidationProvider name="finish_date" v-slot="{ passed, failed }">
            <md-datepicker
              v-model="user.finish_date"
              md-immediately
              :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
            >
              <label>วันที่สิ้นสุดงาน</label>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-datepicker>
          </ValidationProvider>
        </div>
      </div>
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt2 md-small-size-100">
          <ValidationProvider name="bank_number" v-slot="{ passed, failed }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>เลขที่บัญชี</label>
              <md-input v-model="user.bank_number"> </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
        <div class="md-layout-item md-size-40 mt2 md-small-size-100">
          <ValidationProvider name="bank_name" v-slot="{ passed, failed }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>ธนาคาร</label>
              <md-input v-model="user.bank_name"> </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
      </div>
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="salary_amt"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>เงินเดือน</label>
              <md-input
                v-model="user.salarys.salary_amt"
                :disabled="disabled_salary"
              />
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <!-- <label>จัดการเงินเดือน</label> -->
          <!-- <md-switch v-model="permission_salary"  @change="active = true"></md-switch> -->
          <md-button
            class="md-primary md-round md-theme-default"
            @click="active = true"
            :disabled="disabled_pvcode"
          >
            จัดการเงินเดือน
          </md-button>
        </div>
        <div>
          <md-dialog-prompt
            :md-active.sync="active"
            v-model="privatecodeIn"
            md-title="Private code"
            md-input-maxlength="30"
            md-input-placeholder="Type your private code..."
            md-confirm-text="Send"
          />
        </div>
      </div>
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="stafftype"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label for="stafftype">ประเภทพนักงาน</label>
              <md-select v-model="user.stafftype">
                <md-option value="month">รายเดือน</md-option>
                <md-option value="day">รายวัน</md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
        <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
          <label>สิทธิ์การลา</label>
          <md-switch v-model="user.canleave" @change="canleavechange">
            {{ user.canleave_msg }}
          </md-switch>
        </div>
        <div class="md-layout-item md-size-25 mt-2 md-small-size-100">
          <label>สถานะพนักงาน</label>
          <md-switch v-model="user.status" @change="statuschange">
            {{ user.staff_st_msg }}
          </md-switch>
        </div>
      </div>
      <div class="md-layout spacing">
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="workingtime"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>เลือก กะทำงาน</label>
              <md-select
                v-model="user.workingtimes.id"
                :disabled="disabled_workingtime"
              >
                <md-option
                  v-for="item in available_workingtimes"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
        <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
          <ValidationProvider
            name="user.weekend_id"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>เลือก วันหยุดประจำสัปดาห์</label>
              <md-select v-model="user.weekends.id">
                <md-option
                  v-for="item in available_weekends"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import _ from 'lodash';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import md5 from 'js-md5';
import Vuex from 'vuex';
import employeeMixin from '@/mixins/employee-mixin';

extend('required', required);

export default {
  mixins: [employeeMixin],
  data() {
    return {
      //user: Object,
      //available_branches: [],
      available_departments: [],
      available_positions: [],
      available_workingtimes: [],
      available_weekends: [],
      available_bank_name: [
        'เงินสด',
        'SCB',
        'KBANK',
        'KTB',
        'TTB',
        'BBL',
        'BAY',
        'KKP',
        'CIMBT',
        'TISCO',
        'UOBT',
        'GSB',
        'GHB',
        'ISBT',
      ],
      // branches: '',
      // department: '',
      //position: '',
      disabled_department: true,
      disabled_position: true,
      disabled_workingtime: true,
      disabled_weekend: true,
      disabled_salary: true,
      disabled_pvcode: false,
      //start_date: null,
      //finish_date: null,
      //salary_amt: null,
      //company_id: null,
      active: false,
      privatecodeIn: null,
      // query: null,
    };
  },
  async created() {
    if (localStorage.getItem('privatecode')) {
      this.disabled_salary = false;
      this.disabled_pvcode = true;
    }
    this.canleavechange();
    this.statuschange();
    await this.setDepartment();
    await this.setWorking();
    await this.getWeekend(this.company_id);
    await this.getPositions();
    // this.getDepartments(this.company_id);
    // this.getWorkingtime(this.company_id);

    // let employee = JSON.parse(localStorage.getItem("employees"));
    // this.checkPrivateCode(employee.privatecode);
  },

  props: {
    user: Object,
    branchs: Array,
  },

  computed: {
    ...Vuex.mapState({
      departData: (state) => state.departments.list,
      workingData: (state) => state.workingtimes.list,
      branchData: (state) => state.branches.list,
    }),

    available_branches() {
      if (this.branchData && this.branchData.length) {
        return this.$store.getters['branches/dropdown'];
      } else {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        let branchList = this.getBranchData().then(() => {
          return this.$store.getters['branches/dropdown'];
        });
        return branchList;
      }
    },

    company_id() {
      // let branch = this.available_branches.find((branch) => {
      //     return branch.id == this.user.branchs.id
      // })

      // if(branch){
      //   return branch.company_id;
      // }else{
      //   return 1
      // }
      let employee = JSON.parse(localStorage.getItem('employees'));
      return employee.branchs.company_id;
    },
  },

  watch: {
    // query: {
    //   handler: 'getListDebounced',
    //   immediate: true,
    // },

    privatecodeIn: function() {
      this.securePrivatecode(this.privatecodeIn);
    },
    user: function() {
      this.securePrivatecode(localStorage.getItem('privatecode'));
    },
  },

  beforeDestroy() {
    // this.available_branches.destroy;
    // this.available_departments.destroy;
    // this.available_positions.destroy;
    // this.available_workingtimes.destroy;
    // this.available_weekends.destroy;
  },
  methods: {
    // getListDebounced: _.debounce(function() {}, 300),

    validate() {
      return this.$refs.form.validate().then((res) => {
        if (!res) {
          return;
        }
        this.$emit('on-validated', res);
        return res;
      });
    },

    // async getBranches() {
    //   await this.$store.dispatch('branches/list').then(() => {
    //     this.available_branches = this.$store.getters['branches/dropdown'];
    //     if (this.available_branches && this.available_branches.length) {
    //       //this.user.branchs.id = this.available_branches[0].id;
    //       const result = this.available_branches.find((branch) => {
    //         return branch.id == this.user.branchs.id;
    //       });
    //       this.company_id = result.company_id;
    //       this.getDepartments(this.company_id);
    //       this.getWorkingtime(this.company_id);
    //       this.getWeekend(this.company_id);
    //     }
    //   });
    // },

    branchSelect() {
      //this.available_departments = [];
      //this.disabled_department = true;
      if (this.user.branchs.id) {
        this.user.branch_id = this.user.branchs.id;
        // const result = this.available_branches.find((branch) => {
        //   return branch.id == this.user.branchs.id
        // })
        //this.getDepartments(this.company_id.company_id);
        //this.getWorkingtime(this.company_id);
        //this.getWeekend(this.company_id);
      }
    },

    // async getDepartments(company_id){
    //   let params = {
    //     filter: {
    //       ...{company_id: company_id}
    //     },

    //   }
    //   await this.$store.dispatch("departments/list",params).then(() => {
    //     this.available_departments = this.$store.getters["departments/dropdown"];

    //     if (this.available_departments && this.available_departments.length) {
    //       this.disabled_department = false;
    //     }
    //   });
    // },

    setDepartment() {
      if (this.departData && this.departData.length) {
        this.available_departments = this.$store.getters[
          'departments/dropdown'
        ];

        if (this.available_departments && this.available_departments.length) {
          this.disabled_department = false;
        }
      } else {
        this.getDepartmentData(this.company_id).then(() => {
          this.available_departments = this.$store.getters[
            'departments/dropdown'
          ];
          if (this.available_departments && this.available_departments.length) {
            this.disabled_department = false;
          }
        });
      }
    },

    departmentSelect() {
      this.position = [];
      this.available_positions = [];

      this.disabled_position = true;
      if (this.user.departments.id) {
        this.getPositions();
      }
    },

    async getPositions() {
      let params = {
        filter: {
          ...{ dep_id: this.user.departments.id },
        },
      };
      await this.$store.dispatch('positions/list', params).then(async () => {
        this.available_positions = await this.$store.getters[
          'positions/dropdown'
        ];
        if (this.available_positions && this.available_positions.length) {
          this.disabled_position = false;

          var check = -1;
          for (let i = 0; i < this.available_positions.length; i++) {
            if (this.available_positions[i].id == this.user.positions.id) {
              check = i;
              break;
            }
          }

          if (this.user.positions.id == null || check < 0) {
            this.user.positions.id = this.available_positions[0].id;
          }
        }
      });
    },
    setWorking() {
      if (this.departData && this.departData.length) {
        this.available_workingtimes = this.$store.getters[
          'workingtimes/dropdown'
        ];
        if (this.available_workingtimes.length > 0) {
          this.disabled_workingtime = false;
        }
      } else {
        this.getWorkingData(this.company_id).then(() => {
          this.available_workingtimes = this.$store.getters[
            'workingtimes/dropdown'
          ];
          if (this.available_workingtimes.length > 0) {
            this.disabled_workingtime = false;
          }
        });
      }
    },
    // async getWorkingtime(company_id){
    //   let params = {
    //     filter: {
    //       ...{status: "active"},
    //       ...{company_id: company_id}
    //     },

    //   }
    //   await this.$store.dispatch("workingtimes/list",params).then(() => {
    //     this.available_workingtimes = this.$store.getters["workingtimes/dropdown"];
    //     if(this.available_workingtimes.length > 0 ){
    //       this.disabled_workingtime = false;
    //     }
    //   });
    // },

    async getWeekend(company_id) {
      let params = {
        filter: {
          ...{ company_id: company_id },
        },
      };
      await this.$store.dispatch('weekends/list', params).then(() => {
        this.available_weekends = this.$store.getters['weekends/dropdown'];
        if (this.available_weekends.length > 0) {
          this.disabled_weekend = false;
        }
      });
    },

    statuschange() {
      this.user.staff_st_msg = this.user.status ? 'ทำงาน' : 'ลาออก';
    },
    canleavechange() {
      this.user.canleave_msg = this.user.canleave ? 'เปิดสิทธิ์' : 'ปิดสิทธิ์';
    },

    /**
     * function for check private code
     * for display salary
     */
    securePrivatecode(private_code) {
      let employee = JSON.parse(localStorage.getItem('employees'));
      if (private_code && employee.privatecode) {
        if (employee.privatecode === md5(private_code)) {
          localStorage.setItem('privatecode', private_code);
          this.disabled_salary = false;
          this.disabled_pvcode = true;
          this.user.privatecode = private_code;
          this.getSalaryDecrypt();
          return true;
        }
      }
      return false;
    },

    /**
     * get salary for display
     */
    async getSalaryDecrypt() {
      if (this.user.id) {
        let params = {
          filter: {
            ...{ emp_id: this.user.id },
            ...{ private_code: localStorage.getItem('privatecode') },
          },
        };
        await this.$store.dispatch('salarys/salaryDecrypt', params).then(() => {
          this.user.salarys.salary_amt = this.$store.getters[
            'salarys/salaryamt'
          ];
        });
      }
    },
  },
};
</script>
<style lang="scss">
.spacing {
  padding-bottom: 1rem;
}
</style>
