<template>
  <sidebar-item opened :link="{ name: 'Notification', icon: 'notifications' }">
    <!-- <sidebar-item
      :link="{ name: 'อนุมัติวันลา', path: '/staff/leaveapprove' }"
    />
    <sidebar-item
      :link="{ name: 'อนุมัติทำงานล่วงเวลา (OT)', path: '/staff/otapprove' }"
    />
    <sidebar-item
      :link="{
        name: 'อนุมัติทำงานวันหยุด',
        path: '/staff/keepdayapprove',
      }"
    />
    <sidebar-item
      :link="{
        name: 'อนุมัติกะการทำงาน',
        path: '/staff/workingapprovepage',

      }"
    /> -->
    <sidebar-item
      v-if="
        permissions.includes('view report') ||
          (emp_id != 0 && approverOTOverLimit.includes(emp_id))
      "
      :link="{
        name: 'อนุมัติ OT เกิน 3 ชั่วโมง',
        path: '/staff/appvotover',
      }"
    />
    <sidebar-item
      :link="{
        name: 'การอนุมัติรายการทั้งหมด' + showtotal,
        path: '/staff/approveall',
      }"
    />
    <sidebar-item
      :link="{
        name: 'ประวัติการอนุมัติ',
        path: '/staff/historyapprove',
      }"
    />
  </sidebar-item>
</template>
<script>
export default {
  data() {
    return {
      showtotal: '',
    };
  },
  props: {
    totalnotify: {
      type: Number,
      default: 0,
    },
    permissions: {
      type: Array,
      default: () => {},
    },
    approverOTOverLimit: {
      type: Array,
      default: () => {},
    },
    emp_id: {
      type: Number,
      default: 0,
    },
    // workingtotal: {
    //   type: Number,
    //   default: 0,
    // },
    // keepdaytotal: {
    //   type: Number,
    //   default: 0,
    // },
    // ottotal: {
    //   type: Number,
    //   default: 0,
    // },
    // leavetotal: {
    //   type: Number,
    //   default: 0,
    // },
  },
  watch: {
    totalnotify(v) {
      if (v > 0) {
        this.showtotal = ' (' + v + ')';
      } else {
        this.showtotal = '';
      }
    },
  },
  methods: {},
};
</script>
