var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-card',[_c('div',{staticClass:"header",on:{"click":function($event){return _vm.headdropdown()}}},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-primary"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("flight")])],1),_c('h4',{staticClass:"title"},[_vm._v("ลาชดเชยวันหยุดประเพณี (Holiday Leave)")])])],1),_c('md-card-content',{staticClass:"contentleave"},[(_vm.Check_holidays)?_c('md-table',{scopedSlots:_vm._u([{key:"md-table-row",fn:function({ item }){return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"No."}},[_vm._v(" "+_vm._s(_vm.leave_holiday.indexOf(item) + 1)+" ")]),_c('md-table-cell',{attrs:{"md-label":"วันหยุดประเพณี (HOLIDAY)"}},[_vm._v(" "+_vm._s(item.name_th)+" ")]),_c('md-table-cell',{attrs:{"md-label":"วันที่"}},[_vm._v(" "+_vm._s(item.holiday_date_format)+" ")]),_c('md-table-cell',{attrs:{"md-label":"เปลี่ยนวันหยุด"}},[(item.active == 1)?_c('div',[_vm._l((item.leave),function(leave){return _c('md-table-row',{key:leave.from_date +
                  leave.to_date +
                  leave.to_date_type +
                  leave.approve_status +
                  leave.approver_firstname +
                  'hd',staticClass:"hr"},[_vm._v(" "+_vm._s(leave.to_date)+" "),(leave.to_date_type == 'hm')?_c('span',[_vm._v(" ลาช่วงเช้า ")]):(leave.to_date_type == 'hn')?_c('span',[_vm._v(" ลาช่วงบ่าย ")]):_c('span',[_vm._v("เต็มวัน")])])}),(item.active_half > 0 && !_vm.old_leave)?_c('md-table-row',{staticClass:"hr empty"},[_c('b-datepicker',{attrs:{"min":item.holiday_date,"placeholder":"ลาเปลี่ยนวันหยุด","locale":"th","date-disabled-fn":_vm.disabledDate,"date-format-options":{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                },"required":""},model:{value:(item.to_date),callback:function ($$v) {_vm.$set(item, "to_date", $$v)},expression:"item.to_date"}}),(item.active_half >= 1)?_c('md-checkbox',{model:{value:(item.is_leaveend_half),callback:function ($$v) {_vm.$set(item, "is_leaveend_half", $$v)},expression:"item.is_leaveend_half"}},[_c('span',[_vm._v("ลาครึ่งวัน")])]):_c('div',[_c('span',{staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s((item.is_leaveend_half = true))+" ")])]),_c('md-field',{directives:[{name:"show",rawName:"v-show",value:(item.is_leaveend_half),expression:"item.is_leaveend_half"}]},[_c('label',[_vm._v("เลือกช่วงการลา")]),_c('md-select',{model:{value:(item.to_date_type),callback:function ($$v) {_vm.$set(item, "to_date_type", $$v)},expression:"item.to_date_type"}},[_c('md-option',{attrs:{"value":"hm"}},[_vm._v("ช่วงเช้า")]),_c('md-option',{attrs:{"value":"hn"}},[_vm._v("ช่วงบ่าย")])],1)],1)],1):_vm._e()],2):_vm._e()]),_c('md-table-cell',{attrs:{"md-label":"สถานะการอนุมัติ"}},[_vm._l((item.leave),function(leave){return _c('md-table-row',{key:leave.from_date +
                leave.to_date +
                leave.to_date_type +
                leave.approve_status +
                leave.approver_firstname +
                'hd_status',staticClass:"hr"},[(leave.approve_status == 'leaverequest')?_c('div',[_c('span',{staticStyle:{"color":"blue"}},[_vm._v("รอการอนุมัติ")])]):(leave.approve_status == 'approve')?_c('div',[_c('span',{staticStyle:{"color":"green"}},[_vm._v("อนุมัติแล้ว")])]):(leave.approve_status == 'disapprove')?_c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("ไม่อนุมัติ")])]):(leave.approve_status == 'reject')?_c('div'):_vm._e()])}),(item.active == 1 && item.active_half > 0)?_c('md-table-row',{staticClass:"hr empty"}):_vm._e()],2),_c('md-table-cell',{attrs:{"md-label":"ผู้อนุมัติ"}},[_vm._l((item.leave),function(leave){return _c('md-table-row',{key:leave.from_date +
                leave.to_date +
                leave.to_date_type +
                leave.approver_firstname +
                'hd_approver',staticClass:"hr"},[_vm._v(" "+_vm._s(leave.approver_firstname)+" "+_vm._s(leave.approver_lastname)+" ")])}),(item.active == 1 && item.active_half > 0)?_c('md-table-row',{staticClass:"hr empty"}):_vm._e()],2),_c('md-table-cell',{attrs:{"md-label":"Action"}},[_vm._l((item.leave),function(leave){return _c('md-table-row',{key:leave.from_date +
                leave.to_date +
                leave.to_date_type +
                leave.approver_firstname +
                'hd_action',staticClass:"hr"},[(_vm.permissions.includes('process salary') && _vm.persons != 0)?_c('b-button',{staticClass:"space-rt",attrs:{"size":"md","variant":"warning"},on:{"click":function($event){return _vm.editLeave(leave, 'oneday')}}},[_vm._v(" แก้ไข ")]):_vm._e(),(
                leave.approve_status == 'leaverequest' ||
                  (_vm.permissions.includes('process salary') && _vm.persons != 0)
              )?_c('b-button',{staticClass:"space-rt",attrs:{"size":"md","variant":"danger"},on:{"click":function($event){return _vm.deleteLeave(leave)}}},[_vm._v(" ลบ ")]):_vm._e()],1)}),(item.active == 1 && item.active_half > 0)?_c('md-table-row',{staticClass:"hr empty"},[(!_vm.old_leave)?_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.createleave(item)}}},[_vm._v(" เปลี่ยนวันหยุด ")]):_vm._e()],1):_vm._e()],2)],1)}}],null,false,3732710995),model:{value:(_vm.leave_holiday),callback:function ($$v) {_vm.leave_holiday=$$v},expression:"leave_holiday"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }