import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/employees`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .get(
      `${url}/v1/employees/${id}?include=branchs,weekends,salarys,salarys.positions,salarys.positions.departments,employeehasworkingtimes.workingtimes,users`,
      options,
    )
    .then((response) => {
      let user = jsona.deserialize(response.data);
      delete user.links;
      return user;
    });
}

function add(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.post(`${url}/v1/addstaff`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: null,
  });
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/updatestaff`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.delete(`${url}/v1/users/${id}`, options);
}

function upload(user, image) {
  const bodyFormData = new FormData();
  bodyFormData.append('attachment', image);

  return axios
    .post(`${url}/v1/uploads/users/${user.id}/profile-image`, bodyFormData)
    .then((response) => {
      return response.data.url;
    });
}

function passwordReset(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: [],
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/staff-passwordreset`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function privateSetup(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: [],
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/privatecode-setup`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  passwordReset,
  privateSetup,
};
