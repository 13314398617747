<template>
  <div class="md-layout spacing md-alignment-left">
    <div
      class="md-layout-item md-large-size-100 md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
    >
      <div class="slipdata">
        <div
          class="backgrourdslip"
          style="overflow-x:auto; margin-buttom:5px;"
          v-for="i in slipdata"
          :key="i.id"
        >
          <div>
            <div>
              <table class="slip-head" width="790px">
                <tr>
                  <th class="txt-center" colspan="6">
                    ใบจ่ายเงินเดือน
                  </th>
                </tr>
                <tr>
                  <th class="txt-center" colspan="6">
                    {{ i.bch_nameth }}
                    <!-- บริษัท บุ๊คเอนจิ้นดอทคอม -->
                  </th>
                </tr>
                <tr>
                  <td>รหัส {{ i.emp_code }}</td>
                  <td colspan="2">
                    ชื่อ-สกุล
                    {{ i.emptname_th + i.empfname_th + ' ' + i.emplname_th }}
                  </td>
                  <td colspan="2">
                    เลขที่บัญชี
                    <span v-if="i.bank_number">{{ i.bank_number }}</span>
                    <!-- เลขที่บัญชี 0000000000 -->
                  </td>
                </tr>
                <tr>
                  <td colspan="1">
                    วันที่เริ่มงาน
                    {{ i.start_date }}
                  </td>
                  <td colspan="2">
                    ตำแหน่ง
                    {{ i.empposname_en }}
                  </td>
                </tr>
              </table>
            </div>
            <div style="margin-top:5px; display: -webkit-inline-box;">
              <table class="slip-data incost" width="790px">
                <tr>
                  <th class="txt-center">รายได้<br />Earnings</th>
                  <th class="txt-center">จำนวน<br />Number</th>
                  <th class="txt-center">จำนวนเงิน<br />Amount</th>
                  <th class="txt-center">รายการหัก<br />Deductions</th>
                  <th class="txt-center">จำนวน<br />Number</th>
                  <th class="txt-center">จำนวนเงิน<br />Amount</th>
                </tr>
                <tr>
                  <td>
                    <font class="income">อัตรา</font>
                  </td>
                  <td>
                    <font class="income"></font>
                  </td>
                  <td class="txt-right">
                    <font class="income">{{ i.salary_amt_decode }}</font>
                  </td>
                  <td>
                    <font class="cost">หักขาดงาน/ลาไม่รับค่าจ้าง (วัน)</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">{{ i.sumday_notwork }}</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">{{ i.sumbaht_notwork }}</font>
                  </td>
                </tr>
                <tr>
                  <td>
                    <font class="income">เงินเดือน</font>
                  </td>
                  <td class="txt-right">
                    <font class="income">{{ i.daywork }}</font>
                  </td>
                  <td class="txt-right">
                    <font class="income">{{ i.total_salary }}</font>
                  </td>
                  <td>
                    <font class="cost">หักมาสาย/กลับก่อน (นาที)</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">{{ i.late_mins }}</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">{{ i.late_bath }}</font>
                  </td>
                </tr>
                <tr>
                  <td>
                    <font class="income">ค่าล่วงเวลา 1 เท่า</font>
                  </td>
                  <td class="txt-right">
                    <font class="income">
                      {{ i.ot1_bath == 0 ? '0 H 0 M' : i.ot1_hr }}
                    </font>
                  </td>
                  <td class="txt-right">
                    <font class="income">{{ i.ot1_bath }}</font>
                  </td>
                  <td>
                    <font class="cost">หักลาอื่นๆ</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost"></font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">0.00</font>
                  </td>
                </tr>
                <tr>
                  <td>
                    <font class="income">ค่าล่วงเวลา 1.5 เท่า</font>
                  </td>
                  <td class="txt-right">
                    <font class="income">
                      {{ i.ot1_5_bath == 0 ? '0 H 0 M' : i.ot1_5_hr }}
                    </font>
                  </td>
                  <td class="txt-right">
                    <font class="income">{{ i.ot1_5_bath }}</font>
                  </td>
                  <td>
                    <font class="cost">หักค่าบริหารจัดการ</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost"></font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">0.00</font>
                  </td>
                </tr>
                <tr>
                  <td>
                    <font class="income">ค่าล่วงเวลาเหมา/ค่าเที่ยวเรือ</font>
                  </td>
                  <td class="txt-right">
                    <!-- <font class="income">{{ i.ot_hr }}</font> -->
                  </td>
                  <td class="txt-right">
                    <font class="income">{{ i.ot_bath }}</font>
                  </td>
                  <td>
                    <font class="cost">หักค่าน้ำ/ค่าไฟฟ้า</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost"></font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">{{ i.total_elect_water }}</font>
                  </td>
                </tr>
                <tr>
                  <td>
                    <font class="income">ค่าเช่าบ้าน</font>
                  </td>
                  <td>
                    <font class="income"></font>
                  </td>
                  <td class="txt-right">
                    <font class="income">{{ i.home_rent_income }}</font>
                  </td>
                  <td>
                    <font class="cost">หักค่าMOU/บังคับคดี/กยศ.</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost"></font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">{{ i.sum_mou_stu_cost }}</font>
                  </td>
                </tr>
                <tr>
                  <td>
                    <font class="income">ค่านวดสปา/เบี้ยเลี้ยง</font>
                  </td>
                  <td>
                    <font class="income"></font>
                  </td>
                  <td class="txt-right">
                    <font class="income">{{ i.sum_massa_allow }}</font>
                  </td>
                  <td>
                    <font class="cost">หักเบิกล่วงหน้า</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost"></font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">0.00</font>
                  </td>
                </tr>
                <tr>
                  <td>
                    <font class="income">เซอร์วิสชาร์จ</font>
                  </td>
                  <td>
                    <font class="income"></font>
                  </td>
                  <td class="txt-right">
                    <font class="income">
                      <!-- {{
                        number_format(
                          Number(i.service_charge_income) +
                            Number(i.service_charge_income_nosso),
                        )
                      }} -->
                      {{ i.total_svc }}
                    </font>
                  </td>
                  <td>
                    <font class="cost">หักเซอร์วิสชาร์จ</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost"></font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">{{ i.service_charge_cost }}</font>
                  </td>
                </tr>
                <tr>
                  <td>
                    <font class="income">คืนค่าจ้างวันหยุดสะสม (วัน)</font>
                  </td>
                  <td class="txt-right">
                    <font class="income">{{ i.keepdays }}</font>
                  </td>
                  <td class="txt-right">
                    <font class="income">{{ i.keepdays_baht }}</font>
                  </td>
                  <td>
                    <font class="cost">หักอื่นๆ</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost"></font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">{{ i.other_bath_cost }}</font>
                  </td>
                </tr>
                <tr>
                  <td>
                    <font class="income">เงินได้ค้างรับ</font>
                  </td>
                  <td></td>
                  <td class="txt-right">
                    <font class="income">{{ i.accrued_income }}</font>
                  </td>
                  <td>
                    <font class="cost">หักภาษีเงินได้</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost"></font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">{{ i.tax_bath }}</font>
                  </td>
                </tr>
                <tr>
                  <td><font class="income">คืนค่าบริหารจัดการ</font></td>
                  <td></td>
                  <td class="txt-right">
                    <font class="income">{{ i.manage_income }}</font>
                  </td>
                  <td>
                    <font class="cost">หักสมทบประกันสังคม</font>
                  </td>
                  <td class="txt-right">
                    <font class="cost"></font>
                  </td>
                  <td class="txt-right">
                    <font class="cost">{{ i.sso_bath }}</font>
                  </td>
                </tr>
                <tr>
                  <td>
                    <font class="income">รายได้อื่นๆ</font>
                  </td>
                  <td></td>
                  <td class="txt-right">
                    <font class="income">{{ i.other_income }}</font>
                  </td>
                  <td>
                    <!-- <font class="cost">หักสมทบประกันสังคม</font> -->
                  </td>
                  <td class="txt-right">
                    <!-- <font class="cost"></font> -->
                  </td>
                  <td class="txt-right">
                    <!-- <font class="cost">{{ i.sso_bath }}</font> -->
                  </td>
                </tr>
                <tr>
                  <th class="txt-center" colspan="2" rowspan="2">
                    รวมเงินได้<br />Total Earnings
                  </th>
                  <th class="txt-right">
                    <font class="income">{{ i.income_total }}</font>
                  </th>
                  <th class="txt-center" colspan="2" rowspan="2">
                    รวมรายการหัก<br />Total Deduction
                  </th>
                  <th class="txt-right">
                    <font class="cost">{{ i.cost_total }}</font>
                  </th>
                </tr>
              </table>

              <div style="margin-left:10px; margin-right:5px;">
                <table class="slip-data" width="150px">
                  <tr>
                    <th class="txt-center">
                      วันที่จ่าย<br />
                      Payroll Date
                    </th>
                  </tr>
                  <tr>
                    <td class="txt-center">{{ i.pay_date }}</td>
                  </tr>
                </table>

                <table class="slip-noborder" width="150px">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </table>

                <table class="slip-data" width="150px">
                  <tr>
                    <th class="txt-center">
                      เงินรับสุทธิ<br />
                      Net To Pay
                    </th>
                  </tr>
                  <tr>
                    <td class="txt-center">{{ i.income_net }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <table class="slip-data" style="margin-top:10px;" width="790px">
            <tr>
              <th class="txt-center">
                เงินได้สะสมต่อปี
              </th>
              <th class="txt-center">
                ภาษีสะสมต่อปี
              </th>
              <th class="txt-center">
                เงินประกันสังคมต่อปี
              </th>
              <th class="txt-center">
                ค่าลดหย่อนอื่นๆ
              </th>
            </tr>
            <tr>
              <td class="txt-center">{{ i.saving_per_year }}</td>
              <td class="txt-center">{{ i.tax_per_year }}</td>
              <td class="txt-center">{{ i.sso_per_year }}</td>
              <td class="txt-center">{{ i.ssf }}</td>
            </tr>
          </table>

          <!-- <table
        class="slip-data"
        style="margin-top:10px; display:none;"
        width="790px"
      >
        <tr v-if="i.splitmoney">
          <td class="txt-center">{{ i.splitmoney.m1000 }}</td>
          <td class="txt-center">{{ i.splitmoney.m500 }}</td>
          <td class="txt-center">{{ i.splitmoney.m100 }}</td>
          <td class="txt-center">{{ i.splitmoney.m50 }}</td>
          <td class="txt-center">{{ i.splitmoney.m20 }}</td>
          <td class="txt-center">{{ i.splitmoney.m10 }}</td>
          <td class="txt-center">{{ i.splitmoney.m5 }}</td>
          <td class="txt-center">{{ i.splitmoney.m1 }}</td>
          <td class="txt-center">{{ i.splitmoney.m0_5 }}</td>
          <td class="txt-center">{{ i.splitmoney.m0_25 }}</td>
          <td
            rowspan="2"
            style="width:35%; font-size:14px"
            class="txt-center"
          >
            <br />
            <br />
            ลงชื่อพนักงาน
          </td>
        </tr>
        <tr>
          <td class="txt-center">1000</td>
          <td class="txt-center">500</td>
          <td class="txt-center">100</td>
          <td class="txt-center">50</td>
          <td class="txt-center">20</td>
          <td class="txt-center">10</td>
          <td class="txt-center">5</td>
          <td class="txt-center">1</td>
          <td class="txt-center">0.5</td>
          <td class="txt-center">0.25</td>
        </tr>
      </table> -->
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      profile: null,
      rp_id: null,
      slipdata: null,
      logo: process.env.VUE_APP_APP_BASE_URL + '/img/logo-santhiya.png',
    };
  },
  props: {
    bch_id: { type: Number, default: 0 },
    dep_id: { type: Number, default: 0 },
    emp_id: { type: Number, default: 0 },
    chk_permis: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    month: { type: Number, default: 0 },
  },
  computed: {},
  async created() {
    await this.getSlipData();
    await this.decodeSalary();
  },
  watch: {
    year: async function() {
      // if(year){
      //   await this.getSlipData();
      //   await this.decodeSalary();
      // }
    },
    month: async function(mon) {
      if (mon != null) {
        await this.getSlipData();
        await this.decodeSalary();
      }
    },
  },
  methods: {
    async getSlipData() {
      this.profile = this.$store.getters['profile/me'];
      let params = {
        ...{ rp_id: this.rp_id ? this.rp_id : {} },
        ...{ bch_id: this.bch_id ? this.bch_id : {} },
        ...{ dep_id: this.dep_id ? this.dep_id : {} },
        ...{ emp_id: this.emp_id ? this.emp_id : {} },
        ...{ chk_permis: this.chk_permis == 1 ? this.chk_permis : 0 },
        ...{ years: this.year ? this.year : {} },
        ...{
          months: this.month ? this.month : {},
        },
      };
      var data = await this.$store.dispatch('salarys/slipSalary', params);
      if ('status' in data) {
        this.slipdata = [];
      } else {
        this.slipdata = data;
      }
    },
    async decodeSalary() {
      var slipdatatmp = this.slipdata;

      var colformat = [
        'salary_amt_decode',
        'service_charge_income_all',
        'sumbaht_notwork',
        'total_salary',
        // 'ot_hr',
        'ot_bath',
        // 'ot1_hr',
        'ot1_bath',
        // 'ot1_5_hr',
        'ot1_5_bath',
        // 'ot2_hr',
        'ot2_bath',
        'ot_total',
        'service_charge_income',
        'service_charge_income_nosso',
        'total_svc',
        'food_service',
        'massage_income',
        'home_rent_income',
        'accrued_income',
        'manage_income',
        'allowance',
        'tip',
        'other_income',
        'keepdays',
        'keepdays_baht',
        'bonus',
        'salary_sso',
        'income_total',
        'late_mins',
        'late_bath',
        'sso_bath',
        'tax_bath',
        'service_charge_cost',
        'student_loan_fund',
        'home_rent_cost',
        'total_elect_water',
        'electricity_cost',
        'water_bill',
        'general_affairs_fee',
        'sum_mou_stu_cost',
        'sum_massa_allow',
        'food_cost',
        'other_bath_cost',
        'notwork_bath',
        'leave_wop_bath',
        'cost_total',
        'cost_net',
        'income_net',
        'saving_per_year',
        'tax_per_year',
        'sso_per_year',
        'ssf',
      ];
      var colformatnotround = [
        'notwork_day',
        'leave_wop_day',
        'sumday_notwork',
        'dayofmonth',
        'daywork',
      ];
      if (slipdatatmp.length <= 0) {
        return false;
      }
      const m = Math;
      slipdatatmp.forEach((val, key) => {
        let tmp = val;
        let salary_amt = val.salary_amt.slice(0, val.salary_amt.length - 1);
        let salary_amt_decode = this.decodeBase64(salary_amt);
        tmp.salary_amt_decode = salary_amt_decode;
        tmp.splitmoney = this.splitMoney(parseFloat(m.round(val.income_net)));
        tmp.pay_date = moment(val.pay_date).format('DD/MM/YYYY');
        tmp.start_date = moment(val.start_date).format('DD/MM/YYYY');
        for (const [key, value] of Object.entries(val)) {
          if (colformat.includes(key)) {
            tmp[key] = m.round(value);
            tmp[key] = this.number_format(tmp[key]);
          } else if (colformatnotround.includes(key)) {
            tmp[key] = this.number_format(tmp[key]);
          }
        }
        slipdatatmp[key] = tmp;
      });
      this.slipdata = slipdatatmp;
    },
    decodeBase64(data) {
      if (data.length <= 0) {
        return false;
      }
      let decode = this.Base64_decode(data);
      return decode;
    },

    Base64_encode(input) {
      // private property
      var _keyStr =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

      // public method for encoding
      var output = '';
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;

      input = this.Base64_utf8_encode(input);

      while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
          enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }

        output =
          output +
          _keyStr.charAt(enc1) +
          _keyStr.charAt(enc2) +
          _keyStr.charAt(enc3) +
          _keyStr.charAt(enc4);
      }

      return output;
    },

    Base64_decode(input) {
      var _keyStr =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
      var output = '';
      var chr1, chr2, chr3;
      var enc1, enc2, enc3, enc4;
      var i = 0;

      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

      while (i < input.length) {
        enc1 = _keyStr.indexOf(input.charAt(i++));
        enc2 = _keyStr.indexOf(input.charAt(i++));
        enc3 = _keyStr.indexOf(input.charAt(i++));
        enc4 = _keyStr.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        output = output + String.fromCharCode(chr1);

        if (enc3 != 64) {
          output = output + String.fromCharCode(chr2);
        }
        if (enc4 != 64) {
          output = output + String.fromCharCode(chr3);
        }
      }

      output = this.Base64_utf8_decode(output);

      return output;
    },

    Base64_utf8_encode(string) {
      string = string.replace(/\r\n/g, '\n');
      var utftext = '';

      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }

      return utftext;
    },

    Base64_utf8_decode(utftext) {
      var string = '';
      var i = 0;
      var c3 = 0;
      var c2 = 0;
      var c1 = c2;
      var c = c1;
      while (i < utftext.length) {
        c = utftext.charCodeAt(i);

        if (c < 128) {
          string += String.fromCharCode(c);
          i++;
        } else if (c > 191 && c < 224) {
          c2 = utftext.charCodeAt(i + 1);
          string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
          i += 2;
        } else {
          c2 = utftext.charCodeAt(i + 1);
          c3 = utftext.charCodeAt(i + 2);
          string += String.fromCharCode(
            ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63),
          );
          i += 3;
        }
      }

      return string;
    },
    number_format(num = 0) {
      let new_num = parseFloat(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return new_num;
    },
    splitMoney(num = 0) {
      var split = [];
      let money = [1000, 500, 100, 50, 20, 10, 5, 1, 0.5, 0.25];
      for (let index = 0; index < money.length; index++) {
        let key = 'm' + money[index].toString().replace('.', '_');
        split[key] = Math.floor(num / money[index]);
        num = num % money[index];
      }
      return split;
    },
  },
};
</script>
<style></style>
