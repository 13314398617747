import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function list(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/keepdays_offs`, options);
  return {
    list: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

async function keepdaystafflist(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/keepdaystafflist`, options);
  return {
    list: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

async function staffkeepdaylist(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  const response = await axios.get(`${url}/v1/staffkeepdaylist`, options);
  return jsona.deserialize(response.data);
}

async function addStaffKeepDay(keepdays) {
  const payload = jsona.serialize({
    stuff: keepdays,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/keepdaycreate`,
    payload,
    options,
  );
  return response.data;
}

async function addStaffKeepDayApprove(keepdays) {
  const payload = jsona.serialize({
    stuff: keepdays,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/keepdaycreateapprove`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function deleteKeepday(payload) {
  // console.log(payload);
  const response = await axios.post(`${url}/v1/deletekeepday`, payload);
  return response;
}
async function updateKeepday(payload) {
  // console.log(payload);
  const response = await axios.post(`${url}/v1/updatekeepday`, payload);
  // console.log(response.data);
  return jsona.deserialize(response.data);
}

async function keepdayapprove(approval) {
  const payload = jsona.serialize({
    stuff: approval,
    includeNames: [],
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.patch(
    `${url}/v1/keepdayapprove/${approval.id}`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

export default {
  list,
  keepdaystafflist,
  keepdayapprove,
  addStaffKeepDay,
  addStaffKeepDayApprove,
  staffkeepdaylist,
  deleteKeepday,
  updateKeepday,
};
