<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100" v-if="isParams">
      <tabs
        :tab-name="tabList.name"
        :tab-icon="tabList.icon"
        :tab-active="tabactive"
        nav-pills-icons
        plain
        color-button="warning"
      >
        <!-- here you can add your content for tab-content -->
        <template slot="tab-pane-1">
          <md-card>
            <ListUserPage :permissions="permissionsList" />
          </md-card>
        </template>
        <template slot="tab-pane-2">
          <md-card>
            <ListUserPageShowImg
              :permissions="permissionsList"
              :available_branches="available_branches"
            />
          </md-card>
        </template>
      </tabs>
    </div>
  </div>
</template>
<script>
import { Tabs } from '@/components';
import $ from 'jquery';
import moment from 'moment';
import Vuex from 'vuex';
import ListUserPage from '@/pages/Dashboard/Pages/Admin/UserManagement/ListUserPage.vue';
import ListUserPageShowImg from '@/pages/Dashboard/Pages/Admin/UserManagement/ListUserPageShowImg.vue';
// import Vuex from 'vuex';
import _ from 'lodash';

export default {
  components: {
    Tabs,
    ListUserPage,
    ListUserPageShowImg,
  },

  data() {
    return {
      tabactive: 0,
      tabList: {
        name: [],
        icon: [],
      },
      permissionsList: [],
      employee_id: null,
      months: moment()
        // .subtract(1, 'month')
        .month(),
      years: moment().year(),
      branchs: {},
      isParams: false,
      me: null,
      query: null,
      available_branches: [],
    };
  },

  async created() {
    let params = { profile: true };
    this.profile = await this.$store.dispatch('staffs/searchStaff', params);
    this.getProfile().then(() => {
      this.setPermission(this.me);
      this.setEmployeeID(this.me).then(() => {
        this.isParams = true;
        if (this.permissionsList.includes('process salary')) {
          this.tabList.name = ['จัดการพนักงาน', 'ตรวจสอบรูปภาพ'];
          this.tabList.icon = ['people', 'image'];
        } else {
          this.setstaffTab();
        }
      });
    });
    this.getBranches();
    if (this.$route.params.tabactive) {
      this.tabactive = this.$route.params.tabactive;
    }
  },

  computed: {
    // ...Vuex.mapState({
    //   me: (state) => state.profile.me,
    // }),
  },

  methods: {
    setTabActive() {
      if (this.$route.params.tabactive) {
        this.tabactive = this.$route.params.tabactive;
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissionsList.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissionsList.push(permission.name);
      });
    },

    async setEmployeeID(me) {
      this.branchs = me.employees.branchs;
      this.employee_id = me.employee_id;
    },

    setstaffTab() {
      this.tabList.name = ['จัดการพนักงาน', 'ตรวจสอบรูปภาพ'];
      this.tabList.icon = ['people', 'image'];
    },

    async getProfile() {
      await this.$store.dispatch('profile/me');
      this.me = await this.$store.getters['profile/me'];
    },

    async getBranches() {
      let params = {
        com_id: this.profile[0].com_id,
        bch_only: '1',
        ...{ inactive: this.inactive ? this.inactive : {} },
      };
      await this.$store
        .dispatch('staffs/searchStaff', params)
        .then(async (data) => {
          this.available_branches = data;
        });
    },
  },
};
</script>
<style lang="scss">
.tab-pane-1,
.tab-pane-2,
.tab-pane-3,
.tab-pane-4,
.tab-pane-5,
.tab-pane-6 {
  width: 100%;
}
</style>
