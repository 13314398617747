import service from '@/store/services/info-service';

const state = {};

const mutations = {};

const actions = {
  async getFileInfo({ commit, dispatch }, params) {
    return await service.getFileInfo(params);
  },
  async createFileInfo({ commit, dispatch }, params) {
    return await service.createFileInfo(params);
  },
};

const getters = {};

const info = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default info;
