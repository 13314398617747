import service from '@/store/services/keepdays-service';

const state = {
  keepdayamout: 0,
  keepdaytotal: 0,
};

const mutations = {
  SET_KEEPDAYSTAFFLIST: (state, list) => {
    state.list = list;
  },

  SET_KEEPDAYTOTAL: (state, value) => {
    state.keepdaytotal = value;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    //not use
    return service.list(params).then(({ list, meta }) => {
      return list;
    });
  },

  async setKeepdayAmout({ commit, dispatch }, value) {
    await commit('SET_KEEPDAYTOTAL', state.meta.page.keepday.total);
    return state.meta.page.keepday.total;
  },

  keepdaystafflist({ commit, dispatch }, params) {
    return service.keepdaystafflist(params).then(({ list, meta }) => {
      commit('SET_KEEPDAYSTAFFLIST', list);
      return list;
    });
  },

  async addStaffKeepDay({ commit, dispatch }, params) {
    return await service.addStaffKeepDay(params);
  },
  async addStaffKeepDayApprove({ commit, dispatch }, params) {
    return await service.addStaffKeepDayApprove(params);
  },

  async staffkeepdaylist({ commit, dispatch }, params) {
    return await service.staffkeepdaylist(params);
  },

  async deleteKeepday({ commit, dispatch }, params) {
    return await service.deleteKeepday(params);
  },

  async updateKeepday({ commit, dispatch }, params) {
    return await service.updateKeepday(params);
  },

  async keepdayapprove({ commit, dispatch }, params) {
    return await service.keepdayapprove(params);
  },
};

const getters = {
  keepdaytotal: (state) => state.keepdaytotal,
};

const keepdays = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default keepdays;
