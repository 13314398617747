<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100" v-if="isParams">
      <tabs
        :tab-name="tabList.name"
        :tab-icon="tabList.icon"
        :tab-active="tabactive"
        nav-pills-icons
        plain
        color-button="warning"
      >
        <!-- here you can add your content for tab-content -->
        <template slot="tab-pane-1">
          <md-card>
            <SettingPriceOT
              :permissions="permissionsList"
              @get_settingPrice_OT="get_settingPrice_OT"
              :tableData="tableData['nm']"
              :available_branches="available_branches"
              :available_ots="available_ots['nm']"
              :flat_rate_list="flat_rate_list['nm']"
            />
          </md-card>
        </template>
        <template slot="tab-pane-2">
          <md-card>
            <SettingPriceOTspa
              :permissions="permissionsList"
              @get_settingPrice_OT="get_settingPrice_OT"
              :tableData="tableData['sp']"
              :available_branches="available_branches"
              :available_ots="available_ots['sp']"
              :flat_rate_list="flat_rate_list['sp']"
            />
          </md-card>
        </template>
        <template slot="tab-pane-3">
          <md-card>
            <SettingTreatment
              :permissions="permissionsList"
              @getTreatment="getTreatment"
              :flat_rate_list="flat_rate_list['sp']"
              :available_Treatment="available_Treatment"
            />
          </md-card>
        </template>
      </tabs>
    </div>
  </div>
</template>
<script>
import { Tabs } from '@/components';
import $ from 'jquery';
import moment from 'moment';
import Vuex from 'vuex';
import SettingPriceOT from '@/pages/Dashboard/Pages/Payroll/ot_flat_rate/SettingPriceOT.vue';
import SettingPriceOTspa from '@/pages/Dashboard/Pages/Payroll/ot_flat_rate/SettingPriceOTspa.vue';
import SettingTreatment from '@/pages/Dashboard/Pages/Payroll/ot_flat_rate/SettingTreatment.vue';
// import Vuex from 'vuex';
import _ from 'lodash';

export default {
  components: {
    Tabs,
    SettingPriceOT,
    SettingPriceOTspa,
    SettingTreatment,
  },

  data() {
    return {
      tabactive: 0,
      tabList: {
        name: [],
        icon: [],
      },
      permissionsList: [],
      employee_id: null,
      months: moment()
        // .subtract(1, 'month')
        .month(),
      years: moment().year(),
      branchs: {},
      isParams: false,
      me: null,
      query: null,
      tableData: { nm: [], sp: [] },
      available_branches: [],
      available_ots: { nm: [], sp: [] },
      flat_rate_list: { nm: [], sp: [] },
      available_Treatment: [],
    };
  },

  async created() {
    let params = { profile: true };
    this.profile = await this.$store.dispatch('staffs/searchStaff', params);
    this.getProfile().then(() => {
      this.setPermission(this.me);
      this.setEmployeeID(this.me).then(() => {
        this.isParams = true;
        if (this.permissionsList.includes('process salary')) {
          this.tabList.name = [
            'อัตราค่าล่วงเวลาเหมา',
            'อัตราค่านวดสปา',
            'ตั้งค่า Treatment',
          ];
          this.tabList.icon = ['directions_boat', 'spa', 'library_books'];
        } else {
          this.setstaffTab();
        }
      });
    });
    this.get_settingPrice_OT();
    this.getBranches();
    this.getOTType();
    this.getOTType_flat_rate_list();
    this.getTreatment();
    if (this.$route.params.tabactive) {
      this.tabactive = this.$route.params.tabactive;
    }
  },

  computed: {
    // ...Vuex.mapState({
    //   me: (state) => state.profile.me,
    // }),
  },

  methods: {
    setTabActive() {
      if (this.$route.params.tabactive) {
        this.tabactive = this.$route.params.tabactive;
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissionsList.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissionsList.push(permission.name);
      });
    },

    async setEmployeeID(me) {
      this.branchs = me.employees.branchs;
      this.employee_id = me.employee_id;
    },

    setstaffTab() {
      this.tabList.name = [
        'อัตราค่าล่วงเวลาเหมา',
        'อัตราค่านวดสปา',
        'ตั้งค่า Treatmen',
      ];
      this.tabList.icon = ['directions_boat', 'spa', 'library_books'];
    },

    async getProfile() {
      await this.$store.dispatch('profile/me');
      this.me = await this.$store.getters['profile/me'];
    },

    async get_settingPrice_OT() {
      let resp = await this.$store.dispatch('otsflatrate/getPriceOT');
      if (resp == 0) {
        await this.$store.dispatch('alerts/error', 'ไม่มีข้อมูลในระบบ!');
        return false;
      } else {
        var arr = { nm: [], sp: [] };
        $.each(resp, function(i, v) {
          let items = {
            bch_id: v['bch_id'],
            br_nameth: v['br_nameth'],
            name: v['name'],
            ot_id: v['ot_id'],
            ot_price_id: v['ot_price_id'],
            price: v['price'],
            setting_price_name: v['setting_price_name'],
            otType_name: v['otType_name'],
            otTypeot_flat_rate_id_name: v['ot_flat_rate_id'],
          };
          if ([4, 6].includes(items.ot_id)) {
            arr.nm.push(items);
          } else if ([5].includes(items.ot_id)) {
            arr.sp.push(items);
          }
        });
        // item = { value: v['id'], text: v['name'] };
        // if ([1, 2, 3].includes(item.value)) {
        //   arr.push(item);
        // }
        this.tableData = arr;
      }
    },
    async getBranches() {
      let params = {
        com_id: this.profile[0].com_id,
        bch_only: '1',
      };
      await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
        this.available_branches = data;
      });
    },
    async getOTType() {
      let params = {
        filter: {
          ...{ company_id: 1 },
        },
      };
      await this.$store.dispatch('ots/list', params);
      const ottypelist = this.$store.getters['ots/list'];
      var arr = { nm: [], sp: [] };
      if (ottypelist) {
        $.each(ottypelist, function(i, v) {
          let item = { id: v['id'], name: v['name'] };
          if ([4, 6].includes(item.id)) {
            arr.nm.push(item);
          } else if ([5].includes(item.id)) {
            arr.sp.push(item);
          }
        });
        this.available_ots = arr;
      }
    },
    async getOTType_flat_rate_list() {
      let resp = await this.$store.dispatch('otsflatrate/listOT_flat_rat');
      var arr = { nm: [], sp: [] };
      $.each(resp, function(i, v) {
        let items = {
          id: v['id'],
          otType_name: v['otType_name'],
          ot_id: v['ot_id'],
        };
        if ([4].includes(items.ot_id)) {
          arr.nm.push(items);
        } else if ([5].includes(items.ot_id)) {
          arr.sp.push(items);
        }
      });
      this.flat_rate_list = arr;
    },
    async getTreatment() {
      let resp = await this.$store.dispatch('otsflatrate/get_treatment');
      this.available_Treatment = resp;
    },
  },
};
</script>
<style lang="scss">
.tab-pane-1,
.tab-pane-2,
.tab-pane-3,
.tab-pane-4,
.tab-pane-5,
.tab-pane-6 {
  width: 100%;
}
</style>
