<template>
  <div class="md-layout spacing md-alignment-center">
    <div
      class="md-layout-item md-size-10 md-medium-size-20 md-small-size-20 md-xsmall-size-100"
      v-if="headLabel != '' && headLabel != null"
    >
      {{ headLabel }}
    </div>
    <div
      class="md-layout-item md-size-5 md-medium-size-10 md-small-size-10 md-xsmall-size-100"
      style="text-align: left;"
    >
      <label>ค้นหา: </label>
    </div>
    <div
      class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
    >
      <md-field class="searchbranchs">
        <label>เลือก สำนักงาน</label>
        <md-select v-model="branches" name="branch_id">
          <md-option
            v-for="item in available_branches"
            :key="item.bch_id"
            :value="item.bch_id"
          >
            {{ item.brname_th }}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div
      class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
      v-if="(st == 'department' && st != 'branch') || st == null"
    >
      <md-field class="searchdepartments">
        <label>เลือก แผนก</label>
        <md-select
          v-model="departments"
          name="branch_id"
          :disabled="disabled_dep"
        >
          <md-option
            v-for="item in available_departments"
            :key="item.dep_id"
            :value="item.dep_id"
          >
            {{ item.depname_th }}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div
      v-if="st != 'department' && st != 'branch' && st == null"
      class="md-layout-item md-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
    >
      <md-autocomplete
        v-model="staffSearchTerm"
        :md-options="staffs"
        @md-opened="searchStaffs"
        @md-changed="searchStaffs"
        @md-selected="selectStaff"
        :disabled="disabled_employee"
        class="searchstaffs"
      >
        <label>เลือก พนักงาน</label>
        <template slot="md-autocomplete-item" slot-scope="{ item }">
          {{ item.emp_name }}
        </template>
      </md-autocomplete>
    </div>
  </div>
</template>

<script>
import employeeMixin from '@/mixins/employee-mixin';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  mixins: [employeeMixin],
  data() {
    return {
      profile: null,
      branches: null,
      departments: null,
      available_branches: null,
      available_departments: null,
      staffList: [],
      staffSearchTerm: this.value,
      staffs: [],
      disabled_employee: true,
      disabled_dep: true,
    };
  },
  props: {
    headLabel: null,
    branchId: null,
    companyId: null,
    inactive: null,
    st: null, // only department branch
    permissionsList: {},
  },
  computed: {},
  async created() {
    let params = { profile: true };
    this.profile = await this.$store.dispatch('staffs/searchStaff', params);
    this.getBranches();
  },
  watch: {
    branches: async function() {
      this.staffs = [];
      this.disabled_employee = true;
      if (this.branches) {
        this.$emit('childBranches', this.branches);
        this.getDepartments();
        this.getEmpContact();
        if (this.staffSearchTerm) {
          this.staffSearchTerm = '';
        }
      }
    },
    departments: function() {
      if (!!this.departments) {
        this.$emit('childDepartment', this.departments);
        this.getEmpContact();
        if (this.staffSearchTerm) {
          this.staffSearchTerm = '';
        }
      }
    },
    staffSearchTerm: function() {
      if (this.staffSearchTerm == '') {
        this.$emit('childStaffs', null);
      }
    },
    value(val) {
      if (val) {
        this.staffSearchTerm = val;
      }
    },
  },
  methods: {
    async getBranches() {
      let params = {
        com_id: this.profile[0].com_id,
        bch_only: '1',
        ...{ inactive: this.inactive ? this.inactive : {} },
      };
      await this.$store
        .dispatch('staffs/searchStaff', params)
        .then(async (data) => {
          this.available_branches = await data.filter((branch) => {
            return this.permissionsList.includes(branch.brname_en);
          });
        });
    },
    async getDepartments() {
      this.departments = null;
      let params = {
        com_id: this.profile[0].com_id,
        ...{ bch_id: this.branches ? this.branches : {} },
        ...{ dep_id: this.departments ? this.departments : {} },
        ...{ inactive: this.inactive ? this.inactive : {} },
        dep_only: '1',
      };
      await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
        this.available_departments = data;
        if (this.available_departments.length > 0) {
          this.disabled_dep = false;
        }
      });
    },
    async getEmpContact() {
      loading_start();
      let params = {
        ...{ bch_id: this.branches ? this.branches : {} },
        ...{ dep_id: this.departments ? this.departments : {} },
      };
      await this.$store
        .dispatch('contract/getEmpContract', params)
        .then((data) => {
          this.staffList = data;
          if (this.staffList.length > 0) {
            this.disabled_employee = false;
          }
          this.searchStaffs();
          loading_close();
        });
    },
    searchStaffs(searchTerm) {
      if (!searchTerm) {
        searchTerm = this.staffSearchTerm;
      }
      this.staffs = new Promise((resolve) => {
        window.setTimeout(() => {
          if (!searchTerm) {
            resolve(this.staffList);
          } else {
            const term = searchTerm.toLowerCase();
            resolve(
              this.staffList.filter(
                ({ emp_name, emp_idcard }) =>
                  emp_name.toLowerCase().includes(term) ||
                  emp_idcard.toLowerCase().includes(term),
              ),
            );
          }
        }, 300);
      });
    },

    selectStaff(c) {
      if (this.staffSearchTerm != null) {
        this.staffSearchTerm = c.emp_name; // !!!here's the thing!!!
      } else {
        this.staffSearchTerm = null;
      }
      this.$emit('childStaffs', c);
    },
  },
};
</script>
