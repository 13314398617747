<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>edit</md-icon>
          </div>
          <h4 class="title">Edit User Role</h4>
        </md-card-header>
        <md-card-content>
          <div class="text-right">
            <md-button @click="goBack" class="md-primary md-dense">
              Back to List
            </md-button>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-20">
              <md-field>
                <label>เลือก Role</label>
                <md-select v-model="role">
                  <md-option
                    v-for="item in available_roles"
                    :key="item.name"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-40 mt2 md-small-size-20">
              <md-field>
                <label>พนักงาน</label>
                <md-input v-model="name" :disabled="true" />
              </md-field>
            </div>
            <div class="md-layout-item md-size-20 mt2 md-small-size-20">
              <md-button
                type="submit"
                class="md-success"
                @click="updateUserRole"
                >Update</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    available_roles: [],
    role: [],
    name: null,
    assign_user_role: {
      type: 'assignstaff-role',
      userid: null,
      role: null,
    },
  }),

  created() {
    this.getRole();
  },

  methods: {
    async getRole() {
      await this.$store.dispatch('roles/list').then(() => {
        this.available_roles = this.$store.getters['roles/dropdown'];
        this.role = this.$route.params.roleid;
        this.name = this.$route.params.name;
      });
    },

    async updateUserRole() {
      this.assign_user_role.userid = this.$route.params.id;
      this.assign_user_role.role = this.role;
      try {
        await this.$store.dispatch(
          'staffs/assignUserRole',
          this.assign_user_role,
        );
        await this.$store.dispatch(
          'alerts/success',
          'Role updated successfully.',
        );
        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            'alerts/error',
            e.response.data.errors[0].title,
          );
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },

    goBack() {
      this.$router.push({
        name: 'จัดการสิทธิ์ User',
        params: { id: 0, branch: 0 },
      });
    },
  },
};
</script>
