import service from '@/store/services/leaves-service';

const state = {
  list: {},
  leave: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, leave) => {
    state.leave = leave;
  },
};

const actions = {
  async list({ commit, dispatch }, params) {
    return await service.list(params);
  },
  async reportremainleave({ commit, dispatch }, params) {
    return await service.reportremainleave(params);
  },
  async insertremainleave({ commit, dispatch }, params) {
    return await service.insertremainleave(params);
  },
  // add({ commit, dispatch }, params) {
  // 	return service.add(params).then((leave) => {
  // 		commit('SET_RESOURCE', leave);
  // 	});
  // },

  // approve({ commit, dispatch }, params) {
  // 	return service.approve(params).then((leave) => {
  // 		commit('SET_RESOURCE', leave);
  // 	});
  // },
};

const getters = {
  list: (state) => state.list,
  leave: (state) => state.leave,
  dropdown: (state) => {
    return state.list.map((leave) => ({
      id: leave.id,
      name: leave.leave_nameth,
      leave_day: leave.leave_day,
      working_age: leave.working_age,
    }));
  },
};

const leaves = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default leaves;
