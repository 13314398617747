<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>lock</md-icon>
          </div>
          <h4 class="title"></h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout spacing">
            <filter-employee
              :headLabel="headLabel"
              :permissionsList="permissions"
              v-on:childBranches="setBranches"
              v-on:childStaffs="setStaffs"
            >
            </filter-employee>

            <!-- <div
              class="md-layout-item md-size-40 mt-2 md-small-size-40 text-center"
            >
              <md-button @click="get()" class="md-info">Search</md-button>
            </div> -->
          </div>

          <md-table
            :value="tableData"
            class="paginated-table table-striped table-hover"
            table-header-color="green"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="สิทธิ์การใช้งาน">{{
                item.roles[0].name
              }}</md-table-cell>
              <md-table-cell md-label="พนักงาน"
                >{{ item.first_nameen }} {{ item.last_nameen }}</md-table-cell
              >
              <md-table-cell md-label="Actions">
                <md-button
                  class="md-icon-button md-raised md-round md-info"
                  @click="
                    goToEdit(
                      item.id,
                      item.roles[0].name,
                      item.first_nameen + ' ' + item.last_nameen,
                    )
                  "
                  style="margin: .2rem;"
                >
                  <md-icon>edit</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import employeeMixin from '@/mixins/employee-mixin';
import FilterEmployee from '@/components/FilterEmployee';
import Vuex from 'vuex';

export default {
  components: {
    'filter-employee': FilterEmployee,
  },
  mixins: [employeeMixin],
  data: () => ({
    headLabel: '',
    branchs: null,
    tableData: [],
    employee_id: 0,
    //permissions: [],
  }),

  created() {
    this.get();
  },

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      let pms = [];
      if (this.me) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }

      return pms;
    },
  },

  watch: {
    // me: {
    //   handler: function(val) {
    //     val.permissions.map((permis) => {
    //       this.permissions.push(permis.name);
    //     });
    //     val.roles[0].permissions.map((permission) => {
    //       this.permissions.push(permission.name);
    //     });
    //   },
    //   deep: true,
    // },
    employee_id: function() {
      this.get();
    },
  },
  methods: {
    async get() {
      let params = {
        ...(this.employee_id ? { id: this.employee_id } : { id: 0 }),
        ...(this.branchs ? { branch: this.branchs } : 0),
      };
      await this.$store.dispatch('staffs/getStaffRole', params).then(() => {
        this.tableData = [];

        if (this.$store.getters['staffs/staffrole']) {
          this.tableData = this.$store.getters['staffs/staffrole'];
        } else {
          this.$store.dispatch('alerts/error', 'No data!!!');
        }
      });
    },

    goToEdit(id, roleid, name) {
      //return false;
      this.$router.push({
        name: 'แก้ไขสิทธิ์ User',
        params: { id: id, roleid: roleid, name: name },
      });
    },
    /**
     * set branch value from child component
     */
    setBranches(v) {
      this.branchs = v;
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employee_id = v.id;
      }
    },
  },
};
</script>
