<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>calendar_today</md-icon>
          </div>
          <h4 class="title"></h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <!-- <div class="md-layout-item md-size-40 mt-2 md-small-size-100 md-alignment-left">
            <md-switch v-model="boolean">{{status}}</md-switch>
          </div> -->
            <div
              class="md-layout-item md-size-100 mt-4 md-small-size-100 text-right"
            >
              <md-button @click="goToAdd" class="md-primary md-dense">
                Add new
              </md-button>
            </div>
          </div>
          <md-table
            :value="tableData"
            class="paginated-table table-striped table-hover"
            table-header-color="green"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button
                  class="md-icon-button md-raised md-round md-info"
                  @click="goToEdit(item.id)"
                  style="margin: 0.2rem"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-icon-button md-raised md-round md-danger"
                  @click="destroy(item.id)"
                  style="margin: 0.2rem"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import _ from 'lodash';

export default {
  components: {},
  data: () => ({
    tableData: [],
    query: null,
    sortation: {
      field: 'created_at',
      order: 'asc',
    },
    boolean: true,
    status: 'active',
    workingtime: {
      type: 'workingtimes',
      id: null,
      status: null,
    },
  }),

  computed: {},

  watch: {
    query: {
      handler: 'getListDebounced',
      immediate: true,
    },
    boolean(v) {
      this.status = v ? 'active' : 'inactive';
      this.getList();
    },
  },

  methods: {
    getListDebounced: _.debounce(function() {
      this.getList();
    }, 300),

    async getList() {
      let branchs = JSON.parse(localStorage.getItem('branchs'));
      let params = {
        filter: {
          //...(this.status ? {status: this.status} : {}),
          ...{ company_id: branchs.company_id },
        },
      };
      this.$store.watch(
        () => this.$store.getters['weekends/list'],
        (weekend) => {
          this.tableData = weekend;
          //console.log(this.tableData);
        },
      );
      await this.$store.dispatch('weekends/list', params);
    },

    async destroy(id) {
      const confirmation = await Swal.fire({
        title: 'ลบกะการทำงาน ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        // console.log(id);
        // return false;
        this.workingtime.id = id;
        this.workingtime.status = 'inactive';
        await this.$store.dispatch('workingtimes/update', this.workingtime);
        await this.$store.dispatch('alerts/success', 'Successfully.');
        await this.getList();
      }
    },

    goToAdd() {
      this.$router.push({
        name: 'เพิ่ม วันหยุดประจำสัปดาห์',
        path: 'add-weekendtime',
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: 'แก้ไข วันหยุดประจำสัปดาห์',
        path: 'edit-weekend',
        params: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.md-switch {
  display: flex;
}
</style>
