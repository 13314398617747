<template>
  <md-card>
    <md-card-header>
      <h4>นำเข้า เงินได้ / เงินหัก</h4>
    </md-card-header>
    <md-card-content>
      <div class="md-layout md-alignment-center-left">
        <div
          class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <b-input-group>
            <!-- <b-form-file
              accept=".xlsx"
              ref="file"
              v-model="file_import"
              :state="Boolean(file_import)"
              placeholder="Choose a file or drop file here..."
              drop-placeholder="Drop file here..."
            /> -->
            <b-form-file
              accept=".csv, .xlsx"
              ref="file"
              v-model="file_import"
              :state="Boolean(file_import)"
              placeholder="Choose a file or drop file here..."
              drop-placeholder="Drop file here..."
              @change="change_check()"
            />
            <b-input-group-append>
              <!-- <b-button variant="info" @click="readFile()">Read File</b-button> -->
              <b-button variant="info" @click="check_fileType()"
                >Read File</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>
        <div
          v-if="check_file_exl == true"
          class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <b-form-select v-model="selectedSheet" :options="list_worksheet" />
        </div>
        <div
          class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <b-form-checkbox
            v-if="check_file_exl == true"
            v-model="removeHeader"
            switch
          >
            ลบแถวหัวข้อในไฟล์ Excel
          </b-form-checkbox>
          <b-form-checkbox
            v-if="check_file_csv == true"
            v-model="removeHeaderCsv"
            switch
          >
            ลบแถวหัวข้อในไฟล์ csv
          </b-form-checkbox>
        </div>
      </div>
      <div class="md-layout md-alignment-left">
        <div
          class="md-layout-item md-medium-size-50 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <b-datepicker
            variant="primary"
            ref="fromdate"
            placeholder="เลือกวันที่เริ่ม"
            locale="th"
            v-model="from_date"
            required
          />
        </div>
        <div
          class="md-layout-item md-medium-size-50 md-small-size-50 md-xsmall-size-100 spacing"
        >
          <b-datepicker
            variant="primary"
            ref="todate"
            placeholder="เลือกวันที่สิ้นสุด"
            locale="th"
            v-model="to_date"
            required
          />
        </div>
      </div>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item">
          <label>
            หัวข้อ เงินได้/เงินหัก:
          </label>
          <div class="md-layout-item spacing">
            <b-input-group>
              <b-button variant="warning" @click="resetHeader()">
                Reset หัวข้อ
              </b-button>
              <b-input-group-append>
                <b-button variant="primary" @click="setdefaultExcel()">
                  Export หัวข้อ
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="md-layout-item spacing">
            <draggable
              class="list-group"
              tag="ul"
              v-model="headerList"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
            >
              <transition-group
                type="transition"
                :name="!drag ? 'flip-list' : null"
              >
                <li
                  class="list-group-item"
                  v-for="(element, index) in headerList"
                  :key="element.col_name_th"
                >
                  <span
                    v-if="element.col_name_th != 'รหัสพนักงาน'"
                    @click="removeAt(index)"
                  >
                    <md-icon class="pointer" style="color:rgb(255, 0, 0)">
                      close
                    </md-icon>
                  </span>
                  <span v-if="index <= 25">
                    {{ String.fromCharCode(index + 65) }} :
                    {{ element.col_name_th }}
                  </span>
                  <span v-else>
                    A{{ String.fromCharCode(index - 26 + 65) }} :
                    {{ element.col_name_th }}
                  </span>
                </li>
              </transition-group>
              <!-- <button slot="header">Add</button> -->
            </draggable>
          </div>
        </div>
      </div>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item spacing md-alignment">
          <b-button
            v-if="check_file_exl == true"
            variant="success"
            @click="importFileIncomeCost()"
          >
            Import File
          </b-button>
          <b-button
            v-if="check_file_csv == true"
            variant="success"
            @click="importFileIncomeCostCsv()"
          >
            Import File
          </b-button>
        </div>
      </div>
      <div class="md-layout md-alignment-left">
        <div class="md-layout-item spacing md-alignment">
          <b-table-simple responsive v-if="dataExcel.length > 0">
            <b-thead>
              <b-tr>
                <b-th v-for="(col, index) in columnList" :key="'col-' + index">
                  {{ col }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(data, index) in dataExcel.slice(0, limit)"
                :key="'data-' + index"
              >
                <b-td v-for="(d, i) in data" :key="'d-' + i">
                  {{ d.val }}
                </b-td>
              </b-tr>
              <b-tr v-if="limit < dataExcel.length">
                <b-td :colspan="dataExcel[0].length">
                  <b-button
                    variant="info"
                    style="width:60%"
                    @click="limit += 10"
                  >
                    Load more
                  </b-button>
                  <b-button
                    variant="primary"
                    style="width:40%"
                    @click="limit = dataExcel.length"
                  >
                    Load All
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';
import { loading_start, loading_close } from '@/utils/loading.js';
import Excel from 'exceljs';
import draggable from 'vuedraggable';
import Swal from 'sweetalert2';

export default {
  mixins: [employeeMixin],
  components: {
    draggable,
  },
  data() {
    return {
      file_import: null,
      branches: null,
      available_years: [],
      available_months: [],
      available_list: [],
      // available_branches: [],
      fromdate: null,
      todate: null,
      years: moment().year(),
      months: null,
      exporttype: 'time',
      stafftype: 'all',
      status_emp: 'all',
      disabledToDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      permissionsList: [],
      employee_id: null,
      branchs: {},
      isParams: false,
      profile: null,
      query: null,
      list_worksheet: [{ value: null, text: 'Please select an Sheet' }],
      selectedSheet: null,
      from_date: null,
      to_date: null,
      // drag & drop
      columnList: [],
      headeritems: [],
      headerList: [],
      drag: false,
      workbook: {},
      dataExcel: [],
      limit: 15,
      defaultExcel: null,
      removeHeader: false,
      removeHeaderCsv: false,
      dataArray: [],
      dataArray_ins: [],
      check_file_exl: false,
      check_file_csv: false,
    };
  },

  async created() {
    loading_start();
    let type = 1;
    this.headeritems = await this.getImportColumnList(type);
    this.headerList = this.headeritems.map((val, index) => {
      return val;
    });
    this.profile = await this.getProfileData();
    loading_close();
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },

  props: {
    permissions: {
      type: Array,
      default: () => {},
    },
    available_branches: {
      type: Array,
      default: () => {},
    },
  },

  watch: {
    branches: async function(bch_id) {
      if (bch_id) {
        this.genListYear(bch_id);
      }
    },
    years: async function(years) {
      if (years) {
        this.genListMonth(this.branches, years);
      } else {
        this.available_months = [];
        this.months = '';
      }
    },
    selectedSheet: function() {
      this.getSheet();
    },
    removeHeader: function() {
      this.getSheet();
    },
    removeHeaderCsv: function() {
      this.getSheetCsv();
    },
  },

  methods: {
    getSheet() {
      let temp_row = [];
      this.columnList = [];
      this.dataExcel = [];
      if (this.selectedSheet == null) {
        return;
      }
      let select = this.selectedSheet;
      this.workbook.worksheets[select].eachRow((row, rownum) => {
        if (rownum > 8000) {
          return;
        }
        if (
          (this.removeHeader == true && rownum > 1) ||
          this.removeHeader == false
        ) {
          let temp_col = [];
          row.eachCell({ includeEmpty: true }, (cell, colnum) => {
            if (colnum > 51) {
              return;
            }
            if (cell.formula) {
              cell.value = cell.result;
            }
            temp_col.push({ col: colnum, val: cell.value });
          });
          temp_row.push(temp_col);
        }
      });

      let temp_col_list = [];
      let count_col = this.workbook.worksheets[select].columnCount;
      if (count_col > 51) {
        count_col = 51;
      }
      for (let i = 0; i < count_col; i++) {
        let pre = '';
        let str = 65 + i;
        if (i > 25) {
          pre = 'A';
          str = str - 26;
        }
        temp_col_list.push(pre + String.fromCharCode(str));
      }
      this.columnList = temp_col_list;
      this.dataExcel = temp_row;
    },

    getSheetCsv() {
      let temp_row = [];
      let temp_col_list = [];
      this.dataArray.forEach((row, i) => {
        if (
          (this.removeHeaderCsv == true && i > 0) ||
          this.removeHeaderCsv == false
        ) {
          temp_row.push(row);
        }
      });
      let count_col = this.columnList.length;
      if (count_col > 51) {
        count_col = 51;
      }
      for (let i = 0; i < count_col; i++) {
        let pre = '';
        let str = 65 + i;
        if (i > 25) {
          pre = 'A';
          str = str - 26;
        }
        temp_col_list.push(pre + String.fromCharCode(str));
      }
      this.columnList = temp_col_list;
      this.dataExcel = temp_row;
    },

    change_check() {
      this.check_file_exl = false;
      this.check_file_csv = false;
    },

    resetHeader() {
      this.headerList = [...this.headeritems];
    },

    addCol() {},

    removeAt(index) {
      if (this.headerList[index].col_name_th == 'รหัสพนักงาน') {
        return;
      }
      this.headerList.splice(index, 1);
    },

    async check_fileType() {
      this.check_file_exl = false;
      this.check_file_csv = false;
      if (this.file_import == null) {
        Swal.fire({
          icon: 'warning',
          title: 'โปรดเลือกไฟล์ / \n Please Choose a file',
        });
        return;
      }
      if (
        this.file_import.type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.check_file_exl = true;
        this.readFile();
      } else if (this.file_import.type == 'text/csv') {
        this.check_file_csv = true;
        this.readfile_csv();
      }
    },

    async readFile() {
      if (
        this.file_import.type !=
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'ประเภทไฟล์อาจไม่ถูกต้อง',
        });
        return;
      }
      loading_start();

      try {
        this.limit = 10;
        var workbook = new Excel.Workbook();

        this.workbook = null;

        let options = {
          ignoreNodes: [
            'dataValidations', // ignores the workbook's Data Validations
            'autoFilter',
            'picture',
          ],
        };

        this.workbook = await workbook.xlsx
          .load(this.file_import, options)
          .catch((err) => {
            Swal.fire({
              icon: 'warning',
              title: 'ไม่สามารถอ่านไฟล์นี้ได้',
            });
            throw err;
          });
      } catch (error) {
        if (
          error instanceof RangeError &&
          error.message.includes('out of memory')
        ) {
          // Handle out-of-memory error
          Swal.fire({
            icon: 'warning',
            title: 'Out of Memory: Please reload the page or try again later',
          });
          // alert('Out of Memory: Please reload the page or try again later.');
        } else {
          // Handle other types of errors
          Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถอ่านไฟล์นี้ได้',
          });
        }
        loading_close();
      }
      let list_worksheet = [];

      workbook.worksheets.forEach((el, index) => {
        list_worksheet.push({
          value: index,
          text: workbook.getWorksheet(el.id).name,
        });
      });
      this.list_worksheet = [
        { value: null, text: 'Please select an Sheet' },
        ...list_worksheet,
      ];
      loading_close();
    },

    async readfile_csv() {
      if (this.file_import.type != 'text/csv') {
        Swal.fire({
          icon: 'warning',
          title: 'ประเภทไฟล์อาจไม่ถูกต้อง',
        });
        return;
      }

      loading_start();

      let promise = new Promise((resolve, reject) => {
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e) => {
          resolve((vm.fileinput = reader.result));
        };
        reader.readAsText(this.file_import);
      });
      promise.then(
        (result) => {
          let rows = result.split('\n');
          let temp_row = [];
          let temp_row_ins = [];
          rows.forEach((row, i) => {
            let values = row.split(',');
            let formattedValues = values.map((value) =>
              value.replace(/(^"|"$|\r)/g, ''),
            );
            if (i == 0) {
              this.columnList = formattedValues;
            }
            let arr_new = [];
            formattedValues.forEach((arr, i) => {
              let item = {
                col: i + 1,
                val: arr,
              };
              arr_new.push(item);
            });
            temp_row_ins.push(formattedValues);
            temp_row.push(arr_new);
          });
          this.dataArray = temp_row;
          this.dataArray_ins = temp_row_ins;
          this.getSheetCsv();
        },
        (error) => {
          // console.log(error);
        },
      );
      loading_close();
    },

    async setdefaultExcel() {
      let columns = [];
      columns = [
        ...this.headerList.map((v) => {
          return { header: v.col_name_th, key: v.id, width: 20 };
        }),
      ];
      const workbook = new Excel.Workbook();
      const sheet = workbook.addWorksheet('Sheet');
      sheet.columns = columns;
      const buffer = await workbook.xlsx.writeBuffer();
      this.downloadBlob(
        buffer,
        'file.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      );
    },

    downloadBlob(data, fileName, mimeType) {
      var blob, url;

      blob = new Blob([data], {
        type: mimeType,
      });

      url = window.URL.createObjectURL(blob);
      let downloadURL = (data, fileName) => {
        var a = document.createElement('a');
        a.href = data;
        a.download = fileName;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        a.remove();
      };
      downloadURL(url, fileName);

      setTimeout(function() {
        return window.URL.revokeObjectURL(url);
      }, 1500);
    },

    async getImportColumnList(type) {
      const res = await this.$store.dispatch('adjunct/importColumnlist', {
        type: type,
      });
      return res;
    },

    async importFileIncomeCost() {
      if (this.from_date == null || this.to_date == null) {
        await Swal.fire({
          icon: 'warning',
          title: 'Please select an Date',
        });
        setTimeout(() => {
          let el = this.$refs.fromdate.$el;
          el.scrollIntoView(false);
        }, 500);
        return;
      }
      if (this.file_import == null) {
        await Swal.fire({
          icon: 'warning',
          title: 'Please Choose a file',
        });
        setTimeout(() => {
          let el = this.$refs.file.$el;
          el.scrollIntoView(false);
        }, 500);
        return;
      }
      if (this.selectedSheet == null) {
        await Swal.fire({
          icon: 'warning',
          title: 'Please select an Sheet',
        });
        setTimeout(() => {
          let el = this.$refs.file.$el;
          el.scrollIntoView(false);
        }, 500);
        return;
      }

      let select = this.selectedSheet;
      let count_col = this.workbook.worksheets[select].columnCount;
      if (this.headerList.length != count_col) {
        await Swal.fire({
          icon: 'warning',
          title: 'กรุณาเลือกให้หัวข้อเท่ากันกับหัวข้อไฟล์',
        });
        return;
      }

      let temp = this.workbook.worksheets[this.selectedSheet];
      let temp_row = [];
      temp.eachRow((row, rownum) => {
        if (
          (this.removeHeader == true && rownum > 1) ||
          this.removeHeader == false
        ) {
          let temp_col = [];
          row.eachCell({ includeEmpty: true }, (cell, colnum) => {
            temp_col.push(cell.value);
          });
          temp_row.push(temp_col);
        }
      });

      let params = {
        fromdate: this.from_date,
        todate: this.to_date,
        col: this.headerList,
        rows: temp_row,
      };

      try {
        let result = await this.$store.dispatch(
          'adjunct/importIncomeCost',
          params,
        );
        if (result['nonemp'] != '') {
          await Swal.fire({
            icon: 'success',
            title: 'Import File Complete',
            text:
              moment(params.fromdate).format('DD/MM/YYYY') +
              ' - ' +
              moment(params.todate).format('DD/MM/YYYY') +
              '\n' +
              'ไม่มีรหัสพนักงานนี้ในระบบ ' +
              result['nonemp'],
          });
        } else {
          await Swal.fire({
            icon: 'success',
            title: 'Import File Complete',
            text:
              moment(params.fromdate).format('DD/MM/YYYY') +
              ' - ' +
              moment(params.todate).format('DD/MM/YYYY'),
          });
        }
      } catch (error) {
        await Swal.fire({
          icon: 'error',
          title: 'Import File Fail',
          text:
            moment(params.fromdate).format('DD/MM/YYYY') +
            ' - ' +
            moment(params.to_date).format('DD/MM/YYYY'),
        });
      }
    },

    async importFileIncomeCostCsv() {
      let temp_row = [];
      if (this.from_date == null || this.to_date == null) {
        await Swal.fire({
          icon: 'warning',
          title: 'Please select an Date',
        });
        setTimeout(() => {
          let el = this.$refs.fromdate.$el;
          el.scrollIntoView(false);
        }, 500);
        return;
      }
      if (this.file_import == null) {
        await Swal.fire({
          icon: 'warning',
          title: 'Please Choose a file',
        });
        setTimeout(() => {
          let el = this.$refs.file.$el;
          el.scrollIntoView(false);
        }, 500);
        return;
      }

      let count_col = this.columnList.length;
      if (this.headerList.length != count_col) {
        await Swal.fire({
          icon: 'warning',
          title: 'กรุณาเลือกให้หัวข้อเท่ากันกับหัวข้อไฟล์',
        });
        return;
      }

      this.dataArray_ins.forEach((row, i) => {
        if (
          (this.removeHeaderCsv == true && i > 0) ||
          this.removeHeaderCsv == false
        ) {
          temp_row.push(row);
        }
      });

      let params = {
        fromdate: this.from_date,
        todate: this.to_date,
        col: this.headerList,
        rows: temp_row,
      };

      try {
        let result = await this.$store.dispatch(
          'adjunct/importIncomeCost',
          params,
        );
        if (result['nonemp'] != '') {
          await Swal.fire({
            icon: 'success',
            title: 'Import File Complete',
            text:
              moment(params.fromdate).format('DD/MM/YYYY') +
              ' - ' +
              moment(params.todate).format('DD/MM/YYYY') +
              '\n' +
              'ไม่มีรหัสพนักงานนี้ในระบบ ' +
              result['nonemp'],
          });
        } else {
          await Swal.fire({
            icon: 'success',
            title: 'Import File Complete',
            text:
              moment(params.fromdate).format('DD/MM/YYYY') +
              ' - ' +
              moment(params.todate).format('DD/MM/YYYY'),
          });
        }
      } catch (error) {
        await Swal.fire({
          icon: 'error',
          title: 'Import File Fail',
          text:
            moment(params.fromdate).format('DD/MM/YYYY') +
            ' - ' +
            moment(params.todate).format('DD/MM/YYYY'),
        });
      }
    },

    async getReportsalaryList() {
      this.available_list = await this.$store.dispatch(
        'exports/reportsalarylist',
      );
    },

    genListYear(bch_id) {
      this.available_years = [];
      if (bch_id && this.available_list[bch_id]) {
        for (const [key, value] of Object.entries(
          this.available_list[bch_id],
        )) {
          this.available_years.push(key);
        }
      }
      this.years = this.available_years[0];
      return Promise.all(this.available_years);
    },

    genListMonth(bch_id, years) {
      this.available_months = [];
      for (const [key, value] of Object.entries(
        this.available_list[bch_id][years],
      )) {
        this.available_months.push(key);
      }
      this.months = this.available_months[0];
      // this.available_months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      return Promise.all(this.available_months);
    },

    getDataExport() {
      if (this.branches <= 0) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสำนักงาน');
        return false;
      }

      if (this.exporttype == 'exportapprover') {
      } else if (this.exporttype == 'exportsalary') {
        if (this.years == null) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกปี');
          return false;
        }
        if (this.months == null) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกเดือน');
          return false;
        }
      } else {
        if (this.fromdate == null) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกวันที่เริ่ม');
          return false;
        }
        if (this.todate == null) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกวันที่สิ้นสุด');
          return false;
        }
      }

      let params = {
        ...{ bch_id: this.branches },
        ...{ fromdate: moment(this.fromdate).format('YYYY-MM-DD') },
        ...{ todate: moment(this.todate).format('YYYY-MM-DD') },
        ...{ rp_id: this.rp_id ? this.rp_id : {} },
        ...{ emp_id: null },
        ...{ years: this.years },
        ...{
          months: this.months,
        },
        ...{ stafftype: this.stafftype == 'all' ? '' : this.stafftype },
        ...{ status: this.status_emp == 'all' ? '' : this.status_emp },
        ...{ t: moment().unix() },
      };

      switch (this.exporttype) {
        case 'exporttime':
          this.$store.dispatch('exports/exporttime', params);
          break;
        case 'exportot':
          this.$store.dispatch('exports/exportot', params);
          break;
        case 'exportotboat':
          this.$store.dispatch('exports/exportotboat', params);
          break;
        case 'exportotspa':
          this.$store.dispatch('exports/exportotspa', params);
          break;
        case 'exporflatrateShow':
          this.$store.dispatch('exports/exporflatrateShow', params);
          break;
        case 'exportleave':
          this.$store.dispatch('exports/exportleave', params);
          break;
        case 'exportapprover':
          this.$store.dispatch('exports/exportapprover', params);
          break;
        case 'exportsalary':
          this.$store.dispatch('exports/exportsalary', params);
          break;
      }
    },

    /**
     * set branch value from child component
     */
    setBranches(v) {
      // this.branches = v;
      this.branch_id = v;
    },

    /**
     * set employee id value from child component
     */
    setDepartment(v) {
      if (v) {
        this.departments_id = v;
      }
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employeeID = v.id;
      } else {
        this.staffs = [];
        this.employeeID = null;
      }
    },
  },
};
</script>
<style lang="scss">
.tab-pane {
  width: 100%;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
</style>
