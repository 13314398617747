<template>
  <div class="md-layout">
    <md-card-header></md-card-header>
    <md-card-content>
      <div class="md-layout spacing md-alignment-left">
        <div
          class="md-layout-item md-size-25 md-medium-size-30 md-small-size-40 md-xsmall-size-100"
        >
          <!-- <div>สำนักงาน</div> -->
          <md-field class="searchbranchs">
            <label for="branchs">สำนักงาน</label>
            <md-select v-model="branches" name="branch_id">
              <md-option
                v-for="item in available_branches"
                :key="item.bch_id"
                :value="item.bch_id"
              >
                {{ item.brname_th }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-25 md-medium-size-30 md-small-size-40 md-xsmall-size-100"
        >
          <md-field>
            <label for="branchs">ประเภทพนักงาน</label>
            <md-select v-model="stafftype" name="stafftype">
              <md-option value="all">ทั้งหมด</md-option>
              <md-option value="month">รายเดือน</md-option>
              <md-option value="day">รายวัน</md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout md-layout-item md-size-25 md-medium-size-30 md-small-size-40 md-xsmall-size-100"
        >
          <!-- <div>ประเภทข้อมูล</div> -->
          <md-field>
            <label for="exporttype">ประเภทข้อมูล</label>
            <md-select v-model="exporttype">
              <!-- <md-option value=""></md-option> -->
              <md-option value="exporttime">Time</md-option>
              <md-option value="exportot">OT</md-option>
              <md-option value="exportotboat">เที่ยวเรือ</md-option>
              <md-option value="exportotspa">นวดสปา</md-option>
              <md-option value="exporflatrateShow">การแสดง</md-option>
              <md-option value="exportleave">Leave</md-option>
              <md-option value="exportsalary">ReportSalary</md-option>
              <md-option value="exportapprover">การอนุมัติ</md-option>
            </md-select>
          </md-field>
        </div>
        <!-- Date -->
        <!-- <div
          class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-0"
        ></div> -->
        <div
          v-if="exporttype == 'exportsalary'"
          class="md-layout md-layout-item md-size-100 md-alignment-center-center"
        >
          <div
            class="md-layout md-layout-item md-size-100 md-alignment-center-center"
            v-if="branches"
          >
            <div
              class="md-layout md-layout-item md-size-100 md-alignment-center-center"
              v-if="available_years.length"
            >
              <div
                class="md-layout md-layout-item md-size-25 md-medium-size-30 md-small-size-35 md-xsmall-size-100 md-alignment-center-center"
              >
                <md-field>
                  <label>ปี</label>
                  <md-select v-model="years">
                    <md-option v-for="y in available_years" :key="y" :value="y">
                      {{ y }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div
                class="md-layout md-layout-item md-size-25 md-medium-size-30 md-small-size-35 md-xsmall-size-100 md-alignment-center-center"
              >
                <md-field>
                  <label>เดือน</label>
                  <md-select v-model="months">
                    <md-option
                      v-for="mo in available_months"
                      :key="mo"
                      :value="mo"
                    >
                      {{ mo }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div v-else>ไม่มีข้อมูลให้เลือก</div>
          </div>
          <div v-else>
            กรุณาเลือกสาขา
          </div>
        </div>
        <div
          v-else-if="exporttype == 'exportapprover'"
          class="md-layout md-layout-item md-size-25 md-medium-size-30 md-small-size-35 md-xsmall-size-100 md-alignment-center-center"
        >
          <md-field>
            <label for="branchs">ประเภทพนักงาน</label>
            <md-select v-model="status_emp" name="status_emp">
              <md-option value="all">ทั้งหมด</md-option>
              <md-option value="active">ทำงาน</md-option>
              <md-option value="inactive">ลาออกแล้ว</md-option>
            </md-select>
          </md-field>
        </div>
        <div
          v-else
          class="md-layout md-layout-item md-size-100 md-alignment-center-center"
        >
          <div
            class="md-layout md-layout-item md-size-25 md-medium-size-30 md-small-size-35 md-xsmall-size-100 md-alignment-center-center"
          >
            <md-datepicker v-model="fromdate" md-immediately>
              <label for="fromdate">วันที่เริ่ม</label>
            </md-datepicker>
          </div>
          <div
            class="md-layout md-layout-item md-size-25 md-medium-size-30 md-small-size-35 md-xsmall-size-100 md-alignment-center-center"
          >
            <md-datepicker
              v-model="todate"
              md-immediately
              :md-disabled-dates="disabledToDates"
            >
              <label for="todate">วันที่สิ้นสุด</label>
            </md-datepicker>
          </div>
        </div>
        <div class="md-layout-item md-size-10">
          <md-progress-spinner
            :md-diameter="50"
            :md-stroke="3"
            md-mode="indeterminate"
            :md-value="100"
            v-show="false"
          >
          </md-progress-spinner>
        </div>
        <div
          class="md-layout spacing md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 md-alignment-center-center"
        >
          <md-button class="md-info" @click="getDataExport">Export</md-button>
        </div>
      </div>
    </md-card-content>
  </div>
</template>
<script>
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  mixins: [employeeMixin],
  components: {},

  data() {
    return {
      branches: null,
      available_years: [],
      available_months: [],
      available_list: [],
      // available_branches: [],
      fromdate: null,
      todate: null,
      years: moment().year(),
      months: null,
      exporttype: 'time',
      stafftype: 'all',
      status_emp: 'all',
      disabledToDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      permissionsList: [],
      employee_id: null,
      branchs: {},
      isParams: false,
      profile: null,
      query: null,
    };
  },
  created() {
    this.getProfileData().then(async (res) => {
      this.profile = res;
      loading_start();
      await this.getReportsalaryList();
      loading_close();
    });
  },
  props: {
    available_branches: {
      type: Array,
      default: () => {},
    },
  },
  watch: {
    branches: async function(bch_id) {
      if (bch_id) {
        this.genListYear(bch_id);
      }
    },
    years: async function(years) {
      if (years) {
        this.genListMonth(this.branches, years);
      } else {
        this.available_months = [];
        this.months = '';
      }
    },
  },
  methods: {
    async getReportsalaryList() {
      this.available_list = await this.$store.dispatch(
        'exports/reportsalarylist',
      );
    },
    genListYear(bch_id) {
      this.available_years = [];
      if (bch_id && this.available_list[bch_id]) {
        for (const [key, value] of Object.entries(
          this.available_list[bch_id],
        )) {
          this.available_years.push(key);
        }
      }
      this.years = this.available_years[0];
      return Promise.all(this.available_years);
    },
    genListMonth(bch_id, years) {
      this.available_months = [];
      for (const [key, value] of Object.entries(
        this.available_list[bch_id][years],
      )) {
        this.available_months.push(key);
      }
      this.months = this.available_months[0];
      // this.available_months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      return Promise.all(this.available_months);
    },
    getDataExport() {
      if (this.branches <= 0) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสำนักงาน');
        return false;
      }

      if (this.exporttype == 'exportapprover') {
      } else if (this.exporttype == 'exportsalary') {
        if (this.years == null) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกปี');
          return false;
        }
        if (this.months == null) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกเดือน');
          return false;
        }
      } else {
        if (this.fromdate == null) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกวันที่เริ่ม');
          return false;
        }
        if (this.todate == null) {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกวันที่สิ้นสุด');
          return false;
        }
      }

      let params = {
        ...{ bch_id: this.branches },
        ...{ fromdate: moment(this.fromdate).format('YYYY-MM-DD') },
        ...{ todate: moment(this.todate).format('YYYY-MM-DD') },
        ...{ rp_id: this.rp_id ? this.rp_id : {} },
        ...{ emp_id: null },
        ...{ years: this.years },
        ...{
          months: this.months,
        },
        ...{ stafftype: this.stafftype == 'all' ? '' : this.stafftype },
        ...{ status: this.status_emp == 'all' ? '' : this.status_emp },
        ...{ t: moment().unix() },
      };

      switch (this.exporttype) {
        case 'exporttime':
          this.$store.dispatch('exports/exporttime', params);
          break;
        case 'exportot':
          this.$store.dispatch('exports/exportot', params);
          break;
        case 'exportotboat':
          this.$store.dispatch('exports/exportotboat', params);
          break;
        case 'exportotspa':
          this.$store.dispatch('exports/exportotspa', params);
          break;
        case 'exporflatrateShow':
          this.$store.dispatch('exports/exporflatrateShow', params);
          break;
        case 'exportleave':
          this.$store.dispatch('exports/exportleave', params);
          break;
        case 'exportapprover':
          this.$store.dispatch('exports/exportapprover', params);
          break;
        case 'exportsalary':
          this.$store.dispatch('exports/exportsalary', params);
          break;
      }
    },

    /**
     * set branch value from child component
     */
    setBranches(v) {
      // this.branches = v;
      this.branch_id = v;
    },

    /**
     * set employee id value from child component
     */
    setDepartment(v) {
      if (v) {
        this.departments_id = v;
      }
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employeeID = v.id;
      } else {
        this.staffs = [];
        this.employeeID = null;
      }
    },
  },
};
</script>
<style lang="scss">
.tab-pane {
  width: 100%;
}
</style>
