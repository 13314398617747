import service from '@/store/services/workingtimes-service';

const state = {
  list: {},
  workingtime: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, workingtime) => {
    state.workingtime = workingtime;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      commit('SET_META', meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((workingtime) => {
      commit('SET_RESOURCE', workingtime);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((workingtime) => {
      commit('SET_RESOURCE', workingtime);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((workingtime) => {
      commit('SET_RESOURCE', workingtime);
    });
  },

  // destroy({ commit, dispatch }, params) {
  // 	return service.destroy(params);
  // },
};

const getters = {
  list: (state) => state.list,
  //listTotal: (state) => state.meta.page.total,
  workingtime: (state) => state.workingtime,
  dropdown: (state) => {
    return state.list.map((workingtime) => ({
      id: workingtime.id,
      name: workingtime.code + ' ( ' + workingtime.working_name + ' )',
    }));
  },
};

const workingtimes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default workingtimes;
