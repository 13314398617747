import service from '@/store/services/registers-service';

const state = {
  list: {},
  staff: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, staff) => {
    state.staff = staff;
  },
};

const actions = {
  firstpassword({ commit, dispatch }, params) {
    return service.firstpassword(params).then((staff) => {
      commit('SET_RESOURCE', staff);
    });
  },
};

const getters = {};

const registers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default registers;
