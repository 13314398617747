<template>
  <div>
    <md-dialog
      class="edit_OT"
      :md-active.sync="showedit_OT"
      style="min-width: 70%;"
      md-fullscreen
    >
      <md-dialog-content>
        <div class="md-layout-item md-size-100">
          <ValidationObserver>
            <form @submit.prevent="Update_OT(data_editOT)">
              <md-card>
                <div>
                  <md-card-header
                    class="md-card-header-icon md-card-header-warning"
                  >
                    <div class="card-icon">
                      <md-icon>edit</md-icon>
                    </div>
                    <h4 class="title">
                      แก้ไข ขอค่านวดสปา (Request Spa fee)
                    </h4>
                  </md-card-header>
                </div>
                <md-card-content>
                  <div class="md-layout md-alignment-left-left spacing">
                    <!-- type massage -->
                    <div
                      class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                    >
                      <ValidationProvider
                        name="typeintype"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed },
                          ]"
                        >
                          <label for="ot">เลือกประเภทสปา</label>
                          <md-select
                            v-model="typeintype"
                            @input="getTreatment(typeintype)"
                          >
                            <md-option
                              v-for="item in available_typeots"
                              :key="item.id"
                              :value="item.id"
                            >
                              {{ item.otType_name }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <!-- treatment -->
                    <div
                      class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                    >
                      <ValidationProvider
                        name="treatment"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed },
                          ]"
                        >
                          <label for="ot">เลือกทรีทเม้นท์</label>
                          <md-select
                            v-model="treatment"
                            :disabled="disabled_treatment"
                          >
                            <md-option
                              v-for="item in available_treatment"
                              :key="item.treatment_id"
                              :value="item.treatment_id"
                            >
                              {{ item.treatment_name }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div
                      class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                    >
                      <md-field>
                        <label>เลขที่บิล</label>
                        <md-input v-model="billNo"></md-input>
                      </md-field>
                    </div>
                    <div
                      class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                    >
                      <md-field>
                        <label>เลขที่ห้อง</label>
                        <md-input v-model="roomNo"></md-input>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout md-alignment-center-center spacing">
                    <div
                      class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                    >
                      <!-- filter Date -->
                      <!-- <ValidationProvider name="fromdate" rules="required"> -->
                      <ValidationProvider
                        name="fromdate"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <md-datepicker
                          v-model="fromdate"
                          md-immediately
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed },
                          ]"
                          :md-disabled-dates="disabledFromDates"
                        >
                          <label>วันที่เริ่ม</label>
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed"
                              >done</md-icon
                            >
                          </slide-y-down-transition>
                        </md-datepicker>
                      </ValidationProvider>
                    </div>
                    <div
                      class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                    >
                      <ValidationProvider
                        name="time_fromdate"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed },
                          ]"
                        >
                          <VueCtkDateTimePicker
                            id="time_fromdate"
                            v-model="time_fromdate"
                            :only-time="true"
                            :noLabel="true"
                            :minute-interval="15"
                            :disable-date="true"
                            :format="'HH:mm'"
                            :formatted="'HH:mm'"
                            :label="'เวลาที่เริ่มทำ'"
                          />
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed"
                              >done</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div
                      class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                    >
                      <!-- filter Date -->
                      <!-- <ValidationProvider name="fromdate" rules="required"> -->
                      <ValidationProvider
                        name="todate"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <md-datepicker
                          v-model="todate"
                          md-immediately
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed },
                          ]"
                          :md-disabled-dates="disabledToDates"
                        >
                          <label>วันที่สิ้นสุด</label>
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed"
                              >done</md-icon
                            >
                          </slide-y-down-transition>
                        </md-datepicker>
                      </ValidationProvider>
                    </div>
                    <div
                      class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                    >
                      <ValidationProvider
                        name="time_todate"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed },
                          ]"
                        >
                          <VueCtkDateTimePicker
                            id="time_todate"
                            v-model="time_todate"
                            :only-time="true"
                            :noLabel="true"
                            :minute-interval="15"
                            :disable-date="true"
                            :format="'HH:mm'"
                            :formatted="'HH:mm'"
                            :label="'เวลาที่สิ้นสุด'"
                          />
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed"
                              >done</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div
                      class="md-layout-item md-size-40 md-medium-size-40 md-small-size-50 md-xsmall-size-100"
                    >
                      <md-field>
                        <label>หมายเหตุ</label>
                        <md-textarea v-model="notes"></md-textarea>
                      </md-field>
                    </div>
                    <div
                      class="md-layout-item md-size-25 md-medium-size-35 md-small-size-50 md-xsmall-size-100"
                      v-if="diff_time.hours > 0 || diff_time.minutes > 0"
                    >
                      <p style="font-size: 16px; padding-top: 15px">
                        เวลาที่ทำ OT {{ diff_time.hours }} ชั่วโมง
                        {{ diff_time.minutes }} นาที
                      </p>
                    </div>
                  </div>
                  <div class="md-layout md-alignment-center spacing">
                    <div
                      class="md-layout-item md-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50 bt_confirm_editOT"
                    >
                      <md-button
                        type="submit"
                        class="md-success editOT_submit"
                        :disabled="disabled_submit"
                      >
                        ยืนยัน
                      </md-button>
                      <md-button
                        type="button"
                        class="md-rose editOT_return"
                        @click="return_editOT()"
                      >
                        ย้อนกลับ
                      </md-button>
                    </div>
                  </div>
                </md-card-content>
              </md-card>
            </form>
          </ValidationObserver>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showedit_OT = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <div class="md-layout show_listEdit">
      <div class="md-layout-item md-size-100" v-if="selected_employee">
        รหัสพนักงาน: {{ selected_employee[0].emp_code }} <br />
        ชื่อพนักงาน: {{ selected_employee[0].title_nameth }}
        {{ selected_employee[0].firstname_th }}
        {{ selected_employee[0].lastname_th }}
      </div>
      <div class="md-layout-item md-size-100">
        <ValidationObserver>
          <form @submit.prevent="submit">
            <md-card>
              <div>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                  <div class="card-icon">
                    <md-icon>update</md-icon>
                  </div>
                  <h4 class="title">
                    ขอค่านวดสปา (Request Spa fee)
                  </h4>
                </md-card-header>
              </div>
              <md-card-content>
                <div class="md-layout md-alignment-left-left spacing">
                  <!-- type massage -->
                  <div
                    class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                  >
                    <ValidationProvider
                      name="typeintype"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <label for="ot">เลือกสถานที่</label>
                        <md-select
                          v-model="typeintype"
                          @input="getTreatment(typeintype)"
                        >
                          <md-option
                            v-for="item in available_typeots"
                            :key="item.id"
                            :value="item.id"
                          >
                            {{ item.otType_name }}
                          </md-option>
                        </md-select>
                      </md-field>
                    </ValidationProvider>
                  </div>
                  <!-- treatment -->
                  <div
                    class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                  >
                    <ValidationProvider
                      name="treatment"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <label for="ot">เลือกทรีทเม้นท์</label>
                        <md-select
                          v-model="treatment"
                          :disabled="disabled_treatment"
                        >
                          <md-option
                            v-for="item in available_treatment"
                            :key="item.treatment_id"
                            :value="item.treatment_id"
                          >
                            {{ item.treatment_name }}
                          </md-option>
                        </md-select>
                      </md-field>
                    </ValidationProvider>
                  </div>
                  <div
                    class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                  >
                    <md-field>
                      <label>เลขที่บิล</label>
                      <md-input v-model="billNo"></md-input>
                    </md-field>
                  </div>
                  <div
                    class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                  >
                    <md-field>
                      <label>เลขที่ห้อง</label>
                      <md-input v-model="roomNo"></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-alignment-center-center spacing">
                  <div
                    class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                  >
                    <!-- filter Date -->
                    <!-- <ValidationProvider name="fromdate" rules="required"> -->
                    <ValidationProvider
                      name="fromdate"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <md-datepicker
                        v-model="fromdate"
                        md-immediately
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                        :md-disabled-dates="disabledFromDates"
                      >
                        <label>วันที่เริ่ม</label>
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed"
                            >done</md-icon
                          >
                        </slide-y-down-transition>
                      </md-datepicker>
                    </ValidationProvider>
                  </div>
                  <div
                    class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                  >
                    <ValidationProvider
                      name="time_fromdate"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <VueCtkDateTimePicker
                          id="time_fromdate"
                          v-model="time_fromdate"
                          :only-time="true"
                          :noLabel="true"
                          :minute-interval="15"
                          :disable-date="true"
                          :format="'HH:mm'"
                          :formatted="'HH:mm'"
                          :label="'เวลาที่เริ่มทำ'"
                        />
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed"
                            >done</md-icon
                          >
                        </slide-y-down-transition>
                      </md-field>
                    </ValidationProvider>
                  </div>
                  <div
                    class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                  >
                    <!-- filter Date -->
                    <!-- <ValidationProvider name="fromdate" rules="required"> -->
                    <ValidationProvider
                      name="todate"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <md-datepicker
                        v-model="todate"
                        md-immediately
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                        :md-disabled-dates="disabledToDates"
                      >
                        <label>วันที่สิ้นสุด</label>
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed"
                            >done</md-icon
                          >
                        </slide-y-down-transition>
                      </md-datepicker>
                    </ValidationProvider>
                  </div>
                  <div
                    class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
                  >
                    <ValidationProvider
                      name="time_todate"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <VueCtkDateTimePicker
                          id="time_todate"
                          v-model="time_todate"
                          :only-time="true"
                          :noLabel="true"
                          :minute-interval="15"
                          :disable-date="true"
                          :format="'HH:mm'"
                          :formatted="'HH:mm'"
                          :label="'เวลาที่สิ้นสุด'"
                        />
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed"
                            >done</md-icon
                          >
                        </slide-y-down-transition>
                      </md-field>
                    </ValidationProvider>
                  </div>
                  <div
                    class="md-layout-item md-size-40 md-medium-size-40 md-small-size-50 md-xsmall-size-100"
                  >
                    <md-field>
                      <label>หมายเหตุ</label>
                      <md-textarea v-model="notes"></md-textarea>
                    </md-field>
                  </div>
                  <div
                    class="md-layout-item md-size-25 md-medium-size-35 md-small-size-50 md-xsmall-size-100"
                    v-if="diff_time.hours > 0 || diff_time.minutes > 0"
                  >
                    <p style="font-size: 16px; padding-top: 15px">
                      เวลาที่ทำ OT {{ diff_time.hours }} ชั่วโมง
                      {{ diff_time.minutes }} นาที
                    </p>
                  </div>
                  <div
                    class="md-layout-item md-size-15 md-medium-size-15 md-small-size-15 md-xsmall-size-15"
                  >
                    <md-button
                      type="submit"
                      class="md-success"
                      :disabled="disabled_submit"
                    >
                      ยืนยัน
                    </md-button>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </form>
        </ValidationObserver>
      </div>
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-blue">
            <div class="card-icon">
              <md-icon>edit_calendar</md-icon>
            </div>
            <h4 class="title">
              ค่านวดสปา (Request Spa fee)
            </h4>
            <h4>
              <b-form-select v-model="year" :options="yearlist"></b-form-select>
            </h4>
          </md-card-header>
          <md-card-content class="grid">
            <b-table
              id="table-staffotlist"
              :fields="fields"
              :items="staffotlist"
              :current-page="currentPage"
              per-page="20"
              head-variant="light"
              responsive
              hover
              show-empty
            >
              <template #cell(fromto_time)="data">
                {{ data.item.from_date_time }} - {{ data.item.to_date_time }}
              </template>
              <!-- <template #cell(approver)="data">
                <div v-if="data.item.approver_firstname">
                  {{ data.item.approver_firstname }}
                  {{ data.item.approver_lastname }}
                </div>
                <div v-else></div>
              </template> -->
              <template #cell(time)="data">
                <div
                  v-if="
                    data.item.hours_time != '' || data.item.minutes_time != ''
                  "
                >
                  {{ data.item.hours_time }} ชั่วโมง
                  {{ data.item.minutes_time }} นาที
                </div>
                <div v-else>
                  {{ data.item.nottime }}
                </div>
              </template>
              <template #cell(detail)="data">
                <div style="text-align: left;">
                  <b>สถานที่ : </b>{{ data.item.detail.ottype_name }}
                </div>
                <div style="text-align: left;">
                  <b>ชื่อทรีทเม้นท์ : </b>{{ data.item.detail.treatment_name }}
                </div>
                <div style="text-align: left;">
                  <b>เลขที่บิล : </b>{{ data.item.detail.bill_no }}
                </div>
                <div style="text-align: left;">
                  <b>เลขห้อง : </b>{{ data.item.detail.room_no }}
                </div>
              </template>
              <template #cell(approve_status)="data">
                <span
                  v-if="data.item.approve_status == 'request'"
                  style="color: blue"
                >
                  รอการอนุมัติ
                </span>
                <span
                  v-else-if="data.item.approve_status == 'approve'"
                  style="color: green"
                >
                  ส่งข้อมูลแล้ว
                </span>
                <span
                  v-else-if="data.item.approve_status == 'disapprove'"
                  style="color: red"
                >
                  ไม่อนุมัติ
                </span>
                <span v-else-if="data.item.approve_status == 'reject'">
                  reject
                  <div style="display: none"><b-button>แก้ไข</b-button></div>
                </span>
                <span v-else></span>
              </template>
              <template #cell(button)="data">
                <md-button
                  class="md-warning md-just-icon md-round"
                  v-if="
                    permissions.includes('process salary') ||
                      permissions.includes('flatratemanage') ||
                      data.item.approve_status == 'request'
                  "
                  @click="edit_OT(data.item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-rose md-just-icon md-round"
                  v-if="
                    permissions.includes('process salary') ||
                      permissions.includes('flatratemanage') ||
                      data.item.approve_status == 'request'
                  "
                  @click="delete_OT(data.item)"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </template>
              <template #empty>
                <center><h4>ไม่มีข้อมูล</h4></center>
              </template>
            </b-table>
            <div class="overflow-auto">
              <b-pagination
                v-if="staffotlist.length > 0"
                v-model="currentPage"
                align="right"
                aria-controls="table-staffotlist"
                :total-rows="staffotlist.length"
                per-page="20"
                size="lg"
                limit="7"
              />
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import Vuex from 'vuex';
import Swal from 'sweetalert2';
// import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';
export default {
  components: {
    VueCtkDateTimePicker: VueCtkDateTimePicker,
    // 'filter-employee': FilterEmployee,
  },
  mixins: [employeeMixin],
  data() {
    return {
      data_editOT: [],
      dialog_edit: false,
      profileMe: null,
      headLabel: 'Request OT',
      currentPage: 1,
      fields: [
        { key: 'from_date_format', label: 'วันที่' },
        { key: 'fromto_time', label: 'ช่วงเวลา' },
        { key: 'time', label: 'ระยะเวลา' },
        { key: 'otname', label: 'ประเภท' },
        { key: 'detail', label: 'รายละเอียด' },
        { key: 'request_date_format', label: 'วันที่ขออนุมัติ' },
        { key: 'approve_status', label: 'สถานะ' },
        // { key: 'approver', label: 'ผู้อนุมัติ' },
        { key: 'notes', label: 'หมายเหตุ' },
        { key: 'button', label: 'action' },
      ],
      ots: [
        {
          id: null,
          type: 'ots',
        },
      ],
      // check_ot: true,
      staffs: [],
      fromdate: null,
      time_fromdate: null,
      todate: null,
      yearlist: [],
      year: moment().year(),
      time_todate: null,
      notes: '',
      diff_time: {
        hours: 0,
        minutes: 0,
      },
      ot_id: 5,
      moment: moment,
      showProgressBar: false,
      // query: null,
      available_typeots: [],
      staffotlist: [],
      disabledFromDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment();
        let datediff = fdate.diff(tdate, 'days');

        return false;
        // return datediff <= -3 || fdate >= tdate.add(7, 'days');
      },
      disabledToDates: (date) => {
        let fdate = moment(date);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate, 'days');
        return datediff > 1 || datediff < 0;
      },
      employee_ot: {
        type: 'employeeotsflatrate',
        employee_id: null,
        from_date: null,
        to_date: null,
        notes: '',
      },
      data_updateOT: {
        type: 'employeeotsflatrate',
        from_date: null,
        to_date: null,
        notes: '',
        update_id: null,
        employee_id: null,
      },
      disabled_submit: false,
      permissions: [],
      selected_employee: null,
      typeintype: '',
      billNo: '',
      roomNo: '',
      available_treatment: [],
      treatment: '',
      disabled_treatment: true,
      showedit_OT: false,
    };
  },

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  props: {
    persons: {
      type: Number,
      default: 0,
    },
    typecreate: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    persons: async function(id) {
      this.start();
      if (id) {
        loading_start();
        let params = {
          ...{ emp_id: id },
        };
        this.selected_employee = await this.$store.dispatch(
          'staffs/searchStaff',
          params,
        );
        await this.Get_Staffotlist(this.selected_employee[0].emp_id);
        loading_close();
      } else {
        this.fail();
      }
      this.finish();
    },
    fromdate(val) {
      if (this.todate == null) {
        this.todate = val;
      }
      if (moment(this.todate).isBefore(val)) {
        this.todate = val;
      }
      if (moment(val).diff(moment(this.todate), 'days') <= -2) {
        this.todate = val;
      }
      this.diff_Time();
    },
    todate() {
      this.diff_Time();
    },
    time_fromdate() {
      this.diff_Time();
    },
    time_todate() {
      this.diff_Time();
    },
    async year(v) {
      if (this.persons) {
        await this.Get_Staffotlist(this.selected_employee[0].emp_id);
      } else {
        await this.Get_Staffotlist(this.me.employee_id);
      }
    },
    typeintype: function() {
      this.treatment = null;
    },
  },
  async created() {
    this.start();
    loading_start();
    if (this.persons) {
      this.selected_employee = await this.getProfileData(this.persons);
      await this.Get_Staffotlist(this.selected_employee[0].emp_id);
      this.yearList(moment(this.selected_employee[0].start_date).year());
    } else {
      let com_id = 1;
      //this.getOTType(com_id);
      await this.getProfileData();
      await this.Get_Staffotlist(this.me.employee_id);
      this.yearList(moment(this.me.employees.start_date).year());
    }
    this.setPermission(this.me);
    this.show_input();
    loading_close();
    this.finish();
  },
  methods: {
    async edit_OT(v) {
      this.treatment = v.treatment_id;
      this.typeintype = v.type_ot;
      this.billNo = v.bill_no;
      this.roomNo = v.room_no;
      this.fromdate = moment(v.from_date).format('DD/MM/YYYY');
      this.todate = moment(v.to_date).format('DD/MM/YYYY');
      this.time_fromdate = v.from_date_time;
      this.time_todate = v.to_date_time;
      this.notes = v.notes;
      this.data_editOT = v;
      this.showedit_OT = true;
    },
    async return_editOT() {
      $('div.edit_OT').css('display', 'none');
      $('div.show_listEdit').css('display', '');
      this.fromdate = null;
      this.todate = null;
      this.time_fromdate = null;
      this.time_todate = null;
      this.notes = '';
    },
    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },
    async Get_Staffotlist(id) {
      let params = {
        ...{ year: this.year },
        ...{ emp_id: id },
        ...{ ot_id: [5] },
      };
      await this.$store.dispatch('otsflatrate/staffotlist', params);
      const staffotlist = this.$store.getters['otsflatrate/staffotlist'];
      if (staffotlist) {
        this.staffotlist = staffotlist.map((val) => {
          let fromdate = moment(val.from_date).format('DD/MM/YYYY');
          let requestdate = moment(val.requestdate).format('DD/MM/YYYY');
          let fromdate_time = moment(val.from_date);
          let todate_time = moment(val.to_date);
          var datediff_hours = todate_time.diff(fromdate_time, 'hours');
          var datediff_minutes = todate_time.diff(fromdate_time, 'minutes');
          val.detail = {
            ottype_name: val.ottype_name,
            treatment_name: val.treatment_name,
            bill_no: val.bill_no,
            room_no: val.room_no,
          };
          val.from_date_time = moment(val.from_date).format('HH:mm');
          val.to_date_time = moment(val.to_date).format('HH:mm');
          val.hours_time = datediff_hours;
          val.minutes_time = datediff_minutes % 60;
          val.from_date_format = fromdate;
          val.request_date_format = requestdate;
          val.ot_active = val.ot_active;
          if (val.notes == 'null') {
            val.notes = '-';
          } else {
            val.notes = val.notes;
          }
          return val;
        });
      } else {
        this.staffotlist = [];
      }
    },
    yearList(yearstart) {
      let yearnow = moment().year();
      for (let index = yearstart; index <= yearnow; index++) {
        this.yearlist.push(index);
      }
    },

    diff_Time() {
      if (this.fromdate && this.time_fromdate && this.time_todate) {
        let fromdate = moment(this.fromdate).format('YYYY-MM-DD');
        let todate = moment(this.todate).format('YYYY-MM-DD');

        let fromdate_time = moment(fromdate + ' ' + this.time_fromdate);
        let todate_time = moment(todate + ' ' + this.time_todate);
        var datediff_hours = todate_time.diff(fromdate_time, 'hours');
        var datediff_minutes = todate_time.diff(fromdate_time, 'minutes');
      }

      if (datediff_hours >= 0 || datediff_minutes >= 0) {
        this.diff_time.hours = datediff_hours;
        this.diff_time.minutes = datediff_minutes % 60;
      }
    },

    async submit() {
      if (!this.typeintype) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสถานที่');
        return false;
      }
      if (!this.treatment) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกทรีทเม้นท์');
        return false;
      }
      if (!this.billNo) {
        this.$store.dispatch('alerts/error', 'กรุณาระบุเลขบิล');
        return false;
      }
      if (!this.roomNo) {
        this.$store.dispatch('alerts/error', 'กรุณาระบุหมายเลขห้อง');
        return false;
      }

      if (moment(this.fromdate) != null) {
        var fromdate = moment(this.fromdate).format('YYYY-MM-DD');
      } else {
        return false;
      }

      if (moment(this.todate) != null) {
        var todate = moment(this.todate).format('YYYY-MM-DD');
      } else {
        return false;
      }

      let fromdate_time = moment(fromdate + ' ' + this.time_fromdate).format(
        'YYYY-MM-DD HH:mm:ss',
      );
      let todate_time = moment(todate + ' ' + this.time_todate).format(
        'YYYY-MM-DD HH:mm:ss',
      );

      if (!moment(fromdate_time).isBefore(todate_time)) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกเวลาให้ถูกต้อง');
        return false;
      }

      this.employee_ot.ot_treatment_id = this.treatment;
      this.employee_ot.bill_no = this.billNo;
      this.employee_ot.room_no = this.roomNo;
      this.employee_ot.ot_id = this.ot_id;
      this.employee_ot.type_ot = this.typeintype;
      this.employee_ot.ot_active = 1;
      this.employee_ot.from_date = fromdate_time;
      this.employee_ot.to_date = todate_time;
      this.employee_ot.approve_status = 'request';
      this.employee_ot.requestdate = moment().format('YYYY-MM-DD');
      if (this.notes) {
        this.employee_ot.notes = this.notes;
      } else if (this.notes == '') {
        this.employee_ot.notes = '-';
      }
      try {
        if (this.typecreate == 0) {
          if (!this.me.employee_id) {
            await this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
            return false;
          }
          this.employee_ot.employee_id = this.me.employee_id;
          await this.$store.dispatch(
            'otsflatrate/addStaffOTflatReatApprove',
            this.employee_ot,
          );
        } else if (this.typecreate == 1) {
          if (!this.selected_employee[0].emp_id) {
            await this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
            return false;
          }
          this.employee_ot.employee_id = this.selected_employee[0].emp_id;
          await this.$store.dispatch(
            'otsflatrate/addStaffOTflatReatApprove',
            this.employee_ot,
          );
        }
        this.$store.dispatch('alerts/success', 'สำเร็จเรียบร้อยแล้ว');
        this.Get_Staffotlist(this.employee_ot.employee_id);
        this.typeintype = '';
        this.notes = '';
        this.showProgressBar = true;
        // this.check_ot == true;
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch('alerts/error', e.response.data.errors[0]);
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },

    async Update_OT(v) {
      if (!this.typeintype) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสถานที่');
        return false;
      }
      if (!this.treatment) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกทรีทเม้นท์');
        return false;
      }
      if (!this.billNo) {
        this.$store.dispatch('alerts/error', 'กรุณาระบุเลขบิล');
        return false;
      }
      if (!this.roomNo) {
        this.$store.dispatch('alerts/error', 'กรุณาระบุหมายเลขห้อง');
        return false;
      }

      if (moment(this.fromdate) != null) {
        var fromdate = moment(this.fromdate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } else {
        return false;
      }

      if (moment(this.todate) != null) {
        var todate = moment(this.todate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } else {
        return false;
      }

      let fromdate_time = moment(fromdate + ' ' + this.time_fromdate).format(
        'YYYY-MM-DD HH:mm:ss',
      );
      let todate_time = moment(todate + ' ' + this.time_todate).format(
        'YYYY-MM-DD HH:mm:ss',
      );

      if (!moment(fromdate_time).isBefore(todate_time)) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกเวลาให้ถูกต้อง');
        return false;
      }

      this.data_updateOT.ot_treatment_id = this.treatment;
      this.data_updateOT.bill_no = this.billNo;
      this.data_updateOT.room_no = this.roomNo;
      this.data_updateOT.ot_id = Number(this.ot_id);
      this.data_updateOT.type_ot = Number(this.typeintype);
      this.data_updateOT.unit_type_ot = Number(v.unit_type_ot);
      this.data_updateOT.ot_active = Number(v.ot_active);
      this.data_updateOT.employee_id = Number(v.employee_id);
      this.data_updateOT.from_date = fromdate_time;
      this.data_updateOT.to_date = todate_time;
      this.data_updateOT.notes = this.notes;
      this.data_updateOT.update_id = v.id;
      this.data_updateOT.approve_status = v.approve_status;
      this.data_updateOT.requestdate = moment().format('YYYY-MM-DD');
      let resp = await this.$store.dispatch(
        'otsflatrate/updateOT',
        this.data_updateOT,
      );
      if (resp == 0) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        return false;
      } else {
        await this.$store.dispatch('alerts/success', 'สำเร็จเรียบร้อยแล้ว');
        this.Get_Staffotlist(v.employee_id);
        this.return_editOT();
        this.showedit_OT = false;
      }
    },

    async delete_OT(v) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            let params = {
              ...{ modification_id: v.modification_id },
              ...{ approve_status: v.approve_status },
              ...{ id_delete: v.id },
            };
            let resp = await this.$store.dispatch(
              'otsflatrate/deleteOT',
              params,
            );
            if ((resp = 1)) {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'OT request has been deleted.',
                'success',
              );
              this.Get_Staffotlist(v.employee_id);
            } else if ((resp = 0)) {
              swalWithBootstrapButtons.fire(
                'Error!',
                'Oops, something went wrong!',
                'error',
              );
            }
          } else if (result.dismiss) {
            swalWithBootstrapButtons.fire('Cancelled', 'OT request', 'error');
          }
        });
    },

    goBack() {
      //this.$router.push({path: '/admin/otrequest',name: 'Request OT'})
      this.$router.go(0);
    },

    start() {
      this.$Progress.start(); //start progress bar
    },
    finish() {
      this.$Progress.finish(); //finish progress bar
      this.check_progress = true;
    },
    fail() {
      this.$Progress.fail(); //fail progress bar
      this.check_progress = false;
    },

    // set employee id value from child component
    setStaffs(v) {
      if (v) {
        this.persons = v.id;
      }
    },
    async show_input() {
      this.typeintype = '';
      let params = {};
      if (this.typecreate == 0) {
        params = {
          ot_id: this.ot_id,
          emp_brh: this.me.employees.branch_id,
        };
      } else if (this.typecreate == 1) {
        params = {
          ot_id: this.ot_id,
          emp_brh: this.selected_employee[0].bch_id,
        };
      } else if (this.typecreate == 2) {
        params = {
          ot_id: this.ot_id,
          emp_brh: this.selected_employee[0].bch_id,
        };
      }
      let resp = await this.$store.dispatch('otsflatrate/getTypeOT', params);
      this.available_typeots = resp;
    },
    async getTreatment(id) {
      let params = {
        ot_flat_rate_id: id,
      };
      let resp = await this.$store.dispatch(
        'otsflatrate/get_treatment',
        params,
      );
      if (resp.length > 0) {
        this.disabled_treatment = false;
      }
      this.available_treatment = resp;
    },
  },
};
</script>
<style lang="scss">
.background-border {
  background-color: white;
  border-radius: 10px;
}
.md-overlay.md-dialog-overlay.test {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: opacity;
  will-change: opacity;
}
.bt_confirm_editOT {
  text-align: center;
}
</style>
