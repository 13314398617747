import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function empweekendlist(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.get(`${url}/v1/get-staffweekend`, options).then((response) => {
    return response.data;
  });
}

function empweekendcreate(staffweekend) {
  // const payload = jsona.serialize({
  //   stuff: staffweekend,
  //   includeNames: null,
  // });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/create-staffweekend`, staffweekend, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function empweekendEdit(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios
    .post(`${url}/v1/Edit-staffweekend`, params, options)
    .then((response) => {
      return response.data;
    });
}

function empweekendDelete(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/delete-staffweekend`, params, options)
    .then((response) => {
      return response.data;
    });
}

export default {
  empweekendlist,
  empweekendcreate,
  empweekendEdit,
  empweekendDelete,
};
