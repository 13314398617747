import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function rawtime(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios.get(`${url}/v1/rawtime`, options).then((response) => {
    return response.data;
  });
}

function exporttime(params) {
  let bch_id = params.bch_id;
  let fromdate = params.fromdate;
  let todate = params.todate;
  let curtime = params.curtime;
  let geturl = `${url}/v1/exporttimebplus?bch_id=${bch_id}&fromdate=${fromdate}&todate=${todate}&t=${curtime}`;
  window.open(geturl, '_blank');
  return geturl;
}

function get(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.get(`${url}/v2/stafftime`, options).then((response) => {
    return response.data;
  });
}

function get_timeinout(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.get(`${url}/v2/gettimeinout`, options).then((response) => {
    return response.data;
  });
}

function addStafftimeinout(timeinout) {
  const payload = jsona.serialize({
    stuff: timeinout,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v2/addstafftime`, payload, options)
    .then((response) => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta,
      };
    });
}

function processTimeWork(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .get(`${url}/v2/staffsalary`, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
    });
}

function updateTimeMachin(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/updateTimeMachin`, params, options)
    .then((response) => {
      return response.data;
    });
}

function deleteTimeMachin(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/deleteTimeMachin`, params, options)
    .then((response) => {
      return response.data;
    });
}

function getcheck_scan(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios.get(`${url}/v1/getcheck_scan`, options).then((response) => {
    return response.data;
  });
}

function addfingerscan(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/add_fingerscan`, params, options)
    .then((response) => {
      return response.data;
    });
}

function get_search_fingerscan(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios
    .get(`${url}/v1/getSearch_fingerscan`, options)
    .then((response) => {
      return response.data;
    });
}

function updatefingerscan(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/update_fingerscan`, params, options)
    .then((response) => {
      return response.data;
    });
}

function deletefingerscan(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/delete_fingerscan`, params, options)
    .then((response) => {
      return response.data;
    });
}

// function getWorkDay(params) {
// 	let options = {
// 		params: params,
// 		paramsSerializer: function(params) {
// 			return qs.stringify(params, { encode: false });
// 		},
// 		header: {
// 			Accept: 'application/vnd.api+json',
// 			'Content-Type': 'application/vnd.api+json',
// 		},
// 	};

// 	return axios.get(`${url}/staffworks`, options).then((response) => {
// 		return {
// 			list: jsona.deserialize(response.data),
// 			meta: response.data.meta,
// 		};
// 	});
// }
export default {
  rawtime,
  exporttime,
  get,
  processTimeWork,
  addStafftimeinout,
  updateTimeMachin,
  deleteTimeMachin,
  getcheck_scan,
  addfingerscan,
  get_search_fingerscan,
  updatefingerscan,
  deletefingerscan,
  get_timeinout,
};
