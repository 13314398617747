import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function list(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/otitemslist`, options);
  return {
    list: response.data,
    meta: response.data.meta,
  };
}

async function createotsetting(ot) {
  // const payload = jsona.serialize({
  //   stuff: ot,
  //   includeNames: null,
  // });
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(`${url}/v1/createotsetting`, ot, options);
  return {
    list: response.data,
    meta: response.data.meta,
  };
}

async function otlistsetting(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/otlistsetting`, options);
  return {
    list: response.data,
    meta: response.data.meta,
  };
}

async function otstafflist(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/otstafflist`, options);
  return {
    list: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

async function staffotlist(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  const response = await axios.get(`${url}/v1/staffotlist`, options);
  return {
    staffot: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

async function addStaffOT(ot) {
  const payload = jsona.serialize({
    stuff: ot,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  const response = await axios.post(`${url}/v1/otcreate`, payload, options);
  return jsona.deserialize(response.data);
}

async function addStaffOTApprove(ot) {
  const payload = jsona.serialize({
    stuff: ot,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  const response = await axios.post(
    `${url}/v1/otcreateapprove`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function addStaffOTflatReat(ot) {
  const payload = jsona.serialize({
    stuff: ot,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  const response = await axios.post(
    `${url}/v1/otflatreatcreate`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function addStaffOTflatReatApprove(ot) {
  const payload = jsona.serialize({
    stuff: ot,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  const response = await axios.post(
    `${url}/v1/otflatreatcreateapprove`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function otapprove(approval) {
  const payload = jsona.serialize({
    stuff: approval,
    includeNames: [],
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.patch(
    `${url}/v1/otapprove/${approval.id}`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function updateOT(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(`${url}/v1/updateOT`, params, options);
  return response.data;
}

async function deleteOT(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(`${url}/v1/deleteOT`, params, options);
  return response.data;
}

async function getPriceOT(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/get_PriceOT`, options);
  return response.data;
}
async function addPriceOT(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(`${url}/v1/add_PriceOT`, params, options);
  return response.data;
}

async function addTreatment(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(`${url}/v1/add_Treatment`, params, options);
  return response.data;
}

async function updatePriceOT(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/update_PriceOT`,
    params,
    options,
  );
  return response.data;
}

async function deletePriceOT(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/delete_PriceOT`,
    params,
    options,
  );
  return response.data;
}

async function getTypeOT(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/get_TypeOT`, options);
  return response.data;
}

async function listOT_flat_rat(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/listOT_flatRat`, options);
  return response.data;
}

async function get_treatment(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/get_treatment`, options);
  return response.data;
}

async function update_treatment(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/update_treatment`,
    params,
    options,
  );
  return response.data;
}

async function deleteTreatment(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/delete_Treatment`,
    params,
    options,
  );
  return response.data;
}

async function getOTOverLimit(params) {
  const response = await axios.post(`${url}/v1/getotoverlimit`, params);
  return response.data;
}

async function getOTOverLimitReportList(params) {
  const response = await axios.post(
    `${url}/v1/getotoverlimitreportlist`,
    params,
  );
  return response.data;
}

async function getOTOverLimitReport(params) {
  const response = await axios.post(`${url}/v1/getotoverlimitreport`, params);
  return response.data;
}

async function createOTOverLimitReport(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(
    `${url}/v1/createotoverlimitreport`,
    options,
  );
  return response.data;
}

async function approverOTOverLimit(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/approverotoverlimit`, options);
  return response.data;
}

async function approveOTOverLimitReport(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(
    `${url}/v1/approveotoverlimitreport`,
    options,
  );
  return response.data;
}

export default {
  list,
  createotsetting,
  otlistsetting,
  otstafflist,
  otapprove,
  addStaffOT,
  addStaffOTApprove,
  staffotlist,
  updateOT,
  deleteOT,
  getPriceOT,
  addPriceOT,
  updatePriceOT,
  deletePriceOT,
  getTypeOT,
  listOT_flat_rat,
  addTreatment,
  get_treatment,
  update_treatment,
  deleteTreatment,
  addStaffOTflatReat,
  addStaffOTflatReatApprove,
  getOTOverLimit,
  getOTOverLimitReportList,
  getOTOverLimitReport,
  createOTOverLimitReport,
  approverOTOverLimit,
  approveOTOverLimitReport,
};
