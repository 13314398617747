import service from '@/store/services/permissions-service';

const state = {
  list: {},
  permission: {},
  meta: {},
  userpermission: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, permission) => {
    state.permission = permission;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_USERPERMISSION: (state, userpermission) => {
    state.userpermission = userpermission;
  },
};

const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      commit('SET_META', meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((permission) => {
      commit('SET_RESOURCE', permission);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((permission) => {
      commit('SET_RESOURCE', permission);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((permission) => {
      commit('SET_RESOURCE', permission);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },

  getUserPermission({ commit, dispatch }, params) {
    return service.getUserPermission(params).then((userpermission) => {
      commit('SET_USERPERMISSION', userpermission);
    });
  },

  assignDirectPermission({ commit, dispatch }, params) {
    return service.assignDirectPermission(params).then((permission) => {
      commit('SET_RESOURCE', permission);
    });
  },

  assignRolePermission({ commit, dispatch }, params) {
    return service.assignRolePermission(params).then((permission) => {
      commit('SET_RESOURCE', permission);
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  permission: (state) => state.permission,
  userpermission: (state) => state.userpermission,
  dropdown: (state) => {
    return state.list.map((permission) => ({
      id: permission.id,
      name: permission.name,
    }));
  },
};

const permissions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default permissions;
