<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div>
        <h5 class="info-text">
          <!-- Let's start with the basic information (with validation) -->
        </h5>
        <div class="md-layout spacing">
          <div class="md-layout-item md-size-20 md-small-size-100">
            <div class="picture-container">
              <div class="picture">
                <div v-if="!image">
                  <img :src="avatar" class="picture-src" title="" />
                </div>
                <div v-else>
                  <img :src="image" />
                </div>
                <input type="file" accept="image/*" @change="onFileChange" />
              </div>
              <h6 class="description">Choose Picture</h6>
            </div>
          </div>
        </div>
        <div class="md-layout spacing">
          <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
            <md-field>
              <label for="title_nameth">คำนำหน้าชื่อ-ไทย</label>
              <md-select
                v-model="user.title_nameth"
                name="title_nameth"
                id="title_nameth"
              >
                <md-option value="นาย">นาย</md-option>
                <md-option value="นาง">นาง</md-option>
                <md-option value="น.ส.">น.ส.</md-option>
                <md-option value="ว่าที่ ร.ต.">ว่าที่ ร.ต.</md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
            <md-field>
              <label for="title_nameen">คำนำหน้าชื่อ-อังกฤษ</label>
              <md-select
                v-model="user.title_nameen"
                name="title_nameen"
                id="title_nameen"
              >
                <md-option value="Mr.">Mr.</md-option>
                <md-option value="Mrs.">Mrs.</md-option>
                <md-option value="Ms.">Ms.</md-option>
                <md-option value="Miss">Miss</md-option>
                <md-option value="Acting 2 Lt.">Acting 2 Lt.</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="md-layout spacing">
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <md-field>
              <label>ชื่อ-ไทย</label>
              <md-input v-model="user.first_nameth" />
            </md-field>
          </div>
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <md-field>
              <label>ชื่อสกุล-ไทย</label>
              <md-input v-model="user.last_nameth" />
            </md-field>
          </div>
        </div>

        <div class="md-layout spacing">
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <ValidationProvider
              name="user.first_nameen"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label>ชื่อ-อังกฤษ</label>
                <md-input v-model="user.first_nameen" />

                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <ValidationProvider
              name="user.last_nameen"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label>ชื่อสกุล-อังกฤษ</label>
                <md-input v-model="user.last_nameen" />

                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
        </div>

        <div class="md-layout spacing">
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <md-field>
              <label>ชื่อเล่น</label>
              <md-input v-model="user.nick_name" />
            </md-field>
          </div>
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <md-field>
              <label>เบอร์โทร</label>
              <md-input v-model="user.phone" />
            </md-field>
          </div>
        </div>

        <div class="md-layout spacing">
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <ValidationProvider
              name="birth_date"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-datepicker
                v-model="user.birth_date"
                md-immediately
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label>วันเกิด</label>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-datepicker>
            </ValidationProvider>
          </div>
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <ValidationProvider
              name="user.sex"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label for="sex">เพศ</label>
                <md-select v-model="user.sex" name="sex" id="sex">
                  <md-option value="M">ชาย</md-option>
                  <md-option value="F">หญิง</md-option>
                </md-select>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
        </div>

        <div class="md-layout spacing">
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <ValidationProvider
              name="user.idcard"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label>เลขบัตรประชาชน</label>
                <md-input v-model="user.idcard" />
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <ValidationProvider
              name="email"
              rules="email"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label>อีเมล์</label>
                <md-input v-model="user.email" type="email"> </md-input>

                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
        </div>

        <div class="md-layout spacing">
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <ValidationProvider
              name="user.marital_st"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label for="marital_st">สถานภาพ</label>
                <md-select
                  v-model="user.marital_st"
                  name="marital_st"
                  id="marital_st"
                >
                  <md-option value="single">โสด</md-option>
                  <md-option value="merried">แต่งงาน</md-option>
                </md-select>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <ValidationProvider
              name="user.religion"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label>ศาสนา</label>
                <md-input v-model="user.religion" />
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
        </div>

        <div class="md-layout spacing">
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <ValidationProvider
              name="user.race"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label>เชื้อชาติ</label>
                <md-input v-model="user.race" />
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
          <div class="md-layout-item md-size-40 mt2 md-small-size-100">
            <ValidationProvider
              name="user.nationality"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label>สัญชาติ</label>
                <md-input v-model="user.nationality" />
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import formMixin from '@/mixins/form-mixin';

extend('required', required);
extend('email', email);

export default {
  props: {
    user: Object,
    avatar: {
      type: String,
      default: process.env.VUE_APP_APP_BASE_URL + '/img/default-avatar.png',
    },
  },
  data() {
    return {
      image: '',
      firstName: '',
      lastName: '',
      email: '',
    };
  },
  methods: {
    handlePreview(file) {
      this.model.imageUrl = URL.createObjectURL(file.raw);
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.$emit('on-validated', res);
        return res;
      });
    },
    createImage(file) {
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
<style lang="scss">
.spacing {
  padding-bottom: 1rem;
}
</style>
