<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <lock-card>
        <img class="img" slot="imageProfile" :src="image" />
        <h4 slot="title" class="title"></h4>
        <md-field slot="content">
          <label>Enter Staff ID</label>
          <md-input v-model="staffid" />
        </md-field>
        <md-button
          href
          class="md-success md-round"
          slot="footer"
          @click="resetpassword"
        >
          ยืนยันตัวตน
        </md-button>
      </lock-card>
    </div>
  </div>
</template>
<script>
//import { PricingCard } from "@/components";
import { LockCard } from '@/components';
export default {
  components: {
    //PricingCard
    LockCard,
  },
  data() {
    return {
      staffid: null,
      register: {
        type: 'register',
        lineid: null,
        employeeid: null,
        password: null,
        password_confirmation: null,
        valid_employeeid: true,
      },
      image: process.env.VUE_APP_APP_BASE_URL + '/img/key_password.jpg',
    };
  },
  created() {},
  computed: {
    // line_user_id() {
    //   return this.$route.params.id;
    // },
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async resetpassword() {
      if (!this.staffid) {
        await this.$store.dispatch('alerts/error', 'Please enter staff ID');
        return false;
      }
      // let password = this.genPassword();
      this.register.lineid = null;
      this.register.employeeid = this.staffid;
      this.register.password = 'passwordnl123';
      this.register.password_confirmation = 'passwordnl123';
      try {
        await this.$store.dispatch('registers/firstpassword', this.register);
        await this.$store
          .dispatch('alerts/success', 'ยืนยันตัวตนเรียบร้อย')
          .then(() => {
            this.goBack();
          });
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch('alerts/error', e.response.data.errors);
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },

    randomSpecialChar() {
      // example set of special chars as a string in no particular order
      var s = '_+*#!$%&/()=?';

      // generating a random index into the string and extracting the character at that position
      return s.substr(Math.floor(s.length * Math.random()), 1);
    },

    genPassword() {
      return (
        Math.random()
          .toString(36)
          .substr(2, 7) + this.randomSpecialChar()
      );
    },

    async goBack() {
      // this.$router.push({ name: 'Login', path: '/login' });
      const user = {
        data: {
          type: 'token',
          attributes: {
            username: this.staffid,
            password: this.register.password,
          },
        },
      };
      const requestOptions = {
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        },
      };

      try {
        await this.$store.dispatch('login', { user, requestOptions });
        this.$router.push({ name: 'Reset Password', path: '/reset-password' });
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'มีบางอย่างผิดพลาดกรุณาติดต่อ Admin',
        );
        this.setApiValidation(e.response.data.errors);
      }
    },
  },
};
</script>
<style scoped>
.text-center {
  text-align: center;
}
</style>
