<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(update)">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>edit</md-icon>
          </div>
          <h4 class="title"></h4>
          <div class="text-right">
            <md-button @click="goBack" class="md-primary md-dense">
              Back to List
            </md-button>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout-item md-size-40 mt-2 md-small-size-40">
            <ValidationProvider
              name="department"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label for="department">แผนก</label>
                <md-select v-model="position.dep_id" name="department">
                  <md-option
                    v-for="item in available_departments"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>

          <div class="md-layout spacing md-alignment-left">
            <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
              <ValidationProvider
                name="position.pos_nameth"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>ชื่อตำแหน่งภาษาไทย</label>
                  <md-input v-model="position.pos_nameth" type="text">
                  </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
              <ValidationProvider
                name="position.pos_nameen"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>ชื่อแผนกภาษาอังกฤษ</label>
                  <md-input v-model="position.pos_nameen" type="text">
                  </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="md-layout spacing md-alignment-left">
            <div class="md-layout-item md-size-20 mt-2 md-small-size-40">
              <label>การอนุมัติวันลา</label>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <md-radio v-model="position.is_approve" value="1">ใช่</md-radio>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <md-radio v-model="position.is_approve" value="0"
                >ไม่ใช่</md-radio
              >
            </div>
          </div>

          <div class="md-layout spacing md-alignment-left">
            <!-- <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
            <ValidationProvider name="department.dep_code" rules="required" v-slot="{ passed, failed }">
              <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                <label>รหัสแผนก</label>
                <md-input v-model="department.dep_code" type="text"> </md-input>

                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div> -->
            <div class="md-layout-item md-size-80 mt-2 md-small-size-100">
              <md-field>
                <label>คำอธิบาย</label>
                <md-input v-model="position.pos_desc" type="text"> </md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions>
          <md-button type="submit" class="md-success">save </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import { SlideYDownTransition } from 'vue2-transitions';
import { extend } from 'vee-validate';
import { required, email, min } from 'vee-validate/dist/rules';

export default {
  mixins: [formMixin],
  SlideYDownTransition,

  data() {
    return {
      position: {
        type: 'positions',
        id: null,
        pos_code: null,
        pos_nameth: null,
        pos_nameen: null,
        pos_desc: null,
        dep_id: null,
        is_approve: null,
      },
      available_departments: [],
    };
  },
  components: {},
  created() {
    this.get();

    this.getDepartments();
  },
  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch('positions/get', id);
        this.position = this.$store.getters['positions/position'];
        if (this.position.is_approve == 0) {
          this.position.is_approve = '0';
        }
        if (this.position.is_approve == 1) {
          this.position.is_approve = '1';
        }
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
      }
    },

    async update() {
      try {
        await this.$store.dispatch('positions/update', this.position);
        await this.$store.dispatch(
          'alerts/success',
          'แก้ไข ตำแหน่ง เรียบร้อย.',
        );
        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch('alerts/error', e.response.data.errors[0]);
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },

    async getDepartments() {
      let branchs = JSON.parse(localStorage.getItem('branchs'));

      let params = {
        filter: {
          ...{ company_id: branchs.company_id },
        },
      };
      await this.$store.dispatch('departments/list', params).then(() => {
        this.available_departments = this.$store.getters[
          'departments/dropdown'
        ];
      });
    },

    goBack() {
      this.$router.push({
        path: 'list-position',
        name: 'รายการ ตำแหน่ง',
      });
    },
  },
};
</script>
