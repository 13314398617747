import service from '@/store/services/taxs-service';

const state = {
  list: {},
  tax: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, tax) => {
    state.tax = tax;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      commit('SET_META', meta);
    });
  },
};

const getters = {
  list: (state) => state.list,
  tax: (state) => state.tax,
  dropdown: (state) => {
    return state.list.map((tax) => ({
      id: tax.id,
      name: tax.name,
      code: tax.codename,
    }));
  },
};

const taxs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default taxs;
