<template>
  <div class="priority-settings p-4">
    <div class="mb-4">
      <hr class="my-4" />
      <h4 v-if="isGroupEnabled" class="text-lg font-bold mb-4">
        กลุ่มลำดับ{{ title }}
      </h4>
      <b-button
        v-if="isGroupEnabled"
        class="mb-4"
        variant="info"
        @click="addPriorityGroup"
      >
        เพิ่มกลุ่มลำดับ{{ title }}
      </b-button>

      <div>
        <draggable
          v-for="(group, groupIndex) in priorityGroups"
          :key="'g-' + (group.priority = groupIndex)"
          tag="div"
          class="group-container"
        >
          <div class="group bg-light p-4 rounded shadow-sm mb-6">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h5 v-if="isGroupEnabled" class="h5">
                  กลุ่มลำดับ{{ title }}ลำดับที่ {{ groupIndex + 1 }}
                </h5>
              </div>
              <div class="btn-group">
                <b-button
                  size="sm"
                  variant="info"
                  @click="addPriorityMember(group)"
                >
                  เพิ่มสมาชิกลำดับ{{ title }}
                </b-button>
                <b-button
                  size="sm"
                  variant="danger"
                  @click="deletePriorityGroup(groupIndex)"
                >
                  ลบกลุ่ม
                </b-button>
              </div>
            </div>

            <table class="table table-bordered">
              <thead class="thead-light">
                <tr class="d-flex">
                  <th class="custom-width column-center">ลำดับ</th>
                  <th
                    v-for="schemas in inputSchemas"
                    :key="schemas.key"
                    class="col column-center"
                  >
                    {{ schemas.label || '' }}
                  </th>
                  <th class="custom-width column-center">Action</th>
                </tr>
              </thead>
              <draggable tag="tbody">
                <tr
                  class="d-flex"
                  v-for="(member, memberIndex) in group.member"
                  :key="
                    'member-' + (member.memberInfo.order_person = memberIndex)
                  "
                >
                  <td class="custom-width column-center">
                    {{ memberIndex + 1 }}
                  </td>
                  <td
                    class="col"
                    v-for="schemas in inputSchemas"
                    :key="schemas.key"
                  >
                    <component
                      :is="getComponentType(schemas)"
                      v-bind="getComponentProps(member, schemas)"
                      v-model="member.memberInfo[schemas.model]"
                      @select="
                        handleEvent(
                          'selectEvent',
                          $event,
                          group,
                          schemas,
                          member,
                        )
                      "
                      @change="
                        handleEvent(
                          'changeEvent',
                          $event,
                          group,
                          schemas,
                          member,
                        )
                      "
                      @md-changed="
                        handleEvent(
                          'mdChangedEvent',
                          $event,
                          group,
                          schemas,
                          member,
                        )
                      "
                      @md-selected="
                        handleEvent(
                          'mdSelectedEvent',
                          $event,
                          group,
                          schemas,
                          member,
                        )
                      "
                    >
                      <template
                        v-if="schemas.type === 'autocomplete'"
                        slot="md-autocomplete-item"
                        slot-scope="{ item }"
                      >
                        {{ item.firstname_en + ' ' + item.lastname_en }}
                      </template>
                    </component>
                  </td>

                  <td class="custom-width column-center">
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="deleteMember(group, memberIndex)"
                    >
                      ลบ
                    </b-button>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import employeeMixin from '@/mixins/employee-mixin';
import Swal from 'sweetalert2';
import { loading_start, loading_close } from '@/utils/loading.js';
import draggable from 'vuedraggable';

/**
 * @component
 * @name PriorityManagement
 * @description This component allows users to manage priority groups with member settings,
 * including selecting branch, department, and staff member, and setting priority levels.
 */

export default {
  mixins: [employeeMixin],
  components: {
    draggable,
  },
  name: 'PriorityManagement',

  props: {
    priorityGroups: {
      type: Array,
      default: () => [
        {
          member: [
            {
              options: {
                departments: [],
                employees: [],
                positions: [],
              },
              memberInfo: {
                bch_id: '',
                order_person: 0,
                posname_en: '',
                depname_en: '',
                emp_full_name: '',
                dep_id: '',
                position: '',
              },
            },
          ],
          priorityType: '',
          priority: 0,
        },
      ],
    },
    radioOptions: {
      type: Array,
      default: () => [],
    },
    inputSchemas: {
      type: Array,
      default: () => [
        {
          key: 'branch',
          label: 'สำนักงาน',
          type: 'select',
          model: 'bch_id',
          placeholder: 'เลือกสำนักงาน',
          changeEvent: { name: 'handleBranchChange', args: ['member'] },
        },
        {
          key: 'department',
          label: 'แผนก',
          type: 'select',
          model: 'dep_id',
          placeholder: 'เลือกแผนก',
          options: 'options.departments',
          changeEvent: { name: 'handleDepartments', args: ['member'] },
          disabledCondition: 'bch_id',
        },
        {
          key: 'member',
          label: 'สมาชิก',
          type: 'autocomplete',
          model: 'emp_full_name',
          placeholder: 'เลือกพนักงาน',
          options: 'options.employees',
          mdChangedEvent: {
            name: 'searchStaffs',
            args: ['priority', 'member', 'schemas'],
          },
          mdSelectedEvent: {
            name: 'selectStaff',
            args: ['event', 'priority', 'priorityType', 'member'],
          },
          disabledCondition: 'bch_id',
        },
        {
          key: 'position',
          label: 'ตำแหน่ง',
          type: 'input',
          model: 'position',
          placeholder: 'Enter Position',
          disabledCondition: 'emp_full_name',
        },
      ],
    },
    isGroupEnabled: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'ความสำคัญ',
    },
    staffRecordFields: {
      type: Array,
      default: () => [
        'bch_id',
        'emp_id',
        'dep_id',
        'position',
        'firstname_th',
        'lastname_th',
        'type',
        'order_person',
        'priority',
      ],
    },
  },

  data() {
    return {
      availableBranches: [],
      employees: {},
      staffRecords: {},
    };
  },

  async created() {
    let params = { profile: true };
    this.profile = await this.$store.dispatch('staffs/searchStaff', params);
    await this.getBranches();
  },
  async mounted() {
    await this.loadDataFromBchId();
  },
  methods: {
    async loadDataFromBchId() {
      const memberWithBchId = this.priorityGroups.flatMap((group) =>
        group.member.filter((member) => member.memberInfo?.bch_id),
      );

      if (memberWithBchId.length > 0) {
        memberWithBchId.map(async (member) => {
          await this.getStaff(member);
        });
      }
    },
    async getBranches() {
      try {
        const params = {
          com_id: this.profile?.[0]?.com_id,
          bch_only: '1',
        };
        const branches = await this.$store.dispatch(
          'staffs/searchStaff',
          params,
        );

        if (Array.isArray(branches) && branches.length > 0) {
          this.availableBranches = branches.map(({ bch_id, brname_en }) => ({
            value: bch_id,
            text: brname_en,
          }));
        } else {
          this.availableBranches = [];
          throw new Error('No branches available');
        }
      } catch (error) {
        this.availableBranches = [];
        throw new Error(`Error fetching branches: ${error}`);
      }
    },

    addPriorityGroup() {
      this.priorityGroups.push({
        member: [
          {
            options: {
              departments: [],
              employees: [],
              positions: [],
            },
            memberInfo: {
              bch_id: '',
              order_person: 0,
              posname_en: '',
              depname_en: '',
              emp_full_name: '',
              dep_id: '',
              position: '',
            },
          },
        ],
        priorityType: '',
        priority: 0,
      });
      this.$store.dispatch(
        'alerts/success',
        'Priority group added successfully.',
      );
    },

    addStaffMember(priority, priorityType, member) {
      const key = `${priority}${member.memberInfo.order_person}`;
      this.staffRecords[key] = {};

      this.staffRecordFields.forEach((field) => {
        const [originalField, newField] = field.split(':');
        const value = member.memberInfo[originalField];

        if (value) {
          this.staffRecords[key][newField || originalField] = value;
        }
      });

      if (this.staffRecordFields.includes('priority')) {
        this.staffRecords[key].priority = priority;
      }
      if (this.staffRecordFields.includes('priorityType')) {
        this.staffRecords[key].type = priorityType;
      }

      return this.staffRecords;
    },

    clearDisabledFields({ fields, member }) {
      this.inputSchemas.forEach((schema) => {
        const { disabledCondition, model } = schema;

        if (disabledCondition && fields.includes(disabledCondition)) {
          member.memberInfo[model] = '';
        }
      });
    },

    deletePriorityGroup(index) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this priority group?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.priorityGroups.splice(index, 1);
          Swal.fire('Deleted!', 'Priority group has been deleted.', 'success');
        }
      });
    },

    addPriorityMember(group) {
      group.member.push({
        options: {
          departments: [],
          employees: [],
          positions: [],
        },
        memberInfo: {
          bch_id: '',
          order_person: 0,
          posname_en: '',
          depname_en: '',
          emp_full_name: '',
          dep_id: '',
          position: '',
        },
      });
      this.$store.dispatch(
        'alerts/success',
        'Priority member added successfully.',
      );
    },

    deleteMember(group, memberIndex) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this member?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          group.member.splice(memberIndex, 1);
          this.$store.dispatch('alerts/success', 'Member has been deleted.');
        }
      });
    },

    getComponentType(schemas) {
      switch (schemas.type) {
        case 'input':
          return 'b-input';
        case 'select':
          return 'b-form-select';
        case 'autocomplete':
          return 'md-autocomplete';
        case 'button':
          return 'b-button';
        default:
          return 'div';
      }
    },

    getComponentProps(member, schemas) {
      const props = {
        placeholder: schemas.placeholder || 'as;dij',
        size: 'sm',
        disabled: schemas.disabledCondition
          ? !Boolean(member.memberInfo[schemas.disabledCondition])
          : false,
      };

      const text = schemas?.options ? String(schemas.options) : '';
      let options = [];

      if (text) {
        const result = text.split('.');
        options =
          result.reduce((acc, key) => {
            return acc && acc[key] !== undefined ? acc[key] : undefined;
          }, member) || [];
      }

      switch (schemas.type) {
        case 'select':
          const clearOption = { value: '', text: schemas?.placeholder };
          props.options = [clearOption].concat(
            schemas.key === 'branch' ? this.availableBranches : options,
          );
          break;
        case 'autocomplete':
          member.memberInfo.emp_full_name = this.getEmpFullName(
            member.memberInfo,
          );
          props.mdOptions = options;
          break;
        case 'input':
          props.value = member.memberInfo[schemas.model] || '';
          break;
      }

      return props;
    },

    getEmpFullName(memberInfo) {
      return (
        this.employees?.[memberInfo?.bch_id]?.[memberInfo?.dep_id]?.positions?.[
          memberInfo?.position
        ]?.employees[memberInfo?.emp_id]?.emp_full_name ?? ''
      );
    },

    getAll() {
      this.priorityGroups.forEach((group) => {
        group.member.forEach((member) => {
          this.addStaffMember(group.priority, group.priorityType, member);
        });
      });

      this.$emit('staffs', this.staffRecords);
    },

    handleBranchChange({ member }) {
      member.memberInfo.position = '';
      member.memberInfo.dep_id = '';
      member.memberInfo.posname_en = '';
      member.memberInfo.depname_en = '';
      member.memberInfo.emp_full_name = '';
      this.getStaff(member);
    },

    handleDepartments({ member }) {
      member.memberInfo.position = '';
      member.memberInfo.emp_full_name = '';

      this.setPositions(member);
      this.setEmployeeData(member);
    },
    handlePositions({ member }) {
      member.memberInfo.emp_full_name = '';
      const branchId = member.memberInfo.bch_id;
      const targetPositionId = member.memberInfo.position;
      const departments = Object.values(this.employees[branchId]);

      const depId = departments.reduce((acc, dep) => {
        if (acc) return acc;

        const hasTargetPosition = Object.values(dep?.positions).some(
          (pos) => pos.pos_id == targetPositionId,
        );

        return hasTargetPosition ? dep.dep_id : null;
      }, null);

      if (depId) member.memberInfo.dep_id = depId;
      this.setPositions(member);
      this.setEmployeeData(member);
    },

    async getStaff(member) {
      const { bch_id } = member.memberInfo;
      const com_id = this.profile?.[0]?.com_id;

      if (this.employees?.[bch_id]) {
        this.updateStaffData(member);
        return;
      }

      loading_start();
      try {
        const data = await this.$store.dispatch('staffs/searchStaff', {
          com_id,
          bch_id,
        });

        if (!this.employees) {
          this.employees = {};
        }
        if (!this.employees[bch_id]) {
          this.employees[bch_id] = {};
        }

        data.forEach((employee) => this.organizeEmployeeData(bch_id, employee));

        this.updateStaffData(member);
      } catch (error) {
        throw new Error(`Error fetching staff: ${error}`);
      } finally {
        loading_close();
      }
    },

    organizeEmployeeData(bch_id, employee) {
      const {
        com_id,
        dep_id,
        depname_en,
        emp_id,
        firstname_en,
        lastname_en,
        firstname_th,
        lastname_th,
        pos_id,
        posname_en,
      } = employee;

      const fullName = `${firstname_en} ${lastname_en}`;

      if (!this.employees[bch_id][dep_id]) {
        this.employees[bch_id][dep_id] = {
          dep_id,
          depname_en,
          positions: {},
        };
      }
      const department = this.employees[bch_id][dep_id];
      if (!department?.positions[pos_id]) {
        department.positions[pos_id] = { pos_id, posname_en, employees: {} };
      }

      department.positions[pos_id].employees[emp_id] = {
        com_id,
        dep_id,
        bch_id,
        depname_en,
        emp_id,
        firstname_en,
        lastname_en,
        firstname_th,
        lastname_th,
        pos_id,
        position: pos_id,
        posname_en,
        emp_full_name: fullName,
      };
    },
    updateStaffData(member) {
      this.setDepartments(member);
      this.setPositions(member);
      this.setEmployeeData(member);
    },

    setDepartments({ memberInfo, options }) {
      if (this.employees[memberInfo.bch_id]) {
        options.departments = Object.values(
          this.employees[memberInfo.bch_id],
        ).map((dep) => ({
          value: dep.dep_id,
          text: dep.depname_en,
        }));
      }
    },
    setPositions({ memberInfo, options }) {
      if (this.employees[memberInfo.bch_id]) {
        const positions = {};
        const { dep_id } = memberInfo;

        if (dep_id && this.employees[memberInfo.bch_id][dep_id]) {
          const department = this.employees[memberInfo.bch_id][dep_id];

          Object.entries(department?.positions).forEach(
            ([pos_id, position]) => {
              positions[pos_id] = position.posname_en;
            },
          );
        } else {
          Object.values(this.employees[memberInfo.bch_id]).forEach((dep) => {
            Object.entries(dep?.positions).forEach(([pos_id, position]) => {
              if (!positions[pos_id]) {
                positions[pos_id] = position.posname_en;
              }
            });
          });
        }

        options.positions = Object.entries(positions).map(
          ([pos_id, posname_en]) => ({
            value: pos_id,
            text: posname_en,
          }),
        );
      } else {
        options.positions = [];
      }
    },
    setEmployeeData({ options, memberInfo }) {
      const branch = this.employees?.[memberInfo.bch_id];

      if (!branch) return;

      if (memberInfo.dep_id) {
        const department = branch[memberInfo.dep_id];

        if (department) {
          if (memberInfo.position) {
            const position = department?.positions[memberInfo?.position];
            if (position) {
              options.employees = Object.values(position.employees);
            } else {
              options.employees = [];
            }
          } else {
            options.employees = Object.values(
              department?.positions,
            ).flatMap((pos) => Object.values(pos.employees));
          }
        } else {
          options.employees = [];
        }
      } else if (memberInfo?.position) {
        options.employees = Object.values(branch).flatMap((dep) => {
          return Object.values(dep.positions).flatMap((pos) => {
            return pos.pos_id == memberInfo?.position
              ? Object.values(pos.employees)
              : [];
          });
        });
      } else {
        options.employees = Object.values(branch).flatMap((dep) => {
          return Object.values(dep.positions).flatMap((pos) =>
            Object.values(pos.employees),
          );
        });
      }
    },

    searchStaffs({ priority, member, schemas }) {
      if (member.memberInfo?.emp_full_name == '') {
        this.clearDisabledFields({ fields: [schemas.model], member });
        const key = `${priority}${member.memberInfo.order_person}`;
        delete this.staffRecords[key];
        this.$emit('staffs', this.staffRecords);
      }
    },

    selectStaff({ event, priority, priorityType, member }) {
      member.memberInfo = {
        ...event,
        emp_full_name: event.emp_full_name,
        order_person: member.memberInfo.order_person,
      };

      this.$emit('staffs', this.addStaffMember(priority, priorityType, member));
    },

    handleEvent(eventType, event, { priority, priorityType }, schemas, member) {
      const eventConfig = schemas[eventType];

      if (!eventConfig || typeof this[eventConfig.name] !== 'function') {
        throw new Error(
          `Unknown event type or handler not found: ${eventType}`,
        );
      }

      const args = eventConfig.args.reduce((acc, arg) => {
        acc[arg] = eval(arg);
        return acc;
      }, {});

      this[eventConfig.name](args);
    },
  },
};
</script>
<style lang="scss">
.priority-settings {
  .md-input {
    background-color: white !important;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .md-field .md-input-action {
    top: 5px;
    right: 8px;
  }

  .md-field .md-input-action {
    width: 25px;
    min-width: 25px;
    height: 25px;
  }
}
</style>
<style scoped>
.column-center {
  text-align: center;
}

.custom-width {
  width: 5%;
}

.group-container {
  margin-bottom: 1rem;
}

.group {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
}

.column-center {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
</style>
