<template>
  <md-dialog :md-active.sync="editProfile_show">
    <md-dialog-title>Edit Profile Photo</md-dialog-title>
    <form>
      <div class="md-layout spacing">
        <label class="md-layout-item md-size-15 md-form-label">
          Profile Photo
        </label>
        <div class="md-layout-item">
          <div class="file-input">
            <div v-if="image">
              <div class="image-container">
                <img :src="image" />
              </div>
            </div>
            <div class="image-container" v-else>
              <img :src="default_img" />
            </div>
            <div class="button-container">
              <md-button
                class="md-danger md-round"
                @click="removeImage"
                v-if="image"
              >
                <i class="fa fa-times" />
                Remove
              </md-button>
              <md-button class="md-primary md-fileinput">
                <template v-if="!image">Select image</template>
                <template v-else>Change</template>
                <input type="file" @change="onFileChange" />
              </md-button>
            </div>
          </div>
        </div>
      </div>
      <md-dialog-actions>
        <md-button class="md-success md-round" @click="updateProfile()">
          Update Image
        </md-button>
        <md-button class="md-danger md-round" @click="editProfile_show = false">
          Close
        </md-button>
      </md-dialog-actions>
    </form>
  </md-dialog>
  <!-- <md-card>
      <md-card-header class="md-card-header-icon">
        <div class="card-icon">
          <md-icon>perm_identity</md-icon>
        </div>
        <h4 class="title">Edit Profile</h4>
      </md-card-header>
      <md-card-content> -->
  <!-- </md-card-content>
      <md-card-actions>
      </md-card-actions>
    </md-card> -->
</template>
<script>
// import { ValidationError } from '@/components';
import formMixin from '@/mixins/form-mixin';

export default {
  name: 'edit-profile-card',

  props: {
    user: Object,
    editProfile_show: Boolean,
  },

  mixins: [formMixin],

  data() {
    return {
      image: null,
      default_img: process.env.VUE_APP_APP_BASE_URL + '/img/placeholder.jpg',
      file: null,
      me: null,
    };
  },
  mounted() {
    this.$emit('init', true);
  },
  watch: {
    editProfile_show: function(v) {
      this.$emit('showEdit', v);
    },
  },
  methods: {
    async updateProfile() {
      this.$emit('beginUpImg', false);
      try {
        if (!!this.file) {
          await this.$store.dispatch('users/upload', {
            user: this.user,
            image: this.file,
          });
          this.user.profile_image = await this.$store.getters['users/url'];
        }
        await this.$store.dispatch('profile/update', this.user);
        await this.$store.dispatch(
          'alerts/success',
          'Profile updated successfully.',
        );

        this.me = await this.$store.getters['profile/me'];
        this.$emit('setImagePath', this.me.profile_image);
        this.$emit('beginUpImg', true);
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        this.setApiValidation(e.response.data.errors);
      }
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },

    removeImage() {
      this.image = null;
    },
  },
};
</script>

<style lang="scss">
.spacing {
  padding-bottom: 1rem;
}
</style>
