<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-size-20 mt-2 md-small-size-20 md-alignment-center"
    >
      <md-card>
        <md-card-content>
          <md-field>
            <label for="department">แผนก</label>
            <md-select v-model="departments">
              <md-option
                v-for="item in available_departments"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </md-option>
            </md-select>
          </md-field>
        </md-card-content>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-warning">
          <div class="card-text">
            <h4 class="title">Report.........</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <md-table v-model="users" table-header-color="orange">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id">{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="Salary">{{ item.salary }}</md-table-cell>
              <md-table-cell md-label="Country">{{
                item.country
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
// import { StatsCard, ChartCard, NavTabsCard } from '@/components';
import employeeMixin from '@/mixins/employee-mixin';

export default {
  components: {},
  mixins: [employeeMixin],
  data() {
    return {
      product1: process.env.VUE_APP_APP_BASE_URL + '/img/card-2.jpg',
      product2: process.env.VUE_APP_APP_BASE_URL + '/img/card-3.jpg',
      product3: process.env.VUE_APP_APP_BASE_URL + '/img/card-1.jpg',
      available_departments: [],
      departments: null,
    };
  },
  created() {},
  methods: {
    async getDepartments(company_id) {
      if (localStorage.getItem('employees')) {
        let employee = JSON.parse(localStorage.getItem('employees'));
        this.company_id = employee.branchs.company_id;
      }

      let params = {
        filter: {
          ...(this.company_id ? { company_id: this.company_id } : {}),
        },
      };
      await this.$store.dispatch('departments/list', params).then(() => {
        this.available_departments = this.$store.getters[
          'departments/dropdown'
        ];

        if (this.available_departments && this.available_departments.length) {
          this.disabled_department = false;
        }
      });
    },
    onSelect: function(items) {
      this.selected = items;
    },
  },
};
</script>
