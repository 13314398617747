<template>
  <div>
    <!--ไดอะลอก Property-->
    <md-dialog
      style="min-height: 40%; min-width: 40%"
      :md-active.sync="Property"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.6rem" align="center">
        Set Property
      </md-dialog-title>
      <md-content align="left">
        <div class="row">
          <div class="col-12" style="margin-left: 2.5rem; height: 2.5rem">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="selected"
                :options="showSetBranch"
                :aria-describedby="ariaDescribedby"
                plain
                stacked
              ></b-form-checkbox-group>
              <hr />
              <div class="row">
                <div class="col-12">
                  <md-button class="md-rose md-sm" @click="Property = false">
                    Close
                  </md-button>
                  <md-button
                    class="md-success md-sm"
                    @click="setProp(selected)"
                  >
                    OK
                  </md-button>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
      </md-content>
      <md-dialog-actions></md-dialog-actions>
    </md-dialog>
    <!--ปิดไดอะลอก Property-->
    <!--ไดอะลอก Department-->
    <md-dialog
      class="md-layout-item md-size-40 md-medium-size-60 md-small-size-80 md-xsmall-size-80"
      style="height: 70%; width: 40%"
      :md-active.sync="showDepartment"
    >
      <md-dialog-title style="font-size: 1.6rem" align="center">
        Set Department
      </md-dialog-title>
      <md-content
        align="left"
        class="scrol md-scrollbar md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
      >
        <div
          class="md-layout-item md-size-80 md-medium-size-95 md-small-size-90 md-xsmall-size-90"
          style="text-align: left"
          :description="searchDepart"
        >
          <md-field>
            <label>Search Department</label>
            <md-input
              v-model="search_dep"
              class="md-size-100 searchDep"
            ></md-input>
            <md-icon>search</md-icon>
          </md-field>
        </div>
        <div class="row">
          <div class="col-12" style="margin-left: 2.5rem; height: 2.5rem">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="arr"
                :options="showSetDepartment"
                :aria-describedby="ariaDescribedby"
                plain
                stacked
              ></b-form-checkbox-group>
              <hr />
              <div class="row">
                <div class="col-12">
                  <md-button class="md-rose md-sm" @click="Property = false">
                    Close
                  </md-button>
                  <md-button class="md-success md-sm" @click="setDep(arr)">
                    OK
                  </md-button>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
        <!-- <div
          class="row"
          v-for="DPM in showSetDepartment"
          :value="DPM.dep_nameth"
          :key="DPM.dep_nameth + 'Department'"
        >
          <div class="col-12" style="margin-left: 2rem; height: 2.5rem;">
            <md-checkbox v-model="arr" :value="DPM" :key="DPM.id">{{
              DPM.dep_nameth
            }}</md-checkbox>
          </div>
        </div> -->
      </md-content>
      <md-dialog-actions></md-dialog-actions>
    </md-dialog>
    <!--ปิดไดอะลอก Department-->
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>add_circle_outline</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <md-button @click="goBack" class="md-primary md-dense">
            Back to List
          </md-button>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout spacing md-alignment-left memoName">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <label style="margin-top: 1.3rem; text-align: left"
              >Memo Name:
            </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
          >
            <md-field>
              <label>Please specify memo name</label>
              <md-input
                class="md-size-50 searchID"
                v-model="memo_name"
              ></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left detail">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <label style="margin-top: 1.3rem; text-align: left">Detail: </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
          >
            <md-field>
              <label>Please specify detail</label>
              <md-textarea v-model="memo_detail"></md-textarea>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left prop">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <md-button
              class="md-rose md-round bt-size"
              @click="setProperty(showSetProp)"
            >
              Set Property
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
          >
            <div v-for="s in showSetProp" :key="s.id + s.br_nameth">
              {{ s.br_nameth }}
            </div>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left dep">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <md-button
              class="md-warning md-round bt-size"
              @click="setDepartment()"
            >
              Set Department
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
          >
            <div v-for="s in showSetDep" :key="s.id + s.dep_nameth">
              {{ s.dep_nameth }}
            </div>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left addimg">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <md-button class="md-info md-round md-fileinput bt-size">
              <md-icon>add_photo_alternate</md-icon>
              <template v-if="!image">ADD image</template>
              <template v-else>Change</template>
              <input type="file" accept="image/*" @change="onFileChange" />
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
          >
            <div v-if="!image">
              <label class="txt_size">***ขนาดไฟล์ต้องไม่เกิน 2 MB!</label>
            </div>
            <div v-if="image">
              <div class="image-container">
                <img :src="image" />
              </div>
              <md-button
                class="md-danger md-round"
                @click="removeImage()"
                v-if="image"
              >
                <i class="fa fa-times" />
                Remove
              </md-button>
            </div>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left addPDF">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <md-button class="md-primary md-round md-fileinput bt-size">
              <template v-if="!PDF">ADD PDF</template>
              <template v-else>Change PDF</template>
              <input type="file" accept=".pdf" @change="onFilePDFChange" />
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
          >
            <md-field>
              <md-input
                class="md-size-50 setProperty"
                v-model="PDFname"
                :disabled="disabled_dep"
                placeholder="***ขนาดไฟล์ต้องไม่เกิน 10 MB!"
              >
                {{ PDFname }}
              </md-input>
              <md-button
                class="md-just-icon md-round md-sm"
                @click="removePDF()"
                v-if="PDFname"
              >
                <i class="fa fa-times" />
              </md-button>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left addFile">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <md-button class="md-dark md-round md-fileinput bt-size">
              <template v-if="!files">ADD File</template>
              <template v-else>Change File</template>
              <input
                type="file"
                accept=".xlsx,.csv,.docx,.pptx"
                @change="onFilefileChange"
              />
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
          >
            <md-field>
              <md-input
                class="md-size-50 setProperty"
                v-model="filename"
                :disabled="disabled_dep"
                placeholder="***ขนาดไฟล์ต้องไม่เกิน 10 MB!"
              >
                {{ filename }}
              </md-input>
              <md-button
                class="md-just-icon md-round md-sm"
                @click="removefile()"
                v-if="filename"
              >
                <i class="fa fa-times" />
              </md-button>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left addtag">
          <b-form-tags
            v-model="add_tag"
            no-outer-focus
            class="mb-2 md-layout-item md-size-60 md-medium-size-70 md-small-size-90 md-xsmall-size-100"
          >
            <template v-slot="{ tags, disabled, removeTag, inputAttrs }">
              <ul
                v-if="tags.length > 0"
                class="list-inline d-inline-block mb-2"
              >
                <li
                  v-for="tag in add_tag"
                  :key="tag"
                  :value="tag"
                  class="scrol md-scrollbar list-inline-item"
                >
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag"
                    :disabled="disabled"
                    variant="info"
                  >
                    {{ JSON.parse(tag).tag_sos_name }}
                  </b-form-tag>
                </li>
              </ul>
              <b-input-group class="mb-2">
                <b-form-input
                  v-model="search"
                  v-bind="inputAttrs"
                  @keyup.enter="onOptionClick({ id: 0, tag_sos_name: search })"
                  id="tag-search-input"
                  type="search"
                  autocomplete="off"
                  placeholder="New tag - Press enter to add"
                  class="form-control"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    @click="onOptionClick({ id: 0, tag_sos_name: search })"
                    variant="primary"
                  >
                    Add
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-dropdown
                size="sm"
                variant="outline-secondary"
                block
                menu-class="w-100"
                id="dropdown_tag"
              >
                <template #button-content>
                  <b-icon icon="tag-fill"></b-icon> Choose tags
                </template>
                <b-dropdown-form @submit.stop.prevent="() => {}">
                  <b-form-group
                    label="Search tags"
                    label-for="tag-search-input"
                    label-cols-md="auto"
                    class="mb-0"
                    label-size="sm"
                    :description="searchDesc"
                    :disabled="disabled"
                  >
                    <b-form-input
                      v-model="search_tag"
                      id="tag-search-input"
                      type="search"
                      size="sm"
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                </b-dropdown-form>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item-button
                  v-for="option in availableOptions"
                  :key="option.id"
                  :value="option"
                  @click="onOptionClick(option)"
                >
                  {{ option.tag_sos_name }}
                </b-dropdown-item-button>
                <b-dropdown-text v-if="availableOptions.length === 0">
                  There are no tags available to select
                </b-dropdown-text>
              </b-dropdown>
            </template>
          </b-form-tags>
        </div>
        <div class="md-layout spacing md-alignment-left save">
          <div
            class="md-layout-item md-size-45 md-medium-size-80 md-small-size-60 md-xsmall-size-100"
            style="text-align: right"
          >
            <md-button class="md-success md-dense" @click="save_sos()">
              Save
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import $ from 'jquery';
export default {
  components: {},
  async created() {
    this.getlistbranch();
    this.getlistdep();
    await this.show_tag();
  },
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search_tag.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.options.filter(
        (opt) => this.add_tag.indexOf(opt) === -1,
      );
      if (criteria) {
        // Show only options that match criteria
        return options.filter((opt) => {
          if (opt.tag_sos_name.toLowerCase().indexOf(criteria) > -1) {
            return opt;
          }
        });
      }
      // Show all options available

      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria';
      }
      return '';
    },
    key_search_tag() {
      // Compute the search criteria
      return this.search_dep.trim().toLowerCase();
    },
    showSetDepartment() {
      const search_dep = this.key_search_tag;
      if (search_dep) {
        // Show only options that match criteria
        return this.Department.filter(
          (ssd) => ssd.text.toLowerCase().indexOf(search_dep) > -1,
        );
      }
      // Show all options available
      return this.Department;
    },
    searchDepart() {
      if (this.search_dep && this.showSetDepartment.length === 0) {
        return 'There are no tags matching your search criteria';
      }
      return '';
    },
  },
  data() {
    return {
      list: [],
      image: '',
      search: '',
      search_dep: '',
      file: null,
      PDF: '',
      PDFname: '',
      files: '',
      filename: '',
      Property: false,
      showDepartment: false,
      selected: [],
      allSelected: [],
      arr: [],
      showSetProp: [],
      showSetDep: [],
      Department: [],
      showSetBranch: [],
      disabled_prop: true,
      disabled_dep: true,
      add_tag: [],
      search_tag: '',
      options: [],
      memo_name: '',
      memo_detail: '',
      data_filePDF: [],
    };
  },
  methods: {
    showfrom_add() {},
    goBack() {
      this.$router.push({
        path: 'santhiayaSOS-management',
        name: 'จัดการสัญธิญา SOS',
      });
    },
    async getlistbranch() {
      let resp = await this.$store.dispatch('contract/getbranch');
      let branchs = [];
      $(resp).each((i, o) => {
        branchs.push({
          text: o.br_nameth,
          value: { id: o.branch_id, br_nameth: o.br_nameth },
        });
      });
      this.showSetBranch = branchs;
    },
    async setProperty(prop) {
      this.Property = true;
      //this.showSetBranch = data;
    },
    async setProp(array) {
      this.Property = false;
      this.disabled_prop = false;
      this.showSetProp = array;
    },
    async getlistdep() {
      let resp = await this.$store.dispatch('staffs/getdepartment');
      let dep = [];
      $(resp).each((i, o) => {
        dep.push({
          text: o.dep_nameth,
          value: { id: o.id, dep_nameth: o.dep_nameth },
        });
      });
      this.Department = dep;
    },
    async setDepartment() {
      this.showDepartment = true;
    },
    async setDep(arr) {
      this.showDepartment = false;
      this.disabled_dep = false;
      this.showSetDep = arr;
    },
    async onFilePDFChange(e) {
      let files = '';
      let filePDFname = '';
      $(e.target.files).each(async function(i, o) {
        if (o.type == 'application/pdf') {
          files = e.target.files || e.dataTransfer.files;
          filePDFname = o.name;
        }
      });
      if (files.length == 1) {
        this.createPDF(files[0]);
        this.PDFname = filePDFname;
        this.data_filePDF = files;
      } else {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกไฟล์ PDF');
        return false;
      }
    },
    async onFileChange(e) {
      let files = '';
      $(e.target.files).each(async function(i, o) {
        if (
          o.type == 'image/jpeg' ||
          o.type == 'image/png' ||
          o.type == 'image/gif'
        ) {
          files = e.target.files || e.dataTransfer.files;
        }
      });
      if (files.length == 1) {
        if (!files.length) return;
        this.createImage(files[0]);
      } else {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกไฟล์รูปภาพ');
        return false;
      }
    },
    async onFilefileChange(e) {
      let files = '';
      let filefilename = '';
      $(e.target.files).each(async function(i, o) {
        if (o.type != 'application/pdf') {
          if (o.type != 'image/jpeg') {
            if (o.type != 'image/png') {
              if (o.type != 'image/gif') {
                files = e.target.files || e.dataTransfer.files;
                filefilename = o.name;
              }
            }
          }
        }
      });
      if (files.length == 1) {
        this.createfile(files[0]);
        this.filename = filefilename;
      } else {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกไฟล์');
        return false;
      }
    },
    createImage(file) {
      if (file.size < 2000000) {
        this.$store.dispatch('alerts/success', 'ขนาดไฟล์ถูกต้อง');
        let reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
          this.file = file;
        };
        reader.readAsDataURL(file);
      } else {
        this.$store.dispatch('alerts/error', 'ไฟล์มีขนาดเกินกว่า 2MB!');
      }
    },
    createPDF(file) {
      if (file.size < 10000000) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.PDF = e.target.result;
          this.file = file;
        };
        reader.readAsDataURL(file);
      } else {
        this.$store.dispatch('alerts/error', 'ไฟล์มีขนาดเกินกว่า 10MB!');
      }
    },
    createfile(file) {
      if (file.size < 10000000) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.files = e.target.result;
          this.file = file;
        };
        reader.readAsDataURL(file);
      } else {
        this.$store.dispatch('alerts/error', 'ไฟล์มีขนาดเกินกว่า 10MB!');
      }
    },
    removeImage() {
      this.image = null;
    },
    removePDF() {
      this.PDFname = null;
      this.PDF = null;
    },
    removefile() {
      this.filename = null;
      this.files = null;
    },
    onOptionClick(option) {
      this.add_tag.push(option);
      this.search_tag = '';
      this.search = '';
    },
    async show_tag() {
      let obj = '1';
      let resp = await this.$store.dispatch('santhiyaSOS/gettagsos', obj);
      this.options = resp;
    },
    async save_sos() {
      let data = {};
      let id_prop = {};
      let id_dep = {};
      $(this.showSetProp).each(function(i, o) {
        id_prop[i] = o.id;
      });
      $(this.showSetDep).each(function(i, o) {
        id_dep[i] = o.id;
      });
      var tags = [];
      $(this.add_tag).each(function(index, v) {
        tags.push(JSON.parse(v));
      });
      if (this.memo_name == '') {
        await this.$store.dispatch('alerts/error', 'กรุณาระบุชื่อ Memo');
        return false;
      }
      if (this.memo_detail == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุรายละเอียดของ Memo',
        );
        return false;
      }
      if (id_prop == '') {
        await this.$store.dispatch('alerts/error', 'กรุณา Set Property');
        return false;
      }
      if (id_dep == '') {
        await this.$store.dispatch('alerts/error', 'กรุณา Set Department');
        return false;
      }
      if (tags == '') {
        await this.$store.dispatch('alerts/error', 'กรุณาระบุ Tag search');
        return false;
      }
      data['memo_name'] = this.memo_name;
      data['memo_detail'] = this.memo_detail;
      data['memo_property'] = id_prop;
      data['memo_dep'] = id_dep;
      data['memo_image'] = this.image;
      data['memo_PDF'] = this.PDF;
      data['memo_file_other'] = this.files;
      data['memo_tag_data'] = tags;
      let resp = await this.$store.dispatch('santhiyaSOS/saveSos', data);
      if (resp.status == 0) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        return false;
      } else if (resp.status == 1) {
        await this.$store.dispatch('alerts/success', 'เพิ่ม Memmo สำเร็จ.');
        this.goBack();
      }
    },
  },
};
</script>
<style>
.md-content.scrol {
  max-width: 450px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.bt-size {
  width: 100%;
}
.txt_size {
  margin: auto;
  width: 50%;
  padding: 10px;
  color: gray;
}
</style>
