import service from '@/store/services/ots-service';

const state = {
  list: {},
  ot: {},
  meta: {},
  staffot: {},
  otstafflist: {},
  otamout: 0,
  ottotal: 0,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },

  SET_RESOURCE: (state, ot) => {
    state.ot = ot;
  },

  SET_META: (state, meta) => {
    state.meta = meta;
  },

  SET_OTSTAFFLIST: (state, otstafflist) => {
    state.otstafflist = otstafflist;
  },

  SET_STAFFOT: (state, staffot) => {
    state.staffot = staffot;
  },

  SET_OTTOTAL: (state, value) => {
    state.ottotal = value;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      commit('SET_META', meta);
      return list;
    });
  },
  otlistsetting({ commit, dispatch }, params) {
    return service.otlistsetting(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      commit('SET_META', meta);
      return list;
    });
  },
  createotsetting({ commit, dispatch }, params) {
    return service.createotsetting(params).then(({ list, meta }) => {
      return list;
    });
  },
  setOTAmout({ commit, dispatch }, value) {
    commit('SET_OTTOTAL', state.meta.page.ot.total);
  },

  otstafflist({ commit, dispatch }, params) {
    return service.otstafflist(params).then(({ list, meta }) => {
      commit('SET_OTSTAFFLIST', list);
      commit('SET_META', meta);
    });
  },

  addStaffOT({ commit, dispatch }, params) {
    return service.addStaffOT(params).then((ot) => {
      commit('SET_STAFFOT', ot);
    });
  },

  addStaffOTApprove({ commit, dispatch }, params) {
    return service.addStaffOTApprove(params).then((ot) => {
      commit('SET_STAFFOT', ot);
    });
  },

  staffotlist({ commit, dispatch }, params) {
    return service.staffotlist(params).then(({ staffot, meta }) => {
      commit('SET_STAFFOT', staffot);
      commit('SET_META', meta);
    });
  },

  otapprove({ commit, dispatch }, params) {
    return service.otapprove(params).then((approval) => {
      commit('SET_STAFFOT', approval);
    });
  },

  async updateOT({ commit, dispatch }, params) {
    return await service.updateOT(params);
  },

  async deleteOT({ commit, dispatch }, params) {
    return await service.deleteOT(params);
  },

  async getOTOverLimit({ commit, dispatch }, params) {
    return await service.getOTOverLimit(params);
  },

  async getOTOverLimitReportList({ commit, dispatch }, params) {
    return await service.getOTOverLimitReportList(params);
  },
  async getOTOverLimitReport({ commit, dispatch }, params) {
    return await service.getOTOverLimitReport(params);
  },

  async createOTOverLimitReport({ commit, dispatch }, params) {
    return await service.createOTOverLimitReport(params);
  },

  async approverOTOverLimit({ commit, dispatch }, params) {
    return await service.approverOTOverLimit(params);
  },

  async approveOTOverLimitReport({ commit, dispatch }, params) {
    return await service.approveOTOverLimitReport(params);
  },
};

const getters = {
  list: (state) => state.list,
  ot: (state) => state.ot,
  otstafflist: (state) => state.otstafflist,
  ottotal: (state) => state.ottotal,
  staffotlist: (state) => state.staffot,
  dropdown: (state) => {
    return state.list.map((ot) => ({
      id: ot.id,
      name: ot.name,
    }));
  },
};

const ots = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default ots;
