<template>
  <div v-if="permissions.includes('view report')">
    <md-dialog
      :md-active.sync="showImgDialog"
      style="min-height: 30%; min-width: 30%;"
    >
      <md-dialog-title align="center">Profile Image</md-dialog-title>
      <md-content align="center">
        <div class="imgclass">
          <b-img center thumbnail fluid :src="`${showImg}`" width="300"></b-img>
        </div>
      </md-content>
      <md-dialog-actions>
        <md-button class="md-primary md-sm" @click="showImgDialog = false">
          Close
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <b-container fluid>
      <b-row>
        <b-col cols="6" sm="3" md="3" lg="3">
          <b-button
            pill
            variant="primary"
            class="font-size mt-2"
            @click="decodeSalary"
            :disabled="disabled_privatecode"
          >
            <md-icon class="font-imp">lock</md-icon> PRIVATE CODE
          </b-button>
          <b-button class="mt-2" variant="success" @click="exportPDF()">
            PDF
          </b-button>
          <!-- <b-button class="" variant="success" @click="exportExcel()">
            EXPORT
          </b-button> -->
        </b-col>
        <b-col cols="12" sm="6" md="4" lg="4">
          <h3 align="center">รายงานเงินเดือน</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" class="mt-2">
          <b-form-select v-model="branchs">
            <b-form-select-option :value="null" disabled>
              เลือก สำนักงาน
            </b-form-select-option>
            <b-form-select-option
              v-for="item in available_branchs"
              :key="item.bch_id"
              :value="item.bch_id"
            >
              {{ item.brname_th }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" class="mt-2">
          <b-form-select v-model="years">
            <b-form-select-option :value="null">
              เลือก ปี
            </b-form-select-option>
            <b-form-select-option
              v-for="item in listyear"
              :key="item"
              :value="item"
            >
              {{ item }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" class="mt-2">
          <b-form-select v-model="report_select" :disabled="disabled_rp">
            <b-form-select-option :value="null" disabled>
              เลือก รายงาน
            </b-form-select-option>
            <b-form-select-option
              v-for="item in available_report"
              :key="item.report_id"
              :value="item"
            >
              งวดจ่าย {{ item.name_pay_date_th }} (
              {{ item.name_fromdate_th }} - {{ item.name_todate_th }})
              <!-- <span v-if="item.stafftype == 'day'">(รายวัน)</span>
              <span v-else-if="item.stafftype == 'month'">(รายเดือน)</span>
              <span v-else-if="item.stafftype == 'all'">(ทั้งหมด)</span> -->
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" class="mt-2">
          <b-input-group>
            <b-form-select v-model="departments" :disabled="disabled_dep">
              <b-form-select-option :value="null" disabled>
                เลือก แผนก
              </b-form-select-option>
              <b-form-select-option
                v-for="item in available_departments"
                :key="item.dep_id"
                :value="item.dep_id"
              >
                {{ item.depname_th }}
              </b-form-select-option>
            </b-form-select>
            <b-input-group-append>
              <b-button variant="danger" @click="clearDepartment()">
                X
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-container>
    <br />
    <b-container
      fluid
      v-for="(items_report, index) in report"
      :key="index + 'rp'"
    >
      <!-- <div class="box-header-salary" v-if="report_select">
        <div>เงินเดือนพนักงาน บริษัท บุ๊คเอนจิ้นดอทคอม (ประเทศไทย) จำกัด</div>
        <div>ประจำงวดวันที่ {{ report_select.name_fromdate_th }}</div>
      </div> -->
      <div class="box-header-salary" v-if="report_select">
        <div style="font-size: 20px;">{{ items_report.depname_en }}</div>
        <div style="font-size: 18px;">
          ประจำงวดจ่ายวันที่ {{ report_select.name_pay_date_full_th }} ({{
            report_select.name_fromdate_th
          }}
          - {{ report_select.name_todate_th }})
        </div>
      </div>
      <b-table-simple
        sticky-header="750px"
        v-if="report"
        class="tablebordercolor"
      >
        <b-thead head-variant="dark" class="theadbordercolor">
          <b-tr>
            <b-th>No.</b-th>
            <b-th>Image</b-th>
            <b-th>รหัสพนักงาน</b-th>
            <b-th sticky-column>ชื่อพนักงาน</b-th>
            <b-th>แผนก</b-th>
            <b-th>ตำแหน่ง</b-th>
            <b-th>ประเภท</b-th>
            <b-th>เงินเดือน</b-th>
            <b-th>จำนวนวันทำงาน</b-th>
            <b-th>รวมเงินเดือน</b-th>
            <b-th>ค่าล่วงเวลา</b-th>
            <b-th>ค่าล่วงเวลาเหมาจ่าย</b-th>
            <b-th>เซอร์วิสชาร์จ</b-th>
            <b-th>ค่ามือนวดสปา</b-th>
            <!-- <b-th>คืนค่าบริหารจัดการ</b-th> -->
            <!-- <b-th>
              คืนค่าชุด/ประกันทรัพย์สินเสียหาย
            </b-th> -->
            <b-th>เบี้ยเลี้ยงค่าอาหาร</b-th>
            <b-th>ทิป</b-th>
            <b-th>ค่าจ้างค้างรับ</b-th>
            <b-th>เงินพิเศษ</b-th>
            <b-th>คืนวันหยุด/บาท</b-th>
            <b-th>รวมเงินได้ก่อนหัก คชจ.</b-th>
            <!-- <b-th>
              หักขาดงาน/ลาไม่รับค่าจ้าง(วัน)
            </b-th> -->
            <b-th> หักขาดงาน/ลาไม่รับค่าจ้าง(บาท) </b-th>
            <b-th>หักประกันสังคม</b-th>
            <b-th>รวมรายจ่าย</b-th>
            <b-th>รวมเงินได้สุทธิ</b-th>
          </b-tr>
        </b-thead>
        <b-tbody class="background-white">
          <b-tr
            v-for="(items, index) in items_report.data"
            :key="items.id + 'data' + index"
          >
            <b-td>{{ index + 1 }}</b-td>
            <b-td>
              <div class="photo">
                <b-img
                  class="img pointer"
                  rounded="circle"
                  :src="`${items.images}`"
                  @click="openImages(items.images)"
                />
              </div>
            </b-td>
            <b-td>{{ items.emp_code }}</b-td>
            <b-th sticky-column>
              <span>
                {{ items.titlename_th }} {{ items.empfname_th }}
                {{ items.emplname_th }}
              </span>
            </b-th>
            <b-td>
              {{ items_report.depname_en }}
            </b-td>
            <b-td> {{ items.empposname_en }} </b-td>
            <b-td v-if="items.stafftype == 'month'"> รายเดือน </b-td>
            <b-td v-else> รายวัน </b-td>
            <b-td v-if="typeof items.salary_amt == 'number'">
              {{ items.salary_amt }}
            </b-td>
            <b-td v-else> ****** </b-td>
            <b-td> {{ items.daywork }} </b-td>
            <b-td> {{ items.total_salary }} </b-td>
            <b-td> {{ items.ot_total }} </b-td>
            <b-td> {{ items.ot_bath }} </b-td>
            <b-td> {{ items.total_svc }} </b-td>
            <b-td> {{ items.massage_income }} </b-td>
            <!-- <b-td> {{ items.manage_income }} </b-td> -->
            <!-- <b-td> - </b-td> -->
            <b-td> {{ items.food_service }} </b-td>
            <b-td> {{ items.tip }} </b-td>
            <b-td> {{ items.accrued_income }} </b-td>
            <b-td> {{ items.bonus }} </b-td>
            <b-td> {{ items.keepdays_baht }} </b-td>
            <b-td> {{ items.income_total }} </b-td>
            <!-- <b-td> {{ items.notwork_day }} </b-td> -->
            <b-td> {{ items.notwork_bath }} </b-td>
            <b-td> {{ items.sso_bath }} </b-td>
            <b-td> {{ items.cost_total }} </b-td>
            <b-td> {{ items.income_net }} </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot foot-variant="dark">
          <b-tr>
            <b-th></b-th>
            <b-th></b-th>
            <b-th sticky-column></b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th> {{ sum_all[index].salary_amt }} </b-th>
            <b-th> {{ sum_all[index].daywork }} </b-th>
            <b-th> {{ sum_all[index].total_salary }} </b-th>
            <b-th> {{ sum_all[index].ot_total }} </b-th>
            <b-th> {{ sum_all[index].ot_bath }} </b-th>
            <b-th> {{ sum_all[index].total_svc }} </b-th>
            <b-th> {{ sum_all[index].massage_income }} </b-th>
            <!-- <b-th> - </b-th> -->
            <b-th> {{ sum_all[index].food_service }} </b-th>
            <b-th> {{ sum_all[index].tip }} </b-th>
            <b-th> {{ sum_all[index].accrued_income }} </b-th>
            <b-th> {{ sum_all[index].bonus }} </b-th>
            <b-th> {{ sum_all[index].keepdays_baht }} </b-th>
            <b-th> {{ sum_all[index].income_total }} </b-th>
            <!-- <b-th> {{ sum_all[index].notwork_day }} </b-th> -->
            <b-th> {{ sum_all[index].notwork_bath }} </b-th>
            <b-th> {{ sum_all[index].sso_bath }} </b-th>
            <b-th> {{ sum_all[index].cost_total }} </b-th>
            <b-th> {{ sum_all[index].income_net }} </b-th>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-container>
    <b-container>
      <b-table-simple v-if="report" small caption-top stacked>
        <b-thead head-variant="dark" class="theadbordercolor">
          <b-tr>
            <b-th>จำนวนคน</b-th>
            <b-th>เงินเดือน</b-th>
            <b-th>จำนวนวันทำงาน</b-th>
            <b-th>รวมเงินเดือน</b-th>
            <b-th>ค่าล่วงเวลา</b-th>
            <b-th>ค่าล่วงเวลาเหมาจ่าย</b-th>
            <b-th>ค่ามือนวดสปา</b-th>
            <b-th>คืนค่าบริหารจัดการ</b-th>
            <!-- <b-th>
              คืนค่าชุด/ประกันทรัพย์สินเสียหาย
            </b-th> -->
            <b-th>เบี้ยเลี้ยงค่าอาหาร</b-th>
            <b-th>ทิป</b-th>
            <b-th>ค่าจ้างค้างรับ</b-th>
            <b-th>เงินพิเศษ</b-th>
            <b-th>คืนวันหยุด/บาท</b-th>
            <b-th>รวมเงินได้ก่อนหัก คชจ.</b-th>
            <!-- <b-th>
              หักขาดงาน/ลาไม่รับค่าจ้าง(วัน)
            </b-th> -->
            <b-th> หักขาดงาน/ลาไม่รับค่าจ้าง(บาท) </b-th>
            <b-th> หักประกันสังคม </b-th>
            <b-th>รวมรายจ่าย</b-th>
            <b-th>รวมเงินได้สุทธิ</b-th>
          </b-tr>
        </b-thead>
        <b-tbody class="background-white">
          <b-tr>
            <b-td stacked-heading="จำนวนคน :">
              {{ sum_all_property.person_number }}
            </b-td>
            <b-td stacked-heading="เงินเดือน :">
              {{ sum_all_property.salary_amt }}
            </b-td>
            <b-td stacked-heading="จำนวนวันทำงาน :">
              {{ sum_all_property.daywork }}
            </b-td>
            <b-td stacked-heading="รวมเงินเดือน :">
              {{ sum_all_property.total_salary }}
            </b-td>
            <b-td stacked-heading="ค่าล่วงเวลา :">
              {{ sum_all_property.ot_total }}
            </b-td>
            <b-td stacked-heading="ค่าล่วงเวลาเหมาจ่าย :">
              {{ sum_all_property.ot_bath }}
            </b-td>
            <b-td stacked-heading="เซอร์วิสชาร์จ :">
              {{ sum_all_property.total_svc }}
            </b-td>
            <b-td stacked-heading="ค่ามือนวดสปา :">
              {{ sum_all_property.massage_income }}
            </b-td>
            <!-- <b-td stacked-heading="คืนค่าบริหารจัดการ :">
              {{ sum_all_property.manage_income }}
            </b-td> -->
            <!-- <b-td stacked-heading="คืนค่าชุด/ประกันทรัพย์สินเสียหาย :">
              -
            </b-td> -->
            <b-td stacked-heading="เบี้ยเลี้ยงค่าอาหาร :">
              {{ sum_all_property.food_service }}
            </b-td>
            <b-td stacked-heading="ทิป :">
              {{ sum_all_property.tip }}
            </b-td>
            <b-td stacked-heading="ค่าจ้างค้างรับ :">
              {{ sum_all_property.accrued_income }}
            </b-td>
            <b-td stacked-heading="เงินพิเศษ :">
              {{ sum_all_property.bonus }}
            </b-td>
            <b-td stacked-heading="คืนวันหยุด/บาท :">
              {{ sum_all_property.keepdays_baht }}
            </b-td>
            <b-td stacked-heading="รวมเงินได้ก่อนหัก คชจ. :">
              {{ sum_all_property.income_total }}
            </b-td>
            <!-- <b-td stacked-heading="หักขาดงาน/ลาไม่รับค่าจ้าง(วัน) :">
              {{ sum_all_property.notwork_day }}
            </b-td> -->
            <b-td stacked-heading="หักขาดงาน/ลาไม่รับค่าจ้าง(บาท) :">
              {{ sum_all_property.notwork_bath }}
            </b-td>
            <b-td stacked-heading="หักประกันสังคม :">
              {{ sum_all_property.sso_bath }}
            </b-td>
            <b-td stacked-heading="รวมรายจ่าย :">
              {{ sum_all_property.cost_total }}
            </b-td>
            <b-td stacked-heading="รวมเงินได้สุทธิ :">
              {{ sum_all_property.income_net }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-container>
    <BoxAppved
      v-if="report && !departments"
      :persons="persons"
      :branchs="branchs"
      :report_select="report_select"
      :profile="profile"
      @callback="getReport"
    />
  </div>
</template>
<script>
import $ from 'jquery';
import BoxAppved from '@/pages/Dashboard/Components/BoxAppved.vue';
import moment from 'moment';
import Vuex from 'vuex';
import md5 from 'js-md5';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import XLSX from 'xlsx';
import { loading_start, loading_close } from '@/utils/loading.js';
import Swal from 'sweetalert2';
import { createElement } from '@fullcalendar/core';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'popper.js/dist/popper.min.js';
// import 'bootstrap/dist/js/bootstrap.min.js';
// import SignaturePad from 'signature_pad';
export default {
  name: 'ApprovedSalary',
  components: { BoxAppved },
  mixins: [],
  data() {
    return {
      mainProps: {
        width: 75,
        height: 75,
        class: 'm1',
      },
      listyear: [],
      years: null,
      profile: null,
      branchs: null,
      available_branchs: null,
      departments: null,
      available_departments: null,
      disabled_dep: true,
      disabled_rp: true,
      disabled_privatecode: false,
      report: null,
      report_select: {
        report_id: null,
        from_date: null,
        to_date: null,
        // stafftype: null,
      },
      available_report: null,
      sum_all: [],
      sum_all_property: [],
      host: process.env.VUE_APP_API_BASE_URL,
      accesstoken: localStorage.getItem(
        'vue-authenticate.vueauth_access_token',
      ),
      comIds: [],
      avatar: process.env.VUE_APP_APP_BASE_URL + '/img/default-avatar.png',
      showImgDialog: false,
      showImg: null,
    };
  },
  async created() {
    moment.locale('th');
    loading_start();
    let params = { profile: true };
    let profile = await this.$store.dispatch('staffs/searchStaff', params);
    this.profile = { ...profile[0] };
    await this.getBranchs();
    // this.permissions.push('reportnotprivatecode');
    if (
      localStorage.getItem('privatecode') ||
      this.permissions.includes('reportnotprivatecode')
    ) {
      this.disabled_privatecode = true;
    }
    loading_close();
  },
  mounted() {},
  props: {},
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var pms = [];
      if (this.me) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }
      return pms;
    },
  },
  watch: {
    branchs: function() {
      this.getDepartments();
      this.getListyearReport();
      this.listReport();
      this.years = null;
      this.report_select = null;
      this.report = null;
    },
    years: function() {
      this.listReport();
      this.report_select = null;
      this.report = null;
    },
    departments: function() {
      // this.listReport();
      if (this.report_select) {
        this.getReport();
      }
    },
    report_select: function() {
      if (this.report_select != null) {
        this.getReport();
      }
    },
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = this.avatar;
    },
    openImages(img) {
      this.showImg = img;
      this.showImgDialog = true;
    },
    async getListyearReport() {
      let params = {
        bch_id: this.branchs,
      };
      let list = await this.$store.dispatch(
        'salarys/getListyearReport',
        params,
      );
      let arr = [];
      for (let value of list) {
        arr.push(value.years);
      }
      this.listyear = arr;
      if (arr.length > 0) {
        this.years = arr[0]; // select first
        // this.years = arr[arr.length - 1]; // select last
      } else {
        this.listyear = ['ไม่มีรายงาน'];
        this.years = 'ไม่มีรายงาน';
        this.disabled_rp = true;
        this.disabled_dep = true;
      }
    },

    async getBranchs() {
      let params = {
        com_id: this.profile.com_id,
        bch_only: '1',
      };
      await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
        this.available_branchs = data;
      });
      if (!this.permissions.includes('process salary')) {
        var arr = [];
        this.available_branchs.map((bch) => {
          if (this.permissions.includes(bch.brname_en)) {
            arr.push(bch);
          }
        });
        this.available_branchs = arr;
      }
    },

    async getDepartments() {
      this.departments = null;
      let params = {
        com_id: this.profile.com_id,
        ...{ bch_id: this.branchs ? this.branchs : {} },
        dep_only: '1',
      };
      await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
        this.available_departments = data;
        if (this.available_departments.length > 0) {
          this.disabled_dep = false;
        }
      });
    },

    async listReport() {
      this.available_report = null;
      loading_start();
      let params = {
        ...{ years: this.years ? this.years : {} },
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{ dep_id: this.departments ? this.departments : {} },
      };

      let res = await this.$store.dispatch('salarys/listReport', params);
      res.forEach((data) => {
        let from_date_years = moment(data.from_date).year();
        let to_date_years = moment(data.to_date).year();
        let pay_date_years = moment(data.pay_date).year();
        let from_date_years_fmt = from_date_years + 543;
        let to_date_years_fmt = to_date_years + 543;
        let pay_date_years_fmt = pay_date_years + 543;

        data.name_fromdate_th = moment(data.from_date)
          .format('ll')
          .replace(from_date_years, from_date_years_fmt);
        data.name_fromdate_full_th = moment(data.from_date)
          .format('LL')
          .replace(from_date_years, from_date_years_fmt);

        data.name_todate_th = moment(data.to_date)
          .format('ll')
          .replace(to_date_years, to_date_years_fmt);
        data.name_todate_full_th = moment(data.to_date)
          .format('LL')
          .replace(to_date_years, to_date_years_fmt);

        data.name_pay_date_th = moment(data.pay_date)
          .format('ll')
          .replace(pay_date_years, pay_date_years_fmt);
        data.name_pay_date_full_th = moment(data.pay_date)
          .format('LL')
          .replace(pay_date_years, pay_date_years_fmt);
      });
      if (res.length > 0) {
        this.available_report = res;
        this.disabled_rp = false;
      }
      loading_close();
    },

    async getReport() {
      loading_start();
      let params = {
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{ dep_id: this.departments ? this.departments : {} },
        ...{
          from_date: this.report_select.from_date
            ? this.report_select.from_date
            : {},
        },
        ...{
          to_date: this.report_select.to_date ? this.report_select.to_date : {},
        },
        // ...{
        //   stafftype: this.report_select.stafftype
        //     ? this.report_select.stafftype
        //     : {},
        // },
      };
      // if (this.permissions.includes('debug')) {
      //   this.branchs = 2;
      //   params = {
      //     bch_id: 2,
      //     dep_id: {},
      //     from_date: '2022-06-21',
      //     to_date: '2022-07-20',
      //     stafftype: 'month',
      //   };
      // }
      await this.approverReport();
      this.report = null;
      await this.$store
        .dispatch('salarys/getReport', params)
        .then(async (data) => {
          /* eslint-disable */
          if (data.length) {
            var temp_approver = [];
            const nf = new Intl.NumberFormat('en-US');
            const m = Math;
            this.sum_all_property = {
              person_number: 0,
              salary_amt: 0,
              daywork: 0,
              total_salary: 0,
              ot_total: 0,
              ot_bath: 0,
              massage_income: 0,
              manage_income: 0,
              food_service: 0,
              tip: 0,
              accrued_income: 0,
              allowance: 0,
              keepdays_baht: 0,
              bonus: 0,
              total_svc: 0,
              income_total: 0,
              notwork_day: 0,
              notwork_bath: 0,
              sso_bath: 0,
              tax_bath: 0,
              cost_total: 0,
              income_net: 0,
            };
            this.sum_all = [];
            var notformat = [
              'id',
              'rp_id',
              'emp_id',
              'emp_code',
              'emptname_th',
              'empfname_th',
              'emplname_th',
              'emptname_en',
              'empfname_en',
              'emplname_en',
              'empposname_th',
              'empposname_en',
              'fullname_th',
              'fullname_en',
              'stafftype',
              'bchname_th',
              'bch_nameth',
              'bchname_en',
              'comname_th',
              'comname_en',
              'depname_th',
              'depname_en',
              'salary_amt',
              'salary_amt_decode',
              'ot_hr',
              'ot1_hr',
              'ot1_5_hr',
              'ot2_hr',
              'ot2_hr',
              'date_send',
              'empid_send',
              'emp_code_send',
              'emptname_th_send',
              'empfname_th_send',
              'emplname_th_send',
              'emptname_en_send',
              'empfname_en_send',
              'emplname_en_send',
              'from_date',
              'to_date',
              'pay_date',
              'empposname_th_send',
              'empposname_en_send',
              'created_at',
              'updated_at',
              'profile_image',
            ];
            data.forEach(async (rp, index) => {
              var temp = {
                salary_amt: 0,
                daywork: 0,
                total_salary: 0,
                ot_total: 0,
                ot_bath: 0,
                massage_income: 0,
                manage_income: 0,
                food_service: 0,
                tip: 0,
                accrued_income: 0,
                allowance: 0,
                keepdays_baht: 0,
                bonus: 0,
                total_svc: 0,
                income_total: 0,
                notwork_day: 0,
                notwork_bath: 0,
                sso_bath: 0,
                tax_bath: 0,
                cost_total: 0,
                income_net: 0,
              };
              var person_number = 0;
              rp.data.forEach((rp_data, ind) => {
                person_number += 1;
                for (const [key, value] of Object.entries(rp_data)) {
                  if (!notformat.includes(key)) {
                    temp[key] += m.round(value);
                    rp_data[key] = nf.format(m.round(value));
                  }
                }
                if (rp_data.profile_image !== '') {
                  let profile_img =
                    process.env.VUE_APP_IMAGE_BASE_URL + rp_data.profile_image;
                  rp_data['images'] = profile_img;
                  let img = createElement('img');
                  img.src = profile_img;
                  let global = this;
                  img.onerror = function() {
                    rp_data['images'] = global.avatar;
                  };
                } else {
                  rp_data['images'] = this.avatar;
                }
              });
              temp.person_number = person_number;
              for (const [key, value] of Object.entries(temp)) {
                temp[key] = nf.format(value);
              }
              this.sum_all[index] = temp;
              temp_approver = Object.values(JSON.parse(rp.approver));
            });
            this.sum_all.forEach((el) => {
              for (const [key, value] of Object.entries(el)) {
                this.sum_all_property[key] += parseInt(
                  value.replaceAll(',', ''),
                );
              }
            });
            for (const [key, value] of Object.entries(this.sum_all_property)) {
              this.sum_all_property[key] = nf.format(value);
            }
          }
          await this.setPriority(temp_approver);
          this.report = data;
          if (
            this.permissions.includes('reportnotprivatecode') ||
            this.securePrivatecode(localStorage.getItem('privatecode'))
          ) {
            this.decodeSalary();
          }
          /* eslint-enable */
        });
      loading_close();
    },

    async approverReport() {
      let params = { bch_id: this.branchs };
      this.persons = await this.$store.dispatch(
        'salarys/approverReport',
        params,
      );
    },

    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },

    async setPriority(temp_approver) {
      var persons = [...this.persons];
      let array_approver = [];
      var priority = [];
      var priority_count = [];
      var approve_count = 0;
      temp_approver.forEach((approver) => {
        array_approver.push(approver);
        approve_count++;
      });
      let i = 0;
      persons.forEach((p) => {
        priority.push(p.priority);
        i++;
        priority_count[p.priority] = { pri: p.priority, c: i };
      });
      let now_pri = priority_count.filter((pc) => {
        return pc.c <= approve_count;
      });

      let max_pri = priority_count[1];
      if (now_pri.length > 0) {
        max_pri = now_pri.reduce((max, el) => (max.c > el.c ? max : el));
      }

      persons.forEach(async (p) => {
        p.status = 'disabled';
        let have = await array_approver.find((arr_ap) => {
          return arr_ap == p.approver_id;
        });
        if (have) {
          p.status = 'approved';
        } else if (approve_count < 1) {
          if (p.priority == 1) {
            p.status = 'pending';
          }
        } else {
          if (p.priority == max_pri.pri) {
            p.status = 'pending';
          } else if (
            approve_count >= max_pri.c &&
            p.priority == max_pri.pri + 1
          ) {
            p.status = 'pending';
          }
        }
      });
      persons.sort((a, b) => {
        if (a.number < b.number) return -1;
        if (a.number > b.number) return 1;
        return 0;
      });
      this.persons = await Promise.all(persons);
    },

    clearDepartment() {
      if (this.departments == null) {
        return false;
      }
      this.departments = null;
      this.report = null;
    },

    async decodeSalary() {
      if (!this.report) {
        return false;
      }
      var privatecode_st = '';
      if (
        localStorage.getItem('privatecode') ||
        this.permissions.includes('reportnotprivatecode')
      ) {
        if (localStorage.getItem('privatecode')) {
          privatecode_st = localStorage.getItem('privatecode');
          this.disabled_privatecode = true;
        }
      } else {
        const confirmation = await Swal.fire({
          title: 'Private code ?',
          type: 'warning',
          input: 'password',
          inputLabel: 'Enter Private Code',
          inputPlaceholder: 'Enter Private Code',
          inputAttributes: {
            max: 20,
          },
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          confirmButtonClass: 'md-button md-success',
          cancelButtonClass: 'md-button md-danger',
          preConfirm: () => {
            if (!$('.swal2-input').val()) {
              Swal.showValidationMessage('กรุณากรอก Private Code!');
            }
            return [true, $('.swal2-input').val()];
          },
        });
        if (confirmation.dismiss) {
          return false;
        } else {
          if (confirmation.value[0] === true) {
            if (this.securePrivatecode(confirmation.value[1])) {
              privatecode_st = localStorage.getItem('privatecode');
              this.disabled_privatecode = true;
              Swal.fire('Private Code ถูกต้อง!', '', 'success');
            } else {
              Swal.fire('กรุณากรอก Private Code ให้ถูกต้อง!', '', 'warning');
              return false;
            }
          }
        }
      }

      if (
        this.securePrivatecode(privatecode_st) ||
        this.permissions.includes('reportnotprivatecode')
      ) {
        var nf = Intl.NumberFormat();
        this.report.forEach((rp, indexrp) => {
          rp.data.forEach((data, indexdata) => {
            if (typeof data.salary_amt != 'number') {
              let salary_amt = data.salary_amt.slice(
                0,
                data.salary_amt.length - 1,
              );
              let salary_amt_decode = this.decodeBase64(salary_amt);
              if (salary_amt_decode) {
                this.report[indexrp].data[indexdata].salary_amt = parseInt(
                  salary_amt_decode,
                );
                this.sum_all[indexrp]['salary_amt'] =
                  parseInt(this.sum_all[indexrp]['salary_amt']) +
                  parseInt(salary_amt_decode);
                this.sum_all_property['salary_amt'] =
                  parseInt(this.sum_all_property['salary_amt']) +
                  parseInt(salary_amt_decode);
              }
            }
          });
          if (typeof this.sum_all[indexrp]['salary_amt'] == 'number') {
            this.sum_all[indexrp]['salary_amt'] = nf.format(
              this.sum_all[indexrp]['salary_amt'],
            );
          }
        });
        if (typeof this.sum_all_property['salary_amt'] == 'number') {
          this.sum_all_property['salary_amt'] = nf.format(
            this.sum_all_property['salary_amt'],
          );
        }
      }
    },

    decodeBase64(data) {
      if (data.length <= 0) {
        return false;
      }
      let decode = this.Base64_decode(data);
      return decode;
    },

    Base64_encode(input) {
      // private property
      var _keyStr =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

      // public method for encoding
      var output = '';
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;

      input = this.Base64_utf8_encode(input);

      while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
          enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }

        output =
          output +
          _keyStr.charAt(enc1) +
          _keyStr.charAt(enc2) +
          _keyStr.charAt(enc3) +
          _keyStr.charAt(enc4);
      }

      return output;
    },

    Base64_decode(input) {
      var _keyStr =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
      var output = '';
      var chr1, chr2, chr3;
      var enc1, enc2, enc3, enc4;
      var i = 0;

      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

      while (i < input.length) {
        enc1 = _keyStr.indexOf(input.charAt(i++));
        enc2 = _keyStr.indexOf(input.charAt(i++));
        enc3 = _keyStr.indexOf(input.charAt(i++));
        enc4 = _keyStr.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        output = output + String.fromCharCode(chr1);

        if (enc3 != 64) {
          output = output + String.fromCharCode(chr2);
        }
        if (enc4 != 64) {
          output = output + String.fromCharCode(chr3);
        }
      }

      output = this.Base64_utf8_decode(output);

      return output;
    },

    Base64_utf8_encode(string) {
      string = string.replace(/\r\n/g, '\n');
      var utftext = '';

      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }

      return utftext;
    },

    Base64_utf8_decode(utftext) {
      var string = '';
      var i = 0;
      var c2 = 0;
      var c1 = c2;
      var c = c1;

      while (i < utftext.length) {
        c = utftext.charCodeAt(i);

        if (c < 128) {
          string += String.fromCharCode(c);
          i++;
        } else if (c > 191 && c < 224) {
          c2 = utftext.charCodeAt(i + 1);
          string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
          i += 2;
        } else {
          c2 = utftext.charCodeAt(i + 1);
          c3 = utftext.charCodeAt(i + 2);
          string += String.fromCharCode(
            ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63),
          );
          i += 3;
        }
      }

      return string;
    },
    securePrivatecode(private_code) {
      let employee = JSON.parse(localStorage.getItem('employees'));
      if (private_code && employee.privatecode) {
        if (employee.privatecode === md5(private_code)) {
          localStorage.setItem('privatecode', private_code);
          return true;
        } else {
          this.$store.dispatch('alerts/error', 'Invalid private code !!!');
          return false;
        }
      }
      return false;
    },
    async exportPDF() {
      if (typeof this.report_select['years'] == 'undefined') {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกรายงาน');
        return false;
      }
      var notformat = [
        'id',
        'rp_id',
        'emp_id',
        'emp_code',
        'emptname_th',
        'empfname_th',
        'emplname_th',
        'emptname_en',
        'empfname_en',
        'emplname_en',
        'empposname_th',
        'empposname_en',
        'fullname_th',
        'fullname_en',
        'stafftype',
        'bchname_th',
        'bch_nameth',
        'bchname_en',
        'comname_th',
        'comname_en',
        'depname_th',
        'depname_en',
        'salary_amt',
        'salary_amt_decode',
        'ot_hr',
        'ot1_hr',
        'ot1_5_hr',
        'ot2_hr',
        'ot2_hr',
        'date_send',
        'empid_send',
        'emp_code_send',
        'emptname_th_send',
        'empfname_th_send',
        'emplname_th_send',
        'emptname_en_send',
        'empfname_en_send',
        'emplname_en_send',
        'from_date',
        'to_date',
        'pay_date',
        'empposname_th_send',
        'empposname_en_send',
        'created_at',
        'updated_at',
      ];
      var headername = {};
      headername.name = 'ประจำงวดจ่าย';
      var pdfname = 'รายงานเงินเดือน';
      let params = {
        months: this.report_select['months'],
        years: this.report_select['years'],
        bch_id: this.report_select['bch_id'],
        from_date: this.report_select['from_date'],
        to_date: this.report_select['to_date'],
        chk_permis: 1,
      };
      // if (this.permissions.includes('debug')) {
      //   params = {
      //     months: 7,
      //     years: 2022,
      //     bch_id: 2,
      //   };
      // }

      var body = [];
      body = await this.$store.dispatch('salarys/slipSalary', params);
      var font = await this.$store.dispatch('exports/fontthsarabunnew');
      if (body.length <= 0) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return;
      }
      headername.bch_nameth = body[0].bch_nameth;
      headername.pay_date = this.report_select.name_pay_date_full_th;
      headername.name_fromdate_th = this.report_select.name_fromdate_th;
      headername.name_todate_th = this.report_select.name_todate_th;

      const doc = new jsPDF('landscape');
      doc.addFileToVFS('THSarabunNew.ttf', font);
      doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
      doc.setFont('THSarabunNew');
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.text(
        headername.bch_nameth +
          '\n' +
          headername.name +
          ' ' +
          headername.pay_date +
          ' ( ' +
          headername.name_fromdate_th +
          ' - ' +
          headername.name_todate_th +
          ' )',
        148,
        10,
        'center',
      );
      var rows = [];
      var nf = new Intl.NumberFormat('en-US');
      var m = Math;
      body.forEach((val) => {
        let temp;
        temp = val;
        temp.salary_amt = val.salary_amt.slice(0, val.salary_amt.length - 1);
        temp.fullname_th = Object.assign(
          val.emptname_th + val.empfname_th + ' ' + val.emplname_th,
        );
        if (val.emptname_en.length > 0) {
          val.emptname_en = val.emptname_en + ' ';
        } else {
          val.emptname_en = '';
        }
        temp.fullname_en = Object.assign(
          val.emptname_en + val.empfname_en + ' ' + val.emplname_en,
        );
        temp.fullname_en = Object.assign(
          val.emptname_en + val.empfname_en + ' ' + val.emplname_en,
        );
        var privatecode_st = '';
        if (
          localStorage.getItem('privatecode') ||
          this.permissions.includes('reportnotprivatecode')
        ) {
          if (localStorage.getItem('privatecode')) {
            privatecode_st = localStorage.getItem('privatecode');
            this.disabled_privatecode = true;
          }
          if (
            this.securePrivatecode(privatecode_st) ||
            this.permissions.includes('reportnotprivatecode')
          ) {
            temp.salary_amt_decode = this.decodeBase64(temp.salary_amt);
            temp.salary_amt_decode = temp.salary_amt = nf.format(
              temp.salary_amt_decode,
            );
          }
        }
        Object.keys(temp).forEach((key) => {
          if (!notformat.includes(key)) {
            temp[key] = nf.format(m.round(temp[key]).toFixed(2));
          }
        });
        rows.push(temp);
      });
      rows.forEach((value, index) => {
        var no = index + 1;
        rows[index]['no'] = no;
      });

      let total = {
        empposname_th: 'รวม',
        salary_amt: this.sum_all_property['salary_amt'],
        daywork: this.sum_all_property['daywork'],
        total_salary: this.sum_all_property['total_salary'],
        ot_total: this.sum_all_property['ot_total'],
        ot_bath: this.sum_all_property['ot_bath'],
        total_svc: this.sum_all_property['total_svc'],
        massage_income: this.sum_all_property['massage_income'],
        food_service: this.sum_all_property['food_service'],
        tip: this.sum_all_property['tip'],
        accrued_income: this.sum_all_property['accrued_income'],
        bonus: this.sum_all_property['bonus'],
        allowance: this.sum_all_property['allowance'],
        keepdays_baht: this.sum_all_property['keepdays_baht'],
        income_total: this.sum_all_property['income_total'],
        notwork_bath: this.sum_all_property['notwork_bath'],
        sso_bath: this.sum_all_property['sso_bath'],
        tax_bath: this.sum_all_property['tax_bath'],
        cost_total: this.sum_all_property['cost_total'],
        income_net: this.sum_all_property['income_net'],
      };
      rows.push(total);
      var zero = [];
      $.each(this.sum_all_property, function(index, el) {
        if (el == 0) {
          zero.push(index);
        }
      });

      var columns = [
        { header: 'ที่', dataKey: 'no' },
        { header: 'ID', dataKey: 'emp_code' },
        { header: 'ชื่อพนักงาน', dataKey: 'fullname_th' },
        { header: 'แผนก', dataKey: 'depname_en' },
        { header: 'ตำแหน่ง', dataKey: 'empposname_en' },
        // { header: 'แผนก', dataKey: 'depname_th' },
        // { header: 'ตำแหน่ง', dataKey: 'empposname_th' },
        { header: 'เงินเดือน', dataKey: 'salary_amt' },
        { header: 'วัน', dataKey: 'daywork' },
        { header: 'รวมเงินเดือน', dataKey: 'total_salary' },
        { header: 'OT', dataKey: 'ot_total' },
        { header: 'OT เหมา', dataKey: 'ot_bath' },
        { header: 'SVC', dataKey: 'total_svc' },
        { header: 'ค่านวดสปา', dataKey: 'massage_income' },
        { header: 'เบี้ยเลี้ยง', dataKey: 'food_service' },
        { header: 'ทิป', dataKey: 'tip' },
        { header: 'จ้างค้างรับ', dataKey: 'accrued_income' },
        { header: 'Bonus', dataKey: 'bonus' },
        { header: 'ค่าตำแหน่ง', dataKey: 'allowance' },
        { header: 'คืนวันหยุด', dataKey: 'keepdays_baht' },
        { header: 'รวมเงินได้', dataKey: 'income_total' },
        { header: 'ขาดงาน/WOP', dataKey: 'notwork_bath' },
        { header: 'SSF', dataKey: 'sso_bath' },
        { header: 'TAX', dataKey: 'tax_bath' },
        { header: 'รวมรายจ่าย', dataKey: 'cost_total' },
        { header: 'เงินได้สุทธิ', dataKey: 'income_net' },
      ];
      var newcolumns = [];
      columns.forEach((v) => {
        if (!zero.includes(v.dataKey)) {
          newcolumns.push(v);
        }
      });
      var styles = {
        fontSize: 8,
        font: 'THSarabunNew',
        cellPadding: 0.8,
      };
      var sortedDesc = this.persons.reverse();
      var result = sortedDesc.map((item, i) => {
        var c = item.titlename_th
          .concat(item.firstname_th)
          .concat(' ')
          .concat(item.lastname_th)
          .concat(' ')
          .concat(item.position_detail);
        return c;
      });
      var footer_sign = '';
      var cout = 0;
      for (var i = 0; i < result.length; i++) {
        footer_sign = footer_sign
          .concat(result[i])
          .concat(' ')
          .concat(' ')
          .concat(' ')
          .concat(' ')
          .concat(' ')
          .concat(' ')
          .concat(' ');
        cout++;
      }
      var checkperson = 25;
      if (cout > 5) {
        checkperson = 0;
      }
      var config = {
        startY: 22, // ตำแหน่งที่เริ่ม
        startX: 2, // ตำแหน่งที่เริ่ม
        theme: 'grid',
        styles: styles,
        margin: { left: 5, right: 5, bottom: 30 },
        showHead: 'everyPage',
        columns: newcolumns,
        body: rows,
        columnStyles: {
          no: {
            halign: 'center',
            //cellWidth: 8,
          },
          emp_code: {
            //cellWidth: 13,
          },
          fullname_th: {
            //cellWidth: 16,
          },
          depname_th: {
            //cellWidth: 16,
          },
          empposname_th: {
            //cellWidth: 16,
          },
          salary_amt: {
            halign: 'right',
            //cellWidth: 13,
          },
          daywork: {
            halign: 'right',
            //cellWidth: 6,
          },
          total_salary: {
            halign: 'right',
            //cellWidth: 13,
          },
          ot_total: {
            halign: 'right',
            //cellWidth: 10,
          },
          ot_bath: {
            halign: 'right',
            //cellWidth: 10,
          },
          total_svc: {
            halign: 'right',
            //cellWidth: 10,
          },
          massage_income: {
            halign: 'right',
            //cellWidth: 12,
          },
          food_service: {
            halign: 'right',
            //cellWidth: 10,
          },
          tip: {
            halign: 'right',
            //cellWidth: 10,
          },
          accrued_income: {
            halign: 'right',
            //cellWidth: 11,
          },
          bonus: {
            halign: 'right',
            //cellWidth: 10,
          },
          allowance: {
            halign: 'right',
            //cellWidth: 12,
          },
          keepdays_baht: {
            halign: 'right',
            //cellWidth: 11,
          },
          income_total: {
            halign: 'right',
            //cellWidth: 14,
          },
          notwork_bath: {
            halign: 'right',
            //cellWidth: 13.5,
          },
          sso_bath: {
            halign: 'right',
            //cellWidth: 11,
          },
          tax_bath: {
            halign: 'right',
            //cellWidth: 9,
          },
          cost_total: {
            halign: 'right',
            //cellWidth: 12,
          },
          income_net: {
            halign: 'right',
            //cellWidth: 12,
          },
        },
        didDrawPage: function(data) {
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.setFontSize(12);
          doc.setTextColor(0, 0, 0);
          doc.text(
            footer_sign,
            data.settings.margin.left + checkperson,
            pageHeight - 10,
          );
        },
      };

      doc.autoTable(config);
      doc.setProperties({
        title: pdfname,
      });
      window.open(doc.output('bloburl'), '_blank');
      // doc.save(pdfname + '.pdf'); // download
    },
    to_Base64(img) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(img);
      });
    },
    number_format(num = 0) {
      let new_num = parseFloat(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return new_num;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons');
.box-header-salary {
  text-align: center;
  font-weight: bold;
  font-family: 'Kanit';
  padding: 1em 0;
}
.tablebordercolor .theadbordercolor > tr > th {
  background-color: #343a40;
  border-color: #ddd;
}
.background-white {
  background-color: white;
}
.font-imp {
  font-size: 18px !important;
  color: rgb(255, 255, 255) !important;
}
.font-size {
  font-size: 12px !important;
}
.photo {
  width: 54px;
  height: 54px;
  overflow: hidden;
  border-radius: 50%;
}
.imgclass {
  text-align: center;
  width: 250px !important;
  height: 250px !important;
  overflow: hidden !important;
  display: inline-block;
}
/*
canvas.signature-pad {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
}
.tb-salary {
  .th-w-100 {
    min-width: 100px;
  }
  .th-w-150 {
    min-width: 150px;
  }
  .th-w-200 {
    min-width: 200px;
  }
  .th-w-250 {
    min-width: 250px;
  }
  .th-w-300 {
    min-width: 300px;
  }

  thead th {
    min-width: 100px;
    text-align: center;
    font-size: 14px;
  }
  tbody th,
  tbody td {
    text-align: center;
  }
}
*/
</style>
