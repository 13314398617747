import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.get(`${url}/v1/permissions`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.get(`${url}/v1/permissions/${id}`, options).then((response) => {
    let role = jsona.deserialize(response.data);
    delete role.links;
    return role;
  });
}

function add(permission) {
  const payload = jsona.serialize({
    stuff: permission,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/createPermission`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(permission) {
  const payload = jsona.serialize({
    stuff: permission,
    includeNames: [],
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .patch(`${url}/v1/updatePermission/${permission.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.delete(`${url}/v1/permissions/${id}`, options);
}

function getUserPermission(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .get(`${url}/v1/userpermissionlist/${params.id}/${params.type}`, options)
    .then((response) => {
      let d = jsona.deserialize(response.data);
      //delete d.links;
      return d;
    });
}

function assignDirectPermission(permission) {
  const payload = jsona.serialize({
    stuff: permission,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/assign-permission-direct`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function assignRolePermission(permission) {
  const payload = jsona.serialize({
    stuff: permission,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/assign-permission-role`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  getUserPermission,
  assignDirectPermission,
  assignRolePermission,
};
