<template>
  <div class="createMemo">
    <md-card v-if="true">
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>add_circle_outline</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <md-button @click="goBack" class="md-primary md-dense">
            Back to List
          </md-button>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter md-alignment-center-right">
          <b-button variant="info" @click="previewPDF">Preview</b-button>
        </div>
        <div class="md-layout md-gutter md-alignment-center-center">
          <div class="md-layout-item md-size-30">
            <md-field>
              <label>สาขา</label>
              <md-select v-model="branch_id">
                <md-option
                  v-for="(item, index) in branchList"
                  :key="index + '-' + item.bch_id"
                  :value="item.bch_id"
                >
                  {{ item.brname_th }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <center>
              <img
                v-show="showLogo"
                :src="branchLogo[branch_id]"
                style="width:auto; height: 100px;"
              />
            </center>
          </div>
          <div class="md-layout-item md-size-100">
            <center><span class="headMemo">MEMORANDAM</span></center>
          </div>
          <div class="md-layout-item md-size-100">
            <center>
              <b-table-simple class="memo-table">
                <b-thead>
                  <b-tr>
                    <b-td>
                      <p>TO:</p>
                      <b-input
                        class="b-input-memo-table"
                        v-model="toText"
                      ></b-input>
                    </b-td>
                    <b-td>
                      <p>DATE:</p>
                      <b-datepicker
                        class="b-datepicker-memo"
                        v-model="dateMemo"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit',
                        }"
                      ></b-datepicker>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      <p>FROM:</p>
                      <b-input class="b-input-memo-table" v-model="fromText" />
                    </b-td>
                    <b-td>
                      <p>PAGE:</p>
                      <b-input class="b-input-memo-table" v-model="pageText" />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      <p>SUBJECT:</p>
                      <b-input
                        class="b-input-memo-table"
                        v-model="subjectText"
                      />
                    </b-td>
                    <b-td>
                      <p>CC:</p>
                      <!-- <b-input class="b-input-memo-table" v-model="cc"></b-input> -->
                      <b-input class="b-input-memo-table" v-model="cc" />
                    </b-td>
                  </b-tr>
                </b-thead>
              </b-table-simple>
            </center>
          </div>
          <div class="md-layout-item md-size-100" v-if="check_showeditor">
            <hr />
            <!-- <center> -->
            <PdfmeEditor
              v-model="pdfme_template"
              ref="pdfmeEditor"
              :baseTemplate="baseTemplate"
              :contentTemplate="pdfme_template"
              @getPdfTemplate="getPdfTemplate($event)"
              @onMountedPdfme="onMountedPdfme"
            >
            </PdfmeEditor>
            <!-- </center> -->
            <ApproverSettings
              ref="approverSettings"
              @send-approver="sendApproverData"
            ></ApproverSettings>

            <!--            <PrioritySettings-->
            <!--              ref="prioritySettings"-->
            <!--              :title="'อนุมัติ'"-->
            <!--              :priority-groups="staffPriorityGroups"-->
            <!--              @staffs="getStaffs"-->
            <!--            ></PrioritySettings>-->

            <div class="d-flex justify-content-end">
              <b-button class="mr-1" variant="primary" @click="saveMemo(false)">
                Save as Draft
              </b-button>
              <b-button class="ml-1" variant="success" @click="saveMemo(true)">
                Send for Approval
              </b-button>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <div v-else>
      กำลังแก้ไข
    </div>
  </div>
</template>
<script>
import PdfmeEditor from '@/components/Pdfme/RichEditor_Pdfme.vue';
import ApproverSettings from '@/components/ApproverSettings';
import { loading_start, loading_close } from '@/utils/loading.js';
import Vuex from 'vuex';
import Swal from 'sweetalert2';
// import PrioritySettings from '@/components/PrioritySettings.vue';

export default {
  components: {
    // PrioritySettings: PrioritySettings,
    PdfmeEditor: PdfmeEditor,
    ApproverSettings: ApproverSettings,
  },
  async created() {
    this.branchList = await this.getBranchList();
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    check_showeditor() {
      return this.set_showeditor();
    },
    memorandam() {
      const options = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      };

      const date = new Intl.DateTimeFormat('en-US', options).format(
        this.dateMemo ? new Date(this.dateMemo) : new Date(),
      );

      return {
        toText: this.toText,
        fromText: this.fromText,
        dateMemo: date,
        pageText: this.pageText,
        subjectText: this.subjectText,
        cc: this.cc,
      };
    },
  },
  data() {
    return {
      dateMemo: '',
      toText: '',
      fromText: '',
      pageText: 0,
      subjectText: '',
      cc: '',
      pdfme_template: undefined,
      baseTemplate: {
        schemas: [[]],
        basePdf: {},
        pdfmeVersion: '',
      },
      memoTemplate: {
        getBaseTemplate: ({ branchLogo, memorandam }) => ({
          schemas: [[]],
          basePdf: {},
          pdfmeVersion: '',
        }),
        getBranchLogoTemplate: ({ branchLogo }) => ({}),
        getMemorandumTemplate: ({ memorandam }) => {},
        getMemorandumTableTemplate: ({ memorandam }) => {},
        getTemplateSignature: () => {},
        getTemplateSignatureTitle: ({ value }) => {},
        getTemplateAcknowledge: ({ value }) => {},
      },
      showLogo: false,
      branchLogo: {},
      branch_id: undefined,
      brname_en: undefined,
      branchList: undefined,
      showeditor: false,
      memo: {},
      approverData: {},
      staffPriorityGroups: {},
      staffs: {},
    };
  },
  async mounted() {
    const employee = this.me.employees;
    this.staffPriorityGroups = [
      {
        member: {
          options: {
            departments: [],
            employees: [],
            positions: [],
          },
          memberInfo: {
            bch_id: employee?.branch_id,
            order_person: 0,
            posname_en: '',
            depname_en: '',
            emp_full_name: '',
            dep_id: employee?.salarys?.[0]?.positions?.dep_id || '',
            position: employee?.salarys?.[0]?.position_id || '',
            emp_id: employee?.emp_id || '',
          },
        },
        priorityType: '',
        priority: 0,
      },
    ];

    const params = this.$route.params;

    if (params?.id) {
      const response = await this.$store.dispatch('memo/getMemoDetail', params);
      if (response?.data) {
        const memoDetail = response.data.memoDetail;
        const imageids = response.data.imageIds;
        this.memo.id = memoDetail.id;
        this.memo.previous_imagesids = imageids;
        this.memo.branch_id = memoDetail.bch_id;
        this.branch_id = memoDetail?.bch_id;
        this.dateMemo = memoDetail.date;
        this.toText = memoDetail.to_text;
        this.fromText = memoDetail.from_text;
        this.pageText = memoDetail.page;
        this.subjectText = memoDetail.subject;
        this.cc = memoDetail.cc;
        this.pdfme_template = memoDetail.memo_data;
        this.showeditor = this.check_showeditor;
        if (response.data.approvers.length > 0) {
          const interval = setInterval(() => {
            if (this.$refs.approverSettings) {
              this.$refs.approverSettings.setApproverData(
                response.data.approvers,
              );
              clearInterval(interval);
            }
          }, 500);

          // this.staffPriorityGroups = [];
          // response.data.approvers.forEach((access) => {
          //   console.log({ access });
          //   if (!this.staffPriorityGroups[access.priority]) {
          //     this.staffPriorityGroups[access.priority] = {
          //       member: [
          //         {
          //           options: {
          //             departments: [],
          //             employees: [],
          //             positions: [],
          //           },
          //           memberInfo: {
          //             bch_id: access?.bch_id || '',
          //             order_person: access?.order_person,
          //             depname_en: '',
          //             emp_full_name: '',
          //             dep_id: access?.dep_id || '',
          //             position: access?.position || '',
          //             emp_id: access?.emp_id || '',
          //             memo_view_access_id: access.id,
          //           },
          //         },
          //       ],
          //       priorityType: '',
          //       priority: access?.priority,
          //     };
          //     return true;
          //   }
          //
          //   this.staffPriorityGroups[access.priority].member.push({
          //     options: {
          //       departments: [],
          //       employees: [],
          //       positions: [],
          //     },
          //     memberInfo: {
          //       bch_id: access?.bch_id || '',
          //       order_person: access?.order_person,
          //       depname_en: '',
          //       emp_full_name: '',
          //       dep_id: access?.dep_id || '',
          //       position: access?.position || '',
          //       emp_id: access?.emp_id || '',
          //       memo_view_access_id: access.id,
          //     },
          //   });
          // });
        }
      }
    }
  },
  watch: {
    async branch_id() {
      this.showLogo = false;
      await this.getBranchLogo();
      await this.setBranchLogo();
      this.showLogo = true;
    },
    memorandam(value) {
      const memorandam = value;
      this.debounceUpdate(() => {
        if (
          this.memoTemplate.getMemorandumTableTemplate({ memorandam })?.head
        ) {
          this.$refs.pdfmeEditor?.updateContent(
            'memorandam_table',
            this.memoTemplate.getMemorandumTableTemplate({ memorandam })?.head,
            'head',
          );
          this.$refs.pdfmeEditor?.updateContent(
            'memorandam_table',
            this.memoTemplate.getMemorandumTableTemplate({ memorandam })
              ?.content,
            'content',
          );
        }
      });
    },
  },
  methods: {
    debounceUpdate(callback, delay = 800) {
      clearTimeout(this._timeout);
      this._timeout = setTimeout(callback, delay);
    },
    setBranchLogo() {
      const branchLogo = this.branchLogo[this.branch_id];
      this.$refs.pdfmeEditor.updateContent('branch_logo', branchLogo);
    },
    set_showeditor() {
      if (this.showeditor == false) {
        this.showeditor =
          this.branch_id != null &&
          this.toText?.length > 0 &&
          this.dateMemo?.length > 0 &&
          this.pageText > 0 &&
          this.subjectText?.length > 0 &&
          this.cc?.length > 0;
      }
      return this.showeditor;
    },

    goBack() {
      let keySearchMemo = this.$route.params.keySearch;
      let currentPageMemo = this.$route.params.currentPage;

      let params = {
        keySearchMemo,
        currentPageMemo,
      };

      this.$router.push({
        path: 'memoList',
        name: 'จัดการ Memo',
        params: params,
      });
    },

    async getBranchList() {
      let params = {
        ...(this.me?.employees.branchs.company_id
          ? { company_id: this.me?.employees.branchs.company_id }
          : {}),
        bch_only: true,
      };

      loading_start();
      let branchList = await this.$store.dispatch('staffs/searchStaff', params);
      loading_close();

      return branchList;
    },

    getPdfTemplate(template) {
      this.pdfme_template = template;
    },
    getTemplateSignature() {
      return [
        {
          name: 'dotted_line',
          type: 'text',
          content: 'DOTTED_LINE',
          position: { x: 1, y: 1 },
          width: 54.96,
          height: 5.75,
          rotate: 0,
          alignment: 'center',
          verticalAlignment: 'middle',
          fontSize: 16,
          lineHeight: 1,
          characterSpacing: 0,
          fontColor: '#000000',
          backgroundColor: '',
          opacity: 1,
          strikethrough: false,
          underline: false,
          required: false,
          readOnly: false,
          fontName: 'Angsana New',
        },
        {
          name: 'name',
          type: 'text',
          content: 'NAME',
          position: { x: 1, y: 8 },
          width: 53,
          height: 7,
          rotate: 0,
          alignment: 'center',
          verticalAlignment: 'middle',
          fontSize: 18,
          lineHeight: 1,
          characterSpacing: 0,
          fontColor: '#000000',
          backgroundColor: '',
          opacity: 1,
          strikethrough: false,
          underline: false,
          required: false,
          readOnly: false,
          fontName: 'Angsana New',
        },
        {
          name: 'position',
          type: 'text',
          content: 'POSITION',
          position: { x: 1, y: 14 },
          width: 53,
          height: 12.29,
          rotate: 0,
          alignment: 'center',
          verticalAlignment: 'top',
          fontSize: 18,
          lineHeight: 0.8,
          characterSpacing: 0,
          fontColor: '#000000',
          backgroundColor: '',
          opacity: 1,
          strikethrough: false,
          underline: false,
          required: false,
          readOnly: false,
          fontName: 'Angsana New',
        },
      ];
    },

    getInputsSignature(value) {
      return {
        type: value.type,
        dotted_line: '.....................................',
        name: value.name,
        position: value.position,
      };
    },

    async getBranchLogo() {
      if (this.branch_id == null) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกสาขา');
        return;
      }

      if (
        this.branchLogo &&
        typeof this.branchLogo[this.branch_id] != 'undefined'
      ) {
        return this.branchLogo[this.branch_id];
      }

      let params = {
        bch_id: this.branch_id,
      };

      loading_start();
      let branchLogo = await this.$store.dispatch('pdfs/getBranchLogo', params);
      loading_close();

      if (branchLogo.length > 0) {
        this.branchLogo[this.branch_id] = branchLogo[0].image;
      } else {
        await this.$store.dispatch('alerts/error', 'ไม่มีโลโก้ของสาขานี้');
      }

      return branchLogo;
    },

    getStaffs(staffs) {
      this.staffs = staffs;
    },

    // PDFME
    async onMountedPdfme() {
      let branchLogo = '';
      let counterInterval = 0;

      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.branchLogo[this.branch_id] || ++counterInterval == 4) {
            clearInterval(interval);
            branchLogo = this.branchLogo[this.branch_id];
            resolve();
          }
        }, 800);
      });
      this.memoTemplate = await this.$refs.pdfmeEditor.loadTemplate(
        'Memo',
        'Memo',
      );
      await this.$refs.pdfmeEditor.updateBaseTemplate(
        this.memoTemplate.getBaseTemplate({
          branchLogo: branchLogo,
          memorandam: this.memorandam,
        }),
      );
    },

    async previewPDF() {
      try {
        if (this.$refs.pdfmeEditor) {
          await this.$refs.pdfmeEditor.generatePDF();
        }
      } catch (error) {
        throw 'Error generating PDF: ' + error;
      }
    },

    async saveMemo(sendForApproval) {
      try {
        this.pdfme_template = await this.$refs.pdfmeEditor.designer.getTemplate();
        this.approverData = await this.$refs.approverSettings.getApproverData();
        this.pageText = this.pdfme_template.schemas.length;
        // console.log({ getApproverData: this.approverData });

        const memo_data = JSON.stringify(this.pdfme_template);
        this.memo = {
          id: this.memo?.id,
          bch_id: this.branch_id,
          memo_name: this.subjectText,
          to_text: this.toText,
          date: this.dateMemo,
          from_text: this.fromText,
          page: this.pdfme_template.schemas.length,
          subject: this.subjectText,
          cc: this.cc,
          memo_data: memo_data,
          previous_imagesids: JSON.stringify(this.memo?.imageIds),
          approver: JSON.stringify(this.approverData),
          status: sendForApproval ? 'approve' : 'draft',
        };

        loading_start();
        const response = await this.$store.dispatch('memo/saveMemo', this.memo);
        if (response) {
          this.memo = response.data;
          if (response) {
            this.memo = response.data;
            loading_close();
            this.goBack();
            await Swal.fire('บันทึกสำเร็จ!', '', 'success');
          }
        }
      } catch (error) {
        loading_close();
        await Swal.fire('มีบางอย่างผิดพลาด!', '', 'error');
      } finally {
        loading_close();
      }
    },
    async updateSignatures(employees) {
      if (!employees || Object.values(employees).length <= 1) return;

      const contentTemplate = this.$refs.pdfmeEditor.designer.getTemplate();
      const pageCount = contentTemplate.schemas.length - 1;

      contentTemplate.schemas.flat().map(async (schema) => {
        const [type, key] = schema.name.split('-');

        if (employees[key]) {
          const employee = { ...employees[key] };
          const data = this.setDataEmployee(employee);
          const signatureTemplate = this.getTemplateSignature();

          let positions = this.$refs.pdfmeEditor.getTemplatePosition(
            contentTemplate,
          );

          const inputsTemplate = this.getInputsSignature({
            type: data.type,
            name: `คุณ${employee.emp_first_name} ${employee.emp_last_name}`,
            position: employee.position,
          });

          const newTemplateData = {
            template: {
              schemas: [[...signatureTemplate]],
              basePdf: { width: 55, height: 28, padding: [0, 0, 0, 0] },
              pdfmeVersion: '5.0.0',
            },
            inputs: [inputsTemplate],
            name: `${employee.priority}${employee.order_person}`,
          };
          delete employees[key];

          const newTemplate = await this.$refs.pdfmeEditor.convertPdfToBase64(
            newTemplateData,
            { x: positions.x + 15, y: positions.y + 15 },
          );
          this.$refs.pdfmeEditor.updateContent(
            schema.name,
            newTemplate.content,
          );
          await this.$refs.pdfmeEditor.designer.updateTemplate(contentTemplate);
        }
      });

      await Promise.all(
        Object.values(employees).map(async (employee) => {
          const data = this.setDataEmployee(employee);
          const signatureTemplate = this.getTemplateSignature();

          let positions = this.$refs.pdfmeEditor.getTemplatePosition(
            contentTemplate,
          );

          const inputsTemplate = this.getInputsSignature({
            type: data.type,
            name: `คุณ${employee.emp_first_name} ${employee.emp_last_name}`,
            position: employee.position,
          });

          const newTemplateData = {
            template: {
              schemas: [[...signatureTemplate]],
              basePdf: { width: 55, height: 28, padding: [0, 0, 0, 0] },
              pdfmeVersion: '5.0.0',
            },
            inputs: [inputsTemplate],
            name: `${employee.priority}${employee.order_person}`,
          };

          const newTemplate = await this.$refs.pdfmeEditor.convertPdfToBase64(
            newTemplateData,
            { x: positions.x + 15, y: positions.y + 15 },
          );

          contentTemplate.schemas[pageCount].push(newTemplate);
          await this.$refs.pdfmeEditor.designer.updateTemplate(contentTemplate);
        }),
      );
    },
    setDataEmployee(employee) {
      const typeList = {
        createBy: 'จัดทำโดย',
        approver: 'อนุมัติโดย',
        acknowledge: 'รับทราบโดย',
      };
      const firstName =
        employee?.emp_first_name ||
        employee?.firstname_th ||
        employee?.first_nameth;
      const lastName =
        employee?.emp_last_name ||
        employee?.last_nameth ||
        employee?.last_nameth;
      return {
        emp_id: employee.emp_id,
        name: `คุณ${firstName} ${lastName}`,
        position:
          employee?.position || employee?.salarys?.[0]?.positions?.pos_nameen,
        type: typeList[employee.type],
      };
    },
    sendApproverData(approverData) {
      this.approverData = approverData;
      this.updateSignatures({ ...approverData });
    },
  },
};
</script>
<style lang="scss">
.createMemo {
  .table.memo-table {
    width: 700px;
    margin-bottom: 1rem;
    font-weight: bold;

    td {
      width: 50%;
    }
  }

  .memo-table {
    font-family: 'Angsana New';
    font-weight: bold;
    font-size: 25px;
  }

  .headMemo {
    font-family: 'Angsana New';
    font-weight: bold;
    font-size: 26px;
  }

  .b-input-memo-table {
    display: inline-block;
    width: auto;
    font-family: 'Angsana New';
    font-weight: bold;
    font-size: 25px;
    color: black;
    background-image: none;

    &:focus {
      color: black;
    }
  }

  .b-datepicker-memo.b-form-btn-label-control
    .dropdown
    .b-form-datepicker
    .b-datepicker-memo
    .form-control {
    label {
      display: inline-block;
      font-family: 'Angsana New';
      font-weight: bold;
      font-size: 18px;
      color: black;
    }
  }

  .dropdown-menu .b-calendar .b-calendar-grid-body .col[data-date] .btn {
    font-size: 23px;
    line-height: 0.7;
  }

  .b-calendar-grid small {
    font-size: 19px;
  }

  .b-calendar-grid-caption {
    font-size: 24px;
  }

  .b-calendar-inner bdi {
    font-size: 18px;
  }

  table {
    td {
      display: inline-flex;
      align-items: center;
      overflow: hidden;
      height: 60px;
    }

    p {
      width: 130px;
      margin: 0;
    }

    input .b-input-memo-table {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 200;
    }

    .form-control,
    .cc {
      height: 35px;
      width: 100%;
    }
  }
}
</style>
