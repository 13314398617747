import service from '@/store/services/contract-service';

const state = {};

const mutations = {};

const actions = {
  async getsetting({ commit, dispatch }, params) {
    return await service.getsetting(params);
  },
  async getData({ commit, dispatch }, params) {
    return await service.getData(params);
  },
  async saveContract({ commit, dispatch }, params) {
    return await service.saveContract(params);
  },
  async updateContract({ commit, dispatch }, params) {
    return await service.updateContract(params);
  },
  async searchContract({ commit, dispatch }, params) {
    return await service.searchContract(params);
  },
  async getEmpContract({ commit, dispatch }, params) {
    return await service.getEmpContract(params);
  },
  async getbranch({ commit, dispatch }, params) {
    return await service.getbranch(params);
  },
  async updateSettingWit({ commit, dispatch }, params) {
    return await service.updateSettingWit(params);
  },
};

const getters = {};

const contract = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default contract;
