<template>
  <div class="md-layout">
    <!-- Progress bar -->
    <vue-progress-bar></vue-progress-bar>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title"></h4>
      </md-card-header>
      <md-card-content>
        <div class="add-memo" style="text-align: right">
          <md-button class="md-info md-dense" @click="goToMemoList">
            รายการ Memo
          </md-button>
          <md-button class="md-primary md-dense" @click="goToAdd">
            เพิ่ม Memo
          </md-button>
        </div>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-5 md-medium-size-10 md-small-size-10 md-xsmall-size-100"
            style="text-align: left"
          >
            <label style="margin-top: 1.3rem; text-align: right">
              ค้นหา:
            </label>
          </div>
          <!-- <div
              class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
              align="center"
            >
              <md-field class="searchbranchs">
                <label>เลือก สำนักงาน</label>
                <md-select v-model="branches" name="branch_id">
                  <md-option
                    v-for="item in available_branches"
                    :key="item.branch_id"
                    :value="item.branch_id"
                  >
                    {{ item.br_nameth }}
                  </md-option>
                </md-select>
              </md-field>
            </div> -->
          <div
            class="md-layout-item md-size-30 md-medium-size-40 md-small-size-40 md-xsmall-size-85"
            style="text-align: left"
          >
            <md-field>
              <label>Search</label>
              <md-input
                class="md-size-50 searchID"
                v-model="keySearch"
                @keyup.enter="get_sos()"
              ></md-input>
            </md-field>
          </div>
          <div>
            <md-button
              class="md-info md-just-icon md-round md-sm"
              @click="get_sos()"
            >
              <md-icon>search</md-icon>
            </md-button>
          </div>
        </div>
        <b-card-group
          style="margin-top: 0.7rem"
          v-for="(data, index) in show_data_form_search"
          :value="data.sos_name"
          :key="data.sos_id"
        >
          <b-card class="text-left card1">
            <template #header>
              <h5 class="mb-0 memo_sos">
                <span>Memo Name: {{ data.sos_name }}</span>
              </h5>
              <span class="card-subtitle text-muted mb-2">
                Date Update: {{ data.format_updated_at }}
              </span>
              <br />
              <b-badge
                variant="info"
                class="mg-left-badge"
                v-for="tag in data.tag_sos"
                :key="tag.id"
              >
                {{ tag.tag_sos_name }}
              </b-badge>
              <div style="text-align: right;">
                <md-button
                  class="md-info md-sm md-round"
                  @click="goToShow(data.sos_id, 'settingsos')"
                >
                  <i class="fa fa-file"></i>
                  View
                </md-button>
                <md-button
                  class="md-warning md-sm md-round"
                  @click="goToEdit(index)"
                >
                  <md-icon>edit</md-icon>
                  Edit
                </md-button>
                <md-button
                  class="md-rose md-sm md-round"
                  @click="showDialogDelete(data.sos_id)"
                >
                  <md-icon>delete</md-icon>
                  Delete
                </md-button>
              </div>
            </template>
            <b-card-text>
              <p class="detail">{{ data.sos_detail }}</p>
            </b-card-text>
          </b-card>
        </b-card-group>
        <b-pagination
          v-if="data_form_search.length > 0"
          style="margin-top: 15px"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Vuex from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      available_branches: null,
      branches: '',
      keySearch: '',
      show_sos_search: [],
      data_form_search: [],
      show_data_form_search: [],
      perPage: 4,
      currentPage: 1,
      showdeleteWorkingDialog: false,
    };
  },
  async created() {
    this.keySearch = this.$route.params.keySearch || '';
    await this.get_sos();
    this.currentPage = this.$route.params.currentPage || 1;
    // this.getBranches();
  },
  computed: {
    rows() {
      var data_rows = Object.keys(this.data_form_search).length;
      return data_rows;
    },
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  watch: {
    async currentPage() {
      this.filterPage();
    },
  },
  methods: {
    async getBranches() {
      let resp = await this.$store.dispatch('contract/getbranch');
      this.available_branches = resp;
    },

    filterPage() {
      this.show_data_form_search = [];
      var a = this.currentPage > 1 ? this.currentPage * this.perPage - 4 : 0;
      var b = this.perPage + (this.currentPage * this.perPage - 4);
      var data = [];
      $.each(this.data_form_search, function(index, el) {
        if (index >= a && index < b) {
          data.push(el);
        }
      });
      this.show_data_form_search = data;
    },

    async goToAdd() {
      this.$router.push({
        path: 'add-santhiya SOS',
        name: 'เพิ่ม Memo santhiya SOS',
      });
    },

    async goToMemoList() {
      let keySearch = this.keySearch;
      let currentPage = this.currentPage;
      let backPage = 'settingsos';

      let params = {
        keySearch,
        currentPage,
        backPage,
      };

      this.$router.push({
        path: 'memoList',
        name: 'จัดการ Memo',
        params: params,
      });
    },

    async get_sos() {
      let obj = {
        com_id: this.me.employees.com_id,
        // branches_id: this.me.employees.branch_id,
        key_search: this.keySearch,
      };
      let resp = await this.$store.dispatch('santhiyaSOS/searchSOS', obj);
      if (resp == 0) {
        this.keySearch = this.tmpkeySearch;
        await this.$store.dispatch('alerts/error', 'ไม่มีข้อมูลในระบบ!');
        return false;
      } else {
        this.tmpkeySearch = this.keySearch;
        this.currentPage = 1;
        this.data_form_search = Object.values(resp);
        this.data_form_search.forEach((d) => {
          d.format_updated_at = moment(d.updated_at).format(
            'DD/MM/YYYY HH:mm:ss',
          );
        });
        this.filterPage();
      }
    },

    goToEdit(index, backPage = 'settingsos') {
      let selectdata = this.show_data_form_search[index];
      let keySearch = this.keySearch;
      let currentPage = this.currentPage;
      let params = {
        id: { ...selectdata.sos_id },
        data: { ...selectdata },
        keySearch,
        currentPage,
        backPage,
      };
      this.$router.push({
        path: 'Edit-santhiya SOS',
        name: 'Edit santhiya SOS',
        params: params,
      });
    },

    goToShow(id, backPage = 'settingsos') {
      // console.log(backPage);
      let getdata = this.show_data_form_search;
      var data = [];
      $(getdata).each(async function(i, o) {
        if (id == o.sos_id) {
          data = [o];
        }
      });
      let currentPage = this.currentPage;
      let keySearch = this.keySearch;
      this.$router.push({
        path: 'show-santhiyaSOS',
        name: 'santhiya SOS',
        params: {
          id,
          data,
          keySearch,
          currentPage,
          backPage,
        },
      });
    },

    async showDialogDelete(v) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            let params = {
              ...{ sos_id: v },
            };
            let resp = await this.$store.dispatch(
              'santhiyaSOS/deleteSOS',
              params,
            );
            if ((resp = 1)) {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'SanthiyaSOS has been deleted.',
                'success',
              );
            } else if ((resp = 0)) {
              swalWithBootstrapButtons.fire(
                'Error!',
                'Oops, something went wrong!',
                'error',
              );
            }
          } else if (result.dismiss) {
            swalWithBootstrapButtons.fire(
              'Cancelled',
              'Your imaginary SanthiyaSOS is safe :)',
              'error',
            );
          }
        });
    },
  },
};
</script>
<style scoped>
.card1 {
  box-shadow: 3px 3px 2px #ebebeb;
}
.card1:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  border: 1px solid green;
  background-color: white;
}
.card1 p.detail {
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.he_card {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.card1 .mg-left-badge {
  margin-left: 5px;
}
</style>
