import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function listYear(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  return axios.get(`${url}/v1/getyear-fromlmt`, options).then((response) => {
    return response.data;
  });
}
function listMonth(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  return axios.get(`${url}/v1/getmonth-fromlmt`, options).then((response) => {
    return response.data;
  });
}
function listEmp(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  return axios.get(`${url}/v1/get-limit-tax`, options).then((response) => {
    return response.data;
  });
}

function add(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/add-limit-tax`, params, options)
    .then((response) => {
      return response.data;
    });
}

export default {
  listYear,
  listMonth,
  listEmp,
  add,
};
