<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>add_circle_outline</md-icon>
          </div>
          <h4 class="title"></h4>
          <div class="text-right">
            <md-button @click="goBack" class="md-primary md-dense">
              Back to List
            </md-button>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout spacing md-alignment-left">
            <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
              <ValidationProvider
                name="code"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>ชื่อกะทำงาน</label>
                  <md-input v-model="code" type="text"> </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>

          <div class="md-layout spacing md-alignment-left">
            <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
              <ValidationProvider
                name="start_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <VueCtkDateTimePicker
                    v-model="start_time"
                    :only-time="true"
                    :noLabel="true"
                    :minute-interval="15"
                    :disable-date="true"
                    :format="'HH:mm'"
                    :formatted="'HH:mm'"
                    :label="'เวลาเริ่มงาน'"
                    :input-size="'sm'"
                  />
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <ValidationProvider
                name="start_on"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="start_on">Day</label>
                  <md-select v-model="start_on" name="start_on">
                    <md-option
                      v-for="item in day_tomorrow"
                      :key="item.v"
                      :value="item.v"
                    >
                      {{ item.n }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-15 mt-2 md-small-size-40">
              <ValidationProvider
                name="beforestart_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-tooltip md-delay="300" md-direction="top">
                    จำนวนชั่วโมงที่พนักงานสามารถแสกนนิ้วเข้างานได้
                    ก่อนเวลาเข้างาน !!!
                  </md-tooltip>
                  <label for="beforestart_time">เวลาก่อนเริ่มงาน(ชม.)</label>
                  <md-select v-model="beforestart_time" name="beforestart_time">
                    <md-option
                      v-for="item in hours_amount"
                      :key="item.h"
                      :value="item.h"
                    >
                      {{ item.h + ' ชั่วโมง' }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
              <ValidationProvider
                name="finish_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <VueCtkDateTimePicker
                    v-model="finish_time"
                    :only-time="true"
                    :noLabel="true"
                    :minute-interval="15"
                    :disable-date="true"
                    :format="'HH:mm'"
                    :formatted="'HH:mm'"
                    :label="'เวลาออกงาน'"
                    :input-size="'sm'"
                  />

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <ValidationProvider
                name="finish_on"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="finish_on">Day</label>
                  <md-select v-model="finish_on" name="finish_on">
                    <md-option
                      v-for="item in day_tomorrow"
                      :key="item.v"
                      :value="item.v"
                    >
                      {{ item.n }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-15 mt-2 md-small-size-40">
              <ValidationProvider
                name="afterfinish_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-tooltip md-delay="300" md-direction="top">
                    จำนวนชั่วโมงที่พนักงานสามารถแสกนนิ้วออกได้ หลังเวลาออกงาน
                    !!!
                  </md-tooltip>
                  <label for="afterfinish_time">เวลาหลังเลิกงาน(ชม.)</label>
                  <md-select v-model="afterfinish_time" name="afterfinish_time">
                    <md-option
                      v-for="item in hours_amount"
                      :key="item.h"
                      :value="item.h"
                    >
                      {{ item.h + ' ชั่วโมง' }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>

          <div class="md-layout spacing md-alignment-left">
            <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
              <ValidationProvider
                name="breakstart_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <VueCtkDateTimePicker
                    v-model="breakstart_time"
                    :only-time="true"
                    :noLabel="true"
                    :minute-interval="15"
                    :disable-date="true"
                    :format="'HH:mm'"
                    :formatted="'HH:mm'"
                    :label="'เวลาพัก'"
                    :input-size="'sm'"
                  />

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <ValidationProvider
                name="breakstart_on"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="breakstart_on">Day</label>
                  <md-select v-model="breakstart_on" name="breakstart_on">
                    <md-option
                      v-for="item in day_tomorrow"
                      :key="item.v"
                      :value="item.v"
                    >
                      {{ item.n }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
              <ValidationProvider
                name="breakfinish_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <VueCtkDateTimePicker
                    v-model="breakfinish_time"
                    :only-time="true"
                    :noLabel="true"
                    :minute-interval="15"
                    :disable-date="true"
                    :format="'HH:mm'"
                    :formatted="'HH:mm'"
                    :label="'หมดเวลาพัก'"
                    :input-size="'sm'"
                  />

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <ValidationProvider
                name="breakfinish_on"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="breakfinish_on">Day</label>
                  <md-select v-model="breakfinish_on" name="breakfinish_on">
                    <md-option
                      v-for="item in day_tomorrow"
                      :key="item.v"
                      :value="item.v"
                    >
                      {{ item.n }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>

          <div class="md-layout spacing md-alignment-left">
            <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
              <ValidationProvider
                name="half_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <VueCtkDateTimePicker
                    v-model="half_time"
                    :only-time="true"
                    :noLabel="true"
                    :minute-interval="15"
                    :disable-date="true"
                    :format="'HH:mm'"
                    :formatted="'HH:mm'"
                    :label="'เวลาเริ่มงานกะครึ่งวัน'"
                    :input-size="'sm'"
                  />

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <ValidationProvider
                name="half_on"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="half_on">Day</label>
                  <md-select v-model="half_on" name="half_on">
                    <md-option
                      v-for="item in day_tomorrow"
                      :key="item.v"
                      :value="item.v"
                    >
                      {{ item.n }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>
        </md-card-content>
        <md-card-actions>
          <md-button type="submit" class="md-success">save </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>

<script>
// import moment from 'moment';
// import Swal from "sweetalert2";
// import { SimpleWizard, WizardTab } from "@/components";
import formMixin from '@/mixins/form-mixin';
import { SlideYDownTransition } from 'vue2-transitions';
import { extend } from 'vee-validate';
import { required, email, min } from 'vee-validate/dist/rules';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';

extend('email', email);
extend('required', required);
extend('min', min);

export default {
  mixins: [formMixin],
  SlideYDownTransition,

  data() {
    return {
      code: null,
      start_time: null,
      start_on: null,
      beforestart_time: null,
      finish_time: null,
      finish_on: null,
      afterfinish_time: null,
      breakstart_time: null,
      breakstart_on: null,
      breakfinish_time: null,
      breakfinish_on: null,
      half_on: null,
      half_time: null,
      hours_amount: [
        {
          h: 1,
        },
        {
          h: 2,
        },
        {
          h: 3,
        },
        {
          h: 4,
        },
        {
          h: 5,
        },
        {
          h: 6,
        },
        {
          h: 7,
        },
        {
          h: 8,
        },
        {
          h: 9,
        },
        {
          h: 10,
        },
      ],
      day_tomorrow: [
        {
          n: 'Today',
          v: 'today',
        },
        {
          n: 'Tomorrow',
          v: 'tomorrow',
        },
      ],
      weekend: {
        type: 'weekends',
        name: null,
        first_day: null,
        second_day: null,
        third_day: null,
        weekend_type: null,
        week_type: null,
        company_id: null,
      },
    };
  },
  components: {
    VueCtkDateTimePicker: VueCtkDateTimePicker,
  },
  methods: {
    async submit() {
      this.setWorkingTime();
      //console.log(this.workingtime);
      try {
        await this.$store.dispatch('workingtimes/add', this.workingtime);
        await this.$store.dispatch('alerts/success', 'successfully.');

        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch('alerts/error', e.response.data.errors[0]);
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },

    setWorkingTime() {
      this.workingtime.code = this.code;
      (this.workingtime.start_time = this.start_time),
        (this.workingtime.start_on = this.start_on),
        (this.workingtime.half_time = this.half_time);
      (this.workingtime.half_on = this.half_on),
        (this.workingtime.finish_time = this.finish_time),
        (this.workingtime.finish_on = this.finish_on),
        (this.workingtime.breakstart_time = this.breakstart_time),
        (this.workingtime.breakstart_on = this.breakstart_on),
        (this.workingtime.breakfinish_time = this.breakfinish_time),
        (this.workingtime.breakfinish_on = this.breakfinish_on),
        (this.workingtime.beforestart_time = this.beforestart_time),
        (this.workingtime.afterfinish_time = this.afterfinish_time);
    },

    goBack() {
      this.$router.push({
        name: 'รายการ วันหยุดประจำสัปดาห์',
        path: 'list-weekend',
      });
    },
  },
};
</script>
<style>
.date-time-picker .flex {
  display: -webkit-box;
}
</style>
