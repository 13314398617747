<template>
  <div>
    <div class="approver-settings">
      <div class="mb-4">
        <hr class="my-4" />
        <h4 class="text-lg font-bold mb-4">Approval Groups</h4>
        <b-button class="mb-4" variant="info" @click="addApprovalGroup()">
          เพิ่มกลุ่มอนุมัติ
        </b-button>

        <div
          v-for="(group, groupIndex) in approvalGroups"
          :key="'g-' + (group.priority = groupIndex)"
        >
          <div
            v-if="groupIndex == 0"
            class="group bg-light p-4 rounded shadow-sm mb-6"
          >
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h5 class="h5">จัดทำโดย</h5>
            </div>

            <table class="table table-bordered">
              <thead class="thead-light">
                <tr>
                  <th scope="col" class="column-center">#</th>
                  <th scope="col">เลือกสำนักงาน</th>
                  <th scope="col">เลือกแผนก</th>
                  <th scope="col">ผู้อนุมัติ</th>
                  <th scope="col">ตำแหน่ง</th>
                </tr>
              </thead>
              <draggable v-model="group.approver" tag="tbody">
                <tr
                  v-for="(approver, approverIndex) in group.approver"
                  :key="'approver' + (group.order_person = approverIndex)"
                  class="even:bg-light"
                >
                  <td class="column-center">
                    {{ approverIndex + 1 }}
                  </td>

                  <td>
                    <div class="" style="width: 100%;">
                      <b-form-select
                        v-model="approver.bch_id"
                        :options="available_branches"
                        placeholder="เลือกแผนก"
                        :disabled="true"
                      ></b-form-select>
                    </div>
                  </td>
                  <td>
                    <div class="" style="width: 100%;">
                      <b-form-select
                        v-model="approver.employee.dep_id"
                        :options="approver.options.departments"
                        placeholder="เลือกแผนก"
                        :disabled="true"
                      ></b-form-select>
                    </div>
                  </td>
                  <td>
                    <md-autocomplete
                      v-model="approver.employee.emp_full_name"
                      :md-options="approver.options.employees"
                      @md-changed="searchStaffs(approver)"
                      @md-selected="selectStaff($event, group, approver)"
                      :disabled="true"
                    >
                      <template
                        slot="md-autocomplete-item"
                        slot-scope="{ item }"
                      >
                        {{ item.firstname_en + ' ' + item.lastname_en }}
                      </template>
                    </md-autocomplete>
                  </td>

                  <td>
                    <b-input
                      v-model="approver.employee.posname_en"
                      placeholder="Enter Position"
                      size="sm"
                      :disabled="!approver.employee.emp_full_name"
                    />
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
          <draggable
            v-if="groupIndex != 0"
            v-model="approvalGroups"
            tag="div"
            class="group-container"
          >
            <div class="group bg-light p-4 rounded shadow-sm mb-6">
              <div
                class="d-flex justify-content-between align-items-center mb-4"
              >
                <h5 class="h5">กลุ่มอนุมัติลำดับที่ {{ groupIndex }}</h5>
                <div>
                  <b-form-radio-group class="mb-2" v-model="group.approverType">
                    <b-form-radio value="approver">
                      Approver Memo
                    </b-form-radio>
                    <b-form-radio value="acknowledge">
                      Acknowledge Memo
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
                <div class="btn-group">
                  <b-button
                    size="sm"
                    variant="info"
                    @click="addApprover(group)"
                  >
                    เพิ่มผู้อนุมัติ
                  </b-button>
                  <b-button
                    size="sm"
                    variant="danger"
                    @click="deleteApprovalGroup(groupIndex)"
                  >
                    ลบกลุ่ม
                  </b-button>
                </div>
              </div>

              <table class="table table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th scope="col" class="column-center">#</th>
                    <th scope="col">เลือกสำนักงาน</th>
                    <th scope="col">เลือกแผนก</th>
                    <th scope="col">ผู้อนุมัติ</th>
                    <th scope="col">ตำแหน่ง</th>
                    <th scope="col" class="column-center"></th>
                  </tr>
                </thead>
                <draggable v-model="group.approver" tag="tbody">
                  <tr
                    v-for="(approver, approverIndex) in group.approver"
                    :key="'approver' + (group.order_person = approverIndex)"
                    class="even:bg-light"
                  >
                    <td class="column-center">
                      {{ approverIndex + 1 }}
                    </td>

                    <td>
                      <div class="" style="width: 100%;">
                        <b-form-select
                          v-model="approver.bch_id"
                          :options="available_branches"
                          placeholder="เลือกแผนก"
                          @change="handleBranchChange(approver)"
                          :disabled="!group.approverType"
                        ></b-form-select>
                      </div>
                    </td>
                    <td>
                      <div class="" style="width: 100%;">
                        <b-form-select
                          v-model="approver.employee.dep_id"
                          :options="approver.options.departments"
                          placeholder="เลือกแผนก"
                          :disabled="!approver.bch_id"
                          @change="handleDepartments(approver)"
                        ></b-form-select>
                      </div>
                    </td>
                    <td>
                      <md-autocomplete
                        v-model="approver.employee.emp_full_name"
                        :md-options="approver.options.employees"
                        @md-changed="searchStaffs(approver)"
                        @md-selected="selectStaff($event, group, approver)"
                        :disabled="!approver.bch_id"
                      >
                        <template
                          slot="md-autocomplete-item"
                          slot-scope="{ item }"
                        >
                          {{ item.firstname_en + ' ' + item.lastname_en }}
                        </template>
                      </md-autocomplete>
                    </td>

                    <td>
                      <b-input
                        v-model="approver.employee.posname_en"
                        placeholder="Enter Position"
                        size="sm"
                        :disabled="!approver.employee.emp_full_name"
                      />
                    </td>

                    <td class="column-center">
                      <b-button
                        size="sm"
                        variant="danger"
                        @click="deleteApprover(group, approverIndex)"
                      >
                        ลบ
                      </b-button>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import employeeMixin from '@/mixins/employee-mixin';
import Swal from 'sweetalert2';
import { loading_start, loading_close } from '@/utils/loading.js';
import draggable from 'vuedraggable';
import { isEmpty } from 'lodash';

export default {
  mixins: [employeeMixin],
  components: {
    draggable,
  },
  name: 'ApproverSettings',

  data() {
    return {
      employees: {},
      approvalGroups: [
        {
          priority: 0,
          approverType: 'createBy',
          approver: [
            {
              bch_id: '',
              order_person: 0,
              options: {
                departments: [
                  {
                    dep_id: undefined,
                    depname_en: undefined,
                  },
                ],
                employees: [
                  { firstname_en: undefined, lastname_en: undefined },
                ],
              },
              employee: {
                posname_en: '',
                depname_en: '',
                emp_full_name: '',
                dep_id: undefined,
              },
            },
          ],
        },
        {
          priority: 1,
          approverType: null,
          approver: [
            {
              bch_id: '',
              order_person: 1,
              options: {
                departments: [
                  {
                    value: undefined,
                    text: undefined,
                  },
                ],
                employees: [
                  { firstname_en: undefined, lastname_en: undefined },
                ],
              },
              employee: {
                posname_en: '',
                depname_en: '',
                emp_full_name: '',
                dep_id: undefined,
              },
            },
          ],
        },
      ],
      profile: null,
      available_branches: [],
      approverData: {},
    };
  },
  async created() {
    let params = { profile: true };

    loading_start();
    this.profile = await this.$store.dispatch('staffs/searchStaff', params);
    loading_close();

    await this.getBranches();
  },
  computed: {
    permissions() {
      let per = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => per.push(permis.name));
        this.me.roles[0].permissions.map((permission) => {
          per.push(permission.name);
        });
      }
      return per;
    },
  },
  async mounted() {
    await this.setCreator();
  },
  methods: {
    async setCreator() {
      const approver = this.approvalGroups[0].approver[0];
      if (
        this.approvalGroups[0].approverType == 'createBy' &&
        !approver.employee.dep_id
      ) {
        const { branch_id, employee_id, employees } = this.me;
        const { salarys } = employees;
        const { dep_id, pos_nameen } = salarys[0].positions;
        approver.bch_id = branch_id;
        approver.employee = {
          dep_id,
          depname_en: salarys[0].positions.departments.dep_nameen,
          emp_id: employee_id,
          firstname_en: employees.first_nameen,
          lastname_en: employees.last_nameen,
          firstname_th: employees.first_nameth,
          lastname_th: employees.last_nameth,
          emp_first_name: employees.first_nameth,
          emp_last_name: employees.last_nameth,
          pos_id: salarys[0].position_id,
          posname_en: pos_nameen,
          emp_full_name: `${employees.first_nameen} ${employees.last_nameen}`,
        };
        approver.options.departments = [
          {
            value: dep_id,
            text: salarys[0].positions.departments.dep_nameen,
          },
        ];
        const approverData = {
          emp_id: approver.employee.emp_id,
          emp_first_name: approver.employee.firstname_th,
          emp_last_name: approver.employee.lastname_th,
          position: approver.employee.posname_en,
          type: this.approvalGroups[0].approverType,
          order_person: approver.order_person,
          priority: this.approvalGroups[0]?.priority,
        };
        const approverKey = `${approverData?.priority}${approverData.order_person}`;
        this.approverData[approverKey] = approverData;
      }
    },
    addApprovalGroup() {
      this.approvalGroups.push({
        priority: null,
        approverType: null,
        approver: [
          {
            bch_id: undefined,
            order_person: 0,
            options: {
              departments: [
                {
                  value: undefined,
                  text: undefined,
                },
              ],
              employees: [{ firstname_en: undefined, lastname_en: undefined }],
            },
            employee: {
              posname_en: undefined,
              depname_en: undefined,
              emp_full_name: undefined,
              dep_id: undefined,
            },
          },
        ],
      });
    },
    async deleteApprovalGroup(groupIndex) {
      const result = await Swal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: 'การลบกลุ่มอนุมัติจะไม่สามารถกู้คืนได้!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่, ลบกลุ่ม!',
        cancelButtonText: 'ยกเลิก',
      });

      if (result.isConfirmed) {
        try {
          this.approvalGroups.splice(groupIndex, 1);
        } catch (error) {
          await Swal.fire({
            title: 'เกิดข้อผิดพลาด!',
            text: 'ไม่สามารถลบกลุ่มอนุมัติได้',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          });
          throw new Error(`Error deleting approval group: ${error}`);
        }
      }
    },
    async addApprover(group) {
      group.approver.push({
        bch_id: undefined,
        order_person: 0,
        options: {
          departments: [
            {
              value: undefined,
              text: undefined,
            },
          ],
          employees: [{ firstname_en: undefined, lastname_en: undefined }],
        },
        employee: {
          posname_en: undefined,
          depname_en: undefined,
          emp_full_name: undefined,
          dep_id: undefined,
        },
      });
    },
    async deleteApprover(group, approverIndex) {
      try {
        if (approverIndex < 0 || approverIndex >= group.approver.length) {
          throw new Error(`Invalid approverIndex: ${approverIndex}`);
        }

        const result = await Swal.fire({
          title: 'คุณแน่ใจหรือไม่?',
          text: 'การลบจะไม่สามารถกู้คืนได้!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ใช่, ลบ!',
          cancelButtonText: 'ยกเลิก',
        });

        if (result.isConfirmed) {
          group.approver.splice(approverIndex, 1);
        }
      } catch (error) {
        await Swal.fire({
          title: 'เกิดข้อผิดพลาด!',
          icon: 'error',
          showConfirmButton: false,
        });
        throw Error(error.message);
      }
    },
    async getBranches() {
      const params = {
        com_id: this.profile[0].com_id,
        bch_only: '1',
      };
      loading_start();
      const branches = await this.$store.dispatch('staffs/searchStaff', params);
      loading_close();
      this.available_branches = branches
        .filter(({ brname_en }) => this.permissions.includes(brname_en))
        .map(({ bch_id, brname_en }) => ({ value: bch_id, text: brname_en }));
    },
    async getDepartments(approver) {
      try {
        const params = {
          com_id: this.profile[0]?.com_id,
          bch_id: approver.bch_id,
          dep_id: approver.employee?.dep_id,
          dep_only: '1',
        };

        loading_start();
        const dep = await this.$store.dispatch('staffs/searchStaff', params);
        loading_close();

        approver.available_departments = dep.map(({ dep_id, depname_en }) => ({
          value: dep_id,
          text: depname_en,
        }));
      } catch (error) {
        approver.available_departments = [];
        throw new Error(`Failed to get departments: ${error}`);
      }
    },
    async selectStaff(event, { priority, approverType }, approver) {
      approver.employee = {
        ...event,
        emp_full_name: event.emp_full_name,
      };
      this.approverData[`${priority}${approver.order_person}`] = {
        emp_id: approver.employee.emp_id,
        emp_first_name: approver.employee.firstname_th,
        emp_last_name: approver.employee.lastname_th,
        position: approver.employee.posname_en,
        type: approverType,
        order_person: approver.order_person,
        priority,
      };
      await this.$emit('send-approver', this.approverData);
    },
    searchStaffs(approver) {
      if (approver.employee.emp_full_name == '') {
        approver.employee.posname_en = '';
        delete this.approverData[approver.employee.emp_id];
        this.$emit('send-approver', Object.values(this.approverData));
      }
    },
    async getStaff(approver) {
      if (this.employees[approver.bch_id]) {
        this.setDepartments(approver);
        this.setEmployeeData(approver);
        return false;
      } else {
        loading_start();
        try {
          const params = {
            com_id: this.profile[0].com_id,
            bch_id: approver.bch_id,
            dep_id: approver.employee.dep_id,
          };

          const data = await this.$store.dispatch('staffs/searchStaff', params);

          if (!this.employees) {
            this.employees = {};
          }
          if (!this.employees[approver.bch_id]) {
            this.employees[approver.bch_id] = {};
          }
          data.forEach(
            ({
              com_id,
              dep_id,
              depname_en,
              emp_id,
              firstname_en,
              lastname_en,
              firstname_th,
              lastname_th,
              pos_id,
              posname_en,
            }) => {
              if (!this.employees[approver.bch_id][dep_id]) {
                this.employees[approver.bch_id][dep_id] = {
                  dep_id,
                  depname_en,
                  employees: {},
                };
              }

              this.employees[approver.bch_id][dep_id].employees[emp_id] = {
                com_id,
                dep_id,
                depname_en,
                emp_id,
                firstname_en,
                lastname_en,
                firstname_th,
                lastname_th,
                pos_id,
                posname_en,
                emp_full_name: `${firstname_en} ${lastname_en}`,
              };
            },
          );
          this.setDepartments(approver);
          this.setEmployeeData(approver);
          loading_close();
        } catch (error) {
          loading_close();
          throw new Error(`Error fetching staff: ${error}`);
        } finally {
          loading_close();
        }
      }
    },
    setDepartments({ bch_id, options }) {
      if (this.employees[bch_id]) {
        options.departments = Object.values(this.employees[bch_id]).map(
          (dep) => ({
            value: dep.dep_id,
            text: dep.depname_en,
          }),
        );
      }
    },
    setEmployeeData({ bch_id, options, employee }) {
      const branchId = this.employees?.[bch_id];
      if (branchId) {
        if (employee.dep_id) {
          options.employees = Object.values(
            branchId[employee.dep_id].employees,
          );
        } else {
          options.employees = Object.values(branchId).flatMap((dep) => {
            return Object.values(dep.employees);
          });
        }
      }
    },
    handleBranchChange(approver) {
      approver.employee = {
        posname_en: '',
        depname_en: '',
        emp_full_name: '',
      };
      this.getStaff(approver);
    },
    handleDepartments(approver) {
      approver.employee.emp_full_name = '';
      approver.employee.posname_en = '';
      this.getStaff(approver);
    },
    async getApproverData() {
      return Object.values(this.approverData);
    },
    setApproverData(approvers) {
      if (isEmpty(approvers)) return;
      let approval = {};
      approvers.forEach((approver) => {
        if (!approval[approver.priority]) {
          approval[approver.priority] = {};
        }
        approval[approver.priority].priority = approver.priority;
        approval[approver.priority].approverType = approver.type;
        if (!approval[approver.priority].approver) {
          approval[approver.priority].approver = [];
        }
        approval[approver.priority].approver.push({
          bch_id: approver.bch_id,
          order_person: approver.order_person,
          options: {
            departments: [
              {
                value: approver.dep_id,
                text: approver.depname_en,
              },
            ],
            employees: [
              {
                dep_id: approver.dep_id,
                depname_en: approver.depname_en,
                emp_id: approver.emp_id,
                firstname_en: approver.firstname_en,
                lastname_en: approver.lastname_en,
                firstname_th: approver.firstname_th,
                lastname_th: approver.lastname_th,
                emp_first_name: approver.firstname_th,
                emp_last_name: approver.lastname_th,
                pos_id: approver.pos_id,
                posname_en: approver.position,
                emp_full_name: `${approver.firstname_en} ${approver.lastname_en}`,
              },
            ],
          },
          employee: {
            dep_id: approver.dep_id,
            depname_en: approver.depname_en,
            emp_id: approver.emp_id,
            firstname_en: approver.firstname_en,
            lastname_en: approver.lastname_en,
            firstname_th: approver.firstname_th,
            lastname_th: approver.lastname_th,
            emp_first_name: approver.firstname_th,
            emp_last_name: approver.lastname_th,
            pos_id: approver.pos_id,
            posname_en: approver.position,
            emp_full_name: `${approver.firstname_en} ${approver.lastname_en}`,
          },
        });
        const approverData = {
          emp_id: approver.emp_id,
          emp_first_name: approver.firstname_th,
          emp_last_name: approver.lastname_th,
          position: approver.position,
          type: approver.type,
          order_person: approver.order_person,
          priority: approver.priority,
        };
        const approverKey = `${approverData?.priority}${approverData.order_person}`;

        this.approverData[approverKey] = approverData;

        this.approvalGroups[approver.priority] = approval[approver.priority];
      });
      this.$emit('send-approver', this.approverData);
    },
  },
};
</script>
<style lang="scss" scoped>
tr,
th,
td {
  width: 100%;
}

tr {
  display: flex;
}

.group-container {
  margin-bottom: 1rem;
}

.group {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 0.75rem;
  border-bottom: 1px solid #e5e7eb;
}

.even {
  background-color: #f8fafc;
}

.b-button {
  margin-right: 0.5rem;
}

.column-center {
  /* width: auto; */
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.approver-settings {
  .md-menu input {
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    padding: 5px;
  }
}
</style>
