<template>
  <div>
    <div class="md-layout spacing">
      <md-dialog
        style="font-size: 110%"
        v-if="detail_leave.modifications"
        :md-active.sync="showDialog"
      >
        <md-dialog-title>รายละเอียด</md-dialog-title>
        <md-dialog-content>
          <div>
            ชื่อพนักงาน: {{ detail_leave.title_nameth }}
            {{ detail_leave.first_nameth }}
            {{ detail_leave.last_nameth }}<br />
            ประเภทลา: {{ detail_leave.leave_nameth }} <br />
            จากวันที่:
            {{
              moment(detail_leave.modifications.from_date.modified).format(
                'DD/MM/YYYY',
              )
            }}
          </div>
          <div v-if="detail_leave.modifications.leave_id.modified == 7">
            เปลี่ยนเป็นวันที่:
            {{
              moment(detail_leave.modifications.to_date.modified).format(
                'DD/MM/YYYY',
              )
            }}
          </div>
          <div v-else>
            ถึงวันที่:
            {{
              moment(detail_leave.modifications.to_date.modified).format(
                'DD/MM/YYYY',
              )
            }}
          </div>
          <br />
          <md-divider></md-divider>
          <p>
            หมายเหตุ:
            {{ detail_leave.modifications.notes.modified }}
          </p>
          <div v-if="detail_leave.modifications.attach_filepath.modified">
            <b-img
              v-if="detail_leave.modifications.attach_filepath.checked == 'pic'"
              :src="detail_leave.modifications.attach_filepath.modified"
              style="height:auto"
            />
            <b-button
              v-else-if="
                detail_leave.modifications.attach_filepath.checked == 'pdf'
              "
              variant="info"
              download="pdf"
              target="_blank"
              :href="detail_leave.modifications.attach_filepath.modified"
            >
              Download PDF
            </b-button>
          </div>
          <div v-else>ไม่มีรูป</div>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-danger" @click="showDialog = false">
            ปิด
          </md-button>
        </md-dialog-actions>
      </md-dialog>
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>
          <md-card-content>
            <md-button class="md-raised md-success" @click="getLeave">
              <md-icon>refresh</md-icon> refresh</md-button
            >
            <md-table
              v-model="tableData"
              table-header-color="green"
              :value="tableData"
              class="paginated-table table-striped table-hover"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="รหัสพนักงาน">
                  {{ item.emp_id }}
                </md-table-cell>
                <md-table-cell md-label="สาขา/ตำแหน่ง">
                  {{ item.br_nameth }}
                  {{ item.pos_nameth }}
                </md-table-cell>
                <md-table-cell md-label="ชื่อพนักงาน">
                  {{ item.first_nameen }} {{ item.last_nameen }}
                </md-table-cell>
                <md-table-cell md-label="ประเภทลา">
                  {{ item.leave_nameth }}
                </md-table-cell>
                <md-table-cell md-label="จากวันที่">
                  {{
                    moment(item.modifications.from_date.modified).format(
                      'DD/MM/YYYY',
                    )
                  }}
                </md-table-cell>
                <md-table-cell md-label="ถึงวันที่ / เปลี่ยนเป็นวันที่">
                  {{
                    moment(item.modifications.to_date.modified).format(
                      'DD/MM/YYYY',
                    )
                  }}
                </md-table-cell>
                <md-table-cell md-label="สถานะ">
                  <div
                    v-if="
                      item.modifications.approve_status.modified ==
                        'leaverequest'
                    "
                  >
                    <span style="font-size: 110%">
                      ยังไม่รับทราบและรอการอนุมัติ
                    </span>
                  </div>
                  <div
                    v-else-if="
                      item.modifications.approve_status.modified == 'pending'
                    "
                  >
                    <span style="color: blue; font-size: 110%">
                      รับทราบและรอการอนุมัติ
                    </span>
                  </div>
                  <div
                    v-if="
                      item.modifications.approve_status.modified == 'reject'
                    "
                  >
                    <span style="color: orange; font-size: 110%">
                      รอการแก้ไข
                    </span>
                  </div>
                </md-table-cell>
                <md-table-cell md-label="รายละเอียด">
                  <md-button
                    class="md-icon-button md-raised md-round md-info"
                    @click="onDetail(item)"
                    style="margin: 0.2rem"
                  >
                    <md-icon>more_horiz</md-icon>
                    <md-tooltip md-direction="top">Detail</md-tooltip>
                  </md-button>
                </md-table-cell>
                <md-table-cell md-label="Actions">
                  <md-button
                    class="md-icon-button md-raised md-round md-success"
                    @click="onApprove(item.id)"
                    style="margin: 0.2rem"
                  >
                    <md-icon>check</md-icon>
                    <md-tooltip md-direction="top">Approve</md-tooltip>
                  </md-button>

                  <md-button
                    class="md-icon-button md-raised md-round md-warning"
                    @click="onReject(item.id)"
                    style="margin: 0.2rem"
                  >
                    <md-icon>edit</md-icon>
                    <md-tooltip md-direction="top">Reject</md-tooltip>
                  </md-button>

                  <md-button
                    v-if="
                      item.modifications.approve_status.modified ==
                        'leaverequest'
                    "
                    class="md-icon-button md-raised md-round md-info"
                    @click="onPending(item.id)"
                    style="margin: 0.2rem"
                  >
                    <md-icon>visibility</md-icon>
                    <md-tooltip md-direction="top">Pending</md-tooltip>
                  </md-button>

                  <md-button
                    class="md-icon-button md-raised md-round md-danger"
                    @click="onDisapprove(item.id)"
                    style="margin: 0.2rem"
                  >
                    <md-icon>close</md-icon>
                    <md-tooltip md-direction="top">Disapprove</md-tooltip>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import moment from 'moment';
import Vuex from 'vuex';
import $ from 'jquery';

export default {
  data() {
    return {
      detail_leave: {},
      tableData: [],
      showDialog: false,
      moment: moment,
      approval: {
        type: 'approvals',
        id: null,
        status: null,
      },
    };
  },
  async created() {
    await this.getLeave();
  },

  methods: {
    async getLeave() {
      let params = {};
      await this.$store
        .dispatch('staffleaves/leavepending', params)
        .then(() => {
          if (this.$store.getters['staffleaves/list']) {
            this.tableData = this.$store.getters['staffleaves/list'];
            this.setLeaveAmout(this.$store.getters['staffleaves/leavetotal']);
          } else {
            this.tableData = [];
            this.setLeaveAmout(0);
          }
        });
    },
    onDetail(detail) {
      if (detail) {
        var attach_filepath = detail.modifications.attach_filepath.modified;
        this.detail_leave = { ...detail };
        if (attach_filepath) {
          let split = attach_filepath.split(';', 1);
          if (split == 'data:application/pdf') {
            this.detail_leave.modifications.attach_filepath.checked = 'pdf';
          } else {
            this.detail_leave.modifications.attach_filepath.checked = 'pic';
          }
        }
        this.showDialog = true;
      }
    },
    async onApprove(id) {
      const confirmation = await Swal.fire({
        title: 'อนุมัติวันลา ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve!',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        this.approval.id = id;
        this.approval.status = 'approve';
        await this.$store.dispatch('staffleaves/leaveapprove', this.approval);
        await this.$store.dispatch('alerts/success', 'Successfully.');
        this.$store.commit('staffleaves/SET_LIST');
        await this.getLeave();
      }
    },
    async onReject(id) {
      const confirmation = await Swal.fire({
        title: 'ตีกลับการลา ?',
        type: 'warning',
        input: 'text',
        inputLabel: 'หมายเหตุ',
        inputPlaceholder: 'Enter Comment',
        inputAttributes: {
          max: 100,
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, reject!',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
        preConfirm: () => {
          return [true, $('.swal2-input').val()];
        },
      });
      if (confirmation.dismiss) {
      } else {
        if (confirmation.value[0] === true) {
          this.approval.id = id;
          this.approval.status = 'reject';
          this.approval.comment = confirmation.value[1];
          await this.$store.dispatch('staffleaves/leaveapprove', this.approval);
          await this.$store.dispatch('alerts/success', 'Successfully.');
          this.$store.commit('staffleaves/SET_LIST');
          await this.getLeave();
        }
      }
    },
    async onPending(id) {
      const confirmation = await Swal.fire({
        title: 'รับทราบและรอการอนุมัติ ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, pending!',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });
      if (confirmation.value === true) {
        this.approval.id = id;
        this.approval.status = 'pending';
        await this.$store.dispatch('staffleaves/leaveapprove', this.approval);
        await this.$store.dispatch('alerts/success', 'Successfully.');
        this.$store.commit('staffleaves/SET_LIST');
        await this.getLeave();
      }
    },
    async onDisapprove(id) {
      const confirmation = await Swal.fire({
        title: 'ไม่อนุมัติวันลา ?',
        type: 'warning',
        input: 'text',
        inputLabel: 'หมายเหตุ',
        inputPlaceholder: 'Enter Comment',
        inputAttributes: {
          max: 100,
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, disapprove!',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
        preConfirm: () => {
          return [true, $('.swal2-input').val()];
        },
      });
      if (confirmation.dismiss) {
      } else {
        if (confirmation.value[0] === true) {
          this.approval.id = id;
          this.approval.status = 'disapprove';
          this.approval.comment = confirmation.value[1];
          await this.$store.dispatch('staffleaves/leaveapprove', this.approval);
          await this.$store.dispatch('alerts/success', 'Successfully.');
          this.$store.commit('staffleaves/SET_LIST');
          await this.getLeave();
        }
      }
    },
    ...Vuex.mapActions({
      setLeaveAmout: 'staffleaves/setLeaveAmout',
    }),
  },
};
</script>

<style>
/* @media only screen and (min-width: 600px) {
  .md-dialog {
    padding-left: 300px;
  }
} */
</style>
