import service from '@/store/services/salarys-service';

const state = {
  salarydecrypt: {},
  salaryCalculate: {},
};

const mutations = {
  SET_SALARY_DECRYPT: (state, value) => {
    state.salarydecrypt = value;
  },
  SET_SALARY_CALCULATE: (state, value) => {
    state.salaryCalculate = value;
  },
};

const actions = {
  async getListyearReport({ commit, dispatch }, params) {
    return await service.getListyearReport(params);
  },

  async slipSalary({ commit, dispatch }, params) {
    return await service.slipSalary(params);
  },
  async getListSalary({ commit, dispatch }, params) {
    return await service.getListSalary(params);
  },

  async salaryDecrypt({ commit, dispatch }, params) {
    return await service.salaryDecrypt(params).then((salarydecrypt) => {
      commit('SET_SALARY_DECRYPT', salarydecrypt.list);
      return salarydecrypt;
    });
  },

  async salaryCalculate({ commit, dispatch }, params) {
    return await service.salaryCalculate(params);
  },

  async salaryPeriod({ commit, dispatch }, params) {
    return await service.salaryPeriod(params).then((salary) => {
      return salary.list;
    });
  },

  async getReport({ commit, dispatch }, params) {
    return await service.getReport(params);
  },

  async listReport({ commit, dispatch }, params) {
    return await service.listReport(params);
  },

  async approverReport({ commit, dispatch }, params) {
    return await service.approverReport(params);
  },

  async approveReport({ commit, dispatch }, params) {
    return await service.approveReport(params);
  },

  async addIncomeCost({ commit, dispatch }, params) {
    return await service.addIncomeCost(params).then((salary) => {
      return salary.list;
    });
  },
};

const getters = {
  salarydecrypt: (state) => state.salarydecrypt,
  salaryamt: (state) => state.salarydecrypt.salary_amt,
  salarycalculatelist: (state) => state.salaryCalculate,
};

const salarys = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default salarys;
