<template>
  <md-card>
    <div class="header" @click="headdropdown()">
      <md-card-header class="md-card-header-icon md-card-header-danger">
        <div class="card-icon">
          <md-icon>sick</md-icon>
        </div>
        <h4 class="title">
          ลาป่วย (Sick Leave)
          <span v-if="old_leave && leavesicks.length <= 0">
            ไม่มีการลา
          </span>
        </h4>
      </md-card-header>
    </div>
    <md-card-content class="contentleave">
      <md-table
        v-if="leavesicks.length"
        v-model="leavesicks"
        md-sort="from_date_bfformat"
        md-sort-order="desc"
      >
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="From/ จาก">
            {{ item.from_date }}
            <div v-if="item.from_date_type === 'f'">เต็มวัน</div>
            <div v-else-if="item.from_date_type === 'hm'">ช่วงเช้า</div>
            <div v-else-if="item.from_date_type === 'hn'">ช่วงบ่าย</div>
          </md-table-cell>
          <md-table-cell md-label="To / ถึง">
            {{ item.to_date }}
            <div v-if="item.to_date_type === 'f'">เต็มวัน</div>
            <div v-else-if="item.to_date_type === 'hm'">ช่วงเช้า</div>
            <div v-else-if="item.to_date_type === 'hn'">ช่วงบ่าย</div>
          </md-table-cell>
          <md-table-cell md-label="วันที่ขออนุมัติ">
            {{ item.requestdate }}
          </md-table-cell>
          <md-table-cell
            v-if="item.approve_status == 'leaverequest'"
            md-label="สถานะการอนุมัติ"
          >
            <span style="color: ">รอการอนุมัติ</span>
            <!-- <span style="color: ">ยังไม่รับทราบและรอการอนุมัติ</span> -->
          </md-table-cell>
          <md-table-cell
            v-else-if="item.approve_status == 'pending'"
            md-label="สถานะการอนุมัติ"
          >
            <span style="color: blue">รับทราบและรอการอนุมัติ</span>
          </md-table-cell>
          <md-table-cell
            v-else-if="item.approve_status == 'approve'"
            md-label="สถานะการอนุมัติ"
          >
            <span style="color: green">อนุมัติแล้ว</span>
          </md-table-cell>

          <md-table-cell
            v-else-if="item.approve_status == 'disapprove'"
            md-label="สถานะการอนุมัติ"
          >
            <span style="color: red">ไม่อนุมัติวันลา</span>
          </md-table-cell>
          <md-table-cell
            v-else-if="item.approve_status == 'reject'"
            md-label="สถานะการอนุมัติ"
          >
            <!-- <b-button @click="editleave(item)" size="sm" variant="warning">
                แก้ไข
              </b-button> -->
          </md-table-cell>
          <md-table-cell md-label="เอกสารที่แนบ">
            <b-button
              v-if="
                item.attach_filepath != null &&
                  item.attach_filepath != '' &&
                  item.attach_filepath != 'null'
              "
              variant="info"
              @click="alertimg(item.attach_filepath)"
              pill
            >
              เอกสารที่แนบ
            </b-button>
            <div v-else>ไม่มีเอกสารที่แนบ</div>
          </md-table-cell>
          <md-table-cell md-label="ผู้อนุมัติ">
            <div v-if="item.approver_firstname">
              {{ item.approver_firstname }} {{ item.approver_lastname }}
            </div>
            <div v-else>ไม่มีผู้อนุมัติ</div>
          </md-table-cell>
          <md-table-cell md-label="วันที่อนุมัติ">
            {{ item.created_at }}
          </md-table-cell>
          <!-- <md-table-cell md-label="ฝ่ายบุคคล"> </md-table-cell> -->
          <!-- <md-table-cell md-label="วันที่รับทราบ"></md-table-cell> -->
          <md-table-cell md-label="หมายเหตุ">{{ item.notes }}</md-table-cell>
          <md-table-cell md-label="Action">
            <b-button
              v-if="permissions.includes('process salary') && persons != 0"
              @click="editLeave(item)"
              size="md"
              variant="warning"
            >
              แก้ไข
            </b-button>
            <b-button
              v-if="
                item.approve_status == 'leaverequest' ||
                  (permissions.includes('process salary') && persons != 0)
              "
              @click="deleteLeave(item)"
              size="md"
              variant="danger"
            >
              ลบ
            </b-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <md-table v-if="totaldatesick">
        <md-table-row>
          <md-table-head> ผลรวมวันลาป่วย </md-table-head>
          <md-table-head> {{ totaldatesick.total_leave }} </md-table-head>
          <md-table-head> เหลือวันลาป่วย </md-table-head>
          <md-table-head>
            {{ totaldatesick.leave_day - totaldatesick.total_leave }}
          </md-table-head>
        </md-table-row>
      </md-table>

      <br />
      <md-table v-if="!old_leave">
        <md-table-row>
          <md-table-head>From/ จาก</md-table-head>
          <md-table-head>To / ถึง</md-table-head>
          <md-table-head>เอกสารที่แนบ</md-table-head>
          <md-table-head>หมายเหตุ</md-table-head>
          <md-table-head>Action</md-table-head>
        </md-table-row>
        <md-table-row>
          <md-table-cell class="datepicker-cell" md-label="From / จาก">
            <b-datepicker
              variant="primary"
              placeholder="วันที่เริ่ม"
              locale="th"
              v-model="selectdate.from_date"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              :date-disabled-fn="disabledFromDates"
            ></b-datepicker>
            <md-checkbox v-model="is_leavestart_half">
              <span>ลาครึ่งวัน</span>
            </md-checkbox>
            <md-field v-show="is_leavestart_half">
              <label>เลือกช่วงการลา</label>
              <md-select v-model="from_date_type">
                <md-option value="hm">ช่วงเช้า</md-option>
                <md-option value="hn">ช่วงบ่าย</md-option>
              </md-select>
            </md-field>
          </md-table-cell>
          <md-table-cell class="datepicker-cell" md-label="To / ถึง">
            <b-datepicker
              variant="primary"
              placeholder="วันที่สิ้นสุด"
              locale="th"
              v-model="selectdate.to_date"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              :date-disabled-fn="disabledToDates"
            ></b-datepicker>
            <div v-show="is_samedate">
              <md-checkbox v-model="is_leaveend_half">
                ลาครึ่งวัน
              </md-checkbox>
              <md-field v-show="is_leaveend_half">
                <label>เลือกช่วงการลา</label>
                <md-select v-model="to_date_type">
                  <md-option value="hm">ช่วงเช้า </md-option>
                  <md-option value="hn">ช่วงบ่าย </md-option>
                </md-select>
              </md-field>
            </div>
          </md-table-cell>
          <md-table-cell md-label="เอกสารที่แนบ" style="text-align: right">
            <md-field>
              <label>เอกสารที่แนบ</label>
              <md-file
                placeholder="เอกสารต้องไม่เกิน 2MB!"
                id="files"
                ref="file"
                accept="image/*,.pdf"
                @md-change="check_file"
              >
              </md-file>
            </md-field>
            <b-button @click="alertimg" pill variant="info">
              ดูเอกสารที่เลือก
            </b-button>
          </md-table-cell>
          <md-table-cell md-label="หมายเหตุ">
            <md-field>
              <label>หมายเหตุ</label>
              <md-textarea v-model="notes"></md-textarea>
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="Action">
            <b-button @click="createleave" pill variant="success">
              ลาป่วย
            </b-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>

<script>
// import { Tabs } from '@/components';
// import { ValidationError } from '@/components';
// import { size } from 'vee-validate/dist/rules';
// import Vuex from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';

export default {
  components: {},
  mixins: [employeeMixin],
  data() {
    return {
      profile: {},
      available_leaves: null,
      selectdate: {
        from_date: null,
        to_date: null,
      },
      is_leavestart_half: false,
      is_leaveend_half: false,
      is_samedate: false,
      from_date_type: null,
      to_date_type: null,
      notes: '',
    };
  },

  props: {
    old_leave: {
      type: Boolean,
      default: false,
    },
    leavesicks: {
      type: Array,
      default: () => ({}),
    },
    totaldatesick: {
      type: Object,
      default: null,
    },
    resetform: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Array,
      default: () => ({}),
    },
    persons: {
      type: Number,
      default: 0,
    },
  },

  created() {
    // this.getDateAmoutLeave();
    // await this.getProfileData();
    // this.get();
  },

  computed: {
    // employees: function () {
    //   return JSON.parse(localStorage.employees);
    // },
    // ...Vuex.mapState({
    //   me: (state) => state.profile.me,
    // }),
    // ...Vuex.mapState({
    //   staffleaves: (state) => state.staffleaves.staffleave,
    // }),
  },
  watch: {
    selectdate: {
      handler() {
        this.check_date();
      },
      deep: true,
    },
    resetform: function() {
      if (this.resetform == true) {
        this.ResetForm();
        this.$emit('resetform', false);
      }
    },
  },
  methods: {
    check_date() {
      if (this.selectdate.from_date && this.selectdate.to_date) {
        let from_date = moment(this.selectdate.from_date).format('YYYY-MM-DD');
        let to_date = moment(this.selectdate.to_date).format('YYYY-MM-DD');
        if (from_date > to_date) {
          this.$store.dispatch('alerts/error', 'วันที่กรอกให้ถูกต้อง');
          setTimeout(() => {
            this.selectdate.to_date = this.selectdate.from_date;
          }, 50);
        }
        if (from_date == to_date) {
          this.to_date_type = null;

          this.is_samedate = false;
        } else {
          this.is_samedate = true;
        }
      }
    },

    disabledFromDates(date) {
      let fdate = moment(date).add(1, 'days');
      let tdate = moment();
      let datediff = fdate.diff(tdate, 'days');
      return datediff < -120 || datediff > 0;
    },

    disabledToDates(passdate) {
      let fdate = moment(passdate);
      let tdate = moment(this.selectdate.from_date);
      let datediff = fdate.diff(tdate);
      return datediff < -1;
    },

    check_file(files) {
      var elementfile;
      if (files.length) {
        elementfile = files[0];
      } else {
        if (this.$refs.file.$refs.inputFile.files.length) {
          elementfile = this.$refs.file.$refs.inputFile.files[0];
        }
      }
      if (elementfile) {
        const validTypes = [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'image/gif',
        ];

        if (!validTypes.includes(elementfile['type'])) {
          this.$store.dispatch('alerts/error', 'ประเภทไฟล์ไม่ถูกต้อง!');
          return false;
        }

        if (!(elementfile['size'] < 2097152 && elementfile['size'] > 0)) {
          this.$store.dispatch('alerts/error', 'ไฟล์มีขนาดเกินกว่า 2MB!');
          return false;
        }
        return elementfile;
      }
    },

    alertimg(string) {
      if (typeof string === 'string') {
        this.$emit('alertimg', string);
      } else {
        if (this.$refs.file.$refs.inputFile.files.length) {
          var img = this.$refs.file.$refs.inputFile.files[0];
          this.$emit('alertimg', img);
        } else {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกไฟล์!');
        }
      }
    },

    editLeave(leave) {
      this.$emit('editLeave', leave);
    },

    deleteLeave(leave) {
      this.$emit('deleteLeave', leave);
    },

    async createleave() {
      var item = {};
      item.leave_id = 1;
      if (this.selectdate.from_date != null) {
        let fromdate = this.selectdate.from_date;
        item.from_date = fromdate;
      } else {
        this.$store.dispatch('alerts/error', 'กรุณากรอกวันที่ให้ครบ');
        return false;
      }

      if (this.selectdate.to_date != null) {
        let todate = this.selectdate.to_date;
        item.to_date = todate;
      } else {
        this.$store.dispatch('alerts/error', 'กรุณากรอกวันที่ให้ครบ');
        return false;
      }

      if (this.is_samedate == false) {
        if (this.from_date_type == 'hm' || this.from_date_type == 'hn') {
          item.from_date_type = this.from_date_type;
          item.to_date_type = this.from_date_type;
        } else {
          item.from_date_type = 'f';
          item.to_date_type = 'f';
        }
      } else {
        if (this.from_date_type == 'hm' || this.from_date_type == 'hn') {
          item.from_date_type = this.from_date_type;
        } else {
          item.from_date_type = 'f';
        }

        if (this.to_date_type == 'hm' || this.to_date_type == 'hn') {
          item.to_date_type = this.to_date_type;
        } else {
          item.to_date_type = 'f';
        }
      }

      //       if (document.querySelector('#sick_textarea').value) {
      //   item.notes = document.querySelector('#sick_textarea').value;
      // } else {
      //   item.notes = '';
      // }
      // item.notes =
      //   document.querySelector('#sick_textarea').value == ''
      //     ? ''
      //     : document.querySelector('#sick_textarea').value;
      if (this.$refs.file.$refs.inputFile.files.length) {
        let file = this.check_file(this.$refs.file.$refs.inputFile.files[0]);
        if (file) {
          item.file = file;
        } else {
          return;
        }
      } else {
        this.$store.dispatch('alerts/error', 'กรุณาใส่ใบรับรองแพทย์');
        return;
      }

      // if (this.$refs.file.$refs.inputFile.files.length) {
      //   let elementfile = this.$refs.file.$refs.inputFile.files;
      //   if (elementfile[0]['size'] < 3145728 && elementfile[0]['size'] > 0) {
      //     item.file = elementfile[0];
      //   } else if (elementfile[0] != 0) {
      //     this.$store.dispatch('alerts/error', 'ไฟล์มีขนาดเกินกว่า 3MB!');
      //     return false;
      //   }
      // } else {
      //   this.$store.dispatch('alerts/error', 'กรุณาใส่ใบรับรองแพทย์');
      //   return false;
      // }
      item.notes = this.notes;
      this.$emit('createleave', item);
    },

    ResetForm() {
      this.from_date_type = 'f';
      this.to_date_type = 'f';
      this.selectdate = {};
      this.is_leavestart_half = false;
      this.is_leaveend_half = false;
      this.is_samedate = false;
      this.from_date_type = null;
      this.to_date_type = null;
      this.notes = '';
      document.querySelector('#files').value = null;
    },
  },
};
</script>
<style></style>
