import service from '@/store/services/otsflatrate-service';

const state = {
  list: {},
  ot: {},
  staffot: {},
  otstafflist: {},
  otamout: 0,
  ottotal: 0,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },

  SET_RESOURCE: (state, ot) => {
    state.ot = ot;
  },

  SET_OTSTAFFLIST: (state, otstafflist) => {
    state.otstafflist = otstafflist;
  },

  SET_STAFFOT: (state, staffot) => {
    state.staffot = staffot;
  },

  SET_OTTOTAL: (state, value) => {
    state.ottotal = value;
  },
};

const actions = {
  addStaffOTflatReat({ commit, dispatch }, params) {
    return service.addStaffOTflatReat(params).then((ot) => {
      commit('SET_STAFFOT', ot);
      return ot;
    });
  },

  addStaffOTflatReatApprove({ commit, dispatch }, params) {
    return service.addStaffOTflatReatApprove(params).then((ot) => {
      commit('SET_STAFFOT', ot);
      return ot;
    });
  },

  staffotlist({ commit, dispatch }, params) {
    return service.staffotlist(params).then(({ staffot, meta }) => {
      commit('SET_STAFFOT', staffot);
      return staffot;
    });
  },

  async updateOT({ commit, dispatch }, params) {
    return await service.updateOT(params);
  },

  async deleteOT({ commit, dispatch }, params) {
    return await service.deleteOT(params);
  },

  async getPriceOT({ commit, dispatch }, params) {
    return await service.getPriceOT(params);
  },
  async addPriceOT({ commit, dispatch }, params) {
    return await service.addPriceOT(params);
  },
  async addTreatment({ commit, dispatch }, params) {
    return await service.addTreatment(params);
  },
  async updatePriceOT({ commit, dispatch }, params) {
    return await service.updatePriceOT(params);
  },
  async deletePriceOT({ commit, dispatch }, params) {
    return await service.deletePriceOT(params);
  },
  async listOT_flat_rat({ commit, dispatch }, params) {
    return await service.listOT_flat_rat(params);
  },
  async getTypeOT({ commit, dispatch }, params) {
    return await service.getTypeOT(params);
  },
  async get_treatment({ commit, dispatch }, params) {
    return await service.get_treatment(params);
  },
  async update_treatment({ commit, dispatch }, params) {
    return await service.update_treatment(params);
  },
  async deleteTreatment({ commit, dispatch }, params) {
    return await service.deleteTreatment(params);
  },
  async listProcess({ commit, dispatch }, params) {
    return await service.listProcess(params);
  },
  async listflatreatProcess({ commit, dispatch }, params) {
    return await service.listflatreatProcess(params);
  },
  async createReportSpa({ commit, dispatch }, params) {
    return await service.createReportSpa(params);
  },
  async createReportOTFlatrate({ commit, dispatch }, params) {
    return await service.createReportOTFlatrate(params);
  },
  async getListyearReport({ commit, dispatch }, params) {
    return await service.getListyearReport(params);
  },
  async listReportFlatreat({ commit, dispatch }, params) {
    return await service.listReportFlatreat(params);
  },
  async getReport({ commit, dispatch }, params) {
    return await service.getReport(params);
  },
  async approverReport({ commit, dispatch }, params) {
    return await service.approverReport(params);
  },
  async approveReport({ commit, dispatch }, params) {
    return await service.approveReport(params);
  },
  async get_sendrp_flat_rate({ commit, dispatch }, params) {
    return await service.get_sendrp_flat_rate(params);
  },
  async deleteReport({ commit, dispatch }, params) {
    return await service.deleteReport(params);
  },
  async checkApproverReport({ commit, dispatch }, params) {
    return await service.checkApproverReport(params);
  },
};

const getters = {
  list: (state) => state.list,
  ot: (state) => state.ot,
  otstafflist: (state) => state.otstafflist,
  ottotal: (state) => state.ottotal,
  staffotlist: (state) => state.staffot,
  dropdown: (state) => {
    return state.list.map((ot) => ({
      id: ot.id,
      name: ot.name,
    }));
  },
};

const otsflatrate = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default otsflatrate;
