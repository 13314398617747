<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>add_circle_outline</md-icon>
          </div>
          <h4 class="title"></h4>
          <div class="text-right">
            <md-button @click="goBack" class="md-primary md-dense">
              Back to List
            </md-button>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
              <ValidationProvider
                name="workingtime.code"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>ชื่อกะทำงาน</label>
                  <md-input v-model="workingtime.code" type="text"> </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>

          <div class="md-layout spacing md-alignment-left">
            <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
              <ValidationProvider
                name="workingtime.start_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <VueCtkDateTimePicker
                    v-model="workingtime.start_time"
                    :only-time="true"
                    :noLabel="true"
                    :minute-interval="15"
                    :disable-date="true"
                    :format="'HH:mm'"
                    :formatted="'HH:mm'"
                    :label="'เวลาเริ่มงาน'"
                    :input-size="'sm'"
                  />
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <ValidationProvider
                name="workingtime.start_on"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="start_on">Day</label>
                  <md-select v-model="workingtime.start_on" name="start_on">
                    <md-option
                      v-for="item in day_tomorrow"
                      :key="item.v"
                      :value="item.v"
                    >
                      {{ item.n }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-15 mt-2 md-small-size-40">
              <ValidationProvider
                name="workingtime.beforestart_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-tooltip md-delay="300" md-direction="top">
                    จำนวนชั่วโมงที่พนักงานสามารถแสกนนิ้วเข้างานได้
                    ก่อนเวลาเข้างาน !!!
                  </md-tooltip>
                  <label for="beforestart_time">เวลาก่อนเริ่มงาน(ชม.)</label>
                  <md-select
                    v-model="workingtime.beforestart_time"
                    name="beforestart_time"
                  >
                    <md-option
                      v-for="item in hours_amount"
                      :key="item.h"
                      :value="item.h"
                    >
                      {{ item.h + ' ชั่วโมง' }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
              <ValidationProvider
                name="workingtime.finish_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <VueCtkDateTimePicker
                    v-model="workingtime.finish_time"
                    :only-time="true"
                    :noLabel="true"
                    :minute-interval="15"
                    :disable-date="true"
                    :format="'HH:mm'"
                    :formatted="'HH:mm'"
                    :label="'เวลาออกงาน'"
                    :input-size="'sm'"
                  />

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <ValidationProvider
                name="workingtime.finish_on"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="finish_on">Day</label>
                  <md-select v-model="workingtime.finish_on" name="finish_on">
                    <md-option
                      v-for="item in day_tomorrow"
                      :key="item.v"
                      :value="item.v"
                    >
                      {{ item.n }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-15 mt-2 md-small-size-40">
              <ValidationProvider
                name="workingtime.afterfinish_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <md-tooltip md-delay="300" md-direction="top">
                    จำนวนชั่วโมงที่พนักงานสามารถแสกนนิ้วออกได้ หลังเวลาออกงาน
                    !!!
                  </md-tooltip>
                  <label for="afterfinish_time">เวลาหลังเลิกงาน(ชม.)</label>
                  <md-select
                    v-model="workingtime.afterfinish_time"
                    name="afterfinish_time"
                  >
                    <md-option
                      v-for="item in hours_amount"
                      :key="item.h"
                      :value="item.h"
                    >
                      {{ item.h + ' ชั่วโมง' }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>

          <div class="md-layout spacing md-alignment-left">
            <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
              <ValidationProvider
                name="workingtime.breakstart_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <VueCtkDateTimePicker
                    v-model="workingtime.breakstart_time"
                    :only-time="true"
                    :noLabel="true"
                    :minute-interval="15"
                    :disable-date="true"
                    :format="'HH:mm'"
                    :formatted="'HH:mm'"
                    :label="'เวลาพัก'"
                    :input-size="'sm'"
                  />

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <ValidationProvider
                name="workingtime.breakstart_on"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="breakstart_on">Day</label>
                  <md-select
                    v-model="workingtime.breakstart_on"
                    name="breakstart_on"
                  >
                    <md-option
                      v-for="item in day_tomorrow"
                      :key="item.v"
                      :value="item.v"
                    >
                      {{ item.n }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
              <ValidationProvider
                name="workingtime.breakfinish_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <VueCtkDateTimePicker
                    v-model="workingtime.breakfinish_time"
                    :only-time="true"
                    :noLabel="true"
                    :minute-interval="15"
                    :disable-date="true"
                    :format="'HH:mm'"
                    :formatted="'HH:mm'"
                    :label="'หมดเวลาพัก'"
                    :input-size="'sm'"
                  />

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <ValidationProvider
                name="workingtime.breakfinish_on"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="breakfinish_on">Day</label>
                  <md-select
                    v-model="workingtime.breakfinish_on"
                    name="breakfinish_on"
                  >
                    <md-option
                      v-for="item in day_tomorrow"
                      :key="item.v"
                      :value="item.v"
                    >
                      {{ item.n }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>

          <div class="md-layout spacing md-alignment-left">
            <div class="md-layout-item md-size-20 mt-2 md-small-size-100">
              <ValidationProvider
                name="workingtime.half_time"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <VueCtkDateTimePicker
                    v-model="workingtime.half_time"
                    :only-time="true"
                    :noLabel="true"
                    :minute-interval="15"
                    :disable-date="true"
                    :format="'HH:mm'"
                    :formatted="'HH:mm'"
                    :label="'เวลาเริ่มงานกะครึ่งวัน'"
                    :input-size="'sm'"
                  />

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-10 mt-2 md-small-size-40">
              <ValidationProvider
                name="workingtime.half_on"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="half_on">Day</label>
                  <md-select v-model="workingtime.half_on" name="half_on">
                    <md-option
                      v-for="item in day_tomorrow"
                      :key="item.v"
                      :value="item.v"
                    >
                      {{ item.n }}
                    </md-option>
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>
        </md-card-content>
        <md-card-actions>
          <md-button type="submit" class="md-success">save </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>

<script>
// import moment from 'moment';
// import Swal from "sweetalert2";
// import { SimpleWizard, WizardTab } from "@/components";
import formMixin from '@/mixins/form-mixin';
import { SlideYDownTransition } from 'vue2-transitions';
import { extend } from 'vee-validate';
import { required, email, min } from 'vee-validate/dist/rules';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';

extend('email', email);
extend('required', required);
extend('min', min);

export default {
  mixins: [formMixin],
  SlideYDownTransition,

  data() {
    return {
      code: null,
      start_time: null,
      start_on: null,
      beforestart_time: null,
      finish_time: null,
      finish_on: null,
      afterfinish_time: null,
      breakstart_time: null,
      breakstart_on: null,
      breakfinish_time: null,
      breakfinish_on: null,
      half_on: null,
      half_time: null,
      hours_amount: [
        {
          h: 1,
        },
        {
          h: 2,
        },
        {
          h: 3,
        },
        {
          h: 4,
        },
        {
          h: 5,
        },
        {
          h: 6,
        },
        {
          h: 7,
        },
        {
          h: 8,
        },
        {
          h: 9,
        },
        {
          h: 10,
        },
      ],
      day_tomorrow: [
        {
          n: 'Today',
          v: 'today',
        },
        {
          n: 'Tomorrow',
          v: 'tomorrow',
        },
      ],
      workingtime: {
        type: 'workingtimes',
        code: null,
        start_time: null,
        start_on: null,
        half_time: null,
        half_on: null,
        finish_time: null,
        finish_on: null,
        breakstart_time: null,
        breakstart_on: null,
        breakfinish_time: null,
        breakfinish_on: null,
        beforestart_time: null,
        afterfinish_time: null,
      },
    };
  },
  components: {
    VueCtkDateTimePicker: VueCtkDateTimePicker,
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('workingtimes/add', this.workingtime);
        await this.$store.dispatch('alerts/success', 'successfully.');

        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch('alerts/error', e.response.data.errors[0]);
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },

    goBack() {
      this.$router.push({
        name: 'รายการ กะการทำงาน',
      });
    },
  },
};
</script>
<style>
.date-time-picker .flex {
  display: -webkit-box;
}
</style>
