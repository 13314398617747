import qs from 'qs';
import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

async function importColumnlist(params) {
  const response = await axios.post(`${url}/v1/getimportcolumn`, params);
  return response.data;
}

async function importIncomeCost(params) {
  const response = await axios.post(`${url}/v1/importincomecost`, params);
  return response.data;
}

async function importTime(params) {
  const response = await axios.post(`${url}/v1/importtime`, params);
  return response.data;
}

async function importWorkShift(params) {
  const response = await axios.post(`${url}/v1/importworkshift`, params);
  return response.data;
}
async function importLeave(params) {
  const response = await axios.post(`${url}/v1/importLeave`, params);
  return response.data;
}
async function getLeaves(params) {
  const response = await axios.get(`${url}/v1/getLeaves`, params);
  return response.data;
}

export default {
  importColumnlist,
  importIncomeCost,
  importTime,
  importWorkShift,
  importLeave,
  getLeaves,
};
