import service from '@/store/services/pdfs-service';

const state = {
  font: {},
  branchLogo: {},
};

const mutations = {
  SET_FONT: (state, font) => {
    for (const [key, value] of Object.entries(font)) {
      if (!state.font[key]) {
        state.font[key] = {};
      }
      for (const [key2, value2] of Object.entries(value)) {
        if (!state.font[key][key2]) {
          state.font[key][key2] = '';
        }
        state.font[key][key2] = value2;
      }
    }
  },
  SET_LOGO: (state, logo) => {
    state.branchLogo = logo;
  },
};

const actions = {
  async getFont({ commit, dispatch }, params) {
    let font = await service.getFont(params);
    // console.log(font);
    commit('SET_FONT', font);
    return font;
  },

  async getBranchLogo({ commit, dispatch }, params) {
    // console.log('getBranchLogo');
    let img = await service.getBranchLogo(params);
    commit('SET_LOGO', img);
    return img;
  },
};

const getters = {
  font: (state) => state.font,
  branchLogo: (state) => state.branchLogo,
};

const pdfs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default pdfs;
