import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function list(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/getleaves`, options);
  return response.data;
}

async function reportremainleave(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/reportremainleave`, options);
  return response.data;
}

async function insertremainleave(params) {
  const response = await axios.post(`${url}/v1/insertremainleave`, params);
  return response.data;
}

export default {
  list,
  reportremainleave,
  insertremainleave,
};
