<template>
  <div v-show="!hidden" ref="editor"></div>
</template>

<script>
import {
  getInputFromTemplate,
  getDefaultFont,
  DEFAULT_FONT_NAME,
} from '@pdfme/common';
import { Designer } from '@pdfme/ui';
import {
  multiVariableText,
  text,
  barcodes,
  image,
  svg,
  line,
  table,
  rectangle,
  ellipse,
} from '@pdfme/schemas';
import { generate } from '@pdfme/generator';
import $ from 'jquery';
import { getBaseTemplate } from '@/components/Pdfme/BaseTemplate/Memo';

export default {
  name: 'PdfmeEditor',
  props: {
    baseTemplate: {
      type: Object,
      default: () => ({
        schemas: [[]],
        basePdf: {
          width: 210,
          height: 297,
          padding: [15, 15, 15, 15],
        },
        pdfmeVersion: '5.0.0',
      }),
    },
    contentTemplate: {
      type: Object,
      default: () => ({
        schemas: [[]],
        basePdf: {
          width: 210,
          height: 297,
          padding: [15, 15, 15, 15],
        },
        pdfmeVersion: '5.0.0',
      }),
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    previewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      designer: null,
      fontObjList: [
        {
          fallback: true,
          label: 'Angsana New',
          url:
            process.env.VUE_APP_IMAGE_BASE_URL +
            '/storage/font/angsananew/angsana-new-normal.ttf',
        },
        {
          fallback: false,
          label: 'Angsana New Bold',
          url:
            process.env.VUE_APP_IMAGE_BASE_URL +
            '/storage/font/angsananew/angsana-new-bold.ttf',
        },
        {
          fallback: false,
          label: DEFAULT_FONT_NAME,
          data: getDefaultFont()[DEFAULT_FONT_NAME].data,
        },
      ],
      fonts: null,
    };
  },
  async mounted() {
    if (!this.previewOnly) {
      await this.setDesigner();

      let counter = 0;
      const interval = setInterval(() => {
        if (++counter > 5) return clearInterval(interval);

        const editor = this.$refs.editor;
        if (editor?.children?.length > 0) {
          this.$emit('onMountedPdfme');
          clearInterval(interval);
        }
      }, 500);
    }
  },

  methods: {
    addOptionToolbars() {
      const $buttonElement = $(
        'div[role="button"][aria-roledescription="draggable"]',
      ).first();

      if (!$buttonElement.length) return false;

      const options = [
        {
          title: 'Reset Header',
          svg: `<svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 27.206 27.206" xml:space="preserve">
                        <g>
	                        <g>
		                        <path d="M17.986,0H4.518v27.168l18.17,0.038V4.686L17.986,0z M21.534,26.05L5.671,26.018V1.154h11.104v5.213h4.759V26.05z
			                        M21.534,5.214h-3.604V1.571l3.604,3.594V5.214z"/>
		                        <path d="M8.595,16.135L6.56,21.66h1.71l0.248-0.789h1.756l0.233,0.789h1.764l-2.012-5.525H8.595z M8.889,19.688l0.534-1.703h0.015
			                        l0.496,1.703H8.889z"/>
		                        <path d="M15.549,11.685c0.111-0.209,0.167-0.453,0.167-0.731c0-0.201-0.027-0.379-0.081-0.533
			                        c-0.055-0.155-0.125-0.289-0.213-0.399c-0.088-0.111-0.189-0.203-0.305-0.275C15,9.675,14.881,9.618,14.756,9.577
			                        c0.207-0.114,0.373-0.252,0.496-0.418c0.123-0.165,0.187-0.388,0.187-0.666c0-0.139-0.024-0.29-0.069-0.453
			                        c-0.047-0.163-0.136-0.313-0.268-0.452c-0.131-0.139-0.312-0.254-0.542-0.345c-0.229-0.09-0.525-0.135-0.886-0.135h-3.027v5.524
			                        h3.134c0.242,0,0.479-0.036,0.712-0.108c0.231-0.072,0.438-0.178,0.619-0.318C15.292,12.068,15.438,11.893,15.549,11.685z
			                        M12.35,8.423h0.897c0.175,0,0.316,0.028,0.422,0.085c0.106,0.058,0.159,0.155,0.159,0.294c0,0.18-0.052,0.302-0.155,0.364
			                        s-0.245,0.093-0.426,0.093H12.35V8.423z M13.959,11.054c-0.036,0.067-0.085,0.12-0.147,0.159s-0.133,0.066-0.213,0.081
			                        c-0.08,0.016-0.164,0.023-0.251,0.023H12.35v-1.029h1.068c0.397,0,0.596,0.167,0.596,0.502
			                        C14.014,10.899,13.995,10.987,13.959,11.054z"/>
		                        <path d="M15.518,15.134c-0.131,0.357-0.198,0.751-0.198,1.185c0,0.408,0.063,0.789,0.187,1.143s0.306,0.658,0.546,0.917
			                        c0.239,0.258,0.532,0.462,0.877,0.612c0.346,0.148,0.743,0.224,1.192,0.224c0.402,0,0.76-0.067,1.076-0.2
			                        c0.313-0.135,0.578-0.312,0.791-0.531c0.213-0.217,0.377-0.465,0.488-0.738c0.109-0.271,0.167-0.549,0.167-0.828h-1.671
			                        c-0.025,0.129-0.061,0.251-0.105,0.364c-0.043,0.114-0.1,0.212-0.17,0.295c-0.07,0.082-0.154,0.146-0.254,0.192
			                        c-0.102,0.047-0.225,0.069-0.367,0.069c-0.203,0-0.369-0.044-0.504-0.132c-0.134-0.087-0.24-0.204-0.32-0.348
			                        c-0.082-0.145-0.139-0.308-0.174-0.487c-0.037-0.181-0.056-0.364-0.056-0.55c0-0.187,0.019-0.368,0.056-0.549
			                        c0.035-0.181,0.092-0.342,0.174-0.486c0.08-0.145,0.187-0.262,0.32-0.348c0.135-0.09,0.303-0.131,0.504-0.131
			                        c0.174,0,0.318,0.032,0.428,0.099c0.111,0.067,0.201,0.145,0.268,0.231c0.066,0.09,0.113,0.178,0.139,0.268
			                        c0.026,0.091,0.045,0.162,0.055,0.213h1.656c-0.072-0.697-0.316-1.234-0.732-1.613c-0.415-0.379-1.004-0.571-1.768-0.577
			                        c-0.428,0-0.813,0.07-1.156,0.209c-0.344,0.139-0.637,0.336-0.883,0.589C15.838,14.475,15.65,14.779,15.518,15.134z"/>
	                        </g>
                        </g>
                      </svg>`,
          className: 'reset-header',
        },
        {
          title: 'Signature CreateBy',
          svg: `<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="none">
                  <path stroke="#535358" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 27C6 19.333 13.5 5 18.5 5 23 5 10 26 15 26c3.5 0 6-10.5 8.5-10.5s-1 9 1 9c2.5 0 4-4 4-4"/>
                </svg>`,
          className: 'add-createby-signature',
        },
      ];
      options.forEach((o) => {
        const $optionElement = $buttonElement.clone();
        $optionElement.find('svg').replaceWith(o.svg);
        $optionElement.addClass(o.className);
        $buttonElement.parent().append($optionElement);
      });
      return true;
    },
    getNewTextBox(value = {}) {
      const defaults = {
        name: 'NAME',
        type: 'text',
        content: 'CONTENT',
        position: { x: 13, y: 0.5 },
        width: 27,
        height: 7,
        rotate: 0,
        alignment: 'center',
        verticalAlignment: 'middle',
        fontSize: 18,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: '#000000',
        backgroundColor: '',
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: 'Angsana New',
      };

      return {
        ...defaults,
        ...value,
        position: { ...defaults.position, ...value.position },
      };
    },
    getNewTemplate(
      value = { type: 'TYPE', name: 'NAME_TH', position: 'POSITION' },
    ) {
      const signatureTemplate = this.getNewTextBox(value);
      return {
        template: {
          schemas: [[signatureTemplate]],
          basePdf: { width: 55, height: 28, padding: [0, 0, 0, 0] },
          pdfmeVersion: '5.0.0',
        },
      };
    },
    /**
     * Updates the content of a specific field in the designer template.
     *
     * @param {string} fieldName - The name of the field to update in the template.
     * @param {string} content - The new content to set for the specified field.
     * @param {number} [page=0] - The page number where the field is located (defaults to 0).
     *
     * @param key
     * @throws {Error} If the designer instance is not available.
     * @throws {Error} If the page does not exist in the template schemas.
     * @throws {Error} If an error occurs during the content update.
     *
     * @returns {void}
     */
    updateContent(fieldName, content, key = 'content') {
      try {
        if (!this.designer) throw new Error('Designer instance not available');

        const template = this.designer.getTemplate();

        const field = template.schemas
          .flat()
          .find((field) => field.name === fieldName);

        if (field && field?.[key]) {
          field[key] = content;
          this.designer.updateTemplate(template);
        }
      } catch (error) {
        throw new Error(`Error updating content: ${error.message}`);
      }
    },
    async updateBaseTemplate(
      baseTemplate = this.baseTemplate,
      template = this.designer.getTemplate(),
    ) {
      try {
        const schemas = template?.schemas;

        if (!schemas || schemas.length === 0) {
          throw new Error('No schemas available in the template.');
        }

        schemas.forEach((page, pageIndex) => {
          const basePageTemplate =
            baseTemplate.schemas[pageIndex] || baseTemplate.schemas.at(-1);
          if (!basePageTemplate) {
            throw new Error(
              `Base template not found for page index ${pageIndex}`,
            );
          }

          const objBaseTemplate = basePageTemplate.reduce((acc, bt) => {
            acc[bt.name] = bt;
            return acc;
          }, {});

          page.forEach((item) => {
            if (objBaseTemplate[item.name]) {
              item = { ...objBaseTemplate[item.name] };
              delete objBaseTemplate[item.name];
            }
          });

          page.push(...Object.values(objBaseTemplate));
        });

        this.designer?.updateTemplate(template);
      } catch (error) {
        throw new Error(`Error in updateBaseTemplate: ${error.message}`);
      }
    },
    async updateContentTemplate(template = this.contentTemplate) {
      await this.designer.updateTemplate(template);
      await this.updateBaseTemplate();
    },
    getTemplatePosition(template, newLine = false, pageIndex) {
      const existingSchemas =
        template.schemas[pageIndex ?? template.schemas.length - 1];
      const maxYValue = Math.max(
        ...existingSchemas.map((field) => field.position.y),
      );

      const maxYFields = existingSchemas.filter(
        (field) => field.position.y === maxYValue,
      );

      const maxXField = maxYFields.reduce((prev, curr) => {
        return curr.position.x + curr.width > prev.position.x + prev.width
          ? curr
          : prev;
      });

      const maxX = maxXField.position.x;
      const maxY = maxXField.position.y;
      const maxWidth = maxXField.width;
      const maxHeight = maxXField.height;

      const effectiveWidth = 210 - 2 * 18;
      const effectiveHeight = 297 - 2 * 18;

      let newY = maxY - 15;
      let newX = maxX + maxWidth + 1;
      if (newX > effectiveWidth || newLine) {
        newX = 18 + 3;
        newY = maxY + maxHeight + 3;
      }
      if (newY > effectiveWidth) {
        newY = effectiveHeight - maxHeight;
      }

      return { x: newX, y: newY };
    },
    async convertPdfToBase64(values, positions) {
      const font = await this.getFontsData();
      const pdf = await generate({
        template: values.template,
        inputs: values.inputs,
        options: {
          font,
          title: `MEMORANDAM:${this.memorandam?.subjectText}`,
        },
        plugins: this.getPlugins(),
      });
      const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
      const pdfBase64 = await this.getBase64(blob);
      return {
        name: `pdfBase64-${values?.name}`,
        type: 'image',
        content: pdfBase64,
        position: { x: positions.x, y: positions.y },
        width: 55,
        height: 28,
        rotate: 0,
        alignment: 'center',
        verticalAlignment: 'top',
        opacity: 1,
        required: false,
        readOnly: false,
      };
    },
    getBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });
    },
    base64ToBlob(base64, type = 'application/pdf') {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return new Blob([bytes], { type: type });
    },
    async getFontsData() {
      if (this.fonts) return this.fonts;

      const fontDataList = await Promise.all(
        this.fontObjList.map(async (font) => ({
          ...font,
          data:
            font.data ||
            (await fetch(font.url || '').then((res) => res.arrayBuffer())),
        })),
      );

      return fontDataList.reduce(
        (acc, font) => ({ ...acc, [font.label]: font }),
        {},
      );
    },
    async setDesigner() {
      const font = await this.getFontsData();
      if (this.$refs.editor) {
        this.designer = new Designer({
          domContainer: this.$refs.editor,
          template: this.contentTemplate,
          options: { font },
          plugins: this.getPlugins(),
        });
      }
    },
    getPlugins() {
      return {
        'Text': text,
        'Multi-Variable Text': multiVariableText,
        'Table': table,
        'Line': line,
        'Rectangle': rectangle,
        'Ellipse': ellipse,
        'Image': image,
        'SVG': svg,
        'QR': barcodes.qrcode,
      };
    },
    async generatePDF({
      template,
      title = 'Preview PDF',
      preview = !this.previewOnly,
    } = {}) {
      const currentTemplate = template || this.designer?.getTemplate();
      const inputs =
        template?.inputs ||
        this.designer?.getInputs?.() ||
        getInputFromTemplate(currentTemplate);
      const font = await this.getFontsData();

      if (!currentTemplate) {
        throw new Error('ไม่มี template สำหรับการสร้าง PDF');
      }

      try {
        const pdf = await generate({
          template: currentTemplate,
          inputs,
          options: {
            font,
            title,
          },
          plugins: this.getPlugins(),
        });

        const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
        if (preview) window.open(URL.createObjectURL(blob));
        return blob;
      } catch (e) {
        throw new Error(`Error generating PDF: ${e}`);
      }
    },
    async loadTemplate(fileName, functionName, params) {
      const module = await import('./BaseTemplate/' + fileName);
      return module[functionName](params);
    },
  },
};
</script>
