<template>
  <div>
    <div class="md-layout spacing">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>
          <md-card-content>
            <md-button class="md-raised md-success" @click="getKeepday">
              <md-icon>refresh</md-icon>
              refresh
            </md-button>
            <md-table
              v-model="keepdaystafflist"
              table-header-color="green"
              :value="keepdaystafflist"
              class="paginated-table table-striped table-hover"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="รหัสพนักงาน">
                  {{ item.emp_id }}
                </md-table-cell>
                <md-table-cell md-label="สาขา/ตำแหน่ง">
                  {{ item.br_nameth }}
                  {{ item.pos_nameth }}
                </md-table-cell>
                <md-table-cell md-label="ชื่อพนักงาน">
                  {{ item.first_nameen }} {{ item.last_nameen }}
                </md-table-cell>
                <md-table-cell md-label="วันที่">
                  {{ item.from_date_format }}
                </md-table-cell>
                <md-table-cell md-label="ระยะเวลา">
                  {{ item.from_date_time }} - {{ item.to_date_time }}
                </md-table-cell>
                <md-table-cell md-label="รวมเวลา">
                  {{ item.hours_time }} ชั่วโมง {{ item.minutes_time }} นาที
                </md-table-cell>
                <md-table-cell md-label="หมายเหตุ">
                  {{ item.modifications.notes.modified }}
                </md-table-cell>
                <md-table-cell md-label="วันที่ขออนุมัติ">
                  {{ item.request_date_format }}
                </md-table-cell>

                <md-table-cell md-label="Actions">
                  <div>
                    <md-button
                      class="md-icon-button md-raised md-round md-success"
                      @click="onApprove(item.id)"
                      style="margin: 0.2rem"
                    >
                      <md-icon>check</md-icon>
                    </md-button>
                    <md-tooltip md-direction="top">Approve</md-tooltip>
                  </div>

                  <div>
                    <md-button
                      class="md-icon-button md-raised md-round md-danger"
                      @click="onDisapprove(item.id)"
                      style="margin: 0.2rem"
                    >
                      <md-icon>close</md-icon>
                    </md-button>
                    <md-tooltip md-direction="top">Disapprove</md-tooltip>
                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import moment from 'moment';
import Vuex from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      keepdaystafflist: [],
      moment: moment,
      approval: {
        type: 'approvals',
        id: null,
        status: null,
      },
      query: null,
    };
  },
  async created() {
    // await this.getProfileData();
    await this.getKeepday();
  },

  methods: {
    async getKeepday() {
      let params = {};
      const keepdaystafflist = await this.$store.dispatch(
        'keepdays/keepdaystafflist',
        params,
      );
      if (keepdaystafflist) {
        this.keepdaystafflist = await Promise.all(
          keepdaystafflist.map((val) => {
            let fromdate = moment(val.modifications.from_date.modified).format(
              'DD/MM/YYYY',
            );
            let requestdate = moment(
              val.modifications.requestdate.modified,
            ).format('DD/MM/YYYY');

            let fromdate_time = moment(val.modifications.from_date.modified);
            let todate_time = moment(val.modifications.to_date.modified);
            var datediff_hours = todate_time.diff(fromdate_time, 'hours');
            var datediff_minutes = todate_time.diff(fromdate_time, 'minutes');

            val.from_date_time = moment(
              val.modifications.from_date.modified,
            ).format('HH:mm');
            val.to_date_time = moment(
              val.modifications.to_date.modified,
            ).format('HH:mm');
            val.from_date_format = fromdate;
            val.request_date_format = requestdate;
            val.hours_time = datediff_hours;
            val.minutes_time = datediff_minutes % 60;

            return val;
          }),
        );
      } else {
        this.keepdaystafflist = [];
      }
    },
    async onApprove(id) {
      const confirmation = await Swal.fire({
        title: 'อนุมัติทำงานวันหยุด ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve!',
        cancelButtonText: 'No,',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        this.approval.id = id;
        this.approval.status = 'approve';
        await this.$store.dispatch('keepdays/keepdayapprove', this.approval);
        this.getKeepday();
        this.$store.dispatch('alerts/success', 'Successfully.');
      }
    },
    async onDisapprove(id) {
      const confirmation = await Swal.fire({
        title: 'ไม่อนุมัติทำงานวันหยุด ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, disapprove!',
        cancelButtonText: 'No,',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        this.approval.id = id;
        this.approval.status = 'disapprove';
        await this.$store.dispatch('keepdays/keepdayapprove', this.approval);
        this.getKeepday();
        this.$store.dispatch('alerts/success', 'Successfully.');
      }
    },
    // async onReject(id) {
    //   const confirmation = await Swal.fire({
    //     title: 'ไม่อนุมัติ OT ?',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, reject!',
    //     cancelButtonText: 'No,',
    //     confirmButtonClass: 'md-button md-success',
    //     cancelButtonClass: 'md-button md-danger',
    //   });

    //   if (confirmation.value === true) {
    //     this.approval.id = id;
    //     this.approval.status = 'reject';
    //     await this.$store.dispatch('staffs/otapprove', this.approval);
    //     await this.$store.dispatch('alerts/success', 'Successfully.');

    //     //set amout of notify
    //     let params = {};
    //     await this.$store.dispatch('staffs/staffotlist', params).then(() => {
    //       if (this.$store.getters['staffs/ottotal']) {
    //         this.$store.commit(
    //           'staffs/SET_OTAMOUT',
    //           this.$store.getters['staffs/ottotal'],
    //         );
    //       } else {
    //         this.$store.commit('staffs/SET_OTAMOUT', 0);
    //       }
    //     });

    //     this.getOT();
    //   }
    // },

    ...Vuex.mapActions({
      setKeepdayAmout: 'keepdays/setKeepdayAmout',
    }),
  },
};
</script>

<style></style>
