<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">Reset รหัสผ่าน</slot>
            <button type="button" class="close" @click="$emit('close-modal')">
              X
            </button>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="row">
                <div class="col-sm-4 text-right">Name :</div>
                <div class="col-sm-6">
                  <input
                    type="text"
                    name="name-emp"
                    class="form-control"
                    readonly
                    :value="
                      modal_item.title_nameth +
                        ' ' +
                        modal_item.first_nameth +
                        ' ' +
                        modal_item.last_nameth
                    "
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4 mt-3 text-right">New Password :</div>
                <div class="col-sm-6 mt-3">
                  <input
                    type="text"
                    name="mew-emp-pwd"
                    class="form-control"
                    v-model="new_password"
                    maxlength="21"
                  />
                </div>
                <div class="col-sm-2 mt-3 pl-0">
                  <button class="btn btn-info" @click="random_char">
                    <i class="fa fa-random" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-success" @click="resetPassword">
                Reset
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
export default {
  data() {
    return {
      new_password: '',
      passwordReset: {
        type: 'password-reset',
        password: null,
        password_confirmation: null,
        //email: null,
        //token: "",
      },
      showModal: false,
    };
  },
  props: {
    show: Boolean,
    modal_item: Object,
  },
  methods: {
    async resetPassword() {
      if (!this.new_password) {
        this.$store.dispatch(
          'alerts/error',
          'กรุณาใส่รหัสผ่านที่ต้องการเปลี่ยน',
        );
        return false;
      }

      if (this.new_password.length < 8) {
        this.$store.dispatch(
          'alerts/error',
          'กรุณาใส่รหัสผ่านให้มากกว่า 7 ตัวอักษร',
        );
        return false;
      }

      this.passwordReset.password = this.new_password;
      this.passwordReset.password_confirmation = this.new_password;
      this.passwordReset.emp_id = this.modal_item.id;
      try {
        await this.$store.dispatch('users/passwordReset', this.passwordReset);
        await this.$store.dispatch(
          'alerts/success',
          'Password changed successfully.',
        );
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
      }
    },
    random_char() {
      var result = '';
      var in_length = 8;
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < in_length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        );
      }
      this.new_password = result;
    },
    close_modal() {
      this.show = false;
    },
  },
};
</script>
<style>
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
