import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.get(`${url}/v1/getholidays`, options).then((response) => {
    return {
      list: response.data,
      meta: response.data.meta,
    };
  });
}

// function add(leave) {
// 	const payload = jsona.serialize({
// 		stuff: leave,
// 		includeNames: null,
// 	});

// 	const options = {
// 		headers: {
// 			Accept: 'application/vnd.api+json',
// 			'Content-Type': 'application/vnd.api+json',
// 		},
// 	};

// 	return axios.post(`${url}/leavecreate`, payload, options).then((response) => {
// 		return jsona.deserialize(response.data);
// 	});
// }

// function approve(leave) {
// 	const payload = jsona.serialize({
// 		stuff: leave,
// 		includeNames: [],
// 	});

// 	const options = {
// 		headers: {
// 			Accept: 'application/vnd.api+json',
// 			'Content-Type': 'application/vnd.api+json',
// 		},
// 	};

// 	return axios
// 		.patch(`${url}/leaveapprove/${leave.id}`, payload, options)
// 		.then((response) => {
// 			return jsona.deserialize(response.data);
// 		});
// }

export default {
  list,
  //add,
  //approve,
};
