import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function list(params) {
  const response = await axios.post(`${url}/v1/staffworks`, params);
  return response.data;
}

async function emphasworkinglist(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(`${url}/v1/emphasworkinglist`, options);
  return jsona.deserialize(response.data);
}
async function emphasworkinglistapprove(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.get(
    `${url}/v1/emphasworkinglistapprove`,
    options,
  );
  return {
    list: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

async function emphasworkingcreate(staffhasworks) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  try {
    const response = await axios.post(
      `${url}/v1/emphasworkingcreate`,
      staffhasworks,
      options,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

async function emphasworkingcreateapprove(staffhasworks) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/emphasworkingcreateapprove`,
    staffhasworks,
    options,
  );
  return jsona.deserialize(response.data);
}

async function emphasworkingapprove(approval) {
  const payload = jsona.serialize({
    stuff: approval,
    includeNames: [],
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.patch(
    `${url}/v1/emphasworkingapprove/${approval.id}`,
    payload,
    options,
  );
  return jsona.deserialize(response.data);
}

async function updateWorking(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/update_Working`,
    params,
    options,
  );
  return response.data;
}

async function deleteWorking(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(
    `${url}/v1/delete_Working`,
    params,
    options,
  );
  return response.data;
}

export default {
  list,
  emphasworkinglist,
  emphasworkinglistapprove,
  emphasworkingcreate,
  emphasworkingcreateapprove,
  emphasworkingapprove,
  updateWorking,
  deleteWorking,
};
