import service from '@/store/services/branches-service';

const state = {
  list: {},
  branch: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, branch) => {
    state.branch = branch;
  },
};

const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      return list;
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((branch) => {
      commit('SET_RESOURCE', branch);
      return branch;
    });
  },
};

const getters = {
  list: (state) => state.list,
  branch: (state) => state.branch,
  dropdown: (state) => {
    return state.list.map((branch) => ({
      id: branch.id,
      name: branch.br_nameth,
      company_id: branch.company_id,
    }));
  },
};

const branchs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default branchs;
