<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>add_circle_outline</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <!-- <md-button @click="goBack" class="md-primary md-dense">
            Back to List
          </md-button> -->
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout spacing md-alignment-left memoName">
          <div
            class="md-layout-item md-size-20 md-medium-size-35 md-small-size-30 md-xsmall-size-100"
            style="text-align: left"
          >
            <label style="margin-top: 1.3rem; text-align: left">
              ข้อเสนอแนะ / ข้อร้องเรียน:
            </label>
          </div>
          <div
            class="md-layout-item md-size-60 md-medium-size-60 md-small-size-70 md-xsmall-size-100"
            style="text-align: left"
          >
            <md-field>
              <label>ข้อเสนอแนะ / ข้อร้องเรียน</label>
              <md-input
                class="md-size-50 searchID"
                v-model="appeal_name"
              ></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left detail">
          <div
            class="md-layout-item md-size-20 md-medium-size-35 md-small-size-30 md-xsmall-size-100"
            style="text-align: left"
          >
            <label style="margin-top: 1.3rem; text-align: left">
              รายละเอียด:
            </label>
          </div>
          <div
            class="md-layout-item md-size-60 md-medium-size-60 md-small-size-70 md-xsmall-size-100"
          >
            <md-field>
              <label>รายละเอียด</label>
              <md-textarea v-model="appeal_detail"></md-textarea>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left addimg">
          <div
            class="md-layout-item md-size-15 md-medium-size-25 md-small-size-23 md-xsmall-size-50"
            style="text-align: left"
          >
            <md-button class="md-info md-round md-fileinput bt-size">
              <md-icon>add_photo_alternate</md-icon>
              <template v-if="!image">ADD image</template>
              <template v-else>Change</template>
              <input type="file" accept="image/*" @change="onFileChange" />
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-60 md-small-size-60 md-xsmall-size-100"
            style="text-align: left"
          >
            <div v-if="!image">
              <label class="txt_size">***ขนาดไฟล์ต้องไม่เกิน 2 MB!</label>
            </div>
            <div v-if="image">
              <div class="image-container">
                <img :src="image" />
              </div>
              <md-button
                class="md-danger md-round"
                @click="removeImage()"
                v-if="image"
              >
                <i class="fa fa-times" />
                Remove
              </md-button>
            </div>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left save">
          <div
            class="md-layout-item md-size-70 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
            style="text-align: right"
          >
            <md-button class="md-success md-dense" @click="createAppeal()">
              Save
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import $ from 'jquery';
export default {
  components: {},
  async created() {},
  computed: {},
  data() {
    return {
      list: [],
      image: '',
      search: '',
      search_dep: '',
      file: null,
      PDF: '',
      PDFname: '',
      files: '',
      filename: '',
      Property: false,
      showDepartment: false,
      selected: [],
      allSelected: [],
      arr: [],
      showSetProp: [],
      showSetDep: [],
      Department: [],
      showSetBranch: [],
      disabled_prop: true,
      disabled_dep: true,
      add_tag: [],
      search_tag: '',
      options: [],
      appeal_name: '',
      appeal_detail: '',
      data_filePDF: [],
    };
  },
  methods: {
    goBack() {
      let keySearch = this.$route.params.keySearch;
      let currentPage = this.$route.params.currentPage;
      let backPage = this.$route.params.backPage;

      if (backPage == 'list') {
        this.$router.push({
          path: 'listAppeal',
          name: 'รายงานข้อเสนอแนะ / ข้อร้องเรียน',
          params: {
            keySearch,
            currentPage,
          },
        });
      } else {
        this.$router.push({
          path: 'listAppeal',
          name: 'รายงานข้อเสนอแนะ / ข้อร้องเรียน',
          params: {
            keySearch,
            currentPage,
          },
        });
      }
    },
    async onFilePDFChange(e) {
      let files = '';
      let filePDFname = '';
      $(e.target.files).each(async function(i, o) {
        if (o.type == 'application/pdf') {
          files = e.target.files || e.dataTransfer.files;
          filePDFname = o.name;
        }
      });
      if (files.length == 1) {
        this.createPDF(files[0]);
        this.PDFname = filePDFname;
        this.data_filePDF = files;
      } else {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกไฟล์ PDF');
        return false;
      }
    },
    async onFileChange(e) {
      let files = '';
      $(e.target.files).each(async function(i, o) {
        if (
          o.type == 'image/jpeg' ||
          o.type == 'image/png' ||
          o.type == 'image/gif'
        ) {
          files = e.target.files || e.dataTransfer.files;
        }
      });
      if (files.length == 1) {
        if (!files.length) return;
        this.createImage(files[0]);
      } else {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกไฟล์รูปภาพ');
        return false;
      }
    },
    createImage(file) {
      if (file.size < 2000000) {
        this.$store.dispatch('alerts/success', 'ขนาดไฟล์ถูกต้อง');
        let reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
          this.file = file;
        };
        reader.readAsDataURL(file);
      } else {
        this.$store.dispatch('alerts/error', 'ไฟล์มีขนาดเกินกว่า 2MB!');
      }
    },
    createPDF(file) {
      if (file.size < 10000000) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.PDF = e.target.result;
          this.file = file;
        };
        reader.readAsDataURL(file);
      } else {
        this.$store.dispatch('alerts/error', 'ไฟล์มีขนาดเกินกว่า 10MB!');
      }
    },
    createfile(file) {
      if (file.size < 10000000) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.files = e.target.result;
          this.file = file;
        };
        reader.readAsDataURL(file);
      } else {
        this.$store.dispatch('alerts/error', 'ไฟล์มีขนาดเกินกว่า 10MB!');
      }
    },
    removeImage() {
      this.image = null;
    },
    removePDF() {
      this.PDFname = null;
      this.PDF = null;
    },
    async createAppeal() {
      if (this.appeal_name == '') {
        await this.$store.dispatch('alerts/error', 'กรุณาระบุหัวข้อร้องเรียน');
        return false;
      }
      if (this.detail == '') {
        await this.$store.dispatch('alerts/error', 'กรุณาระบุเนื้อหา');
        return false;
      }
      let data = {};
      data['appeal_name'] = this.appeal_name;
      data['appeal_detail'] = this.appeal_detail;
      data['image'] = this.image;
      // data['memo_PDF'] = this.PDF;
      let resp = await this.$store.dispatch('appeal/createAppeal', data);
      if (resp.status == 0) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        return false;
      } else if (resp.status == 1) {
        await this.$store.dispatch('alerts/success', 'เพิ่มสำเร็จ.');
        this.appeal_name = '';
        this.appeal_detail = '';
        this.image = null;
        // this.goBack();
      }
    },
  },
};
</script>
<style>
.md-content.scrol {
  max-width: 450px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.bt-size {
  width: 100%;
}
.txt_size {
  margin: auto;
  width: 50%;
  padding: 10px;
  color: gray;
}
</style>
