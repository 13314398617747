import service from '@/store/services/santhiyaSOS-service';

const state = {};

const mutations = {};

const actions = {
  async gettagsos({ commit, dispatch }, params) {
    return await service.gettagsos(params);
  },
  async saveSos({ commit, dispatch }, params) {
    return await service.saveSos(params);
  },
  async getFileSOS({ commit, dispatch }, params) {
    return await service.getFileSOS(params);
  },
  async searchSOS({ commit, dispatch }, params) {
    return await service.searchSOS(params);
  },
  async updateSos({ commit, dispatch }, params) {
    return await service.updateSos(params);
  },
  async deleteSOS({ commit, dispatch }, params) {
    return await service.deleteSOS(params);
  },
};

const getters = {};

const santhiyaSOS = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default santhiyaSOS;
