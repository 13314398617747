<template>
  <div class="text-center">
    <div class="area-print"></div>
    <div class="btn-action">
      <span class="btn-pd">
        <b-button
          class="btn btn-success"
          id="btn_save"
          @click="saveContract('app')"
        >
          บันทึก
        </b-button>
      </span>
      <span class="btn-pd">
        <button
          class="btn btn-success"
          id="btn_update"
          style="display: none"
          @click="updateContract()"
        >
          Update
        </button>
      </span>
      <span class="btn-pd">
        <button
          class="btn btn-info"
          id="btn_print"
          @click="print('app')"
          style="display: none;"
        >
          Print
        </button>
      </span>
    </div>
    <div id="app" class="print">
      <div
        class="page md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
      >
        <div id="head-form" class="head-form">
          <span>
            <h5>สัญญาจ้างและกำหนดสภาพการจ้าง</h5>
          </span>
          <div class="che-box">
            <span class="check">
              <input
                type="radio"
                name="branch"
                value="2"
                branch_name="syy"
                @click="show_branch({ bch_id: 2 })"
              />
              <label for="SYY"> SYY</label>
            </span>
            <span class="check">
              <input
                type="radio"
                name="branch"
                value="3"
                branch_name="spn"
                @click="show_branch({ bch_id: 3 })"
              />
              <label for="SPN"> SPN</label>
            </span>
            <span class="check">
              <input
                type="radio"
                name="branch"
                value="4"
                branch_name="STKC"
                @click="show_branch({ bch_id: 4 })"
              />
              <label for="STKC"> STKC</label>
            </span>
            <span class="check">
              <input
                type="radio"
                name="branch"
                value="5"
                branch_name="SNT"
                @click="show_branch({ bch_id: 5 })"
              />
              <label for="SNT"> SNT</label>
            </span>
            <span class="check">
              <input
                type="radio"
                name="branch"
                value="1"
                branch_name="STY"
                @click="show_branch({ bch_id: 1 })"
              />
              <label for="STY"> STY</label>
            </span>
            <span class="check">
              <input
                type="radio"
                name="branch"
                value="7"
                branch_name="Bookengine"
                @click="show_branch({ bch_id: 7 })"
              />
              <label for="Bookengine">Bookengine</label>
            </span>
            <span class="check">
              <input
                type="radio"
                name="branch"
                value="8"
                branch_name="VCP"
                @click="show_branch({ bch_id: 8 })"
              />
              <label for="VCP"> VCP</label>
            </span>
            <span class="check">
              <input
                type="radio"
                name="branch"
                value="6"
                branch_name="CVS"
                @click="show_branch({ bch_id: 6 })"
              />
              <label for="CVS"> CVS</label>
            </span>
          </div>
        </div>
        <div class="body-form">
          <div class="line tab-1">
            <span class="txt">สัญญาฉบับนี้ทำขึ้นเมื่อวันที่ </span>
            <span class="input w-15">
              <select class="select_days w-13" name="select_days">
                <option
                  class="sl_day"
                  v-for="day in op_day"
                  :value="day"
                  :key="day + 'day'"
                >
                  {{ day }}
                </option>
              </select>
            </span>
            <span class="txt">เดือน </span>
            <span class="input w-30">
              <select class="select_days w-30" name="select_month">
                <option v-for="mon in op_month" :value="mon" :key="mon + 'mon'">
                  {{ mon }}
                </option>
              </select>
            </span>
            <span class="txt">พ.ศ. </span>
            <span class="input w-10">
              <select class="select_days w-13" name="select_year">
                <option
                  v-for="year in op_year"
                  :value="year"
                  :key="year + 'year'"
                >
                  {{ year }}
                </option>
              </select>
            </span>
            <span class="txt"> ระหว่าง</span>
          </div>
          <div class="line">
            <span class="txt">บริษัท </span>
            <span class="input w-40 company">
              <input class="company" type="text" name="company" value="" />
            </span>
            <span class="txt"> โดย </span>
            <span class="input w-25 by">
              <input class="by" type="text" name="by" value="" />
            </span>
            <span class="txt">ตําแหน่ง กรรมการ/ผู้รับมอบอํานาจ</span>
          </div>
          <div class="line">
            <span class="txt">สํานักงานตั้งอยู่ เลขที่ </span>
            <span class="input w-2 set">
              <input class="ComNumber" type="text" name="ComNumber" value="" />
            </span>
            <span class="txt">หมู่ </span>
            <span class="input w-2 set">
              <input class="ComMoo" type="text" name="ComMoo" value="" />
            </span>
            <span class="txt">ตำบล </span>
            <span class="input w-10 set2">
              <input
                class="ComSubDistrict"
                type="text"
                name="ComSubDistrict"
                value=""
              />
            </span>
            <span class="txt">อำเภอ </span>
            <span class="input w-10 set2">
              <input
                class="ComDistrict"
                type="text"
                name="ComDistrict"
                value=""
              />
            </span>
            <span class="txt">จังหวัด </span>
            <span class="input w-12 set2">
              <input
                class="ComProvince"
                type="text"
                name="ComProvince"
                value=""
              />
            </span>
            <span class="txt">รหัสไปรษณีย์ </span>
            <span class="input w-10 set2">
              <input
                class="ComZipcode"
                type="text"
                name="ComZipcode"
                value=""
              />
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ซึ่งต่อไปในสัญญานี้เรียกว่า "นายจ้าง" ฝ่ายหนึ่งกับ
            </span>
            <span class="input w-45">
              <input class="name" type="text" name="name" value="" />
            </span>
            <span class="txt"> อายุ </span>
            <span class="input w-12">
              <input class="age" type="text" name="age" value="" />
            </span>
            <span class="txt">ปี</span>
          </div>
          <div class="line">
            <span class="txt">สัญชาติ </span>
            <span class="input w-20">
              <input
                class="nationality"
                type="text"
                name="nationality"
                value=""
              />
            </span>
            <span class="txt">บัตรประจําตัวประชาชนเลขที่ </span>
            <span class="input w-50">
              <input class="IDcard" type="text" name="IDcard" value="" />
            </span>
          </div>
          <div class="line">
            <span class="txt">ออกให้เมื่อวันที่ </span>
            <span class="input w-22 non_print">
              <input class="outdate" type="date" name="outdate" value="" />
            </span>
            <span class="txt">ออกให้ ณ เขต </span>
            <span class="input w-30">
              <input class="outat" type="text" name="outat" value="" />
            </span>
            <span class="txt">สิ้นสุดเมื่อ </span>
            <span class="input w-25 non_print">
              <input class="dateent" type="date" name="dateent" value="" />
            </span>
          </div>
          <div class="line">
            <span class="txt">อยู่บ้านเลขที่ </span>
            <span class="input w-10">
              <input class="HouseNo" type="text" name="HouseNo" value="" />
            </span>
            <span class="txt">หมู่ </span>
            <span class="input w-15">
              <input class="moo" type="text" name="moo" value="" />
            </span>
            <span class="txt">ถนน </span>
            <span class="input w-22">
              <input class="road" type="text" name="road" value="" />
            </span>
            <span class="txt">ตําบล/แขวง </span>
            <span class="input w-25">
              <input
                class="Sub-district"
                type="text"
                name="Sub-district"
                value=""
              />
            </span>
          </div>
          <div class="line">
            <span class="txt">อําเภอ/เขต </span>
            <span class="input w-25">
              <input class="district" type="text" name="district" value="" />
            </span>
            <span class="txt">จังหวัด </span>
            <span class="input w-33">
              <input class="province" type="text" name="province" value="" />
            </span>
            <span class="txt">รหัสไปรษณีย์ </span>
            <span class="input w-15">
              <input class="zipcode" type="text" name="zipcode" value="" />
            </span>
          </div>
          <div class="line">
            <span class="txt">มีคุณวุฒิระดับ </span>
            <span class="input w-56">
              <input class="education" type="text" name="education" value="" />
            </span>
            <span class="txt">ดังรายละเอียดตามใบสมัครงานพร้อมเอกสาร</span>
          </div>
          <div class="line">
            <span class="txt">
              ซึ่งต่อไปในสัญญานี้เรียกว่า "ลูกจ้าง" อีกฝ่ายหนึ่ง
              คู่สัญญาทั้งสองตกลงกันดังต่อไปนี้
            </span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              ข้อ 1. นายจ้างตกลงว่าจ้างให้ลูกจ้างทํางานใน บริษัท
            </span>
            <span class="input w-42 company">
              <input class="company" type="text" name="company" value="" />
            </span>
            <span class="txt"> ของนายจ้างซึ่ง</span>
          </div>
          <div class="line">
            <span class="txt">มีวัตถุประสงค์ประกอบกิจการ </span>
            <span class="input w-30">
              <input class="business" type="text" name="business" value="" />
            </span>
            <span class="txt">โดยรับเข้าทํางานในแผนก</span>
            <span class="input w-30">
              <select
                class="department"
                type="text"
                name="department"
                v-model="select_dep"
                @change="set_pos()"
              >
                <option
                  v-for="(value, name) in list_dep"
                  :key="name + 'dep'"
                  :value="name"
                  :name_dep="value"
                >
                  {{ value }}
                </option>
              </select>
              <!-- <input
                class="department"
                type="text"
                name="department"
                value=""
              /> -->
            </span>
          </div>
          <div class="line">
            <span class="txt">ตำแหน่ง </span>
            <span class="input w-90">
              <select
                class="position"
                type="text"
                name="position"
                v-model="select_pos"
              >
                <option
                  v-for="(value, name) in list_pos"
                  :key="name + 'pos'"
                  :value="name"
                  :name_pos="value"
                >
                  {{ value }}
                </option>
              </select>
            </span>
          </div>
          <div class="line tab-1">
            <span>
              <p></p>
            </span>
            <span class="txt">
              โดยระยะเวลาการทดลองงานมีกำหนด 119 วันนับแต่เริ่มงาน
              เมื่อการทํางานเป็นที่พอใจของนายจ้างก็จะรับเข้าทํางานใน
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ตําแหน่งงานประจํา หรืออาจเปลี่ยนแปลงตําแหน่งงานตามความเหมาะสมได้
              รวมทั้งการโอนย้ายตําแหน่ง/ความรับผิดชอบ และหรือมอบ
            </span>
          </div>
          <div class="line">
            <span class="txt">
              หมายหน้าที่การทํางานในบริษัทฯหรือบริษัทฯในเครือตามที่นายจ้างจะเห็นสมควรโดยลูกจ้างพร้อม
              ที่จะปฏิบัติงานทั้งในเขตกรุงเทพฯ
            </span>
          </div>
          <div class="line">
            <span class="txt">
              และต่างจังหวัด
            </span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              ในวันทําสัญญานี้ลูกจ้างได้ทราบระเบียบกฎข้อบังคับการทํางานของบริษัท
            </span>
            <span class="input w-42 company2">
              <input class="company" type="text" name="company" value="" />
            </span>
          </div>
          <div class="line">
            <span class="txt">
              นายจ้างเป็นที่เข้าใจดีแล้วให้ลูกจ้างเริ่มทํางานตั้งแต่วันที่
            </span>
            <span class="input w-17 non_print">
              <input class="startjob" type="date" name="startjob" value="" />
            </span>
            <span class="txt">ถึง</span>
            <span class="input w-17 non_print">
              <input class="endjob" type="date" name="endjob" value="" />
            </span>
            <span class="txt">ซึ่งรวมระยะเวลาทดลองงาน</span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              ข้อ 2. นายจ้างตกลงให้ค่าจ้าง และลูกจ้างตกลงรับค่าจ้างดังนี้
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              2.1 ในระยะทดลองงาน จะจ่ายค่าจ้างให้ลูกจ้างในอัตราเดือนละ
            </span>
            <span class="input w-15">
              <input class="salary" type="text" name="salary" value="" />
            </span>
            <span class="txt">บาท</span>
          </div>
          <div class="line tab-2">
            <span class="txt">(</span>
            <span class="input w-35">
              <input
                class="salary-txt"
                type="text"
                name="salary-txt"
                value=""
              />
            </span>
            <span class="txt">) </span>
            <span class="txt">
              กำหนดวันจ่ายในวันสุดท้ายของเดือนและให้ได้รับเงินเพิ่มพิเศษหรือ
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt txtsm service">
              Service Charge 50% ของข้อ 3.1 เท่านั้น
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              2.2
              เมื่อนายจ้างรับลูกจ้างเข้าทํางานในตําแหน่งที่ว่าจ้างเป็นลูกจ้าง
              ประจําจะจ่ายค่าจ้างเดือนละ
            </span>
            <span class="input w-15">
              <input class="salary2" type="text" name="salary2" value="" />
            </span>
            <span class="txt">บาท</span>
          </div>
          <div class="line tab-2">
            <span class="txt">(</span>
            <span class="input w-35">
              <input
                class="salary2-txt"
                type="text"
                name="salary2-txt"
                value=""
              />
            </span>
            <span class="txt">) </span>
            <span class="txt">
              กำหนดวันจ่ายในวันสุดท้ายของเดือนและให้ได้รับเงินเพิ่มพิเศษหรือ
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt txtsm service">
              Service Charge 100% ของข้อ 3.1 เท่านั้น
            </span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              ข้อ 3. นายจ้างให้ลูกจ้างได้รับเงินเพิ่มพิเศษหรือ Service Charge
              นอกเหนือจากค่าจ้างไม่เกินจํานวน ดังนี้
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">3.1 </span>
            <span class="chk-txt">
              <input
                class="check_service"
                id="1"
                type="checkbox"
                name="service"
                value="3,000-6,000 บาทต่อเดือน"
              />
              3,000-6,000 บาทต่อเดือน
            </span>
            <span class="chk-txt">
              <input
                class="check_service2"
                id="2"
                type="checkbox"
                name="service"
                value="4,500 บาทต่อเดือน"
              />
              4,500 บาทต่อเดือน
            </span>
            <span class="chk-txt">
              <input
                class="check_service3"
                id="3"
                type="checkbox"
                name="service"
                value="ไม่มี Service Charg"
              />
              ไม่มี Service Charg
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">3.2 </span>
            <span class="chk-txt">
              <input
                class="check_ser"
                type="checkbox"
                name="fare"
                id="4"
                value="ค่าช่วยเหลือการเดินทางเดือนละ 1,000 บาท จนกว่านายจ้างจะจัดหาหอพักพนักงานให้"
              />
              ค่าช่วยเหลือการเดินทางเดือนละ 1,000 บาท
              จนกว่านายจ้างจะจัดหาหอพักพนักงานให้
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">3.3 </span>
            <span class="chk">
              <input
                class="check_s"
                type="checkbox"
                name="remark"
                id="5"
                value=""
              />
              อื่นๆ
            </span>
            <span class="input w-85">
              <input class="remark" type="text" name="remarktxt" value="" />
            </span>
          </div>
          <div class="line tab-3">
            <span class="txt">โดยมีเงื่อนไขการจ่ายตามนโยบายบริษัทฯ</span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              ทั้งนี้เงินเพิ่มพิเศษตามข้อนี้
              นายจ้างอาจปรับเพิ่มหรือลดหรือยกเลิกได้
              ตามความเหมาะสมของหน้าที่ลูกจ้าง, ผลประกอบ
            </span>
          </div>
          <div class="line">
            <span class="txt">
              การ, จํานวนลูกค้าหรือนักท่องเที่ยว
              โดยลูกจ้างตกลงยอมรับและจะไม่โต้แย้งใดๆทั้งสิ้น
            </span>
          </div>
          <div class="line tab-4 sign_emp">
            <span class="txt">ลงชื่อ ลูกจ้าง</span>
            <div class="col-100 sign-txt sign-emp txt-left">
              <canvas
                class="signature-pad"
                height="200"
                style="touch-action: ; border: ridge;"
              ></canvas>
              <div>
                <b-button
                  pill
                  variant="danger"
                  style="margin-top:0.5rem;"
                  name="clear"
                  id="clear"
                  @click="signature_clear($event.target)"
                >
                  Clear
                </b-button>
              </div>
            </div>
          </div>
          <div class="line tab-4 after-sign-pad" style="display: none;">
            <div class="row">
              <div class="col-12 after-emp">
                <input
                  class="sign-pad"
                  type="text"
                  disabled
                  style="border: ridge;"
                />
              </div>
              <div class="col-12" style="text-align: left;">
                <span class="txt">ลงชื่อ ลูกจ้าง</span>
              </div>
            </div>
          </div>
          <div class="line tab-4 show_sign_emp" style="display: none;">
            <span class="txt">ลงชื่อ </span>
            <span class="input w-30 sign_emp">
              <img src="" width="155px" height="50px" />
            </span>
            <span class="txt">ลูกจ้าง</span>
          </div>
          <div class="line tab-5 show_sign_emp" style="display: none;">
            <span class="txt">(</span>
            <span class="input w-30 nonborder">
              <input
                class="signemployee2"
                type="text"
                name="signemployee2"
                value=""
              />
            </span>
            <span class="txt">)</span>
          </div>
        </div>
      </div>
      <div
        class="page md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
      >
        <div class="body-form">
          <div class="line tab-1">
            <span class="txt">
              ข้อ 4. ลูกจ้างให้สัญญาแก่นายจ้างว่า
              งานในตําแหน่งหน้าที่ซึ่งได้รับจ้างตามสัญญานี้
              ลูกจ้างมีความรู้ความสามารถและตกลง
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ว่าจะปฏิบัติหน้าที่จากการทํางาน มิให้นายจ้างได้รับความเสียหาย
              อันเกิดจากความบกพร่อง และละเลยทอดทิ้งงานหรือทําให้นายจ้างเสื่อม
            </span>
          </div>
          <div class="line">
            <span class="txt">
              เสียชื่อเสียงเกียรติคุณ ลูกจ้างตกลงจะปฎิบัติงานต่างๆ
              ตามที่นายจ้างมอบหมายให้สําเร็จลุล่วงด้วยดีลูกจ้างให้สัญญาว่าจะไม่นําความลับ
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ที่ล่วงรู้ไม่ว่าโดยทางตรงหรือทางอ้อมไปเปิดเผยให้กับบุคคลภายนอก
            </span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              ข้อ 5. ลูกจ้างต้องมาปฏิบัติการทํางานให้นายจ้างตามเวลาทํางาน
              เป็นประจําทุกวัน ยกเว้นวันหยุดประจําสัปดาห์หรือวันหยุด
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ตามที่นายจ้างได้ประกาศ ซึ่งลูกจ้างได้อ่านและทราบดีแล้ว
            </span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              ข้อ 6. ในกรณีที่ลูกจ้างก่อให้เกิดหนี้สินแก่นายจ้างหรือ
              ก่อให้เกิดความเสียหายใดๆ แก่นายจ้างไม่ว่าจงใจหรือประมาทเลินเล่อ
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ลูกจ้างต้องรับผิดชดใช้หนี้ หรือค่าเสียหายนั้นให้แก่นายจ้าง
            </span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              ข้อ 7. กรณีที่ลูกจ้างฝ่าฝืนระเบียบข้อบังคับการทํางาน
              ในกรณีที่ลูกจ้างไม่ปฏิบัติตามระเบียบข้อบังคับการทํางานที่ได้ตกลงไว้
            </span>
          </div>
          <div class="line">
            <span class="txt">
              กับนายจ้าง นายจ้างจะใช้สิทธิดังต่อไปนี้
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              7.1 มีหนังสือแจ้งเตือน และเรียกมาว่ากล่าวตักเตือน
              และให้ลูกจ้างรับทราบ
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              7.2
              มีหนังสือแจ้งเตือนครั้งที่สองในกรณีกระทําซํ้าหลังจากที่เตือนแล้ว
              หรือกระทําความผิดอยางอื่น
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              7.3 มีหนังสือแจ้งเลิกจ้าง โดยทําเป็นหนังสือบอกกล่าว
              ล่วงหน้าหนึ่งเดือน
            </span>
          </div>
          <div class="line tab-3">
            <span class="txt">
              ในกรณีลูกจ้างจะบอกเลิกการทํางานเป็นลูกจ้างไม่ว่าลูกจ้างนั้นจะเป็นลูกจ้างที่มีกำหนดเวลาหรือ
              ลูกจ้างประจํา
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ลูกจ้างจะต้องทําหนังสือแจ้งให้นายจ้างทราบล่วงหน้าไม่น้อยกว่าหนึ่งเดือน
              อีกทั้งจะต้องสะสางงานที่ค้างอันอยู่ ในความรับผิดชอบของ
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ลูกจ้างส่งมอบให้นายจ้างโดยการทํารายงานโดยละเอียด
            </span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              ข้อ 8.
              ลูกจ้างอาจถูกเลิกจ้างทันทีก่อนกำหนดระยะเวลาการจ้างในกรณีดังต่อไปนี้
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              8.1 ทุจริตต่อหน้าที่หรือกระทําความผิดอาญาโดยเจตนาต่อบริษัทฯ
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              8.2 จงใจทําให้บริษัทฯ ได้รับความเสียหาย
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              8.3
              ฝ่าฝืนข้อบังคับหรือระเบียบเกี่ยวกับการทํางานหรือคําสั่งอันชอบด้วยกฎหมายของบริษัทฯ
              และบริษัทฯได้ตักเตือน
            </span>
          </div>
          <div class="line">
            <span class="txt">เป็นหนังสือแล้ว</span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              8.4 ละทิ้งหน้าที่เป็นระยะเวลาสามวันติดต่อกัน โดยไม่มีเหตุอันสมควร
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              8.5 ประมาทเลินเล่อเป็นเหตุให้นายจ้างได้รับความเสียหายอยางร้ายแรง
            </span>
          </div>
          <div class="line tab-2">
            <span class="txt">
              8.6 ได้รับโทษจําคุกตามคําพิพากษาถึงที่สุดให้จําคุก
            </span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              ข้อ 9. นับตั้งแต่วันลงนามในสัญญานี้และภายใน 1 ปี
              หลังจากวันที่ลูกจ้างลาออกหรือถูกไล่ออก หรือครบกำหนดสัญญา
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ว่าจ้าง ลูกจ้างสัญญาจะไม่ไปทํางานกับบริษัทฯ
              หรือองค์กรธุรกิจใดซึ่งดําเนินธุรกิจอันมีสภาพอย่างเดียวกันหรือ
              แข่งขันในทางตรง
            </span>
          </div>
          <div class="line">
            <span class="txt">
              หรือทางอ้อมกับธุรกิจของนายจ้างที่ดําเนินธุรกิจอยู่บนเกาะยาวใหญ่
              จ.พังงาหรือเกาะพะงัน จ.สุราษฎร์ธานี หรือเกาะช้าง จ.ตราด
            </span>
          </div>
          <div class="line">
            <span class="txt">
              หรือภูเก็ตนาใต้ จ.พังงา หากลูกจ้างปฏิบัติผิดสัญญาข้อนี้
              ลูกจ้างยินยอมให้นายจ้างฟ้องร้องเรียก ค่าเสียหายได้เป็นจํานวนเงิน 5
              เท่า
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ของรายได้ของลูกจ้าง
            </span>
          </div>
          <div class="line tab-1">
            <span class="txt">
              สัญญานี้ได้จัดทําขึ้นเป็นสองฉบับมีข้อความตรงกัน
              ซึ่งคู่สัญญาทั้งสองฝ่ายได้อ่าน และเข้าใจข้อความของสัญญานี้โดย
            </span>
          </div>
          <div class="line">
            <span class="txt">
              ตลอดดีแล้ว จึงลงลายมือชื่อไว้เป็นสําคัญต่อหน้าพยาน
            </span>
          </div>
          <div class="line tab-4 show">
            <div class="row">
              <div
                class="col-12 sign-txt txt-left signature-pad-emp"
                style="text-align: right;"
              >
                <canvas
                  class="signature-pad-emp"
                  height="200"
                  style="height: 200px; touch-action: none; border: ridge; align-items: center;"
                ></canvas>
              </div>
              <div class="col-12 after-sign-pad-emp" style="display: none;">
                <input
                  class="sign-pad-emp"
                  type="text"
                  disabled
                  style="border: ridge;"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-6"
                style="text-align: left; display: flex; align-items: center;"
              >
                <span class="txt">ลงชื่อลูกจ้าง</span>
              </div>
              <div class="col-6" style="text-align: right;">
                <b-button
                  pill
                  variant="danger"
                  style="margin-top:0.5rem;"
                  name="clear"
                  id="clear_emp"
                  @click="signature_clear($event.target)"
                >
                  Clear
                </b-button>
              </div>
            </div>
            <p></p>
            <div class="row">
              <div
                class="col-12 sign-txt txt-left signature-pad-boss"
                style="text-align: right;"
              >
                <canvas
                  class="signature-pad-boss"
                  height="200"
                  style="height: 200px; touch-action: none; border: ridge;"
                ></canvas>
              </div>
              <div class="col-12 after-sign-pad-boss" style="display: none;">
                <input
                  class="sign-pad-boss"
                  type="text"
                  disabled
                  style="border: ridge;"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-6"
                style="text-align: left; display: flex; align-items: center;"
              >
                <span class="txt">ลงชื่อนายจ้าง</span>
              </div>
              <div class="col-6" style="text-align: right;">
                <b-button
                  pill
                  variant="danger"
                  style="margin-top:0.5rem;"
                  name="clear"
                  id="clear_boss"
                  @click="signature_clear($event.target)"
                >
                  Clear
                </b-button>
              </div>
            </div>
            <div class="row" style="text-align: left;">
              <div class="col-12">
                <span class="txt">บริษัท</span>
                <span class="input w-40">
                  <input class="company" type="text" name="company" value="" />
                </span>
              </div>
            </div>
            <div class="row" style="text-align: left;">
              <div class="col-12">
                <span class="txt">โดย</span>
                <span class="input w-30">
                  <input class="by" type="text" name="by" value="" />
                </span>
                <span class="txt">กรรมการ/ผู้รับมอบอํานาจ</span>
              </div>
            </div>
            <p></p>
          </div>
          <div class="line tab-6 noshow" style="display: none;">
            <span class="txt">ลงชื่อ</span>
            <span class="input w-30 sign_boss">
              <img src="" width="155px" height="50px" />
            </span>
            <span class="txt">นายจ้าง</span>
            <span class="txt" style="margin-left: 23%">ลงชื่อ</span>
            <span class="input w-30 sign_emp2">
              <img src="" width="155px" height="50px" />
            </span>
            <span class="txt">ลูกจ้าง</span>
          </div>
          <div class="line tab-8 noshow" style="display: none;">
            <span class="txt">บริษัท</span>
            <span class="input w-40">
              <input class="company" type="text" name="company" value="" />
            </span>
            <span class="txt tab-9" style="margin-left: 7%">(</span>
            <span class="input w-30 nonborder">
              <input
                class="signemployee2"
                type="text"
                name="signemployee2"
                value=""
              />
            </span>
            <span class="txt">)</span>
          </div>
          <div class="line noshow" style="display: none;">
            <span class="txt">โดย</span>
            <span class="input w-30">
              <input class="by" type="text" name="by" value="" />
            </span>
            <span class="txt">กรรมการ/ผู้รับมอบอํานาจ</span>
          </div>
          <div class="line tab-4 show">
            <div class="row">
              <div
                class="col-12 sign-txt txt-left signature-pad-witness"
                style="text-align: right;"
              >
                <canvas
                  class="signature-pad-witness"
                  height="200"
                  style="height: 200px; touch-action: none; border: ridge;"
                ></canvas>
              </div>
              <div class="col-12 after-sign-pad-witness" style="display: none;">
                <input
                  class="sign-pad-witness"
                  type="text"
                  disabled
                  style="border: ridge;"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-6"
                style="text-align: left; display: flex; align-items: center;"
              >
                <span class="txt">ลงชื่อพยาน</span>
              </div>
              <div class="col-6" style="text-align: right;">
                <b-button
                  pill
                  variant="danger"
                  style="margin-top:0.5rem;"
                  name="clear"
                  id="clear_wit"
                  @click="signature_clear($event.target)"
                >
                  Clear
                </b-button>
              </div>
            </div>
            <div class="row" style="text-align: left;">
              <div class="col-6">
                <span class="txt">(</span>
                <span class="input w-90 nonborder">
                  <input
                    class="witness-txt2"
                    type="text"
                    name="witness-txt2"
                    value=""
                  />
                </span>
                <span class="txt">)</span>
              </div>
            </div>
            <p></p>
            <div class="row">
              <div
                class="col-12 sign-txt txt-left signature-pad-witness2"
                style="text-align: right;"
              >
                <canvas
                  class="signature-pad-witness2"
                  height="200"
                  style="border: ridge;"
                ></canvas>
              </div>
              <div
                class="col-12 after-sign-pad-witness2"
                style="display: none;"
              >
                <input
                  class="sign-pad-witness2"
                  type="text"
                  disabled
                  style="border: ridge;"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-6"
                style="text-align: left; display: flex; align-items: center;"
              >
                <span class="txt">ลงชื่อพยาน</span>
              </div>
              <div class="col-6" style="text-align: right;">
                <b-button
                  pill
                  variant="danger"
                  style="margin-top:0.5rem;"
                  name="clear"
                  id="clear_wit2"
                  @click="signature_clear($event.target)"
                >
                  Clear
                </b-button>
              </div>
            </div>
            <div class="row" style="text-align: left;">
              <div class="col-6">
                <span class="txt">(</span>
                <span class="input w-90 nonborder">
                  <input
                    class="witness-txt2"
                    type="text"
                    name="witness-txt2"
                    value=""
                  />
                </span>
                <span class="txt">)</span>
              </div>
            </div>
          </div>
          <div class="line tab-6 noshow" style="display: none;">
            <span class="txt">ลงชื่อ</span>
            <span class="input w-30 sign_wit">
              <img src="" width="155px" height="50px" />
            </span>
            <span class="txt">พยาน</span>
            <span class="txt tab-10" style="margin-left: 13%;">ลงชื่อ</span>
            <span class="input w-30 sign_wit2">
              <img src="" width="155px" height="50px" />
            </span>
            <span class="txt">พยาน</span>
          </div>
          <div class="line tab-11 noshow" style="display: none;">
            <span class="txt">(</span>
            <span class="input w-30 nonborder">
              <input
                class="witness-txt"
                type="text"
                name="witness-txt"
                value=""
              />
            </span>
            <span class="txt">)</span>
            <span class="txt tab-12" style="margin-left: 8%;">(</span>
            <span class="input w-30 nonborder">
              <input
                class="witness-txt2"
                type="text"
                name="witness-txt2"
                value=""
              />
            </span>
            <span class="txt">)</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-action">
      <span class="btn-pd">
        <b-button
          class="btn btn-success"
          id="btn_save"
          @click="saveContract('app')"
        >
          บันทึก
        </b-button>
      </span>
      <span class="btn-pd">
        <button
          class="btn btn-success"
          id="btn_update"
          style="display: none"
          @click="updateContract()"
        >
          Update
        </button>
      </span>
      <span class="btn-pd">
        <button
          class="btn btn-info"
          id="btn_print"
          @click="print('app')"
          style="display: none;"
        >
          Print
        </button>
      </span>
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import moment from 'moment';
import SignaturePad from 'signature_pad';
export default {
  data() {
    return {
      moment: moment,
      dep_id: null,
      update_id: 0,
      list_dep: [],
      list_pos: [],
      set_list_pos: [],
      select_dep: '',
      select_pos: '',
      select_days: '',
      select_month: '',
      select_year: '',
      out_days: '',
      date_ents: '',
      start_job: '',
      end_job: '',
      op_day: [],
      op_month: [],
      op_year: [],
      radio_ck: '',
      sign_pad: {},
      sign_pad_boss: {},
      sign_pad_emp: {},
      sign_pad_wit: {},
      sign_pad_wit2: {},
      signname: {},
      signname_emp: {},
      signname_boss: {},
      signname_wit: {},
      signname_wit2: {},
    };
  },
  props: {
    test: null,
    emp_id: null,
    condata: null,
  },
  created() {
    this.set_date();
    this.getpos_dep();
  },
  mounted: function() {
    this.setdata();
    this.signature_pad();
  },
  methods: {
    signature_pad() {
      var w = $('div.sign-txt.sign-emp')
        .parent()
        .width();
      var b = $('div.sign-txt.signature-pad-boss')
        .parent()
        .width();
      var e = $('div.sign-txt.signature-pad-emp')
        .parent()
        .width();
      var wit = $('div.sign-txt.signature-pad-witness')
        .parent()
        .width();
      var wit2 = $('div.sign-txt.signature-pad-witness2')
        .parent()
        .width();
      $('canvas.signature-pad')
        .attr({ width: w, height: 200 })
        .css({ height: '200px' });
      $('canvas.signature-pad-boss')
        .attr({ width: b, height: 200 })
        .css({ height: '200px' });
      $('canvas.signature-pad-emp')
        .attr({ width: e, height: 200 })
        .css({ height: '200px' });
      $('canvas.signature-pad-witness')
        .attr({ width: wit, height: 200 })
        .css({ height: '200px' });
      $('canvas.signature-pad-witness2')
        .attr({ width: wit2, height: 200 })
        .css({ height: '200px' });
      this.sign_pad = new SignaturePad($('canvas.signature-pad')[0], {
        penColor: 'rgb(0,0,0)',
        onBegin: function() {},
        onEnd: function() {},
      });
      this.sign_pad_boss = new SignaturePad($('canvas.signature-pad-boss')[0], {
        penColor: 'rgb(0,0,0)',
        onBegin: function() {},
        onEnd: function() {},
      });
      this.sign_pad_emp = new SignaturePad($('canvas.signature-pad-emp')[0], {
        penColor: 'rgb(0,0,0)',
        onBegin: function() {},
        onEnd: function() {},
      });
      this.sign_pad_wit = new SignaturePad(
        $('canvas.signature-pad-witness')[0],
        {
          penColor: 'rgb(0,0,0)',
          onBegin: function() {},
          onEnd: function() {},
        },
      );
      this.sign_pad_wit2 = new SignaturePad(
        $('canvas.signature-pad-witness2')[0],
        {
          penColor: 'rgb(0,0,0)',
          onBegin: function() {},
          onEnd: function() {},
        },
      );
    },
    signature_clear(e) {
      if ($(e).attr('id') == 'clear') {
        this.sign_pad.clear();
      } else if ($(e).attr('id') == 'clear_boss') {
        this.sign_pad_boss.clear();
      } else if ($(e).attr('id') == 'clear_emp') {
        this.sign_pad_emp.clear();
      } else if ($(e).attr('id') == 'clear_wit') {
        this.sign_pad_wit.clear();
      } else if ($(e).attr('id') == 'clear_wit2') {
        this.sign_pad_wit2.clear();
      }
    },
    async show_branch(obj = {}) {
      let resp = await this.$store.dispatch('contract/getsetting', obj);
      // if (resp['data'] && resp['data'].length > 0) {
      $('[name=company]').val(resp['data']['bchname_th']);
      // if (resp['data'] && resp['data'].length > 0) {
      $('[name=com]').val(resp['data']['com_nameth']); // บริษัท
      $('[name=company]').val(resp['data']['bchname_th']); // สาขา
      $('[name=by]').val(
        resp['data']['title_nameth'] +
          resp['data']['first_nameth'] +
          ' ' +
          resp['data']['last_nameth'],
      );
      $('[name=ComNumber]').val(resp['data']['office_number']);
      $('[name=ComMoo]').val(resp['data']['village_number']);
      $('[name=ComSubDistrict]').val(resp['data']['subdis_th']);
      $('[name=ComDistrict]').val(resp['data']['district_th']);
      $('[name=ComProvince]').val(resp['data']['province_th']);
      $('[name=ComZipcode]').val(resp['data']['zip_code']);
      $('[name=business]').val(resp['data']['business_type']);
      $('[name=witness-txt]').val(resp['data']['witness_name']);
      $('[name=witness-txt2]').val(resp['data']['witness_name2']);
      // }
    },
    addvalueDate: function(v) {
      if (v.id == 'outdate') {
        var text = v.value;
        var year = text.toString().substring(0, 4);
        var month = text.toString().substring(5, 7);
        var day = text.toString().substring(8, 10);
        year = Number(year);
        year = year + 543;
        var out_day = day + '/' + month + '/' + year;
        $(v).attr('value', out_day);
        this.out_days = out_day;
      }
      if (v.id == 'dateent') {
        var text = v.value;
        var year = text.toString().substring(0, 4);
        var month = text.toString().substring(5, 7);
        var day = text.toString().substring(8, 10);
        year = Number(year);
        year = year + 543;
        var date_ent = day + '/' + month + '/' + year;
        $(v).attr('value', date_ent);
        this.date_ents = date_ent;
      }
      if (v.id == 'startjob') {
        var text = v.value;
        var year = text.toString().substring(0, 4);
        var month = text.toString().substring(5, 7);
        var day = text.toString().substring(8, 10);
        year = Number(year);
        year = year + 543;
        var start_jobs = day + '/' + month + '/' + year;
        $(v).attr('value', start_jobs);
        this.start_job = start_jobs;
      }
      if (v.id == 'endjob') {
        var text = v.value;
        var year = text.toString().substring(0, 4);
        var month = text.toString().substring(5, 7);
        var day = text.toString().substring(8, 10);
        year = Number(year);
        year = year + 543;
        var endjob = day + '/' + month + '/' + year;
        $(v).attr('value', endjob);
        this.end_job = endjob;
      }
    },
    async getpos_dep() {
      let resp = await this.$store.dispatch('staffs/getpos_dep');
      this.list_dep = resp.dep;
      this.set_list_pos = resp.pos;
    },
    async set_pos() {
      let dep_id = this.select_dep.toString();
      this.list_pos =
        typeof this.set_list_pos[dep_id] == 'undefined'
          ? []
          : this.set_list_pos[dep_id];
    },
    set_date: function() {
      var day = [];
      var month = {
        1: 'มกราคม',
        2: 'กุมภาพันธ์',
        3: 'มีนาคม',
        4: 'เมษายน',
        5: 'พฤษภาคม',
        6: 'มิถุนายน',
        7: 'กรกฎาคม',
        8: 'สิงหาคม',
        9: 'กันยายน',
        10: 'ตุลาคม',
        11: 'พฤศจิกายน',
        12: 'ธันวาคม',
      };
      var year = [];

      for (let i = 1; i <= 31; i++) {
        day.push(i);
      }
      for (let i = 2564; i <= moment().year() + 548; i++) {
        year.push(i);
      }
      this.op_day = day;
      this.op_month = month;
      this.op_year = year;
    },
    async saveContract() {
      this.check();
      let data = {}; //สร้างตัวแปรเจสัน
      //รูปลายเซ็น
      if (this.sign_pad.isEmpty()) {
        data['data_sign'] = null;
      } else {
        var data_sign = this.sign_pad.toDataURL('image/png');
        data['data_sign'] = data_sign;
      }
      if (this.sign_pad_boss.isEmpty()) {
        data['data_sign_boss'] = null;
      } else {
        var data_boss = this.sign_pad_boss.toDataURL('image/png');
        data['data_sign_boss'] = data_boss;
      }
      if (this.sign_pad_emp.isEmpty()) {
        data['data_sign_emp'] = null;
      } else {
        var data_emp = this.sign_pad_emp.toDataURL('image/png');
        data['data_sign_emp'] = data_emp;
      }
      if (this.sign_pad_wit.isEmpty()) {
        data['data_sign_wit'] = null;
      } else {
        var data_wit = this.sign_pad_wit.toDataURL('image/png');
        data['data_sign_wit'] = data_wit;
      }
      if (this.sign_pad_wit2.isEmpty()) {
        data['data_sign_wit2'] = null;
      } else {
        var data_wit2 = this.sign_pad_wit2.toDataURL('image/png');
        data['data_sign_wit2'] = data_wit2;
      }
      //เก็บค่าจาก value จาก radio
      let branchID = $('#app.print [name=branch]:checked').val();
      //เก็บค่าจาก branch_name จาก radio
      let branchName = $('#app.print [name=branch]:checked').attr(
        'branch_name',
      );
      data['branch_name'] = branchID; //เก็บข้อมูลลงตัวแปรเจสัน data โดยให้ branch_name เป็นคีย์ branchID เป็น value
      data['branch_id'] = branchID; //เก็บข้อมูลลงตัวแปรเจสัน data โดยให้ branch_id เป็นคีย์ branchID เป็น value
      //select
      var dep_id = this.select_dep.toString();
      var dep_name = this.list_dep[dep_id];
      var pos_id = this.select_pos.toString();
      var pos_name = this.list_pos[pos_id];
      data['dep_id'] = dep_id;
      data['dep_name'] = dep_name;
      data['pos_id'] = pos_id;
      data['pos_name'] = pos_name;
      $('#app.print select').each(function(i, o) {
        let v = $(o).val();
        let name = $(o).attr('name');
        data[name] = v; //เก็บข้อมูลลงตัวแปรเจสัน data โดยให้ name เป็นคีย์ v เป็น value
      });
      $('#app.print [type=text]').each(function(i, o) {
        let name = $(this).attr('name');
        let v = $(o).val();
        data[name] = v; //เก็บข้อมูลลงตัวแปรเจสัน data โดยให้ name เป็นคีย์ v เป็น value
      });
      var month = [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ];
      data['select_month_id'] = '';
      $.each(month, (i, arr) => {
        // i คือ ลำดับอาเรย์ arr คือ ชื่อเดือน
        if (arr == data['select_month']) {
          //เชค arr = select_month ไหม
          data['select_month_id'] = i + 1;
        }
      });
      var days = data['select_days'];
      var month = data['select_month_id'];
      var year = data['select_year'];
      year = year - 543;
      data['contract_date'] = year + '/' + month + '/' + days;
      //date all
      $('#app.print [type=date]').each(function(i, o) {
        let name = $(this).attr('name');
        let v = $(o).val();
        data[name] = v;
      });
      //checkbox
      $('#app.print [type=checkbox]:checked').each(function(i, o) {
        var id = $(o).attr('id');
        if (id == '5') {
          var remark_txt = data['remarktxt'];
          $(o).attr('value', remark_txt);
        }
        let v = $(o).val();
        let name = $(this).attr('name');
        data[name] = v;
      });
      //ส่งค่า data ไป DB
      let resp = await this.$store.dispatch('contract/saveContract', data);
      if (resp.length <= 0) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        return false;
      } else {
        if (resp == 0) {
          await this.$store.dispatch(
            'alerts/error',
            'มีข้อมูลเลขบัตรประชาชนนี้ในระบบแล้ว',
          );
        } else {
          await this.$store.dispatch('alerts/success', 'เพิ่มสัญญาจ้างสำเร็จ.');
          this.sign_pad.clear();
          this.sign_pad_boss.clear();
          this.sign_pad_emp.clear();
          this.sign_pad_wit.clear();
          this.sign_pad_wit2.clear();
        }
        this.goBackContract();
      }
    },
    goBackContract() {
      this.$router.push({
        path: 'form-contract',
        name: 'ฟอร์มสัญญาจ้าง',
      });
    },
    async updateContract() {
      let data = {};
      let get_update = this.update_id;
      if ($('div.sign_emp').attr('update') == 'up') {
        if (this.sign_pad.isEmpty()) {
          data['data_sign'] = null;
        } else {
          var data_sign = this.sign_pad.toDataURL('image/png');
          data['data_sign'] = data_sign;
        }
      } else {
        data['data_sign'] = this.signname;
      }
      if ($('div.signature-pad-boss').attr('update') == 'up') {
        if (this.sign_pad_boss.isEmpty()) {
          data['data_sign_boss'] = null;
        } else {
          var data_boss = this.sign_pad_boss.toDataURL('image/png');
          data['data_sign_boss'] = data_boss;
        }
      } else {
        data['data_sign_boss'] = this.signname_boss;
      }
      if ($('div.signature-pad-emp').attr('update') == 'up') {
        if (this.sign_pad_emp.isEmpty()) {
          data['data_sign_emp'] = null;
        } else {
          var data_emp = this.sign_pad_emp.toDataURL('image/png');
          data['data_sign_emp'] = data_emp;
        }
      } else {
        data['data_sign_emp'] = this.signname_emp;
      }
      if ($('div.signature-pad-witness').attr('update') == 'up') {
        if (this.sign_pad_wit.isEmpty()) {
          data['data_sign_wit'] = null;
        } else {
          var data_wit = this.sign_pad_wit.toDataURL('image/png');
          data['data_sign_wit'] = data_wit;
        }
      } else {
        data['data_sign_wit'] = this.signname_wit;
      }
      if ($('div.signature-pad-witness2').attr('update') == 'up') {
        if (this.sign_pad_wit2.isEmpty()) {
          data['data_sign_wit2'] = null;
        } else {
          var data_wit2 = this.sign_pad_wit2.toDataURL('image/png');
          data['data_sign_wit2'] = data_wit2;
        }
      } else {
        data['data_sign_wit2'] = this.signname_wit2;
      }
      data['get_update'] = get_update;
      let resp = await this.$store.dispatch('contract/updateContract', data);
      if (resp.length <= 0) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        return false;
      } else {
        await this.$store.dispatch('alerts/success', 'Update สัญญาจ้างสำเร็จ.');
        this.sign_pad.clear();
        this.sign_pad_boss.clear();
        this.sign_pad_emp.clear();
        this.sign_pad_wit.clear();
        this.sign_pad_wit2.clear();
        this.goBackContract();
      }
    },
    setdata() {
      var w = $('div.after-sign-pad')
        .parent()
        .width();
      var b = $('div.after-sign-pad-boss')
        .parent()
        .width();
      var e = $('div.after-sign-pad-emp')
        .parent()
        .width();
      var wit = $('div.after-sign-pad-witness')
        .parent()
        .width();
      var wit2 = $('div.after-sign-pad-witness2')
        .parent()
        .width();
      let t = this;
      $(this.condata).each(async function(i, o) {
        t.update_id = o.con_id;
        t.signname = o.signname;
        t.signname_emp = o.signname_emp;
        t.signname_boss = o.signname_boss;
        t.signname_wit = o.signname_wit;
        t.signname_wit2 = o.signname_wit2;
        //image
        if (o.signname != null) {
          $('.after-sign-pad').css('display', '');
          $('.sign-pad').css('background-image', 'url(' + o.signname + ')');
          $('.sign-pad').css({ width: w, height: 200 });
          $('div.sign_emp').css('display', 'none');
          $('div.sign_emp').attr('update', 'none');
          $('.sign_emp img').attr('src', o.signname);
        } else {
          $('div.sign_emp').css('display', '');
          $('.after-sign-pad').css('display', 'none');
          $('div.sign_emp').attr('update', 'up');
        }
        if (o.signname_emp != null) {
          $('.after-sign-pad-emp').css('display', '');
          $('.sign-pad-emp').css(
            'background-image',
            'url(' + o.signname_emp + ')',
          );
          $('.sign-pad-emp').css({ width: b, height: 200 });
          $('div.signature-pad-emp').css('display', 'none');
          $('div.signature-pad-emp').attr('update', 'none');
          $('.sign_emp2 img').attr('src', o.signname_emp);
          $('button#clear_emp').css('display', 'none');
        } else {
          $('div.signature-pad-emp').css('display', '');
          $('.after-sign-pad-emp').css('display', 'none');
          $('div.signature-pad-emp').attr('update', 'up');
        }
        if (o.signname_boss != null) {
          $('.after-sign-pad-boss').css('display', '');
          $('.sign-pad-boss').css(
            'background-image',
            'url(' + o.signname_boss + ')',
          );
          $('.sign-pad-boss').css({ width: e, height: 200 });
          $('div.signature-pad-boss').css('display', 'none');
          $('div.signature-pad-boss').attr('update', 'none');
          $('.sign_boss img').attr('src', o.signname_boss);
          $('button#clear_boss').css('display', 'none');
        } else {
          $('div.signature-pad-boss').css('display', '');
          $('.after-sign-pad-boss').css('display', 'none');
          $('div.signature-pad-boss').attr('update', 'up');
        }
        if (o.signname_wit != null) {
          $('.after-sign-pad-witness').css('display', '');
          $('.sign-pad-witness').css(
            'background-image',
            'url(' + o.signname_wit + ')',
          );
          $('.sign-pad-witness').css({ width: wit, height: 200 });
          $('div.signature-pad-witness').css('display', 'none');
          $('div.signature-pad-witness').attr('update', 'none');
          $('.sign_wit img').attr('src', o.signname_wit);
          $('button#clear_wit').css('display', 'none');
        } else {
          $('div.signature-pad-witness').css('display', '');
          $('.after-sign-pad-witness').css('display', 'none');
          $('div.signature-pad-witness').attr('update', 'up');
        }
        if (o.signname_wit2 != null) {
          $('.after-sign-pad-witness2').css('display', '');
          $('.sign-pad-witness2').css(
            'background-image',
            'url(' + o.signname_wit2 + ')',
          );
          $('.sign-pad-witness2').css({ width: wit2, height: 200 });
          $('div.signature-pad-witness2').css('display', 'none');
          $('div.signature-pad-witness2').attr('update', 'none');
          $('.sign_wit2 img').attr('src', o.signname_wit2);
          $('button#clear_wit2').css('display', 'none');
        } else {
          $('div.signature-pad-witness2').css('display', '');
          $('.after-sign-pad-witness2').css('display', 'none');
          $('div.signature-pad-witness2').attr('update', 'up');
        }
        //check_button_update
        if (
          $('div.sign_emp').attr('update') == 'none' &&
          $('div.signature-pad-emp').attr('update') == 'none' &&
          $('div.signature-pad-boss').attr('update') == 'none' &&
          $('div.signature-pad-witness').attr('update') == 'none' &&
          $('div.signature-pad-witness2').attr('update') == 'none'
        ) {
          $('button#btn_update').attr('disabled', 'disabled');
          $('button#btn_print').css('display', '');
        } else {
          $('button#btn_update').removeAttr('disabled');
        }
        //data_contract
        var year = o.contract_date.toString().substring(0, 4);
        var month = o.contract_date.toString().substring(5, 7);
        var day = o.contract_date.toString().substring(8, 10);
        day = Number(day);
        month = Number(month);
        year = Number(year);
        year = year + 543;
        var m = [
          'มกราคม',
          'กุมภาพันธ์',
          'มีนาคม',
          'เมษายน',
          'พฤษภาคม',
          'มิถุนายน',
          'กรกฎาคม',
          'สิงหาคม',
          'กันยายน',
          'ตุลาคม',
          'พฤศจิกายน',
          'ธันวาคม',
        ];
        var month_name = '';
        $.each(m, (i, arr) => {
          i = i + 1;
          if (month == i) {
            month_name = arr;
          }
        });
        //set branch
        $('#app.print [name=branch]').attr('checked', false);
        if (o.setting_contract_id == 1) {
          $('#app.print [name=branch][value=1]').attr('checked', true);
        } else if (o.setting_contract_id == 2) {
          $('#app.print [name=branch][value=2]').attr('checked', true);
        } else if (o.setting_contract_id == 3) {
          $('#app.print [name=branch][value=3]').attr('checked', true);
        } else if (o.setting_contract_id == 4) {
          $('#app.print [name=branch][value=4]').attr('checked', true);
        } else if (o.setting_contract_id == 5) {
          $('#app.print [name=branch][value=5]').attr('checked', true);
        } else if (o.setting_contract_id == 6) {
          $('#app.print [name=branch][value=6]').attr('checked', true);
        } else if (o.setting_contract_id == 7) {
          $('#app.print [name=branch][value=7]').attr('checked', true);
        } else if (o.setting_contract_id == 8) {
          $('#app.print [name=branch][value=8]').attr('checked', true);
        }
        $('input.check_service').attr('checked', false);
        $('input.check_service2').attr('checked', false);
        $('input.check_service3').attr('checked', false);
        $('input.check_ser').attr('checked', false);
        $('input.check_s').attr('checked', false);
        if (o.servicecharge == '3,000-6,000 บาทต่อเดือน') {
          $('input.check_service').attr('checked', true);
        } else if (o.servicecharge == '4,500 บาทต่อเดือน') {
          $('input.check_service2').attr('checked', true);
        } else if (o.servicecharge == 'ไม่มี Service Charg') {
          $('input.check_service3').attr('checked', true);
        }
        if (o.fare != null) {
          $('input.check_ser').attr('checked', true);
        } else {
          $('input.check_ser').attr('checked', false);
        }
        if (o.remark != null) {
          $('input.check_s').attr('checked', true);
          $('input.remark').attr('value', o.remark);
        } else {
          $('input.check_s').attr('checked', false);
          $('input.remark').attr('value', '');
        }
        //add value input
        //select date_contract
        $('#app.print select[name=select_days]').replaceWith(
          '<input type="text" id="changeInput" class="select_days" style="border: none; border-bottom: 1px dashed #000; text-align: center; width: 20%;">',
        );
        $('#app.print [type=text].select_days').attr('value', day);
        $('#app.print select[name=select_month]').replaceWith(
          '<input type="text" id="changeInput" class="select_month" style="border: none; border-bottom: 1px dashed #000; text-align: center; width: 20%;">',
        );
        $('#app.print [type=text].select_month').attr('value', month_name);
        $('#app.print select[name=select_year]').replaceWith(
          '<input type="text" id="changeInput" class="select_year" style="border: none; border-bottom: 1px dashed #000; text-align: center; width: 20%;">',
        );
        $('#app.print [type=text].select_year').attr('value', year);
        //input date
        var idcard_startdate = moment(o.idcard_startdate)
          .add(543, 'year')
          .format('DD/MM/YYYY');
        $('#app.print [type=date].outdate').replaceWith(
          '<input type="text" id="changeInput" class="outdate" style="border: none; border-bottom: 1px dashed #000; text-align: center;">',
        );
        $('#app.print [type=text].outdate').attr('value', idcard_startdate);
        var idcard_enddate = moment(o.idcard_enddate)
          .add(543, 'year')
          .format('DD/MM/YYYY');
        $('#app.print [type=date].dateent').replaceWith(
          '<input type="text" id="changeInput" class="dateent" style="border: none; border-bottom: 1px dashed #000; text-align: center;">',
        );
        $('#app.print [type=text].dateent').attr('value', idcard_enddate);
        var start_work = moment(o.start_work)
          .add(543, 'year')
          .format('DD/MM/YYYY');
        $('#app.print [type=date].startjob').replaceWith(
          '<input type="text" id="changeInput" class="startjob" style="border: none; border-bottom: 1px dashed #000; text-align: center;">',
        );
        $('#app.print [type=text].startjob').attr('value', start_work);
        var end_work = moment(o.end_work)
          .add(543, 'year')
          .format('DD/MM/YYYY');
        $('#app.print [type=date].endjob').replaceWith(
          '<input type="text" id="changeInput" class="endjob" style="border: none; border-bottom: 1px dashed #000; text-align: center;">',
        );
        $('#app.print [type=text].endjob').attr('value', end_work);
        //input text
        $('#app.print [type=text].company').attr('value', o.bchname_th);
        $('#app.print [type=text].by').attr(
          'value',
          o.title_nameth + o.first_nameth + ' ' + o.last_nameth,
        );
        $('#app.print [type=text].ComNumber').attr('value', o.office_number);
        $('#app.print [type=text].ComMoo').attr('value', o.village_number);
        $('#app.print [type=text].ComSubDistrict').attr('value', o.subdis_th);
        $('#app.print [type=text].ComDistrict').attr('value', o.district_th);
        $('#app.print [type=text].ComProvince').attr('value', o.province_th);
        $('#app.print [type=text].ComZipcode').attr('value', o.zip_code);
        $('#app.print [type=text].name').attr('value', o.emp_name);
        $('#app.print [type=text].age').attr('value', o.emp_age);
        $('#app.print [type=text].nationality').attr('value', o.nationality);
        $('#app.print [type=text].IDcard').attr('value', o.emp_idcard);
        $('#app.print [type=text].outat').attr('value', o.idcard_outat);
        $('#app.print [type=text].HouseNo').attr('value', o.house_number);
        $('#app.print [type=text].moo').attr('value', o.villag_number);
        $('#app.print [type=text].road').attr('value', o.road);
        $('#app.print [type=text].Sub-district').attr('value', o.sub_district);
        $('#app.print [type=text].district').attr('value', o.district);
        $('#app.print [type=text].province').attr('value', o.provine);
        $('#app.print [type=text].zipcode').attr('value', o.postal_code);
        $('#app.print [type=text].province').attr('value', o.provine);
        $('#app.print [type=text].education').attr(
          'value',
          o.educational_level,
        );
        $('#app.print [type=text].business').attr('value', o.business_type);
        $('#app.print [type=text].position').attr('value', o.position_name);
        $('#app.print [type=text].salary').attr('value', o.start_salary);
        $('#app.print [type=text].salary2').attr('value', o.adjust_salary);
        $('#app.print [type=text].salary-txt').attr('value', o.service_50);
        $('#app.print [type=text].salary2-txt').attr('value', o.service_100);
        $('#app.print [type=text].signemployee2').attr('value', o.emp_name);
        $('#app.print [type=text].witness-txt').attr('value', o.witness_name);
        $('#app.print [type=text].witness-txt2').attr('value', o.witness_name2);
        //input select
        $('#app.print select.department').replaceWith(
          '<input type="text" id="changeInput" class="department" style="border: none; border-bottom: 1px dashed #000; text-align: center;">',
        );
        $('#app.print [type=text].department').attr('value', o.department_name);
        $('#app.print select.position').replaceWith(
          '<input type="text" id="changeInput" class="position" style="border: none; border-bottom: 1px dashed #000; text-align: center;">',
        );
        $('#app.print [type=text].position').attr('value', o.position_name);
        //disabled input
        $('#app.print [type=text]').each(function(i, o) {
          $(o).attr('disabled', 'disabled');
          $(o).css('color', '#000');
        });
        $('#app.print [type=checkbox]').each(function(i, o) {
          $(o).attr('disabled', 'disabled');
        });
        $('#app.print [type=radio]').each(function(i, o) {
          $(o).attr('disabled', 'disabled');
        });
        //display button

        $('button#btn_save').css('display', 'none');
        $('button#btn_update').css('display', '');
        // }
      });
    },
    async check() {
      if ($('#app.print [type=radio][name=branch]').checked == false) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกสำนักงาน');
        return false;
      }
      if ($('#app.print [type=text][name=name]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลชื่อลูกจ้าง',
        );
        return false;
      }
      if ($('#app.print [type=text][name=age]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลอายุลูกจ้าง',
        );
        return false;
      }
      let age = $('#app.print [type=text][name=age]').val();
      if (isNaN(age)) {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลอายุลูกจ้างเป็นตัวเลขเท่านั้น',
        );
        return false;
      }
      if ($('#app.print [type=text][name=nationality]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลสัญชาติลูกจ้าง',
        );
        return false;
      }
      if ($('#app.print [type=text][name=IDcard]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุเลขบัตรประจำตัวประชาชนลูกจ้าง',
        );
        return false;
      }
      if ($('#app.print [type=date][name=outdate]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุวันที่ออกบัตรประจำตัวประชาชน',
        );
        return false;
      }
      if ($('#app.print [type=text][name=outat]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลออกบัตรประจำตัวประชาชน',
        );
        return false;
      }
      if ($('#app.print [type=date][name=dateent]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุวันที่สิ้นสุดบัตรประจำตัวประชาชน',
        );
        return false;
      }
      if ($('#app.print [type=text][name=HouseNo]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลที่อยู่ให้ครบ',
        );
        return false;
      }
      if ($('#app.print [type=text][name=moo]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลที่อยู่ให้ครบ',
        );
        return false;
      }
      if ($('#app.print [type=text][name=road]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลที่อยู่ให้ครบ',
        );
        return false;
      }
      if ($('#app.print [type=text][name=Sub-district]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลที่อยู่ให้ครบ',
        );
        return false;
      }
      if ($('#app.print [type=text][name=district]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลที่อยู่ให้ครบ',
        );
        return false;
      }
      if ($('#app.print [type=text][name=province]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลที่อยู่ให้ครบ',
        );
        return false;
      }
      if ($('#app.print [type=text][name=zipcode]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลที่อยู่ให้ครบ',
        );
        return false;
      }
      if ($('#app.print [type=text][name=education]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลระดับคุณวุฒิ',
        );
        return false;
      }
      if ($('#app.print [type=text][name=department]').val() == '') {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกแผนก');
        return false;
      }
      if ($('#app.print [type=text][name=position]').val() == '') {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกตำแหน่ง');
        return false;
      }
      if ($('#app.print [type=date][name=startjob]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุวันเริ่มต้นการทำงาน',
        );
        return false;
      }
      if ($('#app.print [type=date][name=endjob]').val() == '') {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุวันสิ้นสุดการทำงาน',
        );
        return false;
      }
      if ($('#app.print [type=text][name=salary]').val() == '') {
        await this.$store.dispatch('alerts/error', 'กรุณาระบุข้อมูลเงินเดือน');
        return false;
      }
      let salary = $('#app.print [type=text][name=salary]').val();
      if (isNaN(salary)) {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลข้อมูลเงินเดือนเป็นตัวเลขเท่านั้น',
        );
        return false;
      }
      if ($('#app.print [type=text][name=salary2]').val() == '') {
        await this.$store.dispatch('alerts/error', 'กรุณาระบุข้อมูลเงินเดือน');
        return false;
      }
      let salary2 = $('#app.print [type=text][name=salary2]').val();
      if (isNaN(salary2)) {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาระบุข้อมูลข้อมูลเงินเดือนเป็นตัวเลขเท่านั้น',
        );
        return false;
      }
    },
    addvalue_chk: function(value) {
      if (value.checked == true) {
        if (value.id == '1') {
          $(value).attr('checked', true);
          $(value).attr('value', '3,000-6,000 บาทต่อเดือน');
        } else if (value.id == '2') {
          $(value).attr('checked', true);
          $(value).attr('value', '4,500 บาทต่อเดือน');
        }
        if (value.id == '3') {
          $(value).attr('checked', true);
          $(value).attr('value', 'Not have Service Charg');
        } else if (value.id == '4') {
          $(value).attr('checked', true);
          $(value).attr(
            'value',
            'ค่าช่วยเหลือการเดินทางเดือนละ 1,000 บาท จนกว่านายจ้างจะจัดหาหอพักพนักงานให้',
          );
        } else if (value.id == '5') {
          $(value).attr('checked', true);
          $('.remark').attr('value', value.value);
        }
      } else if (value.checked == false) {
        $(value).attr('checked', false);
        $(value).removeAttr('value');
      }
    },
    print: function(app) {
      $('.line.tab-4.sign_emp').css('display', 'none');
      $('.line.tab-4.show').css('display', 'none');
      $('.after-sign-pad').css('display', 'none');
      $('.line.tab-6.show').css('display', 'none');
      $('.line.tab-4.show_sign_emp').css('display', '');
      $('.line.tab-5.show_sign_emp').css('display', '');
      $('.line.tab-6.noshow').css('display', '');
      $('.line.tab-8.noshow').css('display', '');
      $('.line.noshow').css('display', '');
      $('.line.tab-11.noshow').css('display', '');
      var printContents = document.getElementById(app).innerHTML;
      printContents += `
        <style scoped>
          body {
            font-family: TH Sarabun;
            font-size: 11px;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
          }
          .body-form {
            text-align: left;
          }
          .line {
            margin-top: 0.3rem;
            text-align: justify;
          }
          .txtsm {
            font-size: 14px !important;
          }
          .tab-1 {
            box-sizing: border-box;
            padding-left: 8%;
          }
          .tab-2 {
            padding-left: 12.5%;
          }
          .tab-3 {
            padding-left: 16%;
          }
          .tab-4 {
            margin-top: 6%;
            padding-right: 5%;
            text-align: right;
          }
          .tab-5 {
            padding-right: 10%;
            text-align: right;
          }
          .tab-6 {
            margin-top: 6%;
            padding-left: 5%;
            text-align: left;
          }
          .tab-7 {
            padding-left: 10%;
          }
          .tab-8 {
            padding-left: 3%;
            text-align: left;
          }
          .tab-9 {
            padding-left: 11%;
          }
          .tab-10 {
            padding-left: 11.5%;
          }
          .tab-11 {
            padding-left: 9%;
          }
          .tab-12 {
            padding-left: 21.5%;
          }
          .input > input[type='text'] {
            display: inline-block;
            width: 5%;
            min-width: 60px;
            border: none;
            border-bottom: 1px dashed #000;
            text-align: center;
            font-size: 11px !important;
          }
          .input.set > input[type='text'] {
            min-width: 5%;
          }
          .input.set2 > input[type='text'] {
            min-width: 11%;
          }
          .input.dashed > input[type='text'] {
            border: none;
            border-bottom: 1px dashed #000;
          }
          .input.nonborder > input[type='text'] {
            border: none;
            border-bottom: 0px;
          }
          .chk-txt {
            margin-right: 3rem;
          }
          .w-2 > input[type='text'] {
            width: 0% !important;
          }
          .w-5 > input[type='text'] {
            width: 0% !important;
          }
          .w-10 > input[type='text'] {
            width: 0% !important;
          }
          .w-12 > input[type='text'] {
            width: 0% !important;
          }
          .w-15 > input[type='text'] {
            width: 15% !important;
          }
          .w-17 > input[type='text'] {
            width: 17% !important;
          }
          .w-20 > input[type='text'] {
            width: 20% !important;
          }
          .w-22 > input[type='text'] {
            width: 22% !important;
          }
          .w-25 > input[type='text'] {
            width: 25% !important;
          }
          .w-28 > input[type='text'] {
            width: 28% !important;
          }
          .w-30 > input[type='text'] {
            width: 30% !important;
          }
          .w-33 > input[type='text'] {
            width: 33% !important;
          }
          .w-35 > input[type='text'] {
            width: 35% !important;
          }
          .w-37 > input[type='text'] {
            width: 37% !important;
          }
          .w-40 > input[type='text'] {
            width: 40% !important;
          }
          .w-45 > input[type='text'] {
            width: 45% !important;
          }
          .w-50 > input[type='text'] {
            width: 50% !important;
          }
          .w-55 > input[type='text'] {
            width: 55% !important;
          }
          .w-56 > input[type='text'] {
            width: 56% !important;
          }
          .w-85 > input[type='text'] {
            width: 85% !important;
          }
          .w-90 > input[type='text'] {
            width: 90% !important;
          }
          .page {
            width: 280mm;
            height: 100%;
            padding: 2.5cm 2.5cm 2.5cm 2.5cm;
            border: 1px #d3d3d3 solid;
            border-radius: 5px;
            background: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
          }
          input[type='checkbox'] {
            margin-left: 0.7rem;
          }
          .che-box {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
          }
          .ComNumber > input[type='text'] {
            width: 0% !important;
          }
          @page {
            size: A4;
            margin: 2cm;
          }
          @media print {
            .body {
              margin: 0;
              padding: 0;
            }
            .page {
              width: 100%;
              height: 100%;
              visibility: visible;
              padding: 0rem;
              box-shadow: none;
              border: none;
              padding-bottom: 1rem;
            }
            .head-form {
              text-align: center;
              font-size: 16px !important;
            }
            .btn-action {
              display: none;
            }
            .txtsm {
              font-size: 10px !important;
            }
            .print_days {
              display: inline-block;
              text-align: center;
              width: 13%;
              border-bottom: 1px dashed #000;
            }
            .print_month {
              display: inline-block;
              text-align: center;
              width: 30%;
              border-bottom: 1px dashed #000;
            }
            .print_year {
              display: inline-block;
              text-align: center;
              width: 13%;
              border-bottom: 1px dashed #000;
            }
            .outdate > input[type='text'] {
              width: 7% !important;
            }
            .by > input[type='text'] {
              width: 20% !important;
            }
            .company > input[type='text'] {
              min-width: 46%;
            }
            .company2 > input[type='text'] {
              min-width: 43%;
            }
            .check {
              margin-left: 0.5rem;
            }
            .non_show {
              display: inline-block;
              width: 18% !important;
              border-bottom: 1px dashed #000;
              text-align: center;
            }
            input#changeInput.dateent,
            input#changeInput.outdate {
              width: 20% !important;
            }
          }
          </style>
      `;
      // window.print();
      let WindowObject = window.open(
        '',
        'PrintWindow',
        'width=1024,height=500,top=0,left=0,toolbars=no,scrollbars=no,status=no,resizable=no',
      );
      WindowObject.document.writeln(printContents);
      WindowObject.document.close();
      WindowObject.focus();
      WindowObject.print();
      WindowObject.close();
      this.goBackContract();
      // window.location.reload();
    },
  },
};
</script>
<style scoped>
.body-form {
  text-align: left;
}
.line {
  margin-top: 0.5rem;
  text-align: justify;
}
.txtsm {
  font-size: 14px !important;
}
.tab-1 {
  box-sizing: border-box;
  padding-left: 10%;
}
.tab-2 {
  padding-left: 12.5%;
}
.tab-3 {
  padding-left: 16%;
}
.tab-4 {
  margin-top: 6%;
  padding-right: 5%;
  text-align: right;
}
.tab-5 {
  padding-right: 10%;
  text-align: right;
}
.tab-6 {
  margin-top: 6%;
  padding-left: 5%;
  text-align: left;
}
.tab-7 {
  padding-left: 10%;
}
.tab-8 {
  padding-left: 3%;
  text-align: left;
}
.tab-9 {
  padding-left: 11%;
}
.tab-10 {
  padding-left: 11.5%;
}
.tab-11 {
  padding-left: 9%;
}
.tab-12 {
  padding-left: 21.5%;
}
.input > input[type='text'] {
  display: inline-block;
  width: 5%;
  min-width: 60px;
  border: none;
  border-bottom: 1px dashed #000;
  color: mediumblue;
  text-align: center;
}
.input.dashed > input[type='text'] {
  border: none;
  border-bottom: 1px dashed #000;
}
.input.nonborder > input[type='text'] {
  border: none;
  border-bottom: 0px;
}
.input > input[type='date'] {
  display: inline-block;
  width: 18%;
  border: none;
  border-bottom: 1px dashed #000;
  color: mediumblue;
  text-align: center;
}
.select_days {
  border: none;
  border-bottom: 1px dashed #000;
  text-align: center;
  color: mediumblue;
}
.select_days.w-13 {
  width: 13%;
}
.select_days.w-30 {
  width: 30%;
}
select[type='text'].department {
  border: none;
  border-bottom: 1px dashed #000;
  text-align: center;
  width: 30% !important;
  color: mediumblue;
}
select[type='text'].position {
  border: none;
  border-bottom: 1px dashed #000;
  text-align: center;
  width: 30%;
  color: mediumblue;
}
.chk-txt {
  margin-right: 3rem;
}
.w-2 > input[type='text'] {
  width: 2% !important;
}
.w-5 > input[type='text'] {
  width: 5% !important;
}
.w-10 > input[type='text'] {
  width: 10% !important;
}
.w-12 > input[type='text'] {
  width: 12% !important;
}
.w-15 > input[type='text'] {
  width: 15% !important;
}
.w-17 > input[type='text'] {
  width: 17% !important;
}
.w-20 > input[type='text'] {
  width: 20% !important;
}
.w-22 > input[type='text'] {
  width: 22% !important;
}
.w-25 > input[type='text'] {
  width: 25% !important;
}
.w-28 > input[type='text'] {
  width: 28% !important;
}
.w-30 > input[type='text'] {
  width: 30% !important;
}
.w-33 > input[type='text'] {
  width: 33% !important;
}
.w-35 > input[type='text'] {
  width: 35% !important;
}
.w-37 > input[type='text'] {
  width: 37% !important;
}
.w-40 > input[type='text'] {
  width: 40% !important;
}
.w-42 > input[type='text'] {
  width: 44% !important;
}
.w-45 > input[type='text'] {
  width: 45% !important;
}
.w-50 > input[type='text'] {
  width: 50% !important;
}
.w-55 > input[type='text'] {
  width: 55% !important;
}
.w-56 > input[type='text'] {
  width: 56% !important;
}
.w-85 > input[type='text'] {
  width: 85% !important;
}
.w-90 > input[type='text'] {
  width: 90% !important;
}
.page {
  width: 289mm;
  height: 100%;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.check {
  margin-left: 1rem;
}
.che-box {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
/* .body-form {
  display: none;
} */
.btn-pd {
  padding: 1rem;
}
.btn {
  width: auto;
}
.print_days {
  display: none;
  width: 13%;
  border-bottom: 1px dashed #000;
}
.print_month {
  display: none;
  width: 30%;
  border-bottom: 1px dashed #000;
}
.print_year {
  display: none;
  width: 13%;
  border-bottom: 1px dashed #000;
}
input[type='radio'] {
  margin-right: 0.3rem;
}
.non_show {
  display: none;
}
body,
html {
  margin-top: 0%;
  display: block;
  height: 100%;
}
button[name='clear'] {
  width: auto;
}
.sign-pad,
.sign-pad-emp,
.sign-pad-boss,
.sign-pad-witness,
.sign-pad-witness2 {
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 960px) {
  .page {
    padding: 0mm;
    border: none;
    box-shadow: none;
    font-size: 13px !important;
  }
  .btn {
    width: auto;
  }
  .line {
    margin-top: 0rem;
  }
}
@media (max-width: 600px) {
  .btn {
    width: auto;
  }
  .line.tab-6.show {
    margin-top: 1rem !important;
  }
  .page {
    padding: 0mm;
    border: none;
    box-shadow: none;
    font-size: 11px !important;
  }
  span.txtsm.service {
    font-size: 11px !important;
  }
}
@media (max-width: 450px) {
  input#changeInput {
    border-bottom: 0.5px dashed #000;
    width: 15%;
  }
  div.line {
    text-align: inherit;
  }
  input[type='text'] {
    border-bottom: 0.5px dashed #000;
    min-width: 50px !important;
    max-width: 300px !important;
  }
  select[type='text'][name='position'] {
    width: 85% !important;
  }
  input[type='text']#changeInput.position {
    width: 80% !important;
  }
  input[type='text'][name='company'] {
    width: 75% !important;
  }
  input[type='text'][name='name'],
  input[type='text']#changeInput.department,
  input[type='text'][name='remarktxt'] {
    width: 70% !important;
  }
  select[type='text'][name='department'] {
    width: 65% !important;
  }
  input[type='text'][name='road'],
  input#changeInput.dateent,
  input#changeInput.outdate {
    width: 35% !important;
  }
  input[type='text'][name='IDcard'] {
    width: 25% !important;
  }
  input[type='text'][name='zipcode'] {
    width: 30% !important;
  }
  input[type='text'][name='province'],
  input[type='text'][name='salary'],
  input[type='text'][name='salary2'],
  input[type='date'][name='outdate'],
  input[type='date'][name='dateent'],
  select[name='select_month'],
  select[name='select_year'] {
    width: 40% !important;
  }
  input[type='text'][name='business'],
  input[type='text'][name='by'] {
    width: 50% !important;
  }
  .check {
    margin-left: 1.5rem !important;
  }
  .chk-txt {
    margin-right: 1rem;
  }
}
</style>
