import service from '@/store/services/staffhasworks-service';

const state = {
  list: {},
  workinglistapprove: {},
  staffhasworks: {},
  meta: {},
  workingtotal: 0,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_WORKINGLIST_APPROVE: (state, list) => {
    state.workinglistapprove = list;
  },
  SET_RESOURCE: (state, staffhasworks) => {
    state.staffhasworks = staffhasworks;
  },
  SET_WORKING_TOTAL: (state, value) => {
    state.workingtotal = value;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then((list) => {
      commit('SET_LIST', list);
      return list;
    });
  },

  emphasworkinglist({ commit, dispatch }, params) {
    return service.emphasworkinglist(params).then((staffhasworks) => {
      commit('SET_RESOURCE', staffhasworks);
      return staffhasworks;
    });
  },

  emphasworkinglistapprove({ commit, dispatch }, params) {
    return service.emphasworkinglistapprove(params).then(({ list, meta }) => {
      commit('SET_WORKINGLIST_APPROVE', list);
      commit('SET_META', meta);
      return list;
    });
  },

  setWorkingAmount({ commit, dispatch }, value) {
    commit('SET_WORKING_TOTAL', state.meta.page.working.total);
  },

  emphasworkingcreate({ commit, dispatch }, params) {
    return service.emphasworkingcreate(params).then((staffhasworks) => {
      // commit('SET_RESOURCE', staffhasworks);
      return staffhasworks;
    });
  },

  emphasworkingcreateapprove({ commit, dispatch }, params) {
    return service.emphasworkingcreateapprove(params).then((staffhasworks) => {
      // commit('SET_RESOURCE', staffhasworks);
      return staffhasworks;
    });
  },

  emphasworkingapprove({ commit, dispatch }, params) {
    return service.emphasworkingapprove(params).then((staffhasworks) => {
      // commit('SET_RESOURCE', staffhasworks);
      return staffhasworks;
    });
  },

  updateWorking({ commit, dispatch }, params) {
    return service.updateWorking(params).then((staffhasworks) => {
      // commit('SET_RESOURCE', staffhasworks);
      return staffhasworks;
    });
  },

  deleteWorking({ commit, dispatch }, params) {
    return service.deleteWorking(params).then((data) => {
      return data;
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  staffhasworks: (state) => state.staffhasworks,
  workinglistapprove: (state) => state.workinglistapprove,
  workingtotal: (state) => state.meta.page.total,
};

const staffhasworks = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default staffhasworks;
