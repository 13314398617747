import service from '@/store/services/departments-service';

const state = {
  list: {},
  department: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, department) => {
    state.department = department;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    //console.log('param :',params)
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((department) => {
      commit('SET_RESOURCE', department);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((department) => {
      commit('SET_RESOURCE', department);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((department) => {
      commit('SET_RESOURCE', department);
    });
  },
};

const getters = {
  list: (state) => state.list,
  department: (state) => state.department,
  dropdown: (state) => {
    return state.list.map((department) => ({
      id: department.id,
      name: department.dep_nameth,
    }));
  },
};

const departments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default departments;
