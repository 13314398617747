import service from '@/store/services/appeal-service';

const state = {};

const mutations = {};

const actions = {
  async gettagsos({ commit, dispatch }, params) {
    return await service.gettagsos(params);
  },
  async createAppeal({ commit, dispatch }, params) {
    return await service.createAppeal(params);
  },
  async getFileAppeal({ commit, dispatch }, params) {
    return await service.getFileAppeal(params);
  },
  async getAppeal({ commit, dispatch }, params) {
    return await service.getAppeal(params);
  },
  async updateSos({ commit, dispatch }, params) {
    return await service.updateSos(params);
  },
  async deleteSOS({ commit, dispatch }, params) {
    return await service.deleteSOS(params);
  },
};

const getters = {};

const appeal = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default appeal;
