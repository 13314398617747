const state = {
  tabactive: 0,
  employee_id: null,
  transbranches: null,
};

const mutations = {
  SET_TABACTIVE: (state, value) => {
    state.tabactive = value;
  },

  SET_EMPLOYEE: (state, value) => {
    state.employee_id = value;
  },

  SET_TRANSBRANCHES: (state, value) => {
    state.transbranches = value;
  },
};

const actions = {
  setTabactive({ commit, dispatch }, value) {
    commit('SET_TABACTIVE', value);
  },

  setEmployee({ commit, dispatch }, value) {
    commit('SET_EMPLOYEE', value);
  },

  setTransbranches({ commit, dispatch }, value) {
    commit('SET_TRANSBRANCHES', value);
  },
};

const getters = {};

const stafftransaction = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default stafftransaction;
