import service from '@/store/services/adjunct-service';

const state = {};

const mutations = {};

const actions = {
  async importColumnlist({ commit, dispatch }, params) {
    return await service.importColumnlist(params);
  },
  async importIncomeCost({ commit, dispatch }, params) {
    return await service.importIncomeCost(params);
  },
  async importTime({ commit, dispatch }, params) {
    return await service.importTime(params);
  },
  async importWorkShift({ commit, dispatch }, params) {
    return await service.importWorkShift(params);
  },
  async importLeave({ commit, dispatch }, params) {
    return await service.importLeave(params);
  },
  async getLeaves({ commit, dispatch }, params) {
    return await service.getLeaves(params);
  },
};

const getters = {};

const adjunct = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default adjunct;
