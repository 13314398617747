import service from '@/store/services/exports-service';

const state = {};

const mutations = {};

const actions = {
  async fontthsarabunnew({ commit, dispatch }, params) {
    return await service.fontthsarabunnew(params);
  },
  async exporttime({ commit, dispatch }, params) {
    return await service.exporttime(params);
  },
  async exportot({ commit, dispatch }, params) {
    return await service.exportot(params);
  },
  async exportotboat({ commit, dispatch }, params) {
    return await service.exportotboat(params);
  },
  async exportotspa({ commit, dispatch }, params) {
    return await service.exportotspa(params);
  },
  async exporflatrateShow({ commit, dispatch }, params) {
    return await service.exporflatrateShow(params);
  },
  async exportleave({ commit, dispatch }, params) {
    return await service.exportleave(params);
  },
  async exportapprover({ commit, dispatch }, params) {
    return await service.exportapprover(params);
  },
  async exportsalary({ commit, dispatch }, params) {
    return await service.exportsalary(params);
  },
  async reportsalarylist({ commit, dispatch }, params) {
    return await service.reportsalarylist(params);
  },
};

const getters = {};

const exports = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default exports;
