import $ from 'jquery';
class Loading {
  constructor() {}
  loading_start() {
    $('body').append(`
      <style id="loading-style">
        .bg-loading {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          opacity: 0.3;
          z-index: 9999;
        }
        .lds-ripple {
          display: inline-block;
          position: absolute;
          width: 100px;
          height: 100px;
          top: 40%;
          left: 56%;
          z-index: 999999;
          text-align: center;
        }
        .lds-ripple div {
          position: absolute;
          border: 4px solid #adf3b5;
          opacity: 1;
          border-radius: 50%;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }
        .lds-ripple div:nth-child(2) {
          animation-delay: -0.5s;
        }
        @keyframes lds-ripple {
          0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
          }
          100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
          }
        }
      </style>

      <div class="bg-loading loading-style" ></div>
      <div class="lds-ripple loading-style">
        <div></div>
        <div></div>
      </div>
    `);
  }
  loading_close() {
    $('#loading-style,.loading-style').remove();
  }
}
export const { loading_start, loading_close } = new Loading();
