var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:[{ 'nav-open': _vm.$sidebar.showSidebar }]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),_c('template',{slot:"links"},[_c('mobile-menu',{attrs:{"totalnotify":_vm.totalNotify,"permissions":_vm.permissions,"approverOTOverLimit":_vm.approverOTOverLimit,"emp_id":_vm.employee_id}}),_c('sidebar-item',{attrs:{"opened":"","link":{ name: 'Staff Details', image: _vm.image }}},[_c('sidebar-item',{attrs:{"link":{ name: 'พนักงาน', path: '/staff/transaction' }}}),(_vm.approverhas_emp.length > 0)?_c('sidebar-item',{attrs:{"link":{
            name: 'กะพนักงาน (ของหัวหน้างาน)',
            nameen: 'Change Shift Work (For Manager)',
            path: '/admin/ChangeShiftWorkForManager',
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: 'บันทึกการลา (Leave)', path: '/staff/leave' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'ทำงานล่วงเวลา (OT)', path: '/staff/otrequest' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'OT เหมา',
            path: '/staff/otrequestcharter',
          }}}),(_vm.is_spa_dep.includes(_vm.profile?.[0]?.dep_id))?_c('sidebar-item',{attrs:{"link":{
            name: 'ค่านวดสปา',
            path: '/staff/sparequestcharter',
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'santhiya SOS',
            path: '/admin/list_santhiayaSOS',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'ข้อเสนอแนะ / ข้อร้องเรียน',
            nameen: 'Suggestion',
            path: '/admin/requestAppeal',
          }}})],1),_c('sidebar-item',{attrs:{"opened":"","link":{ name: 'Executive', image: _vm.image }}},[(_vm.permissions.includes('reportnotprivatecode'))?_c('sidebar-item',{attrs:{"link":{
            name: 'รายงานเงินเดือน',
            path: '/executive/salary-report',
          }}}):_vm._e(),(_vm.permissions.includes('reportflatrate'))?_c('sidebar-item',{attrs:{"link":{
            name: 'รายงาน OT เหมา และค่านวดสปา',
            path: '/executive/flatreatspa-report',
          }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"opened":"","link":{ name: 'Admin Manager', image: _vm.image }}},[(_vm.permissions.includes('manageAppeal'))?_c('sidebar-item',{attrs:{"link":{
            name: 'รายงานข้อเสนอแนะ / ข้อร้องเรียน',
            nameen: 'Suggestion Report',
            path: '/admin/listAppeal',
          }}}):_vm._e(),(_vm.permissions.includes('manageInfo'))?_c('sidebar-item',{attrs:{"link":{
            name: 'จัดการข้อปฏิบัติ & แนะนำแผนก',
            nameen: 'Informations Setting',
            path: '/admin/InformationsSetting',
          }}}):_vm._e(),(
            _vm.permissions.includes('flatratemanage') ||
              _vm.permissions.includes('view flatreat')
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'จัดการข้อมูลค่านวดสปา',
            path: '/admin/editdata-flatreat',
          }}}):_vm._e(),(
            _vm.permissions.includes('process salary') ||
              _vm.permissions.includes('process Spa')
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'คำนวณค่านวดสปา',
            path: '/admin/spa-process',
          }}}):_vm._e(),(
            _vm.permissions.includes('process salary') ||
              _vm.permissions.includes('process flatrate')
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'คำนวณ OT เหมา',
            path: '/admin/otflatreat-process',
          }}}):_vm._e(),(_vm.permissions.includes('view contract'))?_c('sidebar-item',{attrs:{"link":{ name: 'ฟอร์มสัญญาจ้าง', path: '/admin/form-contract' }}}):_vm._e(),(_vm.permissions.includes('manage santhiyaSOS'))?_c('sidebar-item',{attrs:{"link":{
            name: 'จัดการสัญธิญา SOS',
            path: '/admin/santhiayaSOS-management',
          }}}):_vm._e(),(_vm.permissions.includes('view staff'))?_c('sidebar-item',{attrs:{"link":{ name: 'จัดการพนักงาน', path: '/admin/list-users/' }}}):_vm._e(),(_vm.permissions.includes('view department'))?_c('sidebar-item',{attrs:{"link":{ name: 'จัดการแผนก', path: '/admin/list-department' }}}):_vm._e(),(_vm.permissions.includes('view position'))?_c('sidebar-item',{attrs:{"link":{ name: 'จัดการตำแหน่ง', path: '/admin/list-position' }}}):_vm._e(),(_vm.permissions.includes('view roles'))?_c('sidebar-item',{attrs:{"link":{
            name: 'จัดการ Role',
            path: '/admin/role-management/list-roles',
          }}}):_vm._e(),(_vm.permissions.includes('view permissions'))?_c('sidebar-item',{attrs:{"link":{
            name: 'จัดการ Permission',
            path: '/admin/permission-management/list-permissions',
          }}}):_vm._e(),(_vm.permissions.includes('setup permission'))?_c('sidebar-item',{attrs:{"link":{
            name: 'จัดการสิทธิ์การเข้าใช้งาน',
            path:
              '/admin/permission-access/list-permissions-access/id/type/byrole',
          }}}):_vm._e(),(_vm.permissions.includes('user role'))?_c('sidebar-item',{attrs:{"link":{
            name: 'จัดการสิทธิ์ User',
            path: '/admin/staff-role/list-staff-role/0/0',
          }}}):_vm._e(),(_vm.permissions.includes('setup working time'))?_c('sidebar-item',{attrs:{"link":{ name: 'ตั้งค่ากะทำงาน', path: '/admin/list-workingtime' }}}):_vm._e(),(_vm.permissions.includes('setup approve'))?_c('sidebar-item',{attrs:{"link":{
            name: 'ตั้งค่าการอนุมัติ',
            path: '/admin/approve-setup',
          }}}):_vm._e(),(_vm.permissions.includes('setup private code'))?_c('sidebar-item',{attrs:{"link":{
            name: 'ตั้งค่า Privatecode',
            path: '/admin/privatecode-setup',
          }}}):_vm._e(),(_vm.permissions.includes('reset password'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Reset รหัสผ่านพนักงาน',
            path: '/admin/admin-reset-password',
          }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"opened":"","link":{ name: 'Payroll Manager', image: _vm.image }}},[(_vm.permissions.includes('editdata'))?_c('sidebar-item',{attrs:{"link":{
            name: 'จัดการข้อมูล',
            path: '/payroll/editdata',
          }}}):_vm._e(),(_vm.permissions.includes('view remainleave'))?_c('sidebar-item',{attrs:{"link":{
            name: 'จัดการวันลาคงเหลือ',
            path: '/payroll/reportremainleave',
          }}}):_vm._e(),(_vm.permissions.includes('process salary'))?_c('sidebar-item',{attrs:{"link":{
            name: 'ดึงข้อมูล',
            path: '/payroll/exportpage',
          }}}):_vm._e(),(
            _vm.permissions.includes('import') ||
              _vm.permissions.includes('process salary')
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'นำเข้าข้อมูล',
            path: '/payroll/importpage',
          }}}):_vm._e(),(_vm.permissions.includes('process salary'))?_c('sidebar-item',{attrs:{"link":{
            name: 'ตรวจสอบสลิปเงินเดือน',
            path: '/payroll/checkslippage',
          }}}):_vm._e(),(_vm.permissions.includes('process salary'))?_c('sidebar-item',{attrs:{"link":{
            name: 'ตั้งค่าวันหยุดประจำสัปดาห์',
            path: '/payroll/setting-staff-weekend',
          }}}):_vm._e(),(_vm.permissions.includes('process salary'))?_c('sidebar-item',{attrs:{"link":{
            name: 'ตั้งค่าประกันสังคม',
            path: '/payroll/setting-staff-sso',
          }}}):_vm._e(),(_vm.permissions.includes('process salary'))?_c('sidebar-item',{attrs:{"link":{
            name: 'ตั้งค่าการคำนวณภาษี',
            path: '/payroll/setting-limittax',
          }}}):_vm._e(),(_vm.permissions.includes('process salary'))?_c('sidebar-item',{attrs:{"link":{
            name: 'ตั้งค่า OT',
            path: '/payroll/setting-ot',
          }}}):_vm._e(),(_vm.permissions.includes('process salary'))?_c('sidebar-item',{attrs:{"link":{
            name: 'ตั้งค่า OT เหมาและค่านวดสปา',
            path: '/payroll/SettingOTFlatRate',
          }}}):_vm._e(),(_vm.permissions.includes('process salary'))?_c('sidebar-item',{attrs:{"link":{
            name: 'ตั้งค่าผูกรหัสสแกนลายนิ้วมือ',
            path: '/payroll/settingScan',
          }}}):_vm._e(),(_vm.permissions.includes('process salary'))?_c('sidebar-item',{attrs:{"link":{ name: 'คำนวณเงินเดือน', path: '/payroll/salary-process' }}}):_vm._e(),(
            _vm.permissions.includes('process salary') ||
              _vm.permissions.includes('view report')
          )?_c('sidebar-item',{attrs:{"link":{ name: 'รายงานเงินเดือน', path: '/payroll/salary-report' }}}):_vm._e(),(
            _vm.permissions.includes('process salary') ||
              _vm.permissions.includes('view report')
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'รายงาน OT เหมา และค่านวดสปา',
            path: '/payroll/flatreatspa-report',
          }}}):_vm._e()],1)],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar',{attrs:{"totalnotify":_vm.totalNotify,"selectLang":_vm.lang},on:{"setLang":_vm.setlang}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent }},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }