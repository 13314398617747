const state = {};

const mutations = {};

const actions = {
  success({ commit, dispatch }, message) {
    this.$app.$notify({
      timeout: 3000,
      message: message,
      horizontalAlign: 'right',
      verticalAlign: 'top',
      // icon: 'add_alert',
      icon: 'check_circle',
      type: 'success',
    });
  },

  error({ commit, dispatch }, message) {
    this.$app.$notify({
      timeout: 3000,
      message: message,
      horizontalAlign: 'right',
      verticalAlign: 'top',
      icon: 'warning',
      type: 'warning',
    });
  },

  genQRCode({ commit, dispatch }, params) {
    let op = {
      cht: 'qr',
      chl: params.link,
      chs: params.size,
      choe: 'UTF-8',
    };
    let path = `https://chart.googleapis.com/chart?cht=${op.cht}&chl=${op.chl}&chs=${op.chs}&choe=${op.choe}`;
    return path;
  },
};

const getters = {};

const alerts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default alerts;
