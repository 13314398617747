import Vue from 'vue';
import router from '@/router';
import VueAuthenticate from 'vue-authenticate';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: 'access_token',
  loginUrl: '/v1/login',
  registerUrl: '/register',
});

const vueAuth = Vue.prototype.$auth;

export default {
  state: {
    isAuthenticated:
      localStorage.getItem('vue-authenticate.vueauth_access_token') !== null,
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
  },

  actions: {
    login(context, payload) {
      return vueAuth
        .login(payload.user, payload.requestOptions)
        .then((response) => {
          context.commit('isAuthenticated', {
            isAuthenticated: vueAuth.isAuthenticated(),
          });
          // router.push({ name: 'Home' });
          router.push({ name: 'User Profile' });
          return response.data;
        });
    },

    register(context, payload) {
      return vueAuth
        .register(payload.user, payload.requestOptions)
        .then((response) => {
          context.commit('isAuthenticated', {
            isAuthenticated: vueAuth.isAuthenticated(),
          });
          // router.push({ name: 'User Profile' });
        });
    },

    logout(context, payload) {
      return vueAuth.logout().then((response) => {
        context.commit('isAuthenticated', {
          isAuthenticated: vueAuth.isAuthenticated(),
        });
        window.localStorage.clear();
        router.push({ name: 'Login' });
      });
    },
  },
};
