<template>
  <div class="md-layout">
    <md-card>
      <md-card-header>
        <h4 class="title">
          ตรวจสอบสลิปเงินเดือน
        </h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
          >
            <filter-employee
              @childBranches="setBranches"
              @childDepartment="setDepartment"
              @childStaffs="setStaffs"
              :permissionsList="permissions"
              v-if="permissions.includes('view select staff')"
            >
            </filter-employee>
          </div>
          <div
            class="md-layout md-layout-item md-large-size-100 md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
          >
            <div
              class="md-layout-item md-large-size-25 md-size-50 md-xsmall-size-100"
            >
              <md-field>
                <label for="months">ปี</label>
                <md-select v-model="years">
                  <md-option
                    v-for="year in available_years"
                    :key="year + 'year'"
                    :value="year"
                  >
                    {{ year }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div
              class="md-layout-item md-large-size-25 md-size-50 md-xsmall-size-100"
            >
              <md-field>
                <label for="months">เดือน</label>
                <md-select v-model="months">
                  <md-option
                    v-for="mo in available_months[years]"
                    :key="mo + 'mo'"
                    :value="mo"
                  >
                    {{ mo }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50">
            <b-button variant="info" @click="print_html()">
              PRINT
            </b-button>
          </div>
          <div
            class="md-layout md-layout-item md-size-100 md-alignment-center-center print"
          >
            <slip-salary
              :emp_id="employee_id"
              :bch_id="branch_id"
              :dep_id="departments_id"
              :chk_permis="chk_permis"
              :month="months"
              :year="years"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
    <!-- <tabs
      :tab-name="tabList.name"
      :tab-icon="tabList.icon"
      :tab-active="tabactive"
      nav-pills-icons
      plain
      color-button="warning"
    >
      <template slot="tab-pane-1">
          <export-time></export-time>
        </template>
        <template slot="tab-pane-2">
          <export-ot></export-ot>
        </template>
        <template slot="tab-pane-3">
          <export-leave></export-leave>
        </template>
    </tabs> -->
  </div>
</template>
<script>
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { loading_start, loading_close } from '@/utils/loading.js';

// import { Tabs } from '@/components';
import FilterEmployee from '@/components/FilterEmployee.vue';
import SlipSalary from '@/components/SlipSalary.vue';
// import ExportOT from '@/pages/Dashboard/Pages/Payroll/Export/ExportOT.vue';
// import ExportLeave from '@/pages/Dashboard/Pages/Payroll/Export/ExportLeave.vue';

export default {
  mixins: [employeeMixin],
  components: {
    'filter-employee': FilterEmployee,
    'slip-salary': SlipSalary,
    // Tabs,
    // 'export-time': ExportTime,
    // 'export-ot': ExportOT,
    // 'export-leave': ExportLeave,
  },

  data() {
    return {
      moment: moment,
      departments_id: null,
      branch_id: null,
      chk_permis: 1,
      staffs: [],
      disabledToDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      available_years: [],
      available_months: [],
      available_list: [],
      years: null,
      months: null,
      permissionsList: [],
      permissions: [],
      employee_id: null,
      profile: null,
    };
  },
  created() {
    this.getProfileData().then(async (res) => {
      loading_start();
      this.profile = res;
      this.setPermission(this.me);
      loading_close();
    });
  },
  watch: {
    employee_id: async function(emp_id) {
      if (emp_id != null) {
        this.getReportsalaryList();
      }
    },
    branch_id: async function(bch_id) {
      if (bch_id != null) {
        this.getReportsalaryList();
      }
    },
    years: function(y) {
      if (y != null) {
        this.months = Math.max(...this.available_months[y]);
      }
    },
  },
  methods: {
    async getProfile() {
      await this.$store.dispatch('profile/me');
      this.me = await this.$store.getters['profile/me'];
    },
    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },
    async getReportsalaryList() {
      let params = {
        ...{ emp_id: this.employee_id },
        ...{ bch_id: this.branch_id },
        ...{ chk_permis: this.chk_permis },
      };
      let list = await this.$store.dispatch('salarys/getListSalary', params);
      this.months = null;
      this.years = null;
      this.available_years = [];
      this.available_months = [];
      if (list.length > 0) {
        list.forEach((l) => {
          this.available_years.push(l.years);
          this.available_months[l.years] = l.listmonths.split(',').map(Number);
        });
      }
    },
    async print_html() {
      const gethtml = this.$el.querySelector('.print');
      if (gethtml != null) {
        const m = new moment();
        let datetext = m.format('DD/MM/YYYY HH:mm');
        let url = process.env.VUE_APP_APP_BASE_URL + this.$route.fullPath;
        let print = gethtml.innerHTML;
        /* eslint-disable */
        print +=
          `<div class="footer left">
              <p>
                หมายเหตุ: บริษัทถือว่าขัอความในเอกสารฉบับนี้เป็นความลับ ห้ามเปิดเผยให้ผู้อื่นทราบโดยเด็ดขาด <br/>
                Please Use this e-letter as the payroll or other income payment slip. The infomation is confidential. please do not reveal it to any one.<br/>
                เรียน ท่านผู้เกี่ยวข้อง <br/>
                เอกสารฉบับนี้เป็นเอกสารสำคัญออกจากระบบอิเลคทรอนิคส์ ออกโดย บริษัท ซึ่งสามารถใช้แทนหนังสือรับรองการทำงานและหนังสือรับรองเงินเดือนได้
              </p>
            </div>
            <div class="footer right">
              <a href="` +
          url +
          `">` +
          url +
          `</a>
              <span>` +
          datetext +
          `</span>
            </div>`;
        /* eslint-enable */
        print += `
          <style scoped>
            @media screen {
              div.footer {
                display: none;
              }
            }
            @media print {
              div.footer.right {
                display: fixed;
                position: fixed;
                right: 0;
                bottom: 0;
              }
              div.footer.left {
                display: fixed;
                position: fixed;
                left: 0;
                bottom: 0;
              }
              div.footer.left p {
                color: #b50000;
              }
            }
            table {
              border-collapse: collapse;
            }

            th,
            td {
              text-align: left;
              border: 1px solid #ddd;
            }

            table.slip-head {
              border: 0px solid;
              font-weight: 600;
            }

            table.slip-head th,
            table.slip-head td {
              border: 0px solid;
            }

            table.slip-head .txt-center {
              text-align: center;
            }

            table.slip-head .txt-left {
              text-align: left;
            }

            table.slip-head .txt-right {
              text-align: right;
            }

            table.slip-data {
              font-weight: 600;
            }

            table.slip-data th,
            table.slip-data td {
              border: 1px solid;
              padding: 1px 5px;
            }

            table.slip-data.incost th {
              border: 1px solid;
            }

            table.slip-data.incost td {
              border: 1px solid;
              border-bottom: 0px solid;
              border-top: 0px solid;
            }

            table.slip-data.incost td font.income {
              color: #006700;
            }

            table.slip-data.incost td font.cost {
              color: #b50000;
            }

            table.slip-data .noborder {
              border: 0px solid;
            }

            table.slip-data .txt-center {
              text-align: center;
            }

            table.slip-data .txt-left {
              text-align: left;
            }

            table.slip-data .txt-right {
              text-align: right;
            }

            table.slip-noborder th,
            table.slip-noborder td {
              border: 1px solid rgba(255, 255, 255, 0);
            }
            .backgrourdslip {
              /* background-image: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 0.85),
                  rgba(255, 255, 255, 0.85)
                ),
                url('http://livepocket.local:8081/img/logo-santhiya.png'); */
              background-image: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 0.85),
                  rgba(255, 255, 255, 0.85)
                ),
                url('https://livepocket.com/img/logo-santhiya.png');
              /*filter: grayscale(100%);*/
              background-position: left;
              background-repeat: no-repeat;
              background-size: 790px auto;
            }
          </style>`;
        let WindowObject = window.open(
          '',
          'PrintWindow',
          // 'width=1024,height=500,top=0,left=0,toolbars=no,scrollbars=no,status=no,resizable=no',
        );
        WindowObject.document.writeln(print);
        WindowObject.document.close();
        WindowObject.focus();
        setTimeout(function() {
          WindowObject.print();
          WindowObject.close();
        }, 200);
        // const jspdf = new jsPDF('landscape');
        // var font = await this.$store.dispatch('exports/fontthsarabunnew');

        // jspdf.html(printhtml, {
        //   callback: function() {
        //     jspdf.output('dataurlnewwindow', 'testtest.pdf');
        //   },
        //   x: 15,
        //   y: 10,
        // });
        // jspdf.text(15, 15, 'สวัสดี ยินดีที่ได้รู้จักคุณ');
        // jspdf.save('test.pdf');
      }
      return false;
    },
    /**
     * set branch value from child component
     */
    setBranches(v) {
      // this.branches = v;
      this.branch_id = v;
    },

    /**
     * set employee id value from child component
     */
    setDepartment(v) {
      if (v) {
        this.departments_id = v;
      }
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employee_id = v.id;
      } else {
        this.staffs = [];
        this.employee_id = null;
      }
    },
  },
};
</script>
<style lang="scss">
.tab-pane {
  width: 100%;
}
</style>
