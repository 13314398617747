<template>
  <div class="md-layout">
    <!-- Progress bar -->
    <vue-progress-bar></vue-progress-bar>
    <div class="md-layout-item md-small-size-100 mx-auto">
      <simple-wizard>
        <template slot="header">
          <h3 class="title">ข้อมูลพนักงาน</h3>
          <div class="text-right">
            <md-button @click="goBack" class="md-primary md-dense">
              Back to List
            </md-button>
          </div>
        </template>
        <!-- :before-change="() => validateStep('step1')" -->
        <wizard-tab :before-change="() => validateStep('step1')">
          <template slot="label">ข้อมูลส่วนตัว</template>
          <personal-form
            ref="step1"
            @on-validated="onStepValidated"
            :user="user"
          ></personal-form>
        </wizard-tab>
        <!-- :before-change="() => validateStep('step2')" -->
        <wizard-tab :before-change="() => validateStep('step2')">
          <template slot="label">ข้อมูลพนักงาน</template>
          <staff-form
            ref="step2"
            @on-validated="onStepValidated"
            :user="user"
            :branchs="branchs"
          ></staff-form>
        </wizard-tab>

        <!--  -->
        <wizard-tab :before-change="() => validateStep('step3')">
          <template slot="label">ข้อมูลที่อยู่</template>
          <address-form
            ref="step3"
            @on-validated="wizardComplete"
            :user="user"
          ></address-form>
        </wizard-tab>
        <!-- :before-change="() => validateStep('step4')" -->
        <!-- <wizard-tab :before-change="() => validateStep('step4')">
          <template slot="label">ข้อมูล login</template>
          <password-form ref="step4" @on-validated="wizardComplete" :user="user"></password-form>
        </wizard-tab> -->
      </simple-wizard>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import PersonalForm from './Forms/PersonalForm.vue';
import StaffForm from './Forms/StaffForm.vue';
//import PasswordForm from "./Forms/PasswordForm.vue";
import AddressForm from './Forms/AddressForm.vue';
import Swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from '@/components';
import formMixin from '@/mixins/form-mixin';
import employeeMixin from '@/mixins/employee-mixin';
export default {
  mixins: [formMixin, employeeMixin],

  data() {
    return {
      wizardModel: {},
      user: {
        emp_id: null,
        type: 'employees',
        emp_code: null,
        password: null,
        password_confirmation: null,
        title_nameth: null,
        title_nameen: null,
        first_nameth: null,
        first_nameen: null,
        last_nameth: null,
        last_nameen: null,
        nick_name: null,
        phone: null,
        birth_date: null,
        sex: null,
        idcard: null,
        bank_number: null,
        bank_name: null,
        marital_st: null,
        religion: null,
        race: null,
        nationality: null,
        status: false,
        canleave: true,
        start_date: null,
        finish_date: null,
        staff_st_msg: null,
        address: null,
        zipcode: null,
        stafftype: null,
        privatecode: null,
        genkey: null,
        email: '',
        branchs: {
          id: null,
          type: 'branchs',
        },
        departments: {
          id: null,
          type: 'departments',
        },
        positions: {
          id: null,
          type: 'positions',
        },
        weekends: {
          id: null,
          type: 'weekends',
        },
        workingtimes: {
          type: 'employee_has_working',
          id: null,
          working_id: null,
          start_date: null,
          status: 'active',
          work_type: 'major',
        },
        salarys: {
          id: null,
          type: 'salarys',
          salary_amt: null,
          salary_st: null,
          status: null,
        },
        salary: {
          id: null,
          type: 'salarys',
          salary_amt: null,
          salary_st: null,
          status: 'active',
        },
        provinces: {
          id: null,
          type: 'provinces',
        },
        districts: {
          id: null,
          type: 'districts',
        },
        subdistricts: {
          id: null,
          type: 'subdistricts',
        },
      },
      branchs: [],
      private_code: '',
      employee: null,
      id: this.$route.params.id,
      pageNumber: this.$route.params.page.number,
      pageSize: this.$route.params.page.size,
      branchid: null,
      is_getData: false,
    };
  },
  components: {
    PersonalForm,
    StaffForm,
    //PasswordForm,
    AddressForm,
    SimpleWizard,
    WizardTab,
  },

  async created() {
    this.private_code = localStorage.getItem('privatecode');
    this.employee = JSON.parse(localStorage.getItem('employees'));
    await this.get().then(() => {
      this.getProfileData().then(() => {
        this.getDepartmentData(this.me.employees.branchs.company_id);
        this.getWorkingData(this.me.employees.branchs.company_id);
      });

      this.getBranchData().then(() => {
        this.setBranchesDropdown();
      });
    });
  },
  beforeDestroy() {
    this.user.destroy;
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },

    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },

    async wizardComplete() {
      try {
        this.start();
        this.user.employee_id = this.$route.params.id;
        if (!localStorage.getItem('privatecode')) {
          delete this.user.salarys.salary_amt;
        } else {
          this.user.privatecode = localStorage.getItem('privatecode');
        }
        this.user['salary'] = this.user.salarys;
        this.user['weekend'] = this.user.weekends;
        this.user.status = this.user.status ? 'active' : 'inactive';
        this.user.canleave = this.user.canleave ? 1 : 0;

        var update = { ...this.user };
        await this.$store.dispatch('users/update', update);
        // this.user = await this.$store.getters["users/user"];

        // if(!!this.file) {
        //   // upload image and get image url
        //   await this.$store.dispatch("users/upload", {user: this.user, image: this.file})
        //   this.user.profile_image = await this.$store.getters["users/url"]
        //   // update user with image url
        //   await this.$store.dispatch("users/update", this.user)
        //   this.user = await this.$store.getters["users/user"]
        // }
        this.finish();
        await this.goBack().then(() => {
          this.$store.dispatch('alerts/success', 'Update successfully.');
        });
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch('alerts/error', e.response.data.errors[0]);
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }

        if (this.user.id) {
          await this.$store.dispatch('users/destroy', this.user.id);
        }
      }
    },

    async goBack() {
      this.$router.push({
        name: 'จัดการพนักงาน',
        path: '/admin/list-users',
        params: {
          branchid: this.$route.params.branchid,
          page: {
            currentPage: this.pageNumber,
            size: this.pageSize,
          },
        },
      });
    },

    async get() {
      try {
        let params = {
          filter: {
            ...{ emp_id: this.id },
            ...{ private_code: localStorage.getItem('privatecode') },
          },
        };
        this.start();
        await this.$store.dispatch('salarys/salaryDecrypt', params).then(() => {
          let salary_data = this.$store.getters['salarys/salarydecrypt'];
          let salary_amt = this.$store.getters['salarys/salaryamt'];
          //JsonApi/v1
          this.$store.dispatch('users/get', this.id).then(
            () => {
              this.user = this.$store.getters['users/user'];
              let birth_date = moment(this.user.birth_date).format(
                'DD/MM/YYYY',
              );
              let start_date = moment(this.user.start_date).format(
                'DD/MM/YYYY',
              );
              if (this.user.finish_date != null) {
                var finish_date = moment(this.user.finish_date).format(
                  'DD/MM/YYYY',
                );
              }
              this.user.birth_date = birth_date;
              this.user.start_date = start_date;
              this.user.finish_date = finish_date;

              let department = {
                id: this.user.salarys[0].positions.dep_id,
                type: 'departments',
              };
              let position = {
                id: this.user.salarys[0].position_id,
                type: 'positions',
              };
              let workingtime = {
                id: this.user.employeehasworkingtimes[0].working_id,
                type: 'workingtimes',
                workingref: this.user.employeehasworkingtimes[0].id,
              };
              let branch = { id: this.user.branch_id, type: 'branchs' };
              let province = { id: this.user.province_id, type: 'provinces' };
              let distric = { id: this.user.district_id, type: 'districts' };
              let subdistric = {
                id: this.user.subdist_id,
                type: 'subdistricts',
              };

              let salary = {
                type: 'salarys',
                id: salary_data.id,
                //salary_amt: (this.private_code)?this.user.salarys[0].salary_amt:'*****',
                salary_amt: this.private_code ? salary_amt : '*****',
                status: salary_data.status,
              };

              this.user['departments'] = department;
              this.user['positions'] = position;
              this.user['workingtimes'] = workingtime;
              this.user['salarys'] = salary;
              this.user['provinces'] = province;
              this.user['districts'] = distric;
              this.user['subdistricts'] = subdistric;
              this.user['branchs'] = branch;
              this.user['email'] = this.user.emp_email;
              //this.user.password = "***";
              //this.user.password_confirmation = "***";
              if (this.user.status == 'active') {
                this.user.status = true;
                this.user.staff_st_msg = 'ทำงาน';
              } else {
                this.user.status = false;
                this.user.staff_st_msg = 'ลาออก';
              }
              if (this.user.canleave == 1) {
                this.user.canleave = true;
                this.user.canleave_msg = 'เปิดสิทธิ์';
              } else {
                this.user.canleave = false;
                this.user.canleave_msg = 'ปิดสิทธิ์';
              }
              //this.branchid = this.user.branch_id
              this.finish();
              this.is_getData = true;
            },
            () => {
              this.$Progress.fail();
            },
          );
        });
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
      }
    },

    async setBranchesDropdown() {
      this.branchs = this.$store.getters['branches/dropdown'];

      // this.is_getData = true;
      // this.finish();
    },

    start() {
      this.$Progress.start();
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
  },
};
</script>
<style lang="scss">
.spacing {
  padding-bottom: 1rem;
}
.tab-content {
  width: 100%;
}
.text-right {
  display: flex;
}
</style>
