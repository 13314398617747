import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

async function getApproverList(params) {
  const response = await axios.get(`${url}/v1/getapproverlist`, { params });
  return response.data;
}

async function getMemoList(params) {
  const response = await axios.get(`${url}/v1/getmemolist`, { params });
  return response.data;
}

async function getMemoDetail(params) {
  const response = await axios.get(`${url}/v1/getmemodetail`, { params });
  return response.data;
}

async function getMemoApprovalsList() {
  const response = await axios.get(`${url}/v1/get-memo-approvals-list`);
  return response.data;
}

async function getMemoPublished(params) {
  const response = await axios.get(`${url}/v1/get-memo-published`, { params });
  return response.data;
}

async function saveMemo(params) {
  const response = await axios.post(`${url}/v1/save-memo`, params);
  return response.data;
}

async function saveMemoPublish(params) {
  const response = await axios.post(`${url}/v1/save-memo-publish`, params);
  return response.data;
}

export default {
  getMemoList,
  getApproverList,
  getMemoDetail,
  getMemoApprovalsList,
  getMemoPublished,
  saveMemo,
  saveMemoPublish,
};
