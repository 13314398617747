import service from '@/store/services/staffweekends-service';

const state = {
  list: {},
  staffweekend: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, staffweekend) => {
    state.staffweekend = staffweekend;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  empweekendlist({ commit, dispatch }, params) {
    return service.empweekendlist(params).then((staffweekend) => {
      commit('SET_RESOURCE', staffweekend);
      return staffweekend;
    });
  },

  empweekendcreate({ commit, dispatch }, params) {
    return service.empweekendcreate(params).then((staffweekend) => {
      return staffweekend;
      // commit('SET_RESOURCE', staffweekend);
    });
  },
  empweekendEdit({ commit, dispatch }, params) {
    return service.empweekendEdit(params).then((data) => {
      return data;
    });
  },
  empweekendDelete({ commit, dispatch }, params) {
    return service.empweekendDelete(params).then((data) => {
      return data;
    });
  },
};

const getters = {
  list: (state) => state.list,
  staffweekend: (state) => state.staffweekend,
};

const staffweekends = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default staffweekends;
