import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function listYear(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  return axios.get(`${url}/v1/getyear-fromsm`, options).then((response) => {
    return response.data;
  });
}
function listMonth(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  return axios.get(`${url}/v1/getmonth-fromsm`, options).then((response) => {
    return response.data;
  });
}
function listMonthly(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  return axios.get(`${url}/v1/get-salary-monthly`, options).then((response) => {
    return response.data;
  });
}
function listPeriod(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };

  return axios.get(`${url}/v1/get-salary-period`, options).then((response) => {
    return response.data;
  });
}

function approverReport(params) {
  const payload = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: true });
    },
  };
  // const payload = jsona.serialize({
  //   stuff: params,
  //   includeNames: null,
  // });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios
    .post(`${url}/v1/approverreport`, payload, options)
    .then((response) => {
      return response.data;
    });
}

export default {
  listYear,
  listMonth,
  listMonthly,
  listPeriod,
  approverReport,
};
