import service from '@/store/services/provinces-service';

const state = {
  province: {},
  district: {},
  subdist: {},
};

const mutations = {
  SET_PROVINCE: (state, province) => {
    state.province = province;
  },
  SET_DISTRICT: (state, district) => {
    state.district = district;
  },
  SET_SUBDIST: (state, subdist) => {
    state.subdist = subdist;
  },
};

const actions = {
  listProvince({ commit, dispatch }, params) {
    return service.listProvince(params).then(({ list, meta }) => {
      commit('SET_PROVINCE', list);
      return list;
    });
  },

  listDistrict({ commit, dispatch }, params) {
    return service.listDistrict(params).then(({ list, meta }) => {
      commit('SET_DISTRICT', list);
      return list;
    });
  },

  listSubDistrict({ commit, dispatch }, params) {
    return service.listSubDistrict(params).then(({ list, meta }) => {
      commit('SET_SUBDIST', list);
      return list;
    });
  },
};

const getters = {
  //list: (state) => state.list,
  province: (state) => state.province,
  district: (state) => state.district,
  subdist: (state) => state.subdist,
  dropdownProvince: (state) => {
    return state.province.map((province) => ({
      id: province.id,
      name: province.name_in_thai,
    }));
  },
  dropdownDistrict: (state) => {
    return state.district.map((district) => ({
      id: district.id,
      name: district.name_in_thai,
      province: district.province_id,
    }));
  },
  dropdownSubDistrict: (state) => {
    return state.subdist.map((subdist) => ({
      id: subdist.id,
      name: subdist.name_in_thai,
      district: subdist.district_id,
      zip_code: subdist.zip_code,
    }));
  },
};

const provinces = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default provinces;
