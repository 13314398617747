import Vue from 'vue';

// Plugins import
import App from './App.vue';
// import Chartist from 'chartist';
import DashboardPlugin from './material-dashboard';
import VueProgressBar from 'vue-progressbar';

import router from './router';
import store from './store';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// import { createPopper } from '@popperjs/core';
import PortalVue from 'portal-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/css/main.css';

// import axios from 'axios';
// import VueAxios from 'vue-axios';

const options = {
  color: '#4CAF50',
  failedColor: '#874b4b',
  thickness: '10px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
  postiton: 'relative',
};

// plugin setup
Vue.use(DashboardPlugin);
// Vue.use(VueAxios, axios);
Vue.use(VueProgressBar, options);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(PortalVue);

// global library setup
// Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */
const app = new Vue({
  router: router,
  store: store,
  //filter: filter,
  el: '#app',
  render: (h) => h(App),
});

store.$app = app;
