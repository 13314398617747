<template>
  <md-card>
    <md-card-header
      class="md-card-header-icon md-card-header-green signContract-Sign"
    >
      <div class="card-icon">
        <md-icon>add_circle_outline</md-icon>
      </div>
      <h4 class="title"></h4>
      <div class="text-right">
        <md-button @click="goBack" class="md-primary md-dense">
          Back to List
        </md-button>
      </div>
    </md-card-header>
    <md-card-content>
      <div class="setsize">
        <Form-Contract :condata="con_data" v-if="showcon_add == true" />
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import $ from 'jquery';
import Form_Contract from '@/pages/Dashboard/Pages/Admin/Contract/Form_Contract.vue';

export default {
  components: {
    'Form-Contract': Form_Contract,
  },
  name: 'EditContract',
  data() {
    return {
      con_data: [],
      showcon_add: false,
    };
  },
  created() {
    this.ShowContract();
  },
  // mounted: function() {

  // },
  methods: {
    showfrom_add() {
      this.showcon_add = true;
    },
    goBack() {
      this.$router.push({
        path: 'form-contract',
        name: 'ฟอร์มสัญญาจ้าง',
      });
    },
    async ShowContract() {
      var obj = {
        con_id: this.$route.params.id,
      };
      var resp = await this.$store.dispatch('contract/searchContract', obj);
      this.con_data = resp;
      var obj = {
        con_id: this.$route.params.id,
      };
      var resp = await this.$store.dispatch('contract/searchContract', obj);
      this.con_data = resp;
      this.showfrom_add();
    },
  },
};
</script>
<style></style>
