import service from '@/store/services/timeinout-service';

const state = {
  timeinout: {},
  countworkday: {},
};

const mutations = {
  SET_RESOURCE: (state, timeinout) => {
    state.timeinout = timeinout;
  },
  // SET_COUNTWORK: (state, countworkday) => {
  //   state.countworkday = countworkday;
  // },
};

const actions = {
  timeinout({ commit, dispatch }, params) {
    return service.get(params).then((stafftime) => {
      commit('SET_RESOURCE', stafftime);
      return stafftime;
    });
  },

  get_timeinout({ commit, dispatch }, params) {
    return service.get_timeinout(params).then((stafftime) => {
      commit('SET_RESOURCE', stafftime);
      return stafftime;
    });
  },

  // exporttime({ commit, dispatch }, params) {
  //   return service.exporttime(params);
  // },

  rawtime({ commit, dispatch }, params) {
    return service.rawtime(params);
  },

  getcheck_scan({ commit, dispatch }, params) {
    return service.getcheck_scan(params);
  },

  addStafftimeinout({ commit, dispatch }, params) {
    return service.addStafftimeinout(params).then((stafftime) => {
      // commit('SET_RESOURCE', stafftime.list);
      return stafftime.list;
    });
  },
  processTimeWork({ commit, dispatch }, params) {
    return service.processTimeWork(params).then((stafftime) => {
      // commit('SET_RESOURCE', stafftime.list);
      return stafftime;
    });
  },
  getworkday({ commit, dispatch }, params) {
    return service.getWorkDay(params).then((stafftime) => {
      // commit('SET_COUNTWORK', stafftime.list);
    });
  },
  updateTimeMachin({ commit, dispatch }, params) {
    return service.updateTimeMachin(params).then((data) => {
      return data;
    });
  },
  deleteTimeMachin({ commit, dispatch }, params) {
    return service.deleteTimeMachin(params).then((data) => {
      return data;
    });
  },
  addfingerscan({ commit, dispatch }, params) {
    return service.addfingerscan(params).then((data) => {
      return data;
    });
  },
  get_search_fingerscan({ commit, dispatch }, params) {
    return service.get_search_fingerscan(params);
  },
  updatefingerscan({ commit, dispatch }, params) {
    return service.updatefingerscan(params).then((data) => {
      return data;
    });
  },
  deletefingerscan({ commit, dispatch }, params) {
    return service.deletefingerscan(params).then((data) => {
      return data;
    });
  },
};

const getters = {
  timeinout: (state) => state.timeinout,
  countworkday: (state) => state.countworkday,
};

const stafftime = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default stafftime;
