<template>
  <div>
    <md-dialog class="edit-data" :md-active.sync="showSetting" md-fullscreen>
      <md-dialog-title style="font-size: 1.5rem">
        <!-- กรอกรายได้และค่าใช้จ่าย -->
        กรอก - แก้ไขข้อมูล
      </md-dialog-title>
      <md-dialog-content v-if="emp_setting" style="font-size: 1.1rem">
        <div
          class="md-layout md-gutter"
          v-if="permissions.includes('editdata')"
        >
          <div class="md-layout-item md-size-100 md-small-size-100">
            รหัสพนักงาน : {{ emp_setting.emp_code }} <br />
            ชื่อพนักงาน :
            {{ emp_setting.firstname_th + ' ' + emp_setting.lastname_th }}
            <!-- <br />
            วันที่เริ่ม - สิ้นสุด :
            {{ moment(fromdate).format('DD/MM/YYYY') }} -
            {{ moment(todate).format('DD/MM/YYYY') }} -->
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card>
              <div class="header" @click="headdropdown($event)">
                <md-card-header
                  class="md-card-header-icon md-card-header-danger"
                >
                  <div class="card-icon">
                    <md-icon>schedule</md-icon>
                  </div>
                  <h4 class="title">กะการทำงาน</h4>
                </md-card-header>
              </div>
              <md-card-content class="contentleave">
                <working-time-edit
                  :permissions="permissions"
                  :employeeID="emp_setting.emp_id"
                />
              </md-card-content>
            </md-card>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card>
              <div class="header" @click="headdropdown($event)">
                <md-card-header
                  class="md-card-header-icon md-card-header-green"
                >
                  <div class="card-icon">
                    <md-icon>assignment</md-icon>
                  </div>
                  <h4 class="title">บันทึกการลา</h4>
                </md-card-header>
              </div>
              <md-card-content class="contentleave">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  รหัสพนักงาน : {{ emp_setting.emp_code }} <br />
                  ชื่อพนักงาน :
                  {{ emp_setting.firstname_th + ' ' + emp_setting.lastname_th }}
                  <br />
                  <!-- <br />
                  วันที่เริ่ม - สิ้นสุด :
                  {{ moment(fromdate).format('DD/MM/YYYY') }} -
                  {{ moment(todate).format('DD/MM/YYYY') }} -->
                </div>
                <leave-edit :persons="emp_setting.emp_id" />
              </md-card-content>
            </md-card>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card>
              <div class="header" @click="headdropdown($event)">
                <md-card-header class="md-card-header-icon md-card-header-blue">
                  <div class="card-icon">
                    <md-icon>update</md-icon>
                  </div>
                  <h4 class="title">ทำงานล่วงเวลา (OT)</h4>
                </md-card-header>
              </div>
              <md-card-content class="contentleave">
                <otrequestpage :persons="emp_setting.emp_id" :typecreate="1" />
              </md-card-content>
            </md-card>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card>
              <div class="header" @click="headdropdown($event)">
                <md-card-header
                  class="md-card-header-icon md-card-header-warning"
                >
                  <div class="card-icon">
                    <md-icon>directions_boat</md-icon>
                  </div>
                  <h4 class="title">เที่ยวเรือ และค่าการแสดง</h4>
                </md-card-header>
              </div>
              <md-card-content class="contentleave">
                <otrequestcharter
                  :persons="emp_setting.emp_id"
                  :typecreate="1"
                />
              </md-card-content>
            </md-card>
          </div>
          <div
            class="md-layout-item md-size-100 md-small-size-100"
            v-if="
              permissions.includes('process salary') ||
                permissions.includes('view flatreat')
            "
          >
            <md-card>
              <div class="header" @click="headdropdown($event)">
                <md-card-header
                  class="md-card-header-icon md-card-header-primary"
                >
                  <div class="card-icon">
                    <md-icon>spa</md-icon>
                  </div>
                  <h4 class="title">ค่านวดสปา</h4>
                </md-card-header>
              </div>
              <md-card-content class="contentleave">
                <SpaRequestCharter
                  :persons="emp_setting.emp_id"
                  :typecreate="1"
                />
              </md-card-content>
            </md-card>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showSetting = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon style="transform: scaleX(-1);">
                manage_accounts
              </md-icon>
            </div>
            <h5 class="title">จัดการข้อมูล</h5>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
              >
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-70 mt-2 md-small-size-100 md-xsmall-size-100 md-alignment-left"
                  >
                    <filter-employee
                      v-if="permissions.length > 0"
                      :permissionsList="permissions"
                      @childBranches="setBranchs"
                      @childDepartment="setDepartments"
                      @childStaffs="setStaffs"
                    />
                  </div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                </div>
              </div>
            </div>
            <md-table
              :value="tableData"
              class="paginated-table table-striped table-hover"
              table-header-color="green"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="รหัสพนักงาน">
                  <span class="upfont">
                    {{ item.emp_code }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="ชื่อพนักงาน">
                  <span class="upfont">
                    {{ item.first_nameth }}
                    {{ item.last_nameth }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="Action">
                  <b-button variant="success" @click="getdata(item)">
                    จัดการ
                  </b-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import _ from 'lodash';
import Vuex from 'vuex';
import FilterEmployee from '@/components/FilterEmployee';
import OTRequestPage from '@/pages/Dashboard/Pages/Staff/Transactions/OT/OTRequestPage.vue';
import OTRequestCharter from '@/pages/Dashboard/Pages/Staff/Transactions/OTFlatRateSpa/OTRequestCharter.vue';
import SpaRequestCharter from '@/pages/Dashboard/Pages/Staff/Transactions/OTFlatRateSpa/SpaRequestCharter.vue';
import WorkingTimeEdit from '@/pages/Dashboard/Pages/Staff/Transactions/Working/WorkingTimeEdit.vue';
import LeaveEdit from '@/pages/Dashboard/Pages/Staff/Leave/LeaveEdit.vue';
import { loading_start, loading_close } from '@/utils/loading.js';
import moment from 'moment';

export default {
  components: {
    'filter-employee': FilterEmployee,
    'otrequestpage': OTRequestPage,
    'otrequestcharter': OTRequestCharter,
    'SpaRequestCharter': SpaRequestCharter,
    'working-time-edit': WorkingTimeEdit,
    'leave-edit': LeaveEdit,
  },
  data() {
    return {
      editData: {},
      tableData: [],
      sortation: {
        field: 'created_at',
        order: 'asc',
      },
      boolean: true,
      status: 'active',
      // permissions: [],
      staffs: null,
      employee_id: null,
      departments: null,
      branchs: null,
      employee: null,
      showSetting: false,
      emp_setting: null,
    };
  },
  created() {
    // this.setPermission(this.me);
  },
  watch: {
    departments: function(val) {
      if (val != null) {
        this.getListEmp();
      }
    },
    employee_id: function(val) {
      if (val != null) {
        this.getListEmp();
      }
    },
    // me: {
    //   handler: function(val) {
    //     if (val != null) {
    //       val.permissions.map((permis) => {
    //         this.permissions.push(permis.name);
    //       });
    //       val.roles[0].permissions.map((permission) => {
    //         this.permissions.push(permission.name);
    //       });
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var per = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          per.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          per.push(permission.name);
        });
      }
      return per;
    },
  },
  methods: {
    headdropdown(event) {
      var con = $(event.target).closest('.md-layout-item');
      var content = $('.contentleave', con);
      content.slideToggle();
      content.css('display', 'grid');
    },
    async getListEmp() {
      loading_start();
      let params = {
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{ dep_id: this.departments ? this.departments : {} },
        ...{ emp_id: this.employee_id ? this.employee_id : {} },
      };
      this.tableData = await this.$store.dispatch('staffs/searchStaff', params);
      loading_close();
    },

    async getdata(emp) {
      if (emp == null) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return false;
      }
      this.emp_setting = emp;
      this.showSetting = true;
    },

    // set branch value from child component
    async setBranchs(v) {
      if (v && v != null) {
        this.branchs = v;
      }
    },
    // set departments value from child component
    async setDepartments(v) {
      if (v && v != []) {
        this.departments = v;
      }
    },
    // set staffs value from child component
    async setStaffs(v) {
      if (v && v != []) {
        this.employee_id = v;
      } else {
        this.employee_id = null;
      }
    },

    // setPermission(val) {
    //   val.permissions.map((permis) => {
    //     this.permissions.push(permis.name);
    //   });
    //   val.roles[0].permissions.map((permission) => {
    //     this.permissions.push(permission.name);
    //   });
    // },
  },
};
</script>
<style>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
</style>
