<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>add_circle_outline</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>

          <form @submit.prevent="handleSubmit(add)">
            <md-card-content>
              <div class="text-right">
                <md-button @click="goBack" class="md-primary md-dense">
                  Back to List
                </md-button>
              </div>

              <div class="md-layout">
                <div class="md-layout-item md-size-40 mt-2 md-small-size-100">
                  <ValidationProvider
                    name="role.name"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <md-field
                      class="md-invalid"
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Name</label>
                      <md-input v-model="role.name" />
                      <validation-error :errors="apiValidationErrors.name" />
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
            </md-card-content>

            <md-card-actions md-alignment="right">
              <md-button type="submit" class="md-success">Add Role</md-button>
            </md-card-actions>
          </form>
        </md-card>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { ValidationError } from '@/components';
import formMixin from '@/mixins/form-mixin';

extend('required', required);
export default {
  components: { ValidationError },

  mixins: [formMixin],

  data: () => ({
    role: {
      type: 'roles',
      name: null,
    },
  }),

  methods: {
    async add() {
      try {
        await this.$store.dispatch('roles/add', this.role);
        await this.$store.dispatch(
          'alerts/success',
          'Role added successfully.',
        );
        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            'alerts/error',
            e.response.data.errors[0].title,
          );
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },

    goBack() {
      this.$router.push({ name: 'List Roles' });
    },
  },
};
</script>
